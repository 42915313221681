import { useTranslation } from 'react-i18next';
import { PermissionCode } from 'src/store/api/userPermissionsApi';
import { PermissionGroupCode } from '../../../model/types';

export default function usePermissionsTranslations() {
  const { t } = useTranslation();

  const groupTitleNames: Record<PermissionGroupCode, string> = {
    SETTINGS: t('companySettings.sidebar.departments.permissions.settings'),
    TEAM: t('companySettings.sidebar.departments.permissions.team'),
    GENERAL: t('companySettings.sidebar.departments.permissions.general'),
    ADMIN_PERMISSIONS: t('companySettings.sidebar.departments.permissions.admin'),
  };

  const permissionNames: Record<PermissionCode, { name: string; description?: string }> = {
    MANAGE_DEPARTMENTS: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.manageDepartments'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.manageDepartments'
      ),
    },
    MANAGE_PERMISSIONS: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.managePermissions'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.managePermissions'
      ),
    },
    VIEW_EMPLOYEES: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.viewEmployees'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.viewEmployees'
      ),
    },
    MANAGE_EMPLOYEES: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.manageEmployees'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.manageEmployees'
      ),
    },
    VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS: {
      name: t(
        'companySettings.sidebar.departments.permissions.permissionName.viewTrainingProgress'
      ),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.viewTrainingProgress'
      ),
    },
    ADD_PAGES: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.addPages'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.addPages'
      ),
    },
    ADD_EMBEDDED_APPS: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.addEmbeddedApps'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.addEmbeddedApps'
      ),
    },
    ADD_TRAININGS: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.addTrainings'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.addTrainings'
      ),
    },
    ADD_WIKIS: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.addWikis'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.addWikis'
      ),
    },
    ADMIN_ACCESS_DASHBOARD_AND_PAGES: {
      name: t(
        'companySettings.sidebar.departments.permissions.permissionName.adminAccessToDashboardsPages'
      ),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.adminAccessToDashboardsPages'
      ),
    },
    ADMIN_ACCESS_FILES: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.adminAccessToFiles'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.adminAccessToFiles'
      ),
    },
    ADMIN_ACCESS_WHITEBOARDS: {
      name: t(
        'companySettings.sidebar.departments.permissions.permissionName.adminAccessToWhiteboards'
      ),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.adminAccessToWhiteboards'
      ),
    },
    ADMIN_ACCESS_EMBEDDED_APPS: {
      name: t(
        'companySettings.sidebar.departments.permissions.permissionName.adminAccessToEmbeddedApps'
      ),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.adminAccessToEmbeddedApps'
      ),
    },
    ADMIN_ACCESS_TRAININGS: {
      name: t(
        'companySettings.sidebar.departments.permissions.permissionName.adminAccessToTrainings'
      ),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.adminAccessToTrainings'
      ),
    },
    ADMIN_ACCESS_WIKIS: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.adminAccessToWikis'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.adminAccessToWikis'
      ),
    },
    MANAGE_ALL_COMPANY_SETTINGS: {
      name: t(
        'companySettings.sidebar.departments.permissions.permissionName.manageAllCompanySettings'
      ),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.manageAllCompanySettings'
      ),
    },
    MANAGE_BILLING: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.accessToBilling'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.accessToBilling'
      ),
    },
    ADD_WHITEBOARDS: {
      name: t('companySettings.sidebar.departments.permissions.permissionName.addWhiteboards'),
      description: t(
        'companySettings.sidebar.departments.permissions.permissionDescription.addWhiteboards'
      ),
    },
  };

  return { groupTitleNames, permissionNames };
}
