import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useCreateThreadMutation, useFetchMessagesQuery } from 'src/store/api/aiChatApi';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';

export default function useFetchChats(dispatch: React.Dispatch<any>, threadId: string | null) {
  const [createThread] = useCreateThreadMutation();
  const { t } = useTranslation();
  const { data, error, isSuccess, isFetching } = useFetchMessagesQuery(threadId as string, {
    skip: !threadId,
    refetchOnMountOrArgChange: true,
  });

  useEffect(() => {
    if (isFetching) {
      dispatch({ type: 'SET_CHAT_LOADING', payload: true });
    } else {
      dispatch({ type: 'SET_CHAT_LOADING', payload: false });
    }
  }, [isFetching, dispatch]);

  useEffect(() => {
    dispatch({ type: 'SET_CHAT_LOADING', payload: true });
    const handleCreateThread = async () => {
      try {
        const response = await createThread().unwrap();
        dispatch({ type: 'SET_SELECTED_CHAT', payload: response.id });
      } catch (createError) {
        showNotification(t('assistant.notifications.errorCreatingThread'), 'error');
      }
    };

    if (!threadId) {
      handleCreateThread();
    }

    if (isSuccess && data) {
      const formattedMessages = data.messages.map((msg: any) => ({
        role: msg.role,
        createdAt: msg.createdAt,
        content: msg.content,
        id: msg.id,
      }));
      dispatch({ type: 'SET_INITIAL_MESSAGES', payload: formattedMessages });
      dispatch({ type: 'SET_CHAT_LOADING', payload: false });
    }

    if (error) {
      if ('status' in error && error.status === 404 && threadId) {
        handleCreateThread().then(() => {
          dispatch({ type: 'SET_CHAT_LOADING', payload: false });
        });
      }
    }
  }, [threadId, data, isSuccess, error, createThread, dispatch, t]);
}
