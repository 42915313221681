import { Box, ListItemIcon, Stack, ToggleButton, Typography } from '@mui/material';
import { t } from 'i18next';
import SvgIcon from '@mui/material/SvgIcon';
import { WorkbaseAIIcon } from 'src/assets/icons/workbaseIcons';
import { useContext, useCallback, useEffect } from 'react';
import { ChatContext } from 'src/features/ai-chat/helpers/chatContext';
import { useFetchSettingsQuery } from 'src/store/api/aiChatApi';
import { useNavigate } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';

export default function AiButton({ labelVisible }: { labelVisible: boolean }) {
  const isMac = navigator.platform.toUpperCase().indexOf('MAC') >= 0;
  const navigate = useNavigate();
  const { state, dispatch } = useContext(ChatContext);
  const { data: chatSettings } = useFetchSettingsQuery();
  const isMobile = useIsMobile();
  const handleOpenChat = useCallback(() => {
    if (isMobile) {
      navigate(`/ai/${state.threadId}`);
      return undefined;
    }
    if (state.isFullScreen) {
      dispatch({ type: 'CLOSE_FULLSCREEN' });
      dispatch({ type: 'CLOSE_DIALOG' });
      navigate(state.locationBeforeFullscreen ?? '/');
    } else {
      dispatch({ type: 'TOGGLE_DIALOG' });
    }
    return undefined;
  }, [
    dispatch,
    state.isFullScreen,
    isMobile,
    navigate,
    state.locationBeforeFullscreen,
    state.threadId,
  ]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (
        (isMac && event.metaKey && event.key === 'k') ||
        (!isMac && event.ctrlKey && event.key === 'k')
      ) {
        event.preventDefault();
        handleOpenChat();
      } else if (event.key === 'Escape' && !state.isFullScreen) {
        event.preventDefault();
        dispatch({ type: 'CLOSE_DIALOG' });
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [state.isFullScreen, dispatch, handleOpenChat, isMac]);

  return (
    <ToggleButton
      selected={state.isOpen || window.location.pathname.startsWith('/ai')}
      value="ai"
      size="small"
      color="primary"
      sx={{
        display: 'flex',
        justifyContent: 'space-between',
        pl: 1.5,
      }}
      onClick={handleOpenChat}
    >
      <Stack direction="row" alignItems="center" maxWidth="70%" color="inherit">
        <ListItemIcon sx={{ color: 'inherit' }}>
          <SvgIcon component={WorkbaseAIIcon} fontSize="small" htmlColor="inherit" />
        </ListItemIcon>
        <Typography
          variant="body2"
          fontWeight="medium"
          noWrap
          sx={{ visibility: labelVisible ? 'visible' : 'hidden' }}
        >
          {chatSettings?.name || t('sidebar.assistant')}
        </Typography>
      </Stack>
      {!isMobile && (
        <Stack
          direction="row"
          alignItems="center"
          gap={0.5}
          visibility={labelVisible ? 'visible' : 'hidden'}
        >
          <Box bgcolor="action.selected" px={0.75} borderRadius={0.5}>
            <Typography variant="caption" color="text.secondary">
              {isMac ? '⌘' : 'Ctrl'}
            </Typography>
          </Box>
          <Box bgcolor="action.selected" px={0.75} borderRadius={0.5}>
            <Typography variant="caption" color="text.secondary">
              K
            </Typography>
          </Box>
        </Stack>
      )}{' '}
    </ToggleButton>
  );
}
