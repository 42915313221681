import { ChangeEvent } from 'react';
import { Avatar, Box, Stack, Typography } from '@mui/material';
import { usePatchCurrentUserMutation } from 'src/store/api/currentUserApi';
import { useTranslation } from 'react-i18next';
import { imageUpload } from 'src/lib/utils/imageUpload';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';

export default function UploadAvatar({ text, url }: { text: string; url: string | null }) {
  const { t } = useTranslation();
  const [patchCurrentUser] = usePatchCurrentUserMutation();

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<unknown> => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const response = await imageUpload(file);
        const { id, variants } = response.data.result;
        return await patchCurrentUser({ avatarId: id, avatarUrl: variants[0] });
      } catch (err) {
        showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
      }
    }
    return undefined;
  };

  const removeImage = (): Promise<unknown> => patchCurrentUser({ avatarId: null, avatarUrl: '' });

  return (
    <Stack gap={2} flexDirection="row" alignItems="center">
      <Box width={92} height={92} minWidth={92}>
        <EditableImageUpload
          src={url || ''}
          altText={text}
          placeholder={<Avatar />}
          onChange={handleFileChange}
          onClear={removeImage}
        />
      </Box>
      <Box>
        <Typography variant="body1" fontWeight={700} lineHeight={1.2}>
          {t('generalSettings.information.profileInformation.avatarTitle')}
        </Typography>
        <Typography
          variant="body1"
          sx={{
            fontSize: '.75rem',
            color: 'grey.500',
            width: 'auto',
          }}
        >
          {t('generalSettings.information.profileInformation.avatarDescription')}
        </Typography>
      </Box>
    </Stack>
  );
}
