import { memo } from 'react';
import {
  Chip,
  IconButton,
  Paper,
  Stack,
  SvgIcon,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { formatDateFromTimeStamp } from 'src/lib/utils/format-time';
import { WorkbaseDeleteSquareIcon, WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';

interface Data {
  id: string;
  name: string;
  createdAt: string;
  expiredAt: string;
  status: 'active' | 'expired';
}

export interface ApiKeysTableProps {
  rows: Data[];
  columnNames: string[];
  onDelete?: (id: string, name: string) => void;
  onEdit?: (id: string, name: string, expiredAt: string) => void;
}

function ApiKeysTable({ rows, columnNames, onDelete, onEdit }: ApiKeysTableProps) {
  const { t } = useTranslation();
  return (
    <Paper component={Stack} overflow="auto" maxHeight="100%" variant="outlined">
      <Table>
        <TableHead>
          <TableRow>
            {columnNames.map((column, index) => (
              <TableCell align={index === 0 ? 'left' : 'center'} key={column}>
                {column}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow hover key={row.id}>
              <TableCell>{row.name}</TableCell>
              <TableCell align="center">{formatDateFromTimeStamp(row.createdAt)}</TableCell>
              <TableCell align="center">
                <Typography color={!row.expiredAt ? 'text.secondary' : 'text.primary'}>
                  {!row.expiredAt
                    ? t('companySettings.apiKeys.table.noExpiry')
                    : formatDateFromTimeStamp(row.expiredAt)}
                </Typography>
              </TableCell>
              <TableCell align="center">
                <Chip
                  label={t(`companySettings.apiKeys.table.${row.status}`)}
                  color={row.status === 'active' ? 'success' : 'default'}
                />
              </TableCell>
              <TableCell>
                <Stack direction="row" justifyContent="end">
                  <IconButton
                    data-cy="delete-api-key-button"
                    onClick={() => onDelete?.(row.id, row?.name)}
                  >
                    <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
                  </IconButton>
                  <IconButton
                    data-cy="edit-api-key-button"
                    aria-label="edit"
                    onClick={() => onEdit?.(row.id, row?.name, row?.expiredAt)}
                  >
                    <SvgIcon component={WorkbaseEditIcon} fontSize="small" />
                  </IconButton>
                </Stack>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Paper>
  );
}

export default memo(ApiKeysTable);
