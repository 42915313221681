import { Box, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseTrainingsIcon } from 'src/assets/icons/workbaseIcons';

export default function EmptyTrainingProgressPlaceholder() {
  const { t } = useTranslation();

  return (
    <Container component={Box} height="100%" maxWidth="xs">
      <Stack gap={0.75} alignItems="center" justifyContent="center" height="100%">
        <SvgIcon component={WorkbaseTrainingsIcon} fontSize="large" color="inherit" />
        <Typography color="textPrimary" variant="subtitle1" align="center">
          {t('employeePage.progress.emptyProgressTitle')}
        </Typography>
        <Typography color="textSecondary" variant="body1" align="center">
          {t('employeePage.progress.emptyProgressText')}
        </Typography>
      </Stack>
    </Container>
  );
}
