import { Grid2 } from '@mui/material';
import { useDrag } from 'react-dnd';
import { useAppDispatch } from 'src/store';
import { useEffect } from 'react';
import { GridItemType, GridRow } from '../../model/types';
import DroppableInBetweenRowItem from './droppable-items/DroppableInBetweenRowItem';
import { COLUMN_COUNT } from './constants';
import useElementConfigs from './useElementConfigs';
import { setIsDragging } from '../../controller';
import DroppableEmptyCell from './droppable-items/DroppableEmptyCell';

function hasGapAfter(row: GridItemType[], item: GridItemType): boolean {
  const itemColEnd = item.columnEnd;
  const itemAfter = row.find(({ columnStart }) => columnStart === itemColEnd);
  return !itemAfter;
}

export default function GridCell({
  item,
  cols = 1,
  readOnly,
  rowIndex,
  row,
}: {
  item: GridItemType;
  cols?: number;
  readOnly: boolean;
  rowIndex: number;
  row: GridRow;
}) {
  const dispatch = useAppDispatch();
  const [{ isDragging }, dragRef, previewRef] = useDrag({
    type: 'GRID_ITEM',
    item: {
      rowIndex,
      item,
      row,
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    end: () => {
      if (item) {
        const { activeElement } = document;
        dispatch(setIsDragging(false));
        const getBtnElement = (): HTMLButtonElement | undefined | null =>
          document.getElementById(item?.id)?.querySelector('.editor-item-button');
        const btn = getBtnElement();
        if (btn && activeElement !== document.body) {
          btn.style.transitionDuration = '0.15s';
          btn.style.transform = 'translate(0,0)';
        }
      }
    },
  });

  useEffect(() => {
    if (isDragging) {
      dispatch(setIsDragging(true));
    } else {
      dispatch(setIsDragging(false));
    }
  }, [isDragging, dispatch]);

  useEffect(() => {}, []);

  const elementConfigs = useElementConfigs(row.id);

  const element = elementConfigs[item.elementType];

  if (!element) return null;

  const { render, fullWidth } = element;
  const isLastItem = item.columnEnd === COLUMN_COUNT + 1;

  return (
    <Grid2
      className="grid-cell"
      id={item.id}
      key={item.id}
      size={fullWidth ? 12 : cols * 3}
      zIndex={COLUMN_COUNT - item.columnStart + 1}
      position="relative"
      sx={{
        '& > *:nth-child(1)': {
          opacity: isDragging ? 0 : 1,
        },
      }}
    >
      {render({
        item,
        readOnly,
        dragging: {
          dragRef,
          previewRef,
        },
      })}
      {cols && !readOnly && (
        <Grid2
          container
          sx={{
            position: 'absolute',
            left: 0,
            top: 0,
            bottom: 0,
            right: 0,
            pointerEvents: isDragging ? 'auto' : 'none',
          }}
        >
          {new Array(cols).fill(0).map((_, i) => (
            <DroppableEmptyCell row={row} colStart={item.columnStart + i} size={12 / cols} />
          ))}
        </Grid2>
      )}
      {!isLastItem && !hasGapAfter(row.items, item) && !isDragging && (
        <DroppableInBetweenRowItem itemColumnEnd={item.columnEnd} rowId={row.id} />
      )}
    </Grid2>
  );
}
