/* eslint-disable max-statements */
import { isEqual, isObject, transform } from 'lodash';
import {
  SvgIcon,
  ListItemAvatar,
  Avatar,
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  DialogContent,
} from '@mui/material';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { useAppSelector } from 'src/store';
import useOnLeaveViewBlocker from 'src/lib/hooks/useBlocker';
import { WorkbaseEmailIcon, WorkbaseShareIcon } from 'src/assets/icons/workbaseIcons';
import {
  useEmployeesQuery,
  useEmployeesTrainingProgressQuery,
} from 'src/store/api/employees/employeesApi';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import CircularProgressWithContent from 'src/ui-components/custom/progress/CircularProgressWithContent';
import SplitPaneLayout from 'src/layouts/SplitPaneLayout';
import Sidebar from 'src/ui-components/layout/sidebar/Sidebar';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import CircularProgressWithLabel from 'src/ui-components/custom/progress/CircularProgressWithLabel';
import { Employee, EmployeeStatuses } from '../model';
import EmployeeDetails from './employee-detials';
import Subtext from './Subtext';
import { SaveCancelChangesButtons } from './employee-detials/SaveCancelChangesButtons';
import NewEmployeeDialog from './new-employee/NewEmployeeDialog';

const findDifferences = (obj1: any, obj2: any) =>
  transform(obj1, (result: any, value: any, key: string | number) => {
    if (!isEqual(value, obj2[key])) {
      // eslint-disable-next-line
      result[key] =
        isObject(value) && isObject(obj2[key]) ? findDifferences(value, obj2[key]) : value;
    }
  });

const findRolesDifferences = <T extends object>(
  base: T,
  modified: Partial<T> | null | undefined
): Partial<T> => {
  if (!modified) return {};
  const differences = Object.entries(modified)
    .filter(([key, value]) => key === 'roles' && !isEqual(value, base[key as keyof T]))
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  return differences;
};

export default function Employees() {
  const { t } = useTranslation();
  const {
    data,
    isLoading,
    isSuccess,
    refetch: employeesRefetch,
  } = useEmployeesQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const { data: progressData } = useEmployeesTrainingProgressQuery();
  const location = useLocation();
  const navigate = useNavigate();
  const isEditPath = location.pathname.includes('/edit');
  const { data: currentUserData } = useCurrentUserQuery();
  const employeeDraft = useAppSelector((state) => state.employee.employee);
  const { pathname } = useLocation();
  const hasAnyPermission = useHasAnyPermission();
  const hasManageEmployeePermission = hasAnyPermission(['MANAGE_EMPLOYEES']);
  const hasViewProgressPermission = hasAnyPermission(['VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS']);
  const hasEditingPermissions = currentUserData?.isOwner;
  const pathArray = pathname.split('/').filter(Boolean);
  const idIndex = pathArray.indexOf('team') + 1;
  const id = idIndex !== 0 ? pathArray[idIndex] : '';

  const newEmployeeDialogShow = useBoolean();
  const headerIcon = {
    text: t('sidebar.tabTitleAddButton'),
    icon: WorkbaseShareIcon,
    onClick: () => newEmployeeDialogShow.onTrue(),
  };

  const readOnlyCondition = !hasEditingPermissions
    ? !isEditPath || !hasManageEmployeePermission
    : !isEditPath || !hasEditingPermissions;

  const currentEmployee = isSuccess ? data?.find((emp) => emp.id === id) : undefined;

  const changedData: Partial<Employee> | null =
    currentEmployee && employeeDraft
      ? {
          ...findDifferences(employeeDraft, currentEmployee),
          ...findRolesDifferences(currentEmployee, employeeDraft),
        }
      : null;

  const { showModal, handleConfirm, handleCancel } = useOnLeaveViewBlocker(
    !!(isEditPath && changedData && Object.keys(changedData).length !== 0)
  );

  if (isLoading)
    return (
      <LottieLogoSpinner
        sx={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)' }}
      />
    );

  if (!data) return null;

  const employeeItems = data.map((employee) => {
    const employeeProgress = progressData?.find((prog) => prog.id === employee.id);
    const progressValue = employeeProgress ? Number(employeeProgress.progress) : 0;
    const progressComponent = <CircularProgressWithLabel value={progressValue} />;

    return {
      key: employee.id,
      text:
        employee.firstName || employee.lastName
          ? `${employee.firstName} ${employee.lastName}`
          : employee.email,
      subtext: <Subtext isOwner={employee.isOwner} roles={employee.roles} />,
      prefix: (
        <ListItemAvatar>
          <Avatar src={employee.avatarUrl} />
        </ListItemAvatar>
      ),
      sufix:
        (hasManageEmployeePermission || hasViewProgressPermission) &&
        (employee.status !== EmployeeStatuses.invited ? (
          progressComponent
        ) : (
          <CircularProgressWithContent
            circularProgressProps={{
              variant: 'determinate',
              value: 100,
              color: 'secondary',
            }}
          >
            <SvgIcon component={WorkbaseEmailIcon} fontSize="small" color="secondary" />
          </CircularProgressWithContent>
        )),
      onClick: () => navigate(employee.id, { relative: 'route' }),
    };
  });

  const employeePath =
    !data || data.length === 0 ? `/` : `/team/${currentEmployee?.id ?? data[0].id}`;
  const renderEmployeeDetails = (
    <>
      <EmployeeDetails
        employee={currentEmployee}
        readOnly={readOnlyCondition}
        employeesRefetch={employeesRefetch}
        hasManageEmployeePermission={!!hasManageEmployeePermission}
        hasViewProgressPermission={!!hasViewProgressPermission}
        progressData={progressData}
      />
      {(hasEditingPermissions || hasManageEmployeePermission) && (
        <SaveCancelChangesButtons
          isEditPath={isEditPath}
          currentEmployee={currentEmployee}
          changedData={changedData}
          id={id}
        />
      )}
    </>
  );

  return (
    <>
      <SplitPaneLayout
        leftContent={
          <Sidebar
            dataCy="employees-sidebar"
            items={employeeItems}
            activeKey={id}
            headerIcon={hasManageEmployeePermission ? headerIcon : undefined}
          />
        }
        rightContent={
          <Routes>
            <Route path="*" element={<Navigate to={employeePath} replace />} />
            <Route path=":id" element={renderEmployeeDetails} />
            <Route path=":id/edit" element={renderEmployeeDetails} />
          </Routes>
        }
      />
      <Dialog open={showModal}>
        <DialogTitle>
          {t('employeePage.employeeDetails.contactInfomation.cancelQuestion')}
        </DialogTitle>
        <DialogContent>{t('changesMayNotBeSaved')}</DialogContent>
        <DialogActions>
          <Button color="inherit" onClick={handleCancel}>
            {t('cancel')}
          </Button>
          <Button color="error" onClick={() => handleConfirm()}>
            {t('proceed')}
          </Button>
        </DialogActions>
      </Dialog>
      {(hasEditingPermissions || hasManageEmployeePermission) && (
        <NewEmployeeDialog
          open={newEmployeeDialogShow.value}
          onClose={newEmployeeDialogShow.onFalse}
        />
      )}
    </>
  );
}
