import { Box, styled } from '@mui/material';

const MaskedScrollBox = styled(Box)`
  max-height: 100%;
  width: 100%;
  min-height: 0;
  mask-image: ${({ theme }) =>
    `linear-gradient(
    180deg,
    transparent 0%,
    ${theme.palette.common.black} 3%,
    ${theme.palette.common.black} 97%,
    transparent 100%
  )`};
  padding: ${({ theme }) => theme.spacing(2)} ${({ theme }) => theme.spacing(0.25)};
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;
  scrollbar-width: none;
`;

export default MaskedScrollBox;
