import { Components, Theme } from '@mui/material';

const MuiListItemIcon: Components<Theme>['MuiListItemIcon'] = {
  styleOverrides: {
    root: {
      minWidth: '0 !important',
      marginRight: '12px',
      '&:last-child': {
        marginRight: 0,
      },
    },
  },
};

export default MuiListItemIcon;
