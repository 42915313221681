import { Components, Theme } from '@mui/material';
import { WorkbaseArrowDownIcon } from 'src/assets/icons/workbaseIcons';

const MuiAccordionSummary: Components<Theme>['MuiAccordionSummary'] = {
  defaultProps: {
    expandIcon: <WorkbaseArrowDownIcon />,
  },
  styleOverrides: {
    root: {
      '.MuiAccordionSummary-content': {
        minWidth: 0,
      },
    },
  },
};

export default MuiAccordionSummary;
