import throttle from 'lodash.throttle';
import { enqueueSnackbar } from 'notistack';

type Types = 'success' | 'error' | 'info' | 'default' | 'warning';

export const showNotification = (message: string, type: Types = 'success') =>
  enqueueSnackbar({ message, variant: type });

export const throttledShowNotification = throttle((message: string, type: Types = 'success') => {
  showNotification(message, type);
}, 1000);
