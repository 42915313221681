import { Box, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseTwoEmployeesIcon } from 'src/assets/icons/workbaseIcons';
import Empty from 'src/ui-components/custom/empty/Empty';

export default function EmptyEmployeeRolesListPlaceholder() {
  const { t } = useTranslation();
  return (
    <Box>
      <Empty
        icon={WorkbaseTwoEmployeesIcon}
        description={t('companySettings.sidebar.departments.employeePopup.placeholderTitle')}
      >
        <Typography color="action" variant="body2" textAlign="center">
          {t('companySettings.sidebar.departments.employeePopup.placeholderMessage')}
        </Typography>
      </Empty>
    </Box>
  );
}
