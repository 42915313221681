declare global {
  interface Window {
    Chargebee: any;
  }
}

export function handleOpenBillingSettingsPopup() {
  const src = 'https://js.chargebee.com/v2/chargebee.js';
  const existingScript = document.querySelector(`script[src="${src}"]`);

  function openChargbeeDialog() {
    const instance = window.Chargebee.getInstance();
    const portal = instance.createChargebeePortal();
    portal.open();
  }

  if (!existingScript) {
    const createdScript = document.createElement('script');
    createdScript.src = src;
    createdScript.async = true;
    createdScript.setAttribute('data-cb-site', 'workbase');
    createdScript.setAttribute('data-cb-domain', 'https://billing.workbase.com');
    document.body.appendChild(createdScript);

    createdScript.onload = () => {
      window.Chargebee.init({
        isItemsModel: true,
      });
      openChargbeeDialog();
    };
  } else {
    openChargbeeDialog();
  }
}

export default function Billing() {
  handleOpenBillingSettingsPopup();
  return null;
}
