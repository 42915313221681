import { Box, InputAdornment, Paper, Stack, SvgIcon, TextField } from '@mui/material';
import { WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import { Employee, EmployeeStatus } from 'src/features/employees/model';
import { noSpecialCharsRegex } from 'src/lib/utils/validateUserData';
import { useTranslation } from 'react-i18next';

export type Props = {
  employee: Employee;
  handleUpdateEmployee: (val: Partial<Employee>) => void;
};
const typographyColor = 'primary.contrastText';
function NameTextField({
  value,
  onChange,
  status,
  label,
  type,
}: {
  value: string;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  status: EmployeeStatus;
  type?: string;
  label: string;
}) {
  const { t } = useTranslation();
  const validate = (): string | null => {
    if (value === '' && status === 'active') {
      return t('validationErrorMessages.requiredErrorMsg');
    }
    if (!noSpecialCharsRegex.test(value) && status === 'active') {
      return t('validationErrorMessages.specialCharsErrorMsg');
    }
    if (value.length > 35) {
      return t('validationErrorMessages.fieldLengthErrorMsg');
    }
    return null;
  };
  const error = validate();

  return (
    <TextField
      data-cy={`${type}-edit-field`}
      autoFocus
      fullWidth
      value={value}
      onChange={onChange}
      error={!!error}
      label={label}
      slotProps={{
        input: {
          endAdornment: (
            <InputAdornment position="end">
              <Box color={error ? 'error.main' : 'primary.contrastText'} display="flex">
                <SvgIcon component={WorkbaseEditIcon} color="inherit" />
              </Box>
            </InputAdornment>
          ),
        },
      }}
    />
  );
}

export default function HeaderEdit({ employee, handleUpdateEmployee }: Props) {
  const { t } = useTranslation();
  const handleUpdate = ({ type, val }: { type: keyof Employee; val: string }) => {
    const updatedField: Partial<Employee> = { [type]: val };
    handleUpdateEmployee(updatedField);
  };
  const nameFields: { type: keyof Employee; value: string; label: string }[] = [
    {
      type: 'firstName',
      value: employee?.firstName,
      label: t('employeePage.employeeDetails.firstNameLabel'),
    },
    {
      type: 'lastName',
      value: employee?.lastName,
      label: t('employeePage.employeeDetails.lastNameLabel'),
    },
  ];

  return (
    <Stack
      component={Paper}
      height={90}
      p={2.5}
      direction="row"
      justifyContent="center"
      alignItems="flex-start"
      gap={2.5}
      width="80%"
      maxWidth="80%"
      color={typographyColor}
    >
      {nameFields.map(({ type, value, label }) => (
        <NameTextField
          key={type}
          type={type}
          label={label}
          status={employee?.status}
          value={value}
          onChange={(e) => {
            handleUpdate({ type, val: e.target.value });
          }}
        />
      ))}
    </Stack>
  );
}
