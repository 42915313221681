import defaultFaviconLight from 'src/assets/logo/defaultFaviconLight.png';
import defaultFaviconDark from 'src/assets/logo/defaultFaviconDark.png';
import defaultLogoLight from 'src/assets/logo/defaultLogoLight.png';
import defaultLogoDark from 'src/assets/logo/defaultLogoDark.png';

const defaultLogos = {
  siteLogo: { src: defaultLogoLight, alt: 'Light logo' },
  siteLogoDark: { src: defaultLogoDark, alt: 'Dark logo' },
  siteFavicon: { src: defaultFaviconLight, alt: 'Light favicon' },
  siteFaviconDark: { src: defaultFaviconDark, alt: 'Dark favicon' },
};

export default defaultLogos;
