import { Box, Divider, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { useActive, useCommands, useRemirrorContext } from '@remirror/react';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseBoldIcon,
  WorkbaseItalicIcon,
  WorkbaseLinksIcon,
  WorkbaseTextCenterIcon,
  WorkbaseTextLeftIcon,
  WorkbaseTextRightIcon,
  WorkbaseUnderlineIcon,
} from '../../../../../../../assets/icons/workbaseIcons';
import TextStylesDropdown from './text-styles/TextStylesDropdown';

interface Props {
  onLinkIconClick: () => void;
  linkIconAlwaysClickable?: boolean;
  textAllignmentHidden?: boolean;
}

function TextAlignmentButtons() {
  const { leftAlign, centerAlign, rightAlign } = useCommands();
  const { t } = useTranslation();

  const handleAlignment = (alignFunction: any) => {
    try {
      return alignFunction.active?.();
    } catch (err) {
      return false;
    }
  };

  const data = [
    {
      key: 'align-left',
      onClick: leftAlign,
      icon: WorkbaseTextLeftIcon,
      active: handleAlignment(leftAlign),
      hint: t('editor.topBar.tooltip.alignLeft'),
    },
    {
      key: 'align-center',
      onClick: centerAlign,
      icon: WorkbaseTextCenterIcon,
      active: handleAlignment(centerAlign),
      hint: t('editor.topBar.tooltip.alignCenter'),
    },
    {
      key: 'align-right',
      onClick: rightAlign,
      icon: WorkbaseTextRightIcon,
      active: handleAlignment(rightAlign),
      hint: t('editor.topBar.tooltip.alignRight'),
    },
  ];
  return data.map(({ key, onClick, icon, hint, active }) => (
    <Tooltip key={key} title={hint} placement="top">
      <ToggleButton
        key={key}
        size="small"
        value={key}
        selected={active}
        onMouseDown={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        <SvgIcon component={icon} fontSize="small" />
      </ToggleButton>
    </Tooltip>
  ));
}

export default function TextMenu({
  onLinkIconClick,
  textAllignmentHidden = false,
  linkIconAlwaysClickable = false,
}: Props) {
  const { toggleBold, toggleItalic, toggleUnderline } = useCommands();
  const { bold, underline, italic, heading } = useActive();
  const titleActive = heading({ level: 1 });
  const headingActive = heading({ level: 2 });
  const subtitleActive = heading({ level: 3 });
  const isAnyHeadingActive = titleActive || headingActive || subtitleActive;

  const { getState } = useRemirrorContext();

  const selectionEmpty = getState().selection.empty;
  const { t } = useTranslation();

  const iconsData = [
    {
      key: 'strong',
      onClick: toggleBold,
      icon: WorkbaseBoldIcon,
      active: bold() && !isAnyHeadingActive,
      disabled: isAnyHeadingActive,
      hint: t('editor.topBar.tooltip.bold'),
    },
    {
      key: 'italic',
      onClick: toggleItalic,
      icon: WorkbaseItalicIcon,
      active: italic(),
      hint: t('editor.topBar.tooltip.italic'),
    },
    {
      key: 'underline',
      onClick: toggleUnderline,
      icon: WorkbaseUnderlineIcon,
      active: underline(),
      hint: t('editor.topBar.tooltip.underline'),
    },
    {
      key: 'link',
      onClick: () => {
        onLinkIconClick();
      },
      icon: WorkbaseLinksIcon,
      disabled: linkIconAlwaysClickable ? false : selectionEmpty,
      active: false,
      hint: t('editor.topBar.tooltip.link'),
    },
  ];

  return (
    <>
      <TextStylesDropdown />
      {iconsData.map(({ key, onClick, icon, disabled, hint, active }) => (
        <Tooltip key={key} title={hint} placement="top">
          <Box>
            <ToggleButton
              value={key}
              size="small"
              disabled={disabled}
              selected={active}
              onMouseDown={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <SvgIcon component={icon} fontSize="small" />
            </ToggleButton>
          </Box>
        </Tooltip>
      ))}
      {!textAllignmentHidden && (
        <Divider orientation="vertical" flexItem sx={{ alignSelf: 'stretch' }} />
      )}
      {!textAllignmentHidden && <TextAlignmentButtons />}
    </>
  );
}
