import { Box, Container, InputAdornment, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import {
  usePatchTrainingLessonMutation,
  useTrainingLessonQuery,
} from 'src/store/api/trainings/trainingLessonsApi';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import { ChangeEvent, useCallback } from 'react';
import BrandedHeader from 'src/ui-components/branded-header';
import ContrastTextField from 'src/ui-components/custom/textfield/contrast';
import LessonBreadcrumbs from './LessonBreadcrumbs';

interface Props {
  lessonId: string;
  readOnly: boolean;
  showBreadcrumbs: boolean;
}

export default function LessonHeader({ readOnly, showBreadcrumbs, lessonId }: Props) {
  const { t } = useTranslation();
  const { data } = useTrainingLessonQuery(lessonId);
  const [patchLesson] = usePatchTrainingLessonMutation();

  const handleLessonNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      patchLesson({ id: lessonId, name: e.target.value });
    },
    [lessonId, patchLesson]
  );
  const { handleChange, value } = useDebouncedValue(data?.name, handleLessonNameChange);

  return (
    <Stack gap={1} alignItems="flex-start">
      {showBreadcrumbs && (
        <Container>
          <LessonBreadcrumbs />
        </Container>
      )}
      <BrandedHeader>
        <Stack gap={1} alignItems="center" justifyContent="center" width="100%">
          {!readOnly && (
            <ContrastTextField
              data-cy="lesson-header-input"
              value={value}
              placeholder={t('writeTitlePlaceholder')}
              onChange={handleChange}
              fullWidth
              slotProps={{
                input: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <Box color="primary.contrastText">
                        <SvgIcon component={WorkbaseEditIcon} />
                      </Box>
                    </InputAdornment>
                  ),
                },
              }}
            />
          )}
          {readOnly && (
            <Typography variant="h4" fontWeight="bold">
              {data?.name ? data?.name : t('modulePage.unnamedLesson')}
            </Typography>
          )}
        </Stack>
      </BrandedHeader>
    </Stack>
  );
}
