import { IconButton, Stack, Typography } from '@mui/material';
import { WorkbaseCloseIcon } from 'src/assets/icons/workbaseIcons';
import { byteConverter } from 'src/lib/utils/format-number';
import CircularProgressWithLabel from 'src/ui-components/custom/progress/CircularProgressWithLabel';
import { useTranslation } from 'react-i18next';
import { FileType } from '../edit-bar/UploadActions';

function UploadProgress({
  mediaType,
  size,
  progress,
  onClose,
}: {
  mediaType: FileType;
  size: number;
  progress: number;
  onClose?: () => void;
}) {
  const { t } = useTranslation();
  const uploadInProgress = progress > 0 && progress < 100;
  return (
    <Stack alignItems="center" py={5} px={2}>
      <CircularProgressWithLabel value={progress} />
      <Typography variant="body1" fontWeight="bold">
        {t(`uploadProgress.${mediaType}.title`)}{' '}
        {`${uploadInProgress ? `${t('uploadProgress.beingLabel')} ` : ''}`}
        {t('uploadProgress.uploadedLabel')}
      </Typography>
      <Typography color="secondary" variant="body2">
        {size ? `${byteConverter((progress / 100) * size)} / ${byteConverter(size)}` : null}
      </Typography>
      <IconButton
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 20,
        }}
      >
        <WorkbaseCloseIcon />
      </IconButton>
    </Stack>
  );
}

export default UploadProgress;
