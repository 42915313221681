import { Checkbox, FormControlLabel, Stack, Tooltip, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  addPermissionToRoles,
  removePermissionFromRoles,
} from 'src/features/company-settings/departments/controller/departments.slice';
import { useAppDispatch, useAppSelector } from 'src/store';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';

export interface BaseSinglePermissionProps {
  id: string;
  name: string;
  description?: string;
  disabled?: boolean;
  onChangeCallback?: (checked: boolean, indeterminate: boolean) => void;
  tooltipText?: string;
  hasManagingPermission: boolean;
}

export default function BaseSinglePermission({
  id,
  name,
  description,
  disabled,
  onChangeCallback,
  tooltipText,
  hasManagingPermission,
}: BaseSinglePermissionProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { data: userPermissions } = useUserPermissionsQuery();

  const checkedRolesIds = useAppSelector((state) => state.departments.checkedRoles);
  const deps = useAppSelector((state) => state.departments.departmentsDraftState);

  const checkedRoles = deps
    .flatMap((dep) => dep.roles)
    .filter((role) => checkedRolesIds.includes(role.id));

  const isChecked = checkedRoles.some((role) => role.permissionIds.includes(id));
  const rolesWithCurrentPermissionCount = checkedRoles.filter((role) =>
    role.permissionIds.includes(id)
  ).length;

  const editablePermission = userPermissions?.some((perm) => perm.id === id);
  const isAllRolesHaveCurrentPermission = rolesWithCurrentPermissionCount === checkedRoles.length;
  const isNoRolesHaveCurrentPermission = rolesWithCurrentPermissionCount === 0;
  const isIndeterminate = !isAllRolesHaveCurrentPermission && !isNoRolesHaveCurrentPermission;

  const handlePermissionCheckboxChange = () => {
    if (!isChecked || (isIndeterminate && isChecked)) {
      dispatch(
        addPermissionToRoles({
          permissionToAdd: id,
          rolesIds: checkedRoles.map((checkedRole) => checkedRole.id),
        })
      );
    } else {
      dispatch(
        removePermissionFromRoles({
          permissionToRemove: id,
          rolesIds: checkedRoles.map((checkedRole) => checkedRole.id),
        })
      );
    }
    onChangeCallback?.(isChecked, isIndeterminate);
  };

  return (
    <Tooltip title={disabled && hasManagingPermission && tooltipText} placement="top">
      <FormControlLabel
        key={id}
        sx={{ alignItems: 'flex-start' }}
        control={
          <Checkbox
            disableRipple
            data-cy="permission-checkbox"
            checked={isChecked}
            indeterminate={isIndeterminate && isChecked}
            color={isIndeterminate ? 'warning' : 'primary'}
            disabled={hasManagingPermission ? !editablePermission || disabled : true}
            onChange={handlePermissionCheckboxChange}
          />
        }
        label={
          <Stack>
            <Stack direction="row" position="relative" gap={1}>
              <Typography variant="body1" color="text.secondary">
                {name}
              </Typography>
              {isIndeterminate && (
                <Tooltip
                  title={t('companySettings.sidebar.departments.permissions.conflictTooltip', {
                    enabledRole: rolesWithCurrentPermissionCount,
                    selectedRole: new Set(checkedRolesIds).size,
                  })}
                  placement="top"
                >
                  <Typography variant="body1" fontWeight="bold" color="secondary.main">
                    {t('companySettings.sidebar.departments.permissions.conflictTitle')}
                  </Typography>
                </Tooltip>
              )}
            </Stack>
            <Typography variant="caption" color="grey[500]">
              {description}
            </Typography>
          </Stack>
        }
      />
    </Tooltip>
  );
}
