import { useLottie } from 'lottie-react';
import React, { useEffect, useMemo, useRef } from 'react';
import { colorify } from 'lottie-colorify';
import { useTheme } from '@mui/material';
import hamburgerAnimaion from './hamburgerAnimation.json';

interface Props {
  isOpen: boolean;
}

function toFullHexColor(color: string) {
  if (color.startsWith('#')) {
    if (color.length === 4) {
      return `#${color[1]}${color[1]}${color[2]}${color[2]}${color[3]}${color[3]}`.toUpperCase();
    }
    return color.toUpperCase();
  }

  const matches = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*([\d.]+))?\)$/);

  if (!matches) {
    return color;
  }

  const r = parseInt(matches[1], 10);
  const g = parseInt(matches[2], 10);
  const b = parseInt(matches[3], 10);

  // eslint-disable-next-line no-bitwise
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
}

const sizes = { width: 150, height: 150 };

export default React.memo(({ isOpen }: Props) => {
  const mountedRef = useRef(false);
  const { palette } = useTheme();
  const animationData = useMemo(
    () =>
      colorify(
        Array.from({ length: 3 }, () => toFullHexColor(palette.action.active)),
        hamburgerAnimaion
      ),
    [palette.action.active]
  );

  const options = useMemo(
    () => ({
      animationData,
      loop: false,
      autoplay: false,
    }),
    [animationData]
  );

  const { View, playSegments } = useLottie(options, sizes);

  useEffect(() => {
    if (mountedRef.current) {
      if (isOpen) {
        playSegments([0, 50], true);
      } else {
        playSegments([50, 0], true);
      }
    } else {
      mountedRef.current = true;
    }
    // eslint-disable-next-line
  }, [isOpen]);

  return View;
});
