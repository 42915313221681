import WorkbaseTree from 'src/ui-components/custom/hierarchical-tree/Tree';
import { TreeNodeModel } from 'src/ui-components/custom/hierarchical-tree/types';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import React from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import useOnDropTree from './useOnDropTree';

interface Props {
  data: TreeNodeModel[];
}

export default React.memo(({ data }: Props) => {
  const hasAnyPermission = useHasAnyPermission();
  const isMobile = useIsMobile();
  const canDragTree = hasAnyPermission([
    'ADMIN_ACCESS_WHITEBOARDS',
    'ADMIN_ACCESS_EMBEDDED_APPS',
    'ADMIN_ACCESS_DASHBOARD_AND_PAGES',
  ]);

  const onDrop = useOnDropTree();

  if (data.length === 0) return null;
  return <WorkbaseTree tree={data} onDrop={onDrop} canDrag={() => !!canDragTree && !isMobile} />;
});
