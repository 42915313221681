import { SvgIcon } from '@mui/material';
import { WorkbaseEmployeeIcon } from 'src/assets/icons/workbaseIcons';

const MuiAvatar: any = {
  defaultProps: {
    size: 'medium',
    children: <SvgIcon component={WorkbaseEmployeeIcon} color="action" />,
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        width: 24,
        height: 24,
      },
      defaultProps: {},
    },
    {
      props: { size: 'medium' },
      style: {
        width: 40,
        height: 40,
      },
    },
  ],
};

export default MuiAvatar;
