import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import {
  useDeleteTrainingsMutation,
  useDuplicateTrainingsMutation,
  useGetSingleTrainingQuery,
} from 'src/store/api/trainings/trainingsApi';
import ShareModal from 'src/core/share-modal/ShareModal';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import useTrainingPermissions from 'src/lib/hooks/useTrainingPermissions';
import { EditorWithModeSwitch } from 'src/features/editor';
import { ShareLevel } from 'src/lib/types/share-level';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import TrainingCourseHeader from './header';

export default function TrainingCourseView() {
  const param = useParams();
  if (!param.trainingId) throw new Error('Training id param missing');
  const navigate = useNavigate();
  const hasAnyPermission = useHasAnyPermission();
  const hasAddTrainingsPermission = hasAnyPermission(['ADD_TRAININGS']);
  const [handleDeleteTraining] = useDeleteTrainingsMutation();
  const [handleDuplicateTraining] = useDuplicateTrainingsMutation();
  const { t } = useTranslation();

  const { data } = useGetSingleTrainingQuery(param.trainingId as string);

  const trainingName = data?.name || t('trainingDetailPage.unnamedTraining');

  const { data: shareLevelData, error } = useShareLevelQuery({
    resourceType: 'training',
    resourceId: param.trainingId as string,
  });

  const { hasEditingPermissions } = useTrainingPermissions();

  const hasManagePermission = shareLevelData?.shareLevel === ShareLevel.manage;

  return (
    <EditorWithModeSwitch
      canEdit={hasEditingPermissions}
      canView={shareLevelData?.shareLevel !== ShareLevel.none && !error}
      renderHeader={(readOnly) => (
        <TrainingCourseHeader readOnly={readOnly} trainingId={param.trainingId as string} />
      )}
      topBarRightContent={
        <>
          <ShareModal
            dataCy="training-course-view"
            name={trainingName}
            resourceId={param.trainingId as string}
            resourceType="training"
            shareLevel={shareLevelData?.shareLevel}
          />
          <DeleteDuplicateMenu
            onDuplicate={
              hasAddTrainingsPermission
                ? () => {
                    handleDuplicateTraining(param.trainingId as string).then((resp: any) => {
                      if (resp?.error) {
                        showNotification(t('notifications.duplicateError'), 'error');
                      } else {
                        showNotification(t('notifications.duplicateSuccess'));
                        navigate(`/trainings/${resp.data.id}/edit`);
                      }
                    });
                  }
                : undefined
            }
            deleteDialogDescription={t(
              'editor.topBar.customQuestionsOnSettingIcon.deleteTrainingQuestion'
            )}
            onDelete={
              hasManagePermission
                ? () => {
                    handleDeleteTraining(param.trainingId as string).then((resp: any) => {
                      if (resp?.error) {
                        showNotification(t('notifications.deleteError'), 'error');
                      } else {
                        showNotification(t('notifications.deleteSuccess'));
                        navigate('/');
                      }
                    });
                  }
                : undefined
            }
          />
        </>
      }
      documentName={`training.${param.trainingId}`}
    />
  );
}
