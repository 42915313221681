import { Components, Theme } from '@mui/material';

const MuiBreadcrumbs: Components<Theme>['MuiBreadcrumbs'] = {
  styleOverrides: {
    ol: {
      flexWrap: 'nowrap',
    },
    li: {
      minWidth: 40,
      flexShrink: 1,
      '& > *': {
        maxWidth: '100%',
        whiteSpace: 'nowrap',
        display: 'inline-block',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
      },
    },
  },
};

export default MuiBreadcrumbs;
