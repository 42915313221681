import { Button, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { WorkbaseCheckmarkBigFilledIcon, WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';

export default function EditButton() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const isEditPath = pathname.endsWith('/edit');

  return (
    <Button
      color="inherit"
      size="large"
      component={Link}
      to={isEditPath ? pathname.replace('/edit', '/') : './edit'}
      disableElevation={false}
      startIcon={
        <SvgIcon component={isEditPath ? WorkbaseCheckmarkBigFilledIcon : WorkbaseEditIcon} />
      }
    >
      {isEditPath ? t('editButton.finish') : t('editButton.edit')}
    </Button>
  );
}
