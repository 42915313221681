import { Button } from '@mui/material';
import { useUnAssignUserFromRoleMutation } from 'src/store/api/employees/employeesApi';
import { t } from 'i18next';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';

export default function EmployeeActionButton({
  roleId,
  userId,
}: {
  roleId: string;
  userId: string;
}) {
  const [unAssignUserFromRole] = useUnAssignUserFromRoleMutation();

  return (
    <Button
      data-cy="unassign-role-button"
      color="inherit"
      size="small"
      onClick={() => {
        unAssignUserFromRole({ roleId, userId }).then((resp: any) => {
          if (resp.error) {
            if (resp.error.data.code === 'CANNOT_UNASSIGN_LAST_ROLE') {
              showNotification(
                t('companySettings.sidebar.departments.employeePopup.unAssignError'),
                'error'
              );
            } else {
              showNotification(
                t('companySettings.sidebar.departments.removeLinkModal.ActionFailedPermissions'),
                'error'
              );
            }
          }
        });
      }}
    >
      {t('companySettings.sidebar.departments.employeePopup.unAssignButton')}
    </Button>
  );
}
