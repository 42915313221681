import {
  Box,
  Checkbox,
  FormControlLabel,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseCodeIcon, WorkbaseEmailIcon } from 'src/assets/icons/workbaseIcons';
import {
  AdminNotificationPreferenceKeys,
  RecommendationNotificationAdminPreferences,
} from 'src/store/api/settingsApi';
import validateUrl from 'src/lib/utils/validateUrl';
import useRecommendationForm from './useRecommendationForm';

const emailEnabledKey = 'isEmailEnabled';
const webhookEnabledKey = 'isWebhookEnabled';
const webhookPlaceholder = 'https://hooks.zapier.com/hooks/catch/7333360/buoefv2…';

export default function EmployeeRecommendationForm({
  email,
  patchSettings,
  isEmailEnabled,
  webhookUrl,
  isWebhookEnabled,
}: {
  email: string;
  isEmailEnabled: boolean;
  webhookUrl: string;
  patchSettings: (updates: Partial<RecommendationNotificationAdminPreferences> | undefined) => void;
  isWebhookEnabled: boolean;
}) {
  const { t } = useTranslation();
  const {
    showErrorEmailMessage,
    showErrorWebhookMessage,
    emailEnabled,
    setEmailEnabled,
    emailValue,
    setEmailValue,
    webhookEnabled,
    setWebhookEnabled,
    validateEmail,
    webhookValue,
    setWebhookValue,
    handleBlur,
    handleChecked,
  } = useRecommendationForm({
    email,
    webhookUrl,
    isEmailEnabled,
    isWebhookEnabled,
    patchSettings,
  });

  return (
    <Stack gap={1.5}>
      <Typography color="text.primary" variant="subtitle1" fontWeight="medium">
        {t('companySettings.employeeRecommendations.formTitle')}
      </Typography>
      <Box>
        <FormControlLabel
          label={t('companySettings.employeeRecommendations.email.label')}
          control={
            <Checkbox
              data-cy="employee-recommendations-email-checkbox"
              disableRipple
              checked={emailEnabled}
              onChange={(e) => {
                setEmailEnabled(e.target.checked);
                handleChecked({
                  val: emailValue,
                  enable: e.target.checked,
                  defaultEnable: isEmailEnabled,
                  enabledKey: emailEnabledKey,
                });
              }}
            />
          }
        />
        <TextField
          data-cy="employee-recommendations-email-textfield"
          onBlur={() =>
            handleBlur({
              valKey: 'email' as AdminNotificationPreferenceKeys,
              val: emailValue,
              defaultVal: email,
              enabledKey: emailEnabledKey as AdminNotificationPreferenceKeys,
              defaultEnable: isEmailEnabled,
              validateFn: validateEmail,
              enable: emailEnabled,
            })
          }
          onChange={(e) => setEmailValue(e.target.value)}
          value={emailValue}
          placeholder={t('companySettings.employeeRecommendations.email.placeholder')}
          fullWidth
          size="small"
          disabled={!emailEnabled}
          error={showErrorEmailMessage}
          helperText={
            showErrorEmailMessage ? t('companySettings.employeeRecommendations.email.error') : ''
          }
          slotProps={{
            input: {
              endAdornment: (
                <InputAdornment position="end">
                  <InputAdornment position="end">
                    <SvgIcon fontSize="inherit" component={WorkbaseEmailIcon} color="inherit" />
                  </InputAdornment>
                </InputAdornment>
              ),
            },
          }}
        />
      </Box>
      <Box gap={1}>
        <FormControlLabel
          label={t('companySettings.employeeRecommendations.webhook.label')}
          control={
            <Checkbox
              data-cy="employee-recommendations-webhook-checkbox"
              disableRipple
              checked={webhookEnabled}
              onChange={(e) => {
                setWebhookEnabled(e.target.checked);
                handleChecked({
                  val: webhookValue,
                  enable: e.target.checked,
                  defaultEnable: isWebhookEnabled,
                  enabledKey: webhookEnabledKey,
                });
              }}
            />
          }
        />
        <Stack gap={1}>
          <TextField
            data-cy="employee-recommendations-webhook-textfield"
            onBlur={() =>
              handleBlur({
                valKey: 'webhookUrl' as AdminNotificationPreferenceKeys,
                val: webhookValue,
                defaultVal: webhookUrl,
                enabledKey: webhookEnabledKey as AdminNotificationPreferenceKeys,
                defaultEnable: isWebhookEnabled,
                validateFn: validateUrl,
                enable: webhookEnabled,
              })
            }
            value={webhookValue}
            onChange={(e) => setWebhookValue(e.target.value)}
            placeholder={webhookPlaceholder}
            fullWidth
            size="small"
            error={showErrorWebhookMessage}
            helperText={
              showErrorWebhookMessage
                ? t('companySettings.employeeRecommendations.webhook.error')
                : t('companySettings.employeeRecommendations.webhook.helperText')
            }
            disabled={!webhookEnabled}
            slotProps={{
              input: {
                endAdornment: (
                  <InputAdornment position="end">
                    <InputAdornment position="end">
                      <SvgIcon fontSize="inherit" component={WorkbaseCodeIcon} color="inherit" />
                    </InputAdornment>
                  </InputAdornment>
                ),
              },
            }}
          />
        </Stack>
      </Box>
    </Stack>
  );
}
