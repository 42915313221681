import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useUserPermissionsQuery } from 'src/store/api/userPermissionsApi';
import { SidebarItem } from 'src/ui-components/layout/sidebar/types';

export const useRouteChecker = (items: SidebarItem[]) => {
  const navigate = useNavigate();
  const { isSuccess } = useUserPermissionsQuery();
  const location = useLocation();
  const currPathname = location.pathname;

  useEffect(() => {
    if (!isSuccess) return;
    const checkAndRedirect = () => {
      const visibleItems = items.filter((item) => !item.hide);

      if (visibleItems.length === 0) {
        return '/';
      }

      const indexOfCurrent = visibleItems.findIndex(
        (item) => `settings/${item.key}` === currPathname
      );
      const nextVisibleItem = visibleItems[indexOfCurrent + 1];
      if (nextVisibleItem) {
        return `/settings/${nextVisibleItem.key}`;
      }
      return '/';
    };

    /* eslint-disable max-depth */
    items.forEach((item) => {
      if (currPathname === `/settings/${item.key}`) {
        if (item.hide) {
          const redirectTo = checkAndRedirect();
          if (redirectTo === null) {
            navigate('/');
          } else {
            navigate(redirectTo, { relative: 'route' });
          }
        }
      }
    });
  }, [items, navigate, currPathname, isSuccess]);
};
