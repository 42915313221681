import { Components } from '@mui/material';
import { Theme } from '@mui/material/styles';
import MuiCssBaseline from './MuiCssBaseline';
import MuiButton from './MuiButton';
import type {} from '@mui/lab/themeAugmentation';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import MuiTabPanel from './MuiTabPanel';
import MuiTab from './MuiTab';
import MuiAutocomplete from './MuiAutocomplete';
import MuiFormControlLabel from './MuiFormControlLabel';
import MuiAvatar from './MuiAvatar';
import MuiDatePicker from './MuiDatePicker';
import MuiAccordionSummary from './MuiAccordionSummary';
import MuiTableRow from './MuiTableRow';
import MuiToggleButton from './MuiToggleButton';
import MuiListItem from './MuiListItem';
import MuiListItemButton from './MuiListItemButton';
import MuiMenuItem from './MuiMenuitem';
import MuiMenu from './MuiMenu';
import MuiOutlinedInput from './MuiOutlinedInput';
import MuiIconButton from './MuiIconButton';
import MuiDialogActions from './MuiDialogActions';
import MuiListItemIcon from './MuiListItemIcon';
import MuiPaper from './MuiPaper';
import MuiListItemText from './MuiListItemText';
import MuiChip from './MuiChip';
import MuiCardHeader from './MuiCardHeader';
import MuiCardContent from './MuiCardContent';
import MuiCardActions from './MuiCardActions';
import MuiDialogTitle from './MuiDialogTitle';
import MuiDialogContent from './MuiDialogContent';
import MuiSkeleton from './MuiSkeleton';
import MuiSelect from './MuiSelect';
import MuiTextField from './MuiTextField';
import MuiTooltip from './MuiTooltip';
import MuiPopper from './MuiPopper';
import MuiPopover from './MuiPopover';
import MuiBreadcrumbs from './MuiBreadcrumbs';
import MuiTabs from './MuiTabs';
import MuiSwitch from './MuiSwitch';

const components: Components<Theme> = {
  MuiCssBaseline,
  MuiButton,
  MuiTabPanel,
  MuiTab,
  MuiAutocomplete,
  MuiFormControlLabel,
  MuiAvatar,
  MuiDatePicker,
  MuiAccordionSummary,
  MuiTableRow,
  MuiToggleButton,
  MuiListItem,
  MuiListItemButton,
  MuiMenuItem,
  MuiMenu,
  MuiOutlinedInput,
  MuiIconButton,
  MuiDialogActions,
  MuiListItemIcon,
  MuiPaper,
  MuiListItemText,
  MuiChip,
  MuiCardHeader,
  MuiCardContent,
  MuiCardActions,
  MuiDialogTitle,
  MuiDialogContent,
  MuiSkeleton,
  MuiSelect,
  MuiTextField,
  MuiTooltip,
  MuiPopper,
  MuiPopover,
  MuiBreadcrumbs,
  MuiTabs,
  MuiSwitch,
};

export default components;
