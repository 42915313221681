import { Box, Dialog, DialogContent, SvgIcon, ToggleButton } from '@mui/material';
import ScreenCameraRecorder from 'src/features/screen-camera-recorder';
import { useAppDispatch, useAppSelector } from 'src/store';
import { setRecordingModalPayload } from 'src/features/editor/controller/Editor.slice';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { videoUpload } from 'src/lib/utils/videoUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { WorkbaseCloseIcon } from 'src/assets/icons/workbaseIcons';
import { useEditor } from '../controller';
import { DocumentNameContext } from './DocumentNameContext';

export default function RecordingModal() {
  const dispatch = useAppDispatch();
  const recordingModalPayload = useAppSelector((state) => state.editor.recordingModalPayload);
  const { handleSetVideo } = useEditor();
  const documentName = useContext(DocumentNameContext);
  const { t } = useTranslation();

  const handleClearRecordingModalPayload = useCallback(
    () => dispatch(setRecordingModalPayload(undefined)),
    [dispatch]
  );
  const uploadVideo = useCallback(
    async (newFile: File) => {
      if (!recordingModalPayload) return undefined;
      recordingModalPayload.onLoading?.(true);
      const abortController = new AbortController();
      const { signal } = abortController;

      const uploadVid = (videoId: string) => {
        handleSetVideo(recordingModalPayload.id)({
          url: null,
          fileName: newFile.name,
          type: 'video',
          videoPoster: '',
          videoId,
          isVideoUploaded: false,
        });
        recordingModalPayload.onLoading?.(false);
      };
      try {
        await videoUpload({
          file: newFile,
          errorMessage: t('validationErrorMessages.UploadFailed'),
          setPercentage: recordingModalPayload.onUploadProgress,
          onVideoUploadSuccess: uploadVid,
          documentName,
          signal,
        });
      } catch {
        showNotification(t('validationErrorMessages.UploadFailed'), 'error');
        recordingModalPayload.onLoading?.(false);
      }
      return undefined;
    },
    [documentName, handleSetVideo, t, recordingModalPayload]
  );

  const onRecordingFinish = useCallback(
    (blob: Blob) => {
      const f = new File([blob], 'recording.mp4', { type: 'video/mp4' });
      uploadVideo(f);
      handleClearRecordingModalPayload();
    },
    [uploadVideo, handleClearRecordingModalPayload]
  );

  return (
    <Dialog open={!!recordingModalPayload} fullWidth maxWidth="lg" onClose={() => {}}>
      <Box position="fixed" top={20} right={20} bgcolor="background.paper" borderRadius={1}>
        <ToggleButton value="closeRecording" onClick={handleClearRecordingModalPayload}>
          <SvgIcon component={WorkbaseCloseIcon} fontSize="small" />
        </ToggleButton>
      </Box>
      <DialogContent sx={{ display: 'flex' }}>
        <ScreenCameraRecorder onRecordingFinish={onRecordingFinish} />
      </DialogContent>
    </Dialog>
  );
}
