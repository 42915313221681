import { Stack } from '@mui/material';
import { TrainingLesson } from 'src/store/api/trainings/trainingLessonsApi';
import SingleLesson from './SingleLesson';

interface Props {
  lessonData: TrainingLesson[];
  trainingId: string;
  trainingModuleId: string;
  blockAccess?: boolean;
}

export default function LessonList({
  lessonData,
  trainingId,
  trainingModuleId,
  blockAccess = false,
}: Props) {
  return (
    <Stack gap={2}>
      {lessonData
        .filter(({ status }) => status === 'published')
        .map((lesson) => (
          <SingleLesson
            blockAccess={blockAccess}
            key={lesson.id}
            lesson={lesson}
            trainingId={trainingId}
            trainingModuleId={trainingModuleId}
          />
        ))}
    </Stack>
  );
}
