import { useNavigate, useParams } from 'react-router-dom';
import { useCallback } from 'react';
import { Alert, Box, Button } from '@mui/material';
import {
  TrainingLessonStatus,
  useDeleteTrainingLessonMutation,
  useDuplicateTrainingLessonMutation,
  useTrainingLessonCanMarkAsDoneQuery,
  usePatchTrainingLessonMutation,
  useTrainingLessonQuery,
} from 'src/store/api/trainings/trainingLessonsApi';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import { useTrainingSectionsByModuleIdQuery } from 'src/store/api/trainings/trainingSectionsApi';
import DraftModeMenu from 'src/core/DraftModeMenu';
import EditorWithContextProvider from 'src/features/editor/view/EditorWithContextProvider';
import debounce from 'lodash.debounce';
import invalidateTrainingEstimatedTime from 'src/store/api/utils/invalidateTrainingEstimatedTime';
import { useDispatch } from 'react-redux';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import LessonPageHeader from './LessonHeader';
import LessonFooter from './LessonFooter';

interface Props {
  editMode: boolean;
  showBreadcrumbs?: boolean;
  hasEditAccess: boolean;
}

export default function Lesson({ editMode, showBreadcrumbs = false, hasEditAccess }: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams<{
    trainingId: string;
    moduleId: string;
    lessonId: string;
    sectionId: string;
  }>();
  const { data, error, refetch } = useTrainingLessonQuery(params.lessonId as string);
  const dispatch = useDispatch();
  const [patchTrainingLesson] = usePatchTrainingLessonMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedInvalidateTrainingEstimatedTime = useCallback(
    debounce(() => {
      if (params.sectionId && params.trainingId && params.lessonId) {
        invalidateTrainingEstimatedTime({
          dispatch,
          sectionId: params.sectionId,
          trainingId: params.trainingId,
          lessonId: params.lessonId,
        });
      }
    }, 5000),
    [dispatch, params.sectionId, params.trainingId, params.lessonId]
  );

  const [duplicateTrainingLesson] = useDuplicateTrainingLessonMutation();
  const [deleteTrainingLesson] = useDeleteTrainingLessonMutation();
  const { data: canMarkAsDone } = useTrainingLessonCanMarkAsDoneQuery({
    lessonId: params.lessonId as string,
    moduleId: params.moduleId as string,
  });

  const { data: sections } = useTrainingSectionsByModuleIdQuery(params.moduleId as string);
  const isDraft =
    data?.status === 'draft' ||
    sections?.find(({ id }) => id === params.sectionId)?.status === 'draft';

  const showLessonFooter = !editMode && data && !data?.done && !isDraft;

  if (error) {
    return (
      <Alert
        sx={{ alignSelf: 'flex-start', margin: '16px', width: '100%' }}
        severity="error"
        action={
          <Button onClick={refetch} size="small">
            {t('validationErrorMessages.labelErrorTryAgainButton')}
          </Button>
        }
      >
        {t('lessonPage.fetchLessonError')}
      </Alert>
    );
  }

  return (
    <Box position="relative" height="100%">
      <EditorWithContextProvider
        readOnly={!editMode}
        onEditorDataChangeCallback={debouncedInvalidateTrainingEstimatedTime}
        renderHeader={(readOnly) => (
          <LessonPageHeader
            lessonId={params.lessonId as string}
            readOnly={readOnly}
            showBreadcrumbs={showBreadcrumbs}
          />
        )}
        topBarRightContent={
          <>
            <DraftModeMenu
              status={data?.status as TrainingLessonStatus}
              onStatusChange={(status) => {
                patchTrainingLesson({
                  status,
                  id: data?.id as string,
                  trainingSectionId: data?.trainingSectionId as string,
                  trainingId: params.trainingId as string,
                  moduleId: params.moduleId as string,
                });
              }}
            />
            <DeleteDuplicateMenu
              onDelete={() => {
                deleteTrainingLesson({
                  id: params.lessonId as string,
                  trainingSectionId: params.sectionId as string,
                  trainingId: params.trainingId as string,
                  moduleId: params.moduleId as string,
                }).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.deleteError'), 'error');
                  } else {
                    showNotification(t('notifications.deleteSuccess'));
                    navigate(`/trainings/${params.trainingId}/${params.moduleId}`);
                  }
                });
              }}
              onDuplicate={() => {
                duplicateTrainingLesson({
                  lessonId: params.lessonId as string,
                  trainingId: params.trainingId as string,
                  moduleId: params.moduleId as string,
                  trainingSectionId: params.sectionId as string,
                }).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.duplicateError'), 'error');
                  } else {
                    showNotification(t('notifications.duplicateSuccess'));
                  }
                });
              }}
              deleteDialogDescription={t('deleteLessonModal.content')}
            />
          </>
        }
        documentName={`trainingLesson.${params.lessonId}`}
      />
      {showLessonFooter && data && (
        <LessonFooter
          lessonId={data.id}
          nextPublishedTrainingLessonId={data.nextPublishedTrainingLessonId}
          disabledFinishButton={!canMarkAsDone?.result}
          contentSwiftedLeft={hasEditAccess}
        />
      )}
    </Box>
  );
}
