import { ButtonBase, SxProps } from '@mui/material';

interface DotProps {
  sx?: SxProps;
}

export default function ResizeDot({ sx }: DotProps) {
  return (
    <ButtonBase
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      className="opacity-editor-item"
      sx={{
        transition: (theme) =>
          theme.transitions.create(['transform', 'opacity'], {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
          }),
        borderWidth: 1,
        borderStyle: 'solid',
        borderColor: 'primary.contrastText',
        bgcolor: 'primary.main',
        position: 'absolute',
        width: 12,
        height: 12,
        borderRadius: '50%',
        zIndex: 1,
        opacity: 0,
        ...sx,
        '&:hover': {
          transform: `scale(1.4)`,
        },
      }}
    />
  );
}

export function HorizontalResizeDot() {
  return <ResizeDot sx={{ cursor: 'ew-resize' }} />;
}
export function VerticalResizeDot() {
  return <ResizeDot sx={{ cursor: 'ns-resize' }} />;
}
export function VerticalBottomLeftResizeDot() {
  return <ResizeDot sx={{ cursor: 'nesw-resize' }} />;
}

export function VerticalBottomRightResizeDot() {
  return <ResizeDot sx={{ cursor: 'nwse-resize' }} />;
}
