import { Box, Button, Menu, MenuItem, SvgIcon } from '@mui/material';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import { WorkbaseArrowDownIcon } from '../../../../../../../../assets/icons/workbaseIcons';
import useTextStylesItems from './useTextStylesItems';

export default function TextStylesDropdown() {
  const items = useTextStylesItems();
  const popoverState = usePopupState({ variant: 'popover', popupId: 'heading-menu' });

  const menuProps = bindPopover(popoverState);
  return (
    <Box color="text.secondary">
      <Button
        variant="text"
        color="inherit"
        size="small"
        sx={{ color: 'text.secondary' }}
        {...bindTrigger(popoverState)}
        endIcon={
          <SvgIcon
            component={WorkbaseArrowDownIcon}
            fontSize="small"
            sx={{
              transition: 'transform 0.15s',
              transform: menuProps.open ? 'rotate(180deg)' : 'rotate(0deg)',
            }}
          />
        }
      >
        {items.find(({ active }) => active)?.placeholder}
      </Button>
      <Menu {...menuProps}>
        {items.map(({ text, onClick, active, key }) => (
          <MenuItem
            dense
            key={key}
            selected={active}
            onClick={() => {
              menuProps.onClose();
              if (!active) onClick();
            }}
          >
            {text}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
}
