import { useNavigate, useParams } from 'react-router-dom';
import EditViewProtectedWrapper from 'src/core/EditViewProtectedWrapper';
import ModeSwitch from 'src/core/ModeSwitch';
import Lesson from 'src/features/lesson/view/Lesson';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import { ShareLevel } from 'src/lib/types/share-level';
import ModuleOverview from 'src/features/module-overview/view';
import SplitPaneLayout from 'src/layouts/SplitPaneLayout';
import { Box, useTheme } from '@mui/material';
import { useRef, useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';

export default function LessonView() {
  const param = useParams();
  const theme = useTheme();
  const { data: shareLevelData, isSuccess } = useShareLevelQuery({
    resourceType: 'training',
    resourceId: param.trainingId as string,
  });
  const navigate = useNavigate();
  const containerRef = useRef<HTMLDivElement | null>(null);

  const [moduleOverviewVisible, setModuleOverviewVisible] = useState(true);

  useResizeObserver(isSuccess ? containerRef : null, (entry) => {
    setModuleOverviewVisible(entry.contentRect.width > theme.breakpoints.values.lg);
  });

  const canEdit =
    shareLevelData?.shareLevel === ShareLevel.edit ||
    shareLevelData?.shareLevel === ShareLevel.manage;
  if (!isSuccess) return null;

  return (
    <Box height="100%" ref={containerRef}>
      <EditViewProtectedWrapper
        canEdit={canEdit}
        canView={shareLevelData.shareLevel !== ShareLevel.none}
      >
        <ModeSwitch switchButtonVisible={canEdit}>
          {(editMode) =>
            moduleOverviewVisible ? (
              <SplitPaneLayout
                leftContent={
                  <ModuleOverview
                    editMode={canEdit ? editMode : false}
                    onAddLessonCallback={(id) =>
                      navigate(
                        `/trainings/${param.trainingId}/${param.moduleId}/${param.sectionId}/${id}/edit`
                      )
                    }
                  />
                }
                rightContent={<Lesson editMode={editMode} hasEditAccess={canEdit} />}
              />
            ) : (
              <Lesson editMode={editMode} hasEditAccess={canEdit} showBreadcrumbs />
            )
          }
        </ModeSwitch>
      </EditViewProtectedWrapper>
    </Box>
  );
}
