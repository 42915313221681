import { useCallback, useMemo } from 'react';
import { Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'src/store';
import { Permission } from 'src/features/company-settings/departments/model/types';
import { addPermissionToRoles } from 'src/features/company-settings/departments/controller/departments.slice';
import { PermissionCode } from 'src/store/api/userPermissionsApi';
import BaseSinglePermission, { BaseSinglePermissionProps } from './BaseSinglePermission';

interface TeamPermissionProps extends BaseSinglePermissionProps {
  code: PermissionCode;
  permissions: Permission[];
  hasManagingPermission: boolean;
  tooltipWithoutManagingPermission: string;
}

export default function TeamPermission(props: TeamPermissionProps) {
  const { code, permissions, hasManagingPermission, tooltipWithoutManagingPermission } = props;
  const dispatch = useAppDispatch();
  const { t } = useTranslation();
  const checkedRolesIds = useAppSelector((state) => state.departments.checkedRoles);
  const departments = useAppSelector((state) => state.departments.departmentsDraftState);
  const tooltipText = t(
    'companySettings.sidebar.departments.permissions.permissionTooltip.viewEmployees'
  );

  const { checkedRoles, manageEmployeesPermissionId, viewEmployeesProgressPermissionId } =
    useMemo(() => {
      const roles = departments
        .flatMap((dep) => dep.roles)
        .filter((role) => checkedRolesIds.includes(role.id));
      const manageId = permissions.find((p) => p.code === 'MANAGE_EMPLOYEES')?.id;
      const viewProgressId = permissions.find(
        (p) => p.code === 'VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS'
      )?.id;
      return {
        checkedRoles: roles,
        manageEmployeesPermissionId: manageId,
        viewEmployeesProgressPermissionId: viewProgressId,
      };
    }, [departments, checkedRolesIds, permissions]);

  const isDisabled = useMemo(() => {
    let result = false;
    if (
      (code === 'VIEW_EMPLOYEES' || code === 'VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS') &&
      manageEmployeesPermissionId &&
      checkedRoles.some((role) => role.permissionIds.includes(manageEmployeesPermissionId))
    ) {
      result = true;
    } else if (
      code === 'VIEW_EMPLOYEES' &&
      viewEmployeesProgressPermissionId &&
      checkedRoles.some((role) => role.permissionIds.includes(viewEmployeesProgressPermissionId))
    ) {
      result = true;
    }
    return result;
  }, [code, checkedRoles, manageEmployeesPermissionId, viewEmployeesProgressPermissionId]);

  const permissionId = permissions.find((permission) => code === permission.code)?.id as string;

  const handlePermissionChange = useCallback(
    (isChecked: boolean, isIndeterminate: boolean) => {
      if (!isChecked || (isIndeterminate && isChecked)) {
        if (code === 'MANAGE_EMPLOYEES' || code === 'VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS') {
          permissions
            .filter(
              (p) =>
                p.code === 'VIEW_EMPLOYEES' ||
                p.code === 'VIEW_EMPLOYEES_DETAILED_TRAINING_PROGRESS'
            )
            .forEach((permission) => {
              dispatch(
                addPermissionToRoles({
                  permissionToAdd: permission.id,

                  rolesIds: checkedRoles.map((role) => role.id),
                })
              );
            });
        } else {
          dispatch(
            addPermissionToRoles({
              permissionToAdd: permissionId,
              rolesIds: checkedRoles.map((role) => role.id),
            })
          );
        }
      }
    },
    [dispatch, checkedRoles, permissions, permissionId, code]
  );

  return (
    <Tooltip placement="top" title={!hasManagingPermission ? tooltipWithoutManagingPermission : ''}>
      <BaseSinglePermission
        {...props}
        disabled={isDisabled}
        tooltipText={tooltipText}
        onChangeCallback={handlePermissionChange}
      />
    </Tooltip>
  );
}
