import { createApi } from '@reduxjs/toolkit/query/react';
import { t } from 'i18next';
import { FetchResponse } from 'src/lib/types/paginatedResponse';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { baseQuery } from 'src/store/api/api';
import { ShareLevel } from 'src/lib/types/share-level';

export interface WikiCardType {
  id: string;
  name: string;
  icon: string;
  editorJson: any;
  shareLevel: ShareLevel;
  position: string;
}

export type WikiFetchResponse = FetchResponse<WikiCardType>;

export const wikiApi = createApi({
  reducerPath: 'wikiCardsApi',
  baseQuery,
  tagTypes: ['WikiSidebar', 'Wiki', 'WikiItem', 'FetchSingleWiki'],
  endpoints: (builder) => ({
    fetchWiki: builder.query<WikiCardType, string>({
      query: (id) => ({
        url: `wiki/cards/${id}`,
        method: 'GET',
      }),
      providesTags: (_, __, id) => [{ type: 'FetchSingleWiki', id }],
    }),
    fetchWikiCardsNoPagination: builder.query<WikiCardType[], void>({
      query: () => ({
        url: `wiki/cards?paginate=false`,
        method: 'GET',
      }),
      providesTags: ['WikiSidebar'],
      transformResponse: (response: any) => response.data,
    }),
    createWikiCard: builder.mutation<WikiCardType, Partial<WikiCardType> | void>({
      query: (newCard) => ({
        url: `wiki/cards`,
        method: 'POST',
        body: newCard,
      }),
      invalidatesTags: ['WikiItem', 'WikiSidebar'],
    }),
    duplicateWikiCard: builder.mutation<WikiCardType, string>({
      query: (id) => ({ url: `wiki/cards/${id}/duplicate`, method: 'POST' }),
      invalidatesTags: ['Wiki', 'WikiSidebar'],
    }),
    deleteWikiCard: builder.mutation<void, string>({
      query: (id) => ({
        url: `wiki/cards/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Wiki', 'WikiSidebar'],
      onQueryStarted: (_, { queryFulfilled }) => {
        queryFulfilled.catch(() => {
          showNotification(t('wikiPage.deleteError'), 'error');
        });
      },
    }),
    updateCardsOrder: builder.mutation<void, { id: string; position: string }>({
      query: ({ id, position }) => ({
        url: `wiki/cards/${id}`,
        body: { position },
        method: 'PATCH',
      }),
      invalidatesTags: ['Wiki'],
      onQueryStarted: ({ id, position }, { queryFulfilled, dispatch }) => {
        const patchResult = dispatch(
          wikiApi.util.updateQueryData('fetchWikiCardsNoPagination', undefined, (draft) => {
            const card = draft.find((cardItem) => cardItem.id === id);
            if (card) {
              card.position = position;
            }
            draft.sort((a, b) => {
              if (a.position < b.position) {
                return -1;
              }
              if (a.position > b.position) {
                return 1;
              }
              return 0;
            });
          })
        );

        queryFulfilled.catch(() => {
          showNotification(t('wikiPage.updateOrderError'), 'error');
          patchResult.undo();
        });
      },
    }),
    patchWikiCard: builder.mutation<void, Partial<WikiCardType> & { id: string }>({
      query: ({ id, ...rest }) => ({
        url: `wiki/cards/${id}`,
        body: { ...rest },
        method: 'PATCH',
      }),
      invalidatesTags: (_, __, payload) => [
        { type: 'FetchSingleWiki', id: payload.id },
        { type: 'WikiSidebar' },
        'WikiItem',
      ],
    }),
  }),
});

export const {
  useCreateWikiCardMutation,
  useDuplicateWikiCardMutation,
  useDeleteWikiCardMutation,
  useFetchWikiCardsNoPaginationQuery,
  useUpdateCardsOrderMutation,
  usePatchWikiCardMutation,
  useFetchWikiQuery,
} = wikiApi;
