import 'remirror/styles/all.css';
import {
  BoldExtension,
  ItalicExtension,
  UnderlineExtension,
  NodeFormattingExtension,
  HeadingExtension,
  BulletListExtension,
  OrderedListExtension,
  LinkExtension,
  HardBreakExtension,
} from 'remirror/extensions';
import { EditorComponent, Remirror, useRemirror } from '@remirror/react';
import { Box, SxProps, styled } from '@mui/material';
import React, { useCallback, useEffect, useRef } from 'react';
import debounce from 'lodash.debounce';
import { EditorState, TextSelection } from 'prosemirror-state';
import { htmlToProsemirrorNode, prosemirrorNodeToHtml } from 'remirror';
import { Plugin } from '@remirror/pm/state';
import { CustomSelectAllExtension } from './Extensions';

interface Props {
  onChange?: (val: string) => void;
  value?: string;
  children?: React.ReactNode;
  textColor?: string;
  sx?: SxProps;
  autoLink?: boolean;
  readOnly?: boolean;
  singleLine?: boolean;
}
const StyledWrapper = styled(Box)`
  .remirror-editor-wrapper {
    padding-top: 0;
  }
  .ProseMirror {
    min-height: 1em !important;
    padding: ${({ theme }) => theme.spacing(0.5)} ${({ theme }) => theme.spacing(1)} !important;
    border-radius: ${({ theme }) => theme.shape.borderRadius};
    overflow-y: auto;
  }
  .ProseMirror:focus,
  .ProseMirror {
    box-shadow: none !important;
  }
  a {
    color: ${({
      theme,
      // @ts-ignore
      textColor,
    }) =>
      textColor?.trim() === '#FFFFFF'
        ? theme.palette.info.main
        : theme.palette.primary.main} !important;
    text-decoration: none;
    cursor: pointer;
  }
  .remirror-editor {
    overflow-y: auto !important;
    user-select: text;
    .ProseMirror-selectednode {
      outline: none;
    }
  }
`;

const singleLinePlugin = () =>
  new Plugin({
    props: {
      handleKeyDown: (_, event) => {
        if (event.key === 'Enter') {
          return true;
        }
        return false;
      },
    },
  });

export default function BaseEditor({
  value = '',
  onChange,
  children,
  textColor,
  sx,
  autoLink = true,
  readOnly = false,
  singleLine = false,
}: Props) {
  const extensions = useCallback(
    () => [
      new BoldExtension({}),
      new ItalicExtension(),
      new UnderlineExtension(),
      new NodeFormattingExtension({}),
      new HeadingExtension({}),
      new BulletListExtension({}),
      new OrderedListExtension(),
      new LinkExtension({ autoLink, defaultTarget: '_blank' }),
      new CustomSelectAllExtension(),
      new HardBreakExtension(),
    ],
    [autoLink]
  );
  const { manager, state, setState } = useRemirror({
    extensions,
    plugins: singleLine ? [singleLinePlugin()] : [],
    selection: 'start',
    stringHandler: 'html',
    content: value,
  });
  const debouncedValueRef = useRef(value);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedOnChange = useCallback(
    debounce((docContent) => {
      if (!readOnly) onChange?.(docContent);
    }, 300),
    [readOnly]
  );
  useEffect(() => {
    if (!manager || !value) return;

    // Only update the state if the content has actually changed
    const currentContent = prosemirrorNodeToHtml(state.doc);
    if (currentContent === value) return;
    const createProsemirrorState = (htmlContent: string) => {
      const node = htmlToProsemirrorNode({
        content: htmlContent,
        schema: manager.schema,
      });
      return EditorState.create({
        doc: node,
        plugins: state.plugins,
        storedMarks: state.storedMarks,
      });
    };
    let newEditorState = createProsemirrorState(value);
    const { from, to } = state.selection;
    try {
      // Check if the positions are still valid in the new document
      if (
        newEditorState.doc.resolve(from).pos === from &&
        newEditorState.doc.resolve(to).pos === to
      ) {
        // Create a new selection based on the stored range
        const newSelection = TextSelection.create(newEditorState.doc, from, to);
        newEditorState = EditorState.create({
          doc: newEditorState.doc,
          selection: newSelection,
          plugins: state.plugins,
          storedMarks: state.storedMarks,
        });
      }
      // eslint-disable-next-line no-empty
    } catch (err) {}
    setState(newEditorState);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value, manager.schema]);
  return (
    <StyledWrapper
      sx={{
        ...sx,
        width: '100%',
        '.ProseMirror *': {
          color: textColor || 'text.primary',
          fontFamily: 'Base',
        },
        '.ProseMirror ul, ol': {
          margin: 0,
        },
        '& p': {
          marginLeft: '0 !important',
        },
        '.ProseMirror p:not(:last-child), h1:not(:last-child), h2:not(:last-child), h3:not(:last-child)':
          {
            pb: 1,
          },
      }}
      // @ts-ignore
      textColor={textColor}
      className="remirror-theme"
    >
      <Remirror
        manager={manager}
        state={state}
        initialContent={value}
        onChange={(parameter) => {
          setState(parameter.state);
          const docContent = prosemirrorNodeToHtml(parameter.state.doc);
          if (value !== docContent || debouncedValueRef.current !== docContent) {
            debouncedValueRef.current = docContent;
            debouncedOnChange(docContent);
          }
        }}
        editable={!readOnly}
      >
        {children}
        <EditorComponent />
      </Remirror>
    </StyledWrapper>
  );
}
