import { createApi } from '@reduxjs/toolkit/query/react';
import { DefaultImage } from 'src/ui-components/branded-default-image/BrandedDefaultImage';
import { baseQuery } from '../api';
import { employeesApi } from '../employees/employeesApi';

type TrainingLessonStatus = 'draft' | 'published';

export interface Training {
  id: string;
  name: string;
  description: string;
  imageUrl: string | null;
  hasAccess: boolean;
  isPublic: boolean;
  category: TrainingCategory;
  defaultImage: DefaultImage;
}

export interface DetailedTraining extends Training {
  description: string;
  status: string;
  modulesCount: number;
  lessonsCount: number;
  sectionsCount: number;
  estimatedTimeToComplete: number;
}

export interface DetailedTrainingWithAccess extends DetailedTraining {
  blockAccess: boolean;
}

export interface TrainingsAccess {
  id: string;
  hasAccess: boolean;
}

export interface LessonsCount {
  id: string;
  lessonsCount: number;
  status: TrainingLessonStatus;
}
export interface EstimatedTime {
  id: string;
  estimatedTimeToComplete: number;
  status: TrainingLessonStatus;
}
export interface TrainingsProgress {
  id: string;
  progress: number;
}

type TrainingCategory = 'optionalForMe' | 'requiredForMe' | 'requiredPublic' | 'optionalPublic';

export const trainingsApi = createApi({
  reducerPath: 'trainingsApi',
  baseQuery,
  tagTypes: [
    'Trainings',
    'Training',
    'TrainingsByUserId',
    'TrainingLessonsCount',
    'TrainingEstimatedTime',
    'TrainingLessonsCountById',
    'TrainingEstimatedTimeById',
    'TrainingProgress',
    'TrainingsProgressByUserId',
    'TrainingProgressById',
    'TrainingsAccess',
  ],
  endpoints: (builder) => ({
    getTrainings: builder.query<Training[], void>({
      query: () => ({
        url: `/trainings`,
      }),
      providesTags: ['Trainings'],
    }),
    getSingleTraining: builder.query<Training, string>({
      query: (id) => ({
        url: `/trainings/${id}`,
      }),
      providesTags: (_, __, id) => [{ type: 'Training', id }],
    }),
    postTraining: builder.mutation<void, void>({
      query: (data) => ({
        url: `/trainings`,
        method: 'POST',
        body: data,
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(
            trainingsApi.util.invalidateTags([
              'TrainingProgress',
              'Trainings',
              'TrainingsAccess',
              'TrainingsProgressByUserId',
              'TrainingsByUserId',
            ])
          );
          dispatch(employeesApi.util.invalidateTags(['EmployeesTrainingProgress']));
        });
      },
    }),
    patchTraining: builder.mutation<
      void,
      Partial<Omit<Training, 'id' | 'imageUrl'> & { id: string; imageId?: string | null }>
    >({
      query: ({ id, ...rest }) => ({
        url: `/trainings/${id}`,
        method: 'PATCH',
        body: { ...rest },
      }),
      invalidatesTags: (_, __, { id }) => [
        'Trainings',
        'TrainingsByUserId',
        { type: 'Training', id },
      ],
    }),
    deleteTrainings: builder.mutation<void, string>({
      query: (id) => ({
        url: `/trainings/${id}`,
        method: 'DELETE',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          trainingsApi.util.invalidateTags([
            'TrainingsByUserId',
            'Trainings',
            'TrainingsAccess',
            'TrainingsProgressByUserId',
          ]);
          dispatch(trainingsApi.endpoints.getTrainings.initiate(undefined, { forceRefetch: true }));
          dispatch(employeesApi.util.invalidateTags(['EmployeesTrainingProgress']));
        });
      },
    }),
    duplicateTrainings: builder.mutation<void, string>({
      query: (id) => ({
        url: `/trainings/${id}/duplicate`,
        method: 'POST',
      }),
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then(() => {
          dispatch(
            trainingsApi.util.invalidateTags([
              'TrainingsByUserId',
              'Trainings',
              'TrainingsAccess',
              'TrainingsProgressByUserId',
            ])
          );
          dispatch(trainingsApi.endpoints.getTrainings.initiate(undefined, { forceRefetch: true }));
          dispatch(employeesApi.util.invalidateTags(['EmployeesTrainingProgress']));
        });
      },
    }),
    trainingLessonsCount: builder.query<LessonsCount[], void>({
      query: () => ({
        url: `/trainings/lessons-count`,
      }),
      providesTags: ['TrainingLessonsCount'],
    }),
    trainingEstimatedTime: builder.query<EstimatedTime[], void>({
      query: () => ({
        url: `/trainings/estimated-time-to-complete`,
      }),
      providesTags: ['TrainingEstimatedTime'],
    }),
    trainingLessonsCountById: builder.query<LessonsCount[], string>({
      query: (trainingId) => ({
        url: `/trainings/${trainingId}/modules/lessons-count`,
      }),
      providesTags: (_, __, trainingId) => [{ type: 'TrainingLessonsCountById', id: trainingId }],
    }),
    trainingEstimatedTimeById: builder.query<EstimatedTime[], string>({
      query: (trainingId) => ({
        url: `/trainings/${trainingId}/modules/estimated-time-to-complete`,
      }),
      providesTags: (_, __, trainingId) => [{ type: 'TrainingEstimatedTimeById', id: trainingId }],
    }),
    trainingProgress: builder.query<TrainingsProgress[], void>({
      query: () => ({
        url: `/trainings/user-progress`,
      }),
      providesTags: ['TrainingProgress'],
    }),
    trainingsProgressByUserId: builder.query<TrainingsProgress[], string>({
      query: (userId) => ({
        url: `/trainings/user-progress?userId=${userId}`,
      }),
      providesTags: ['TrainingsProgressByUserId'],
    }),
    trainingsByUserId: builder.query<DetailedTraining[], string>({
      query: (userId) => ({
        url: `/trainings?userId=${userId}`,
      }),
      providesTags: ['TrainingsByUserId'],
    }),
    trainingProgressById: builder.query<TrainingsProgress, string>({
      query: (trainingId) => ({
        url: `/trainings/${trainingId}/user-progress`,
      }),
      providesTags: (_, __, trainingId) => [{ type: 'TrainingProgressById', id: trainingId }],
    }),
    trainingsAccess: builder.query<TrainingsAccess[], void>({
      query: () => ({
        url: `trainings/has-access`,
        method: 'GET',
      }),
      providesTags: ['TrainingsAccess'],
      keepUnusedDataFor: 0,
    }),
  }),
});

export const {
  useGetTrainingsQuery,
  usePatchTrainingMutation,
  useGetSingleTrainingQuery,
  usePostTrainingMutation,
  useDeleteTrainingsMutation,
  useDuplicateTrainingsMutation,
  useTrainingLessonsCountQuery,
  useTrainingEstimatedTimeQuery,
  useTrainingLessonsCountByIdQuery,
  useTrainingEstimatedTimeByIdQuery,
  useTrainingProgressQuery,
  useTrainingsProgressByUserIdQuery,
  useTrainingProgressByIdQuery,
  useTrainingsByUserIdQuery,
  useTrainingsAccessQuery,
} = trainingsApi;
