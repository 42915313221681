import { useState } from 'react';
import { Stack, Box, Container } from '@mui/material';
import { usePatchFileMutation } from 'src/store/api/driveApi';
import { TabContext } from '@mui/lab';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import { DropzoneProps, useDropzone } from 'react-dropzone';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import DocumentsDataList from './DocumentsDataList';
import DocumentsHeader from './DocumentsHeader';
import { TabValue } from './types';
import { fileUpload } from './helpers/fileUpload';
import useTabsData from './useTabsData';

export default function Documents() {
  const [activeTab, setActiveTab] = useState<TabValue>('documents');
  const [isDragging, setIsDragging] = useState(false);
  const [uploadCount, setUploadCount] = useState(0);
  const { t } = useTranslation();

  const [patchFile] = usePatchFileMutation();

  const hadleUpload = (files: File[]) => {
    setUploadCount(files.length);
    files.forEach((file) => {
      fileUpload({
        file,
        filename: file.name,
        errorMessage: t('validationErrorMessages.UploadFailed'),
        onFileUploadSuccess: (id) => {
          setUploadCount((prev) => (prev - 1 ? prev - 1 : 0));
          showNotification(t('documentsPage.fileSuccessfulUpload'));
          patchFile({ id, uploadStatus: 'uploaded' });
        },
        onFileUploadError: (id) => {
          patchFile({ id, uploadStatus: 'failed' });
          setUploadCount((prev) => (prev - 1 ? prev - 1 : 0));
        },
      });
    });
  };

  const { getRootProps, getInputProps, open } = useDropzone({
    onDrop: (acceptedFiles) => {
      hadleUpload(acceptedFiles);
      setIsDragging(false); // Reset drag state when files are dropped
    },
    noClick: true, // Prevents the file dialog from opening on click
    accept: {
      'file/*': ['*'],
    },
    onDragEnter: () => setIsDragging(true),
    onDragLeave: () => setIsDragging(false),
  });

  const dropzoneProps = {
    ...getRootProps({
      onClick: (e) => {
        e.preventDefault(); // Prevent clicking from triggering the file dialog
      },
    }),
  };

  const { tabsData, isDocumentsDataLoading, isFilesDataLoading } = useTabsData();

  if (isDocumentsDataLoading || isFilesDataLoading) {
    return (
      <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <LottieLogoSpinner />
      </Box>
    );
  }

  return (
    <Stack>
      <TabContext value={activeTab}>
        <DocumentsHeader
          tabsData={tabsData}
          onActiveTabChange={(val) => setActiveTab(val)}
          openUpload={open}
        />
        <Container component={Box} maxWidth="lg">
          <DocumentsDataList
            tabsData={tabsData}
            dropZoneProps={dropzoneProps as DropzoneProps}
            isDragging={isDragging}
            uploadCount={uploadCount}
          />
        </Container>
      </TabContext>
      <input {...getInputProps()} />
    </Stack>
  );
}
