import { Components, Theme } from '@mui/material';

const MuiTextField: Components<Theme>['MuiTextField'] = {
  styleOverrides: {
    root: {
      '.MuiSelect-icon': {
        top: 'calc(50% - 0.75em)',
        right: 14,
      },
    },
  },
  variants: [
    {
      props: { select: true },
      style: {
        '& .MuiSelect-select': {
          paddingRight: '48px !important',
          fontWeight: 500,
        },
      },
    },
    {
      props: { multiline: true },
      style: {
        '& .MuiInputBase-inputMultiline': {
          paddingTop: '0 !important',
          paddingBottom: '0 !important',
        },
        '& .MuiInputBase-multiline': {
          paddingTop: 8,
          paddingBottom: 8,
        },
      },
    },
  ],
};

export default MuiTextField;
