import { memo, useEffect, useState } from 'react';
import { t } from 'i18next';
import validateEmail from 'src/lib/utils/validateEmail';
import { TextField } from '@mui/material';
import { useEmailChangeCooldownQuery } from 'src/store/api/employees/employeesApi';

export interface ValidationResult {
  isError: boolean;
  message?: string;
}

export interface EmployeeEmailTextFieldProps {
  value: string;
  onChange: (value: string) => void;
  dataCy?: string;
  label: string;
  employeeId: string;
}

function EmployeeEmailTextField({
  value,
  onChange,
  dataCy,
  label,
  employeeId,
}: EmployeeEmailTextFieldProps) {
  const isValidEmailSyntax = validateEmail(value);
  const { data } = useEmailChangeCooldownQuery(employeeId, {
    refetchOnMountOrArgChange: true,
    skip: !employeeId,
  });
  const [cooldown, setCooldown] = useState(0);

  useEffect(() => {
    if (data?.cooldown && data.cooldown > 0) {
      const intervalSpan = 1000;
      setCooldown(data.cooldown);
      const interval = setInterval(() => {
        setCooldown((prev) => prev - intervalSpan);
      }, intervalSpan);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [data?.cooldown]);

  const disabled = cooldown > 0;

  const cooldownMinutesCount = Math.ceil(cooldown / 60000);

  let cooldownMessage = '';

  if (cooldownMinutesCount > 0) {
    const timeUnit =
      cooldownMinutesCount > 1
        ? t('employeePage.employeeDetails.changeEmail.minutes')
        : t('employeePage.employeeDetails.changeEmail.minute');

    cooldownMessage = t('employeePage.employeeDetails.changeEmail.waitNotification', {
      time: cooldownMinutesCount,
      timeUnit,
    });
  }

  return (
    <TextField
      data-cy={dataCy}
      fullWidth
      disabled={disabled}
      error={!isValidEmailSyntax}
      helperText={
        disabled
          ? cooldownMessage
          : !isValidEmailSyntax && t('validationErrorMessages.emailErrorMsg')
      }
      value={value}
      label={label}
      size="small"
      onChange={(e) => onChange(e.target.value)}
    />
  );
}

export default memo(EmployeeEmailTextField);
