import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import BaseEditor from '../base-editor/BaseEditor';
import HighlightWrapper from '../highlight-wrapper';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';
import BorderWrapper from '../border-wrapper';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  value?: string;
  readOnly?: boolean;
  onChange?: (val: string) => void;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
  id: string;
}

export default function TextItem({
  onDelete,
  onDuplicate,
  value,
  readOnly = false,
  onChange,
  onResizeStop,
  dragging,
  onResizeStart,
  id,
}: Props) {
  return (
    <HighlightWrapper
      id={id}
      editBarProps={{ baseActionsProps: { onDelete, onDuplicate }, textActionsProps: {} }}
      dragging={dragging}
      horizontalResize={{}}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      readOnly={readOnly}
    >
      {({ EditBarComponent }) => (
        <BorderWrapper readOnly={readOnly}>
          <BaseEditor value={value} readOnly={readOnly} onChange={onChange}>
            {EditBarComponent}
          </BaseEditor>
        </BorderWrapper>
      )}
    </HighlightWrapper>
  );
}
