import { Box, Card, CardContent, Stack, Typography } from '@mui/material';
import {
  Training,
  useTrainingEstimatedTimeQuery,
  useTrainingLessonsCountQuery,
  useTrainingProgressQuery,
} from 'src/store/api/trainings/trainingsApi';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import ImageWithDefaultFallback from 'src/ui-components/image/ImageWithDefaultFallback';
import CircularProgressWithLabel from 'src/ui-components/custom/progress/CircularProgressWithLabel';

const IMAGE_BOX_CLASS_NAME = 'image-box';

export default function SingleTraining({ name, imageUrl, hasAccess, id, defaultImage }: Training) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: estimatedTimeData } = useTrainingEstimatedTimeQuery();
  const { data: lessonsData } = useTrainingLessonsCountQuery();
  const { data: progressData } = useTrainingProgressQuery();
  const { pathname } = useLocation();

  const lessonsCount = lessonsData?.find((item) => item.id === id)?.lessonsCount;
  const estimatedTime = estimatedTimeData?.find((item) => item.id === id)?.estimatedTimeToComplete;
  const progress = progressData?.find((item) => item.id === id)?.progress;

  const lessonsCountLabel = t('lesson', { count: lessonsCount || 0 });
  const estimatedTimeLabel = t('minute', { count: Number(estimatedTime) || 0 });

  const isEditing = pathname.includes('/edit');

  const handleClick = () => {
    navigate(`/trainings/${id}${isEditing ? '/edit' : ''}`, { relative: 'route' });
  };
  return (
    <Card
      onClick={handleClick}
      variant="outlined"
      sx={{
        [`.${IMAGE_BOX_CLASS_NAME} img, .${IMAGE_BOX_CLASS_NAME} svg`]: {
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.short,
              easing: theme.transitions.easing.easeInOut,
            }),
        },
        [`&:hover .${IMAGE_BOX_CLASS_NAME} img, &:hover .${IMAGE_BOX_CLASS_NAME} svg`]: hasAccess
          ? {
              transform: 'scale(1.2)',
            }
          : {},
        cursor: hasAccess ? 'pointer' : 'auto',
        overflow: 'hidden',
      }}
    >
      <Stack>
        <Box
          className={IMAGE_BOX_CLASS_NAME}
          sx={{
            overflow: 'hidden',
            width: '100%',
            height: 190,
          }}
        >
          <ImageWithDefaultFallback src={imageUrl} alt={name} defaultImage={defaultImage as any} />
        </Box>
        <CardContent>
          <Stack direction="row" alignItems="center" justifyContent="space-between">
            <Stack minWidth={0}>
              <Typography variant="h6" noWrap>
                {name || t('trainingDetailPage.unnamedTraining')}
              </Typography>
              <Typography variant="body2" fontWeight="bold" color="grey[500]">
                {lessonsCountLabel} – {estimatedTimeLabel}
              </Typography>
            </Stack>
            <CircularProgressWithLabel value={progress || 0} />
          </Stack>
        </CardContent>
      </Stack>
    </Card>
  );
}
