import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { WorkbaseVideoIcon, WorkbaseVoiceIcon } from 'src/assets/icons/workbaseIcons';
import DeviceSelect from './DeviceSelect';
import ScreenRecorderButton from './ScreenRecorderButton';

interface Props {
  selectedCamera: string;
  onSelectCamera: (camera: string) => void;
  selectedMicrophone: string;
  onSelectMicrophone: (camera: string) => void;
  onScreenStreamSelect: (val: MediaStream | null) => void;
  screenStream: MediaStream | null;
  isRecording: boolean;
  isCountdownActive: boolean;
}

export default React.memo(
  ({
    onSelectCamera,
    selectedCamera,
    onScreenStreamSelect,
    onSelectMicrophone,
    selectedMicrophone,
    screenStream,
    isRecording,
    isCountdownActive,
  }: Props) => {
    const { t } = useTranslation();
    return (
      <Stack gap={5} direction="row" width="100%" alignItems="center">
        <DeviceSelect
          label={{
            icon: WorkbaseVoiceIcon,
            text: t('recording.microphoneSelectLabel'),
          }}
          deviceKind="audioinput"
          onSelect={onSelectMicrophone}
          selectedValue={selectedMicrophone}
        />
        <DeviceSelect
          label={{
            icon: WorkbaseVideoIcon,
            text: t('recording.cameraSelectLabel'),
          }}
          deviceKind="videoinput"
          onSelect={onSelectCamera}
          selectedValue={selectedCamera}
        />
        <ScreenRecorderButton
          disabled={isRecording || isCountdownActive}
          onScreenStreamSelect={onScreenStreamSelect}
          stream={screenStream}
        />
      </Stack>
    );
  }
);
