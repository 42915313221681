import { t } from 'i18next';
import { useEffect } from 'react';
import { useBlocker } from 'react-router-dom';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { FileType } from 'src/features/editor/view/grid/widgets/edit-bar/UploadActions';
import { useAppSelector } from 'src/store';

export default function BlockerComponent() {
  const uploadingMedia = useAppSelector((state) => state.editor.uploadingMedia);

  const mediaType = uploadingMedia.map((el) => el.mediaType)[uploadingMedia.length - 1];

  const mediaDict: Record<FileType, string> = {
    image: t('editor.uploadMediaModal.image'),
    video: t('editor.uploadMediaModal.video'),
    file: t('editor.uploadMediaModal.file'),
  };
  const blocker = useBlocker(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname && uploadingMedia.length !== 0
  );

  useEffect(() => {
    const handleBeforeUnload = (e: any) => {
      if (uploadingMedia?.length !== 0) {
        e.returnValue = 'val';
        return 'val';
      }
      return undefined;
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [uploadingMedia]);

  return (
    <Dialog open={blocker.state === 'blocked' && !!mediaType}>
      <DialogTitle>{t('editor.uploadMediaModal.title')}</DialogTitle>
      <DialogContent>
        {t('editor.uploadMediaModal.content', { media: mediaDict[mediaType] })}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => blocker.reset?.()}>{t('cancel')}</Button>
        <Button color="error" onClick={() => blocker.proceed?.()}>
          {t('proceed')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
