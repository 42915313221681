import { Components, Theme } from '@mui/material';

const MuiToggleButton: Components<Theme>['MuiToggleButton'] = {
  styleOverrides: {
    root: {
      border: 'none !important',
      textTransform: 'unset',
    },
  },
};

export default MuiToggleButton;
