import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseFoldInV2Icon } from '../../../../../../assets/icons/workbaseIcons';

export interface UndoCropActionProps {
  onClick: () => void;
}

export default function UndoCropAction({ onClick }: UndoCropActionProps) {
  const { t } = useTranslation();
  return (
    <Tooltip title={t('editor.topBar.tooltip.undoCrop')} placement="top">
      <IconButton
        onMouseDown={(e) => {
          e.preventDefault();
          onClick();
        }}
      >
        <SvgIcon component={WorkbaseFoldInV2Icon} fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
