import { Divider, Stack, ListItemAvatar, Avatar, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ResourceType } from 'src/store/api/searchResourceApi';
import { ResourceInfo, useSharedResourceInfoQuery } from 'src/store/api/shareResourceApi';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { WorkbaseAccountLockIcon } from 'src/assets/icons/workbaseIcons';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import AccessControlItem from './access-items/AccessControlItem';
import AccessUserItem from './access-items/AccessUserItem';
import AccessDepartmentItem from './access-items/DepartmentAccessItem';
import AccessRoleItem from './access-items/RoleAccessItem';

interface Props {
  resourceId: string;
  resourceType: ResourceType;
  filterDataFn?: (resource: ResourceInfo) => boolean;
}

export default function AccessControlList(props: Props) {
  const { resourceId, resourceType, filterDataFn = () => true } = props;
  const { t } = useTranslation();
  const { data, isLoading } = useSharedResourceInfoQuery({ resourceId, resourceType });
  const { data: userData } = useCurrentUserQuery();
  const currentUserResource = data?.find((resource) => resource.companyUser?.id === userData?.id);
  const isTrainingResourceType = resourceType === 'training';

  const filteredData = data?.filter((resource) => {
    if (resource.companyUser && resource.companyUser.id === userData?.id) {
      return false;
    }
    return filterDataFn(resource);
  });

  function renderResourceItem(resource: ResourceInfo) {
    if (resource.companyUser) {
      return (
        <AccessUserItem
          key={resource.id}
          resource={resource}
          resourceId={resourceId}
          resourceType={resourceType}
          isTrainingResourceType={isTrainingResourceType}
        />
      );
    }
    if (resource.department) {
      return (
        <AccessDepartmentItem
          key={resource.id}
          resource={resource}
          resourceId={resourceId}
          resourceType={resourceType}
          isTrainingResourceType={isTrainingResourceType}
        />
      );
    }
    if (resource.role) {
      return (
        <AccessRoleItem
          key={resource.id}
          resource={resource}
          resourceId={resourceId}
          resourceType={resourceType}
          isTrainingResourceType={isTrainingResourceType}
        />
      );
    }
    return null;
  }

  return (
    <>
      <Divider />
      <Stack maxHeight={220} overflow="auto">
        <AccessControlItem
          prefix={
            <ListItemAvatar>
              <Avatar>
                <SvgIcon component={WorkbaseAccountLockIcon} />
              </Avatar>
            </ListItemAvatar>
          }
          primaryText={t('permissionsShare.adminAccess')}
          secondaryText={t('permissionsShare.adminAccessDescription')}
        />
        {currentUserResource && filterDataFn(currentUserResource) && (
          <AccessUserItem
            key={currentUserResource.id}
            resource={currentUserResource}
            resourceId={resourceId}
            resourceType={resourceType}
            isTrainingResourceType={isTrainingResourceType}
            isCurrentUser
          />
        )}
        {isLoading && <LottieLogoSpinner sx={{ margin: 'auto' }} />}
        {filteredData?.map(renderResourceItem)}
      </Stack>
      <Divider />
    </>
  );
}
