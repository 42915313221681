import { Stack } from '@mui/material';
import { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Logo, usePatchSettingsMutation } from 'src/store/api/settingsApi';
import { imageUpload } from 'src/lib/utils/imageUpload';
import resizeImageFile from 'src/lib/utils/resizeImageFile';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import defaultLogos from 'src/constants/defaultLogos';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { darkModePalette, lightModePalette } from 'src/mui/palette';
import Image from 'src/ui-components/image';

interface Props {
  text: string;
  url: string | null;
  name: Logo;
}

export default function UploadLogo({ text, url, name }: Props) {
  const { t } = useTranslation();
  const [patchSettings] = usePatchSettingsMutation();

  const snackBarHandler = () => {
    showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
  };

  const uploadNewImage = async (file: File): Promise<unknown> => {
    try {
      const response = await imageUpload(file);
      const { id } = response.data.result;
      return await patchSettings({ [name]: id });
    } catch (err) {
      snackBarHandler();
    }
    return undefined;
  };

  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>): Promise<unknown> => {
    const file = e.target.files?.[0];

    if (!file) return undefined;

    if (name === 'siteFavicon' || name === 'siteFaviconDark') {
      const imgSize = 64;
      const croppedFile = await resizeImageFile(file, imgSize);
      if (croppedFile) {
        return uploadNewImage(croppedFile);
      }
      snackBarHandler();
    } else {
      return uploadNewImage(file);
    }
    return undefined;
  };
  const removeImage = async (): Promise<unknown> => patchSettings({ [name]: null });

  const boxStylesDict = {
    siteFavicon: {
      maxWidth: '66px',
    },
    siteFaviconDark: {
      maxWidth: '66px',
    },
    siteLogo: {
      maxWidth: '174px',
    },
    siteLogoDark: {
      maxWidth: '174px',
    },
  };

  const bgcolor =
    name === 'siteFavicon' || name === 'siteLogo'
      ? lightModePalette.background.default
      : darkModePalette.background.default;

  return (
    <Stack
      gap={1.5}
      flexDirection="row"
      alignItems="center"
      sx={{ height: '66px', width: '50%', ...boxStylesDict[name] }}
    >
      <EditableImageUpload
        dataCy={`upload-logo-${name}`}
        src={url || undefined}
        altText={text}
        gutters
        onChange={handleFileChange}
        onClear={removeImage}
        bgcolor={bgcolor}
        fit="contain"
        placeholder={
          <Image
            src={defaultLogos[name].src}
            alt={defaultLogos[name].alt}
            height="100%"
            width="100%"
            fit="contain"
          />
        }
      />
    </Stack>
  );
}
