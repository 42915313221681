import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import DashboardHeader from 'src/views/dashboard/dashboard-header';
import { useAppSelector } from 'src/store';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import EditorWithModeSwitch from 'src/features/editor/view/EditorWithModeSwitch';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { useDashboardQuery } from 'src/store/api/dashboardApi';
import { setSelectedNewsfeedItemIdx, setSelectedRole } from '../../store/client/dashboardSlice';

export default function DashboardView() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { data } = useCurrentUserQuery();
  const { data: dashboardData, isSuccess } = useDashboardQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const selectedRole = useAppSelector((state) => state.dashboard.selectedRole);
  const hasAnyPermission = useHasAnyPermission();
  const hasAdminAccessToDashboardsPages = hasAnyPermission(['ADMIN_ACCESS_DASHBOARD_AND_PAGES']);
  const hasEditingPermissions = data?.isOwner || hasAdminAccessToDashboardsPages;

  useEffect(() => {
    dispatch(setSelectedNewsfeedItemIdx(0));
  }, [dispatch]);

  useEffect(() => {
    if (dashboardData?.roles && dashboardData.roles.length > 0) {
      const roleExists = dashboardData.roles.some((role) => role.id === selectedRole);

      if (selectedRole === undefined || !roleExists) {
        dispatch(setSelectedRole(dashboardData.roles[0].id));
      }
    }
  }, [selectedRole, dashboardData?.roles, dispatch]);

  const renderHeader = useCallback(
    (readOnly: boolean) => <DashboardHeader readOnly={readOnly} />,
    []
  );

  if (!selectedRole || !isSuccess) return null;

  if (!['/', '/edit'].includes(location.pathname)) {
    return <Navigate to="/" replace />;
  }

  return (
    <EditorWithModeSwitch
      canEdit={!!hasEditingPermissions}
      canView
      renderHeader={renderHeader}
      documentName={`role.${selectedRole}`}
    />
  );
}
