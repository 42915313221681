import {
  Box,
  Divider,
  Typography,
  useTheme,
  IconButton,
  SvgIcon,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  styled,
} from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkBreaks from 'remark-breaks';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { materialDark, materialLight } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { WorkbaseCopyDuplicateIcon } from 'src/assets/icons/workbaseIcons';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { memo } from 'react';

function extractPathname(url: string) {
  try {
    const parsedUrl = new URL(url);
    return parsedUrl.pathname;
  } catch (error) {
    return '';
  }
}

function Paragraph({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="body1" color="primary.contrastText" component="p">
      {children}
    </Typography>
  );
}
function Heading1({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="h4" color="primary.contrastText">
      {children}
    </Typography>
  );
}

function Heading2({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="h5" color="primary.contrastText">
      {children}
    </Typography>
  );
}

function Heading3({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="h6" color="primary.contrastText">
      {children}
    </Typography>
  );
}

function Strong({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="body1" component="strong" fontWeight="bold" color="primary.contrastText">
      {children}
    </Typography>
  );
}

function Emphasis({ children }: { children: React.ReactNode }) {
  return (
    <Typography variant="body1" fontStyle="italic" color="primary.contrastText">
      {children}
    </Typography>
  );
}

function Anchor({ href, children }: { href?: string; children: React.ReactNode }) {
  const navigate = useNavigate();
  return (
    <Typography
      component="span"
      onClick={() => navigate(extractPathname(href as string))}
      color="primary.contrastText"
      sx={{ cursor: 'pointer', textDecoration: 'underline' }}
    >
      {children}
    </Typography>
  );
}

function Blockquote({ children }: { children: React.ReactNode }) {
  return (
    <Box component="blockquote" borderLeft={2} pl={2} color="primary.contrastText">
      {children}
    </Box>
  );
}

function CodeBlock({
  className,
  children,
  ...props
}: {
  className?: string;
  children: React.ReactNode;
  handleCopy: (text: string) => void;
  theme: any;
}) {
  const theme = useTheme();
  const { t } = useTranslation();
  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text).then(() => {
      showNotification(t('copiedToClipboard'));
    });
  };
  const match = /language-(\w+)/.exec(className || '');
  const codeString = String(children).replace(/\n$/, '');
  return match ? (
    <Box
      position="relative"
      sx={{
        '& .code-block': {
          borderRadius: `${theme.shape.borderRadius}px !important`,
        },
      }}
    >
      <Box position="absolute" top={1} right={1} zIndex={2}>
        <IconButton size="small" onClick={() => handleCopy(codeString)}>
          <SvgIcon component={WorkbaseCopyDuplicateIcon} />
        </IconButton>
      </Box>
      <SyntaxHighlighter
        style={theme.palette.mode === 'dark' ? materialDark : materialLight}
        language={match[1]}
        useInlineStyles
        className="code-block"
        PreTag="div"
        {...props}
        codeTagProps={{
          style: { fontSize: '.8em' },
        }}
      >
        {codeString}
      </SyntaxHighlighter>
    </Box>
  ) : (
    <code {...props}>{children}</code>
  );
}

function HorizontalRule() {
  return <Divider />;
}

function UnorderedList({ children }: { children: React.ReactNode }) {
  return (
    <Box component="ul" color="primary.contrastText">
      {children}
    </Box>
  );
}

function OrderedList({ children }: { children: React.ReactNode }) {
  return (
    <Box component="ol" color="primary.contrastText">
      {children}
    </Box>
  );
}

function MdTable({ children }: { children: React.ReactNode }) {
  return (
    <TableContainer
      component={Box}
      borderRadius={1}
      p={1}
      bgcolor="action.hover"
      color="primary.contrastText"
    >
      <Table size="small">{children}</Table>
    </TableContainer>
  );
}

function MdTableHead({ children }: { children: React.ReactNode }) {
  return <TableHead>{children}</TableHead>;
}

function MdTableBody({ children }: { children: React.ReactNode }) {
  return <TableBody>{children}</TableBody>;
}

function MdTableRow({ children }: { children: React.ReactNode }) {
  return <TableRow>{children}</TableRow>;
}

function MdTableCell({ children }: { children: React.ReactNode }) {
  return (
    <TableCell
      size="small"
      align="left"
      sx={{
        whiteSpace: 'nowrap', // Ensure the text doesn't wrap
        overflow: 'hidden', // Hide overflowing text
        textOverflow: 'ellipsis', // Show ellipsis when text overflows
        maxWidth: '215px',
        color: 'primary.contrastText',
      }}
    >
      {children}
    </TableCell>
  );
}

const StyledMarkdown = styled(ReactMarkdown)`
  p {
    padding-bottom: 10px;
  }
`;

function MarkdownMessage({ message }: { message: string }) {
  const components = {
    p: Paragraph,
    h1: Heading1,
    h2: Heading2,
    h3: Heading3,
    strong: Strong,
    em: Emphasis,
    a: Anchor,
    blockquote: Blockquote,
    code: CodeBlock,
    hr: HorizontalRule,
    ul: UnorderedList,
    ol: OrderedList,
    table: MdTable,
    thead: MdTableHead,
    tbody: MdTableBody,
    tr: MdTableRow,
    td: MdTableCell,
  };

  return (
    // marking components any because react-markdown doesnt accept the react-syntax-highlighter as code component
    <StyledMarkdown remarkPlugins={[remarkGfm, remarkBreaks]} components={components as any}>
      {message}
    </StyledMarkdown>
  );
}

export default memo(MarkdownMessage);
