import { Stack, Typography, IconButton, SvgIcon, Box } from '@mui/material';
import { WorkbaseCloseIcon } from 'src/assets/icons/workbaseIcons';
import Image from 'src/ui-components/image';

interface AttachmentImageProps {
  imageUrl: string;
  fileName: string;
  readonly: boolean;
  onClose?: () => void;
}

export default function AttachmentImage({
  imageUrl,
  fileName,
  readonly,
  onClose,
}: AttachmentImageProps) {
  return (
    <Stack direction="row" alignItems="center" justifyContent="space-between" width="100%">
      <Stack direction="row" gap={1} alignItems="center" maxWidth="100%" overflow="hidden">
        <Box borderRadius={1} bgcolor="background.default" p={0.25}>
          <Image src={imageUrl} alt={fileName} width={40} height={40} />
        </Box>
        <Typography noWrap variant="caption" flexShrink={0.75}>
          {fileName}
        </Typography>
      </Stack>
      {!readonly && (
        <IconButton size="small" onClick={onClose}>
          <SvgIcon component={WorkbaseCloseIcon} fontSize="inherit" />
        </IconButton>
      )}
    </Stack>
  );
}
