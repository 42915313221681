import { MenuItem, Select, Stack, Typography } from '@mui/material';
import { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSelectedRole } from 'src/store/client/dashboardSlice';
import { useAppSelector } from 'src/store';
import { useDashboardQuery } from 'src/store/api/dashboardApi';
import { WorkbaseChevronDownIcon } from 'src/assets/icons/workbaseIcons';
import useIsMobile from 'src/hooks/useIsMobile';

export default function RoleSelector() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const selectedRole = useAppSelector((state) => state.dashboard.selectedRole);
  const { data: dashboardData } = useDashboardQuery();
  const options = useMemo(
    () =>
      (dashboardData?.roles || []).map((role) => ({
        value: role.id,
        label: `${role.department.name} - ${role.name}`,
      })),
    [dashboardData?.roles]
  );

  const isMobile = useIsMobile();

  if (options.length <= 1) return null;

  return (
    <Stack
      direction={isMobile ? 'column' : 'row'}
      justifyContent="space-between"
      alignItems="center"
      gap={2}
    >
      <Typography variant="body1" fontWeight="medium" noWrap flexShrink={0}>
        {t('dashboardPage.header.roleSelectorQuestion')}
      </Typography>
      <Select
        onChange={(e) => dispatch(setSelectedRole(e.target.value))}
        size="small"
        value={selectedRole}
        fullWidth
        IconComponent={WorkbaseChevronDownIcon}
        MenuProps={{
          PaperProps: {
            style: {
              maxHeight: 300,
              // makes popover match select width
              width: 0,
            },
          },
        }}
      >
        {options.map(({ value, label }) => (
          <MenuItem key={value} value={value}>
            <Typography noWrap>{label}</Typography>
          </MenuItem>
        ))}
      </Select>
    </Stack>
  );
}
