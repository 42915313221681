import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { useParams } from 'react-router-dom';
import HighlightWrapper from 'src/features/editor/view/grid/widgets/highlight-wrapper';
import { ResizeStopCallbackType } from 'src/features/editor/view/grid/widgets/highlight-wrapper/resize/ResizableWrapper';
import { Stack } from '@mui/material';
import {
  useTrainingModulesByTrainingIdQuery,
  TrainingModuleWithStatus,
  useTrainingModulesProgressByTrainingIdQuery,
} from 'src/store/api/trainings/trainingModulesApi';
import {
  useTrainingEstimatedTimeByIdQuery,
  useTrainingLessonsCountByIdQuery,
} from 'src/store/api/trainings/trainingsApi';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import ReadOnlyModules from './ReadOnlyModules';
import Title from './Title';
import EmptyPlaceholder from './EmptyPlaceholder';
import EditingModules from './editing-modules';

export interface TrainingModulesWidgetProps {
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
  id: string;
}

export default function TrainingModulesWidget({
  readOnly = false,
  onResizeStop,
  dragging,
  onResizeStart,
  id,
}: TrainingModulesWidgetProps) {
  const param = useParams() as { trainingId: string };

  const { data, isLoading, isSuccess } = useTrainingModulesByTrainingIdQuery(param.trainingId, {
    skip: !param.trainingId,
  });
  const { data: estimatedTimeData } = useTrainingEstimatedTimeByIdQuery(
    param.trainingId as string,
    { skip: !param.trainingId }
  );
  const { data: lessonsCountData } = useTrainingLessonsCountByIdQuery(param.trainingId as string, {
    skip: !param.trainingId,
  });
  const { data: progressData } = useTrainingModulesProgressByTrainingIdQuery(
    param.trainingId as string,
    { skip: !param.trainingId }
  );

  const publishedModules = data?.filter(({ status }) => status === 'published');

  const displayData = !isSuccess ? [] : data;

  if (
    readOnly &&
    (!displayData || displayData.length === 0 || !publishedModules || publishedModules.length === 0)
  ) {
    return null;
  }

  return (
    <HighlightWrapper
      id={id}
      hideEditbar
      dragging={dragging}
      readOnly={readOnly}
      horizontalResize={{}}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
    >
      {() => (
        <Stack width="100%" minHeight={100}>
          <Title />
          {isLoading && <LottieLogoSpinner sx={{ margin: 'auto' }} />}
          {displayData && (
            <>
              {displayData.length === 0 && <EmptyPlaceholder />}
              <Stack>
                {readOnly ? (
                  <Stack gap={2.5}>
                    <ReadOnlyModules
                      modules={publishedModules as TrainingModuleWithStatus[]}
                      estimatedTimeData={estimatedTimeData}
                      lessonsCountData={lessonsCountData}
                      progressData={progressData}
                    />
                  </Stack>
                ) : (
                  <EditingModules
                    modules={displayData}
                    estimatedTimeData={estimatedTimeData}
                    lessonsCountData={lessonsCountData}
                    progressData={progressData}
                  />
                )}
              </Stack>
            </>
          )}
        </Stack>
      )}
    </HighlightWrapper>
  );
}
