import { ChangeEvent } from 'react';
import { Stack, SvgIcon, TextField, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseRoleIcon } from 'src/assets/icons/workbaseIcons';
import { useAppDispatch } from 'src/store';
import { Department, Role } from '../../../model/types';
import { editRoleName } from '../../../controller/departments.slice';
import { useRoleValidity } from '../useRoleValidity';

interface RoleTextfieldProps {
  role: Role;
  department: Department;
  inputRoleRef?: React.RefObject<HTMLInputElement>;
}

export default function RoleTextfield({ role, department, inputRoleRef }: RoleTextfieldProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { setRoleBlured, showRoleError, validationMessage } = useRoleValidity(
    role.name,
    role?.id,
    department.id
  );

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(
      editRoleName({ departmentId: department.id, roleId: role.id, rolename: e.target.value })
    );
  };
  const handleBlur = () => {
    setRoleBlured(true);
  };

  return (
    <Stack direction="row" gap={2} width="100%">
      <Stack direction="row" gap={1} alignItems="center">
        <SvgIcon component={WorkbaseRoleIcon} fontSize="small" color="primary" />
        <Typography variant="h6" fontWeight="bold" color="primary">
          {t('companySettings.sidebar.departments.collapsibleDepartmentContentTitle')}
        </Typography>
      </Stack>
      <TextField
        fullWidth
        variant="outlined"
        data-cy="role-textfield"
        inputRef={inputRoleRef}
        slotProps={{
          input: {
            draggable: true,
          },
        }}
        onDragStart={(e) => {
          e.stopPropagation();
          e.preventDefault();
        }}
        size="small"
        value={role.name}
        onChange={(e) => handleChange(e)}
        onBlur={handleBlur}
        error={showRoleError}
        helperText={showRoleError && validationMessage}
      />
    </Stack>
  );
}
