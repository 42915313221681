import { useRef } from 'react';
import { DropzoneInputProps } from 'react-dropzone';
import { IconButton, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon } from '@mui/material';
import { t } from 'i18next';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import {
  WorkbaseDeleteSquareIcon,
  WorkbaseImageNewIcon,
  WorkbaseImageRemoveIcon,
} from 'src/assets/icons/workbaseIcons';

interface Props {
  faviconUrl?: string;
  onRemoveFavicon?: () => void;
  getInputProps: <T extends DropzoneInputProps>(props?: T | undefined) => T | null;
  onFaviconChange?: (e: any) => void;
}
export default function FaviconMenu({
  faviconUrl,
  onRemoveFavicon,
  getInputProps,
  onFaviconChange,
}: Props) {
  const fileInputRef = useRef<HTMLInputElement>(null);
  const popupState = usePopupState({ variant: 'popover' });

  const handleEditFaviconClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const popoverProps = bindPopover(popupState);

  return (
    <>
      {faviconUrl ? (
        <>
          <IconButton {...bindTrigger(popupState)} size="small" color="inherit">
            <SvgIcon component={WorkbaseImageRemoveIcon} />
          </IconButton>
          <Menu
            {...popoverProps}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            <MenuItem
              onClick={() => {
                handleEditFaviconClick();
                popoverProps.onClose();
              }}
            >
              <ListItemIcon>
                <SvgIcon component={WorkbaseImageNewIcon} />
              </ListItemIcon>
              <ListItemText primary={t('links.changeFavicon')} />
            </MenuItem>
            <MenuItem
              onClick={() => {
                onRemoveFavicon?.();
                popoverProps.onClose();
              }}
            >
              <ListItemIcon>
                <SvgIcon component={WorkbaseDeleteSquareIcon} />
              </ListItemIcon>
              <ListItemText primary={t('links.removeFavicon')} />
            </MenuItem>
          </Menu>
        </>
      ) : (
        <IconButton onClick={handleEditFaviconClick} size="small" color="inherit">
          <SvgIcon component={WorkbaseImageNewIcon} />
        </IconButton>
      )}
      <input
        {...getInputProps()}
        ref={fileInputRef}
        type="file"
        accept=".jpg, .jpeg, .jfif, .pjpeg, .pjp, .gif, .apng, .png, .avif, .svg, .webp"
        onChange={(e: any) => {
          onFaviconChange?.(e.target.files?.[0]);
          popoverProps.onClose();
        }}
      />
    </>
  );
}
