import { MenuItem, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import { t } from 'i18next';
import { updateEmployeeDetails } from 'src/features/employees/controller/employee.slice';
import EmployeeFormSection from './EmployeeFormSection';

interface Props {
  items: {
    key: string;
    text: string;
    label: string;
  }[];
}

export default function CompensationDetailsSection({ items }: Props) {
  const dispatch = useDispatch();

  const payFrequencyOptions = [
    { value: 'monthly', label: t('employeePage.employeeDetails.compensationDetails.monthly') },
    {
      value: 'biWeekly',
      label: t('employeePage.employeeDetails.compensationDetails.biWeekly'),
    },
    { value: 'weekly', label: t('employeePage.employeeDetails.compensationDetails.weekly') },
  ];

  type Option = {
    value: string;
    label: string;
  };

  const getValueFromLabel = (label: string, options: Option[]): string => {
    const option = options.find((opt) => opt.label === label);
    return option ? option.value : '';
  };

  return (
    <EmployeeFormSection>
      {items.map(({ key, text, label }) => {
        if (key === 'bankAccount') {
          return (
            <TextField
              label={label}
              data-сy="employee-compensation-details-bank-account-number-selector"
              value={text}
              onChange={(e) =>
                dispatch(
                  updateEmployeeDetails({
                    keyPath: 'compensationDetails.bankAccount',
                    value: e.target.value,
                  })
                )
              }
              fullWidth
              size="small"
            />
          );
        }
        if (key === 'salary') {
          return (
            <TextField
              label={label}
              data-сy="employee-compensation-details-salary-selector"
              value={text}
              onChange={(e) =>
                dispatch(
                  updateEmployeeDetails({
                    keyPath: 'compensationDetails.salary',
                    value: e.target.value,
                  })
                )
              }
              fullWidth
              size="small"
            />
          );
        }
        if (key === 'bonus') {
          return (
            <TextField
              label={label}
              data-сy="employee-compensation-details-bonus-selector"
              value={text}
              onChange={(e) =>
                dispatch(
                  updateEmployeeDetails({
                    keyPath: 'compensationDetails.bonus',
                    value: e.target.value,
                  })
                )
              }
              fullWidth
              size="small"
            />
          );
        }
        if (key === 'currency') {
          return (
            <TextField
              label={label}
              data-сy="employee-compensation-details-currency-selector"
              value={text}
              onChange={(e) =>
                dispatch(
                  updateEmployeeDetails({
                    keyPath: 'compensationDetails.currency',
                    value: e.target.value,
                  })
                )
              }
              fullWidth
              size="small"
            />
          );
        }
        if (key === 'payFrequency') {
          return (
            <TextField
              select
              fullWidth
              label={label}
              data-cy="employee-compensation-details-payFrequency-selector"
              value={getValueFromLabel(text, payFrequencyOptions)}
              onChange={(event) => {
                const newValue = event.target.value;
                if (text !== newValue) {
                  dispatch(
                    updateEmployeeDetails({
                      keyPath: 'compensationDetails.payFrequency',
                      value: newValue,
                    })
                  );
                }
              }}
              size="small"
            >
              {payFrequencyOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          );
        }

        return null;
      })}
    </EmployeeFormSection>
  );
}
