import { Button, Stack, SvgIcon } from '@mui/material';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import { useTranslation } from 'react-i18next';
import { WorkbaseAddSquareIcon } from 'src/assets/icons/workbaseIcons';
import { ResourceType, useLinksQuery } from 'src/store/api/linksApi';
import AddEditLinkModal from './AddEditLinkModal';
import Link from './Link';

interface Props {
  readOnly: boolean;
  resourceId: string;
  resourceType: ResourceType;
}

export default function Links({ readOnly, resourceId, resourceType }: Props) {
  const { data } = useLinksQuery({ resourceId, resourceType });
  const { t } = useTranslation();
  const addLinkDialog = useBoolean();
  if (data && data.length === 0 && readOnly) return null;
  return (
    <Stack gap={2.5} direction="row" flexWrap="wrap" sx={{ '& > *': { flexGrow: 1 } }}>
      {data?.map(({ url, name, id, imageUrl }) => (
        // eslint-disable-next-line
        <Link
          id={id}
          resourceId={resourceId}
          resourceType={resourceType}
          key={id}
          url={url}
          imageUrl={imageUrl || undefined}
          readOnly={readOnly}
          linkName={name}
        />
      ))}
      {!readOnly && (
        <Button
          onClick={() => addLinkDialog.onTrue()}
          startIcon={
            <SvgIcon fontSize="inherit">
              <WorkbaseAddSquareIcon />
            </SvgIcon>
          }
        >
          {t('links.addLinkButton')}
        </Button>
      )}
      {!readOnly && (
        <AddEditLinkModal
          resourceId={resourceId}
          resourceType={resourceType}
          open={addLinkDialog.value}
          onClose={() => addLinkDialog.onFalse()}
        />
      )}
    </Stack>
  );
}
