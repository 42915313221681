import { Divider, Grid2 } from '@mui/material';

interface Props {
  leftContent: React.ReactNode;
  rightContent: React.ReactNode;
}

function SplitPaneLayout({ leftContent, rightContent }: Props) {
  return (
    <Grid2 container height="100%" overflow="hidden">
      <Grid2
        height="100%"
        size={{
          xs: 'auto',
          sm: 'auto',
          md: 5,
          lg: 4,
          xl: 2.75,
        }}
      >
        {leftContent}
      </Grid2>
      <Divider orientation="vertical" sx={{ ml: '-1px' }} flexItem />
      <Grid2
        size="grow"
        height="100%"
        position="relative"
        sx={{ overflowY: 'auto', display: 'flex', flexDirection: 'column' }}
      >
        {rightContent}
      </Grid2>
    </Grid2>
  );
}

export default SplitPaneLayout;
