import { Chip, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseDepartmentsIcon } from 'src/assets/icons/workbaseIcons';
import { Employee, EmployeeStatus } from 'src/features/employees/model';

const typographyColor = 'primary.contrastText';

export default function HeaderReadOnly({ employee }: { employee: Employee }) {
  const { t } = useTranslation();

  const employeeStatusDict: Record<
    EmployeeStatus,
    { label: string; color: string; backgroundColor: string }
  > = {
    active: {
      label: t('employeePage.employeeDetails.activeLabelChip'),
      color: 'success.main',
      backgroundColor: 'green.light',
    },
    invited: {
      label: t('employeePage.employeeDetails.invitedLabelChip'),
      color: 'purple.main',
      backgroundColor: 'purple.light',
    },
  };

  const uniqueDepartments =
    Array.from(new Set(employee?.roles.map((role) => role.department.name))) || [];

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        gap={2}
        width="100%"
        maxWidth="100%"
        flexShrink={1}
        minWidth={0}
        color={typographyColor}
      >
        <Stack direction="row" gap={2} alignItems="center" flexShrink={1} overflow="hidden">
          <Typography
            variant="h4"
            noWrap
            fontWeight="bold"
            data-cy="employee-name"
            overflow="hidden"
            minWidth={0}
            flexShrink={1}
          >
            {employee?.firstName || employee?.lastName ? `${employee?.firstName}` : employee?.email}
          </Typography>
          <Typography noWrap variant="h4" fontWeight="bold" data-cy="employee-lastName">
            {employee?.lastName ? `${employee?.lastName}` : ''}
          </Typography>
        </Stack>
        <Chip
          data-cy="employee-status"
          label={employeeStatusDict[employee.status].label}
          color={employee.status === 'active' ? 'success' : 'secondary'}
        />
      </Stack>
      <Stack direction="row" justifyContent="center" alignItems="center" gap={1} maxWidth="100%">
        <SvgIcon component={WorkbaseDepartmentsIcon} fontSize="small" color="inherit" />
        <Typography data-cy="employee-unique-departments" variant="body1" fontWeight="medium">
          {t('employeePage.employeeDetails.departmentLabel')}: {uniqueDepartments.join(', ')}
        </Typography>
      </Stack>
    </>
  );
}
