import { createApi } from '@reduxjs/toolkit/query/react';
import { DefaultImage } from 'src/ui-components/branded-default-image/BrandedDefaultImage';
import { baseQuery } from '../api';
import { TrainingsProgress, trainingsApi } from './trainingsApi';
import { employeesApi } from '../employees/employeesApi';

export type TrainingModuleStatus = 'draft' | 'published';

export interface TrainingModule {
  id: string;
  name: string;
  imageUrl: string | null;
  trainingId: string;
  order: number;
  defaultImage: DefaultImage;
  sectionsCount: number;
  lessonsCount: number;
  estimatedTimeToComplete?: number;
}

export interface TrainingModuleWithStatus extends TrainingModule {
  status: TrainingModuleStatus;
}

interface LessonsCount {
  id: string;
  lessonsCount: number;
}
interface EstimatedTime {
  id: string;
  estimatedTimeToComplete: number;
}

interface TrainingModuleProgressByUserId {
  id: string;
  progress: number;
  status: TrainingModuleStatus;
}

export const trainingModulesApi = createApi({
  reducerPath: 'trainingModulesApi',
  baseQuery,
  tagTypes: [
    'AllTrainingModules',
    'TrainingModule',
    'TrainingModuleLessonsCount',
    'TrainingModuleEstimatedTime',
    'TrainingModulesProgress',
    'TrainingModulesByTrainingId',
    'TrainingModulesProgressByTrainingId',
    'TrainingModulesProgressByTrainingAndUserId',
  ],
  endpoints: (builder) => ({
    // queries
    allTrainingModules: builder.query<TrainingModuleWithStatus[], void>({
      query: () => ({
        url: `/training-modules`,
      }),
      providesTags: ['AllTrainingModules'],
    }),
    trainingModule: builder.query<TrainingModuleWithStatus, string>({
      query: (id) => ({
        url: `/training-modules/${id}`,
      }),
      providesTags: ['TrainingModule'],
    }),
    trainingModulesByTrainingId: builder.query<TrainingModuleWithStatus[], string>({
      query: (trainingId) => ({
        url: `/trainings/${trainingId}/modules`,
      }),
      providesTags: (_, __, id) => [{ type: 'TrainingModulesByTrainingId', id }],
    }),
    // mutations
    postTrainingModule: builder.mutation<
      TrainingModuleWithStatus,
      {
        name?: string;
        status?: TrainingModuleStatus;
        imageId?: string;
        trainingId: string;
        sectionsCount?: number;
      }
    >({
      query: (data) => ({
        url: `/training-modules`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: (_, __, { trainingId }) => [
        'AllTrainingModules',
        { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
        { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
      ],
      onQueryStarted: (_, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            trainingModulesApi.util.updateQueryData(
              'trainingModulesByTrainingId',
              response.data.trainingId,
              (draft) => [...draft, response.data]
            )
          );
        });
      },
    }),
    patchTrainingModule: builder.mutation<
      void,
      {
        module: {
          id: string;
          name?: string;
          status?: TrainingModuleStatus;
          imageId?: string | null;
          trainingId: string;
        };
        imageSrc?: string;
      }
    >({
      query: ({ module }) => ({
        url: `/training-modules/${module.id}`,
        method: 'PATCH',
        body: { ...module, id: undefined },
      }),
      invalidatesTags: (_, __, { module }) => [
        'AllTrainingModules',
        'TrainingModule',
        { type: 'TrainingModulesProgressByTrainingId', id: module.trainingId },
        { type: 'TrainingModulesProgressByTrainingAndUserId', id: module.trainingId },
        { type: 'TrainingModulesByTrainingId', id: module.trainingId },
      ],
      onQueryStarted: ({ module, imageSrc }, { dispatch, queryFulfilled }) => {
        const disp = dispatch(
          trainingModulesApi.util.updateQueryData(
            'trainingModulesByTrainingId',
            module.trainingId,
            (draft) =>
              draft.map((mod) =>
                mod.id === module.id
                  ? { ...mod, ...module, imageUrl: imageSrc || mod.imageUrl }
                  : mod
              )
          )
        );
        queryFulfilled
          .then(() => {
            dispatch(
              trainingsApi.util.invalidateTags([
                'TrainingLessonsCount',
                'TrainingEstimatedTime',
                'TrainingProgress',
                'TrainingsProgressByUserId',
                'TrainingsByUserId',
                { type: 'TrainingLessonsCountById', id: module.trainingId },
                { type: 'TrainingEstimatedTimeById', id: module.trainingId },
                { type: 'TrainingProgressById', id: module.trainingId },
              ])
            );
          })
          .catch(disp.undo);
      },
    }),
    deleteTrainingModule: builder.mutation<
      void,
      Pick<TrainingModuleWithStatus, 'id' | 'trainingId'>
    >({
      query: ({ id }) => ({
        url: `/training-modules/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: (_, __, { id, trainingId }) => [
        'AllTrainingModules',
        'TrainingModule',
        'TrainingModuleEstimatedTime',
        'TrainingModuleLessonsCount',
        { type: 'TrainingModulesProgress', id },
        { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
        { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
      ],
      onQueryStarted: ({ id, trainingId }, { dispatch, queryFulfilled }) => {
        const disp = dispatch(
          trainingModulesApi.util.updateQueryData(
            'trainingModulesByTrainingId',
            trainingId,
            (draft) => draft.filter((module) => module.id !== id)
          )
        );
        queryFulfilled
          .then(() => {
            dispatch(
              trainingsApi.util.invalidateTags([
                'TrainingLessonsCount',
                'TrainingEstimatedTime',
                'TrainingProgress',
                'TrainingsProgressByUserId',
                'TrainingsByUserId',
                { type: 'TrainingLessonsCountById', id: trainingId },
                { type: 'TrainingEstimatedTimeById', id: trainingId },
                { type: 'TrainingProgressById', id: trainingId },
              ])
            );
            dispatch(employeesApi.util.invalidateTags(['EmployeesTrainingProgress']));
          })
          .catch(disp.undo);
      },
    }),
    duplicateTrainingModule: builder.mutation<
      TrainingModuleWithStatus,
      { trainingId: string; moduleId: string }
    >({
      query: ({ trainingId, moduleId }) => ({
        url: `/training-modules/${moduleId}/duplicate`,
        method: 'POST',
        body: {
          trainingId,
        },
      }),
      invalidatesTags: (_, __, { trainingId, moduleId }) => [
        'AllTrainingModules',
        'TrainingModuleEstimatedTime',
        'TrainingModuleLessonsCount',
        { type: 'TrainingModulesProgress', id: moduleId },
        { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
        { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
      ],
      onQueryStarted: ({ moduleId, trainingId }, { dispatch, queryFulfilled }) => {
        queryFulfilled.then((response) => {
          dispatch(
            trainingModulesApi.util.updateQueryData(
              'trainingModulesByTrainingId',
              trainingId,
              (draft) => {
                const moduleIndex = draft.findIndex((module) => module.id === moduleId);
                if (moduleIndex >= 0) {
                  draft.splice(moduleIndex + 1, 0, response.data);
                }
                return draft;
              }
            )
          );
          dispatch(
            trainingsApi.util.invalidateTags([
              'TrainingLessonsCount',
              'TrainingEstimatedTime',
              'TrainingProgress',
              'TrainingsProgressByUserId',
              'TrainingsByUserId',
              { type: 'TrainingLessonsCountById', id: trainingId },
              { type: 'TrainingEstimatedTimeById', id: trainingId },
              { type: 'TrainingProgressById', id: trainingId },
            ])
          );
          dispatch(employeesApi.util.invalidateTags(['EmployeesTrainingProgress']));
        });
      },
    }),
    moveTrainingModuleToNewPosition: builder.mutation<
      void,
      { order: number; moduleId: string; trainingId: string }
    >({
      query: ({ order, moduleId }) => ({
        url: `/training-modules/${moduleId}`,
        method: 'PATCH',
        body: {
          order,
        },
      }),
      invalidatesTags: ['AllTrainingModules', 'TrainingModule'],
      onQueryStarted: async ({ moduleId, order, trainingId }, { dispatch, queryFulfilled }) => {
        const undoAction = dispatch(
          trainingModulesApi.util.updateQueryData(
            'trainingModulesByTrainingId',
            trainingId,
            (draft) => {
              const moduleIdx = draft.findIndex((module) => module.id === moduleId);
              if (moduleIdx === -1) return;
              const [moduleToMove] = draft.splice(moduleIdx, 1);
              draft.splice(order - 1, 0, moduleToMove);
            }
          )
        );
        try {
          await queryFulfilled;
        } catch {
          queryFulfilled.catch(undoAction.undo);
        }
      },
    }),
    trainingModuleLessonsCount: builder.query<LessonsCount[], void>({
      query: () => ({
        url: `/training-modules/lessons-count`,
      }),
      providesTags: ['TrainingModuleLessonsCount'],
    }),
    trainingModuleEstimatedTime: builder.query<EstimatedTime[], void>({
      query: () => ({
        url: `/training-modules/estimated-time-to-complete`,
      }),
      providesTags: ['TrainingModuleEstimatedTime'],
    }),
    trainingModulesProgress: builder.query<TrainingsProgress, string>({
      query: (id) => ({
        url: `training-modules/${id}/user-progress`,
      }),
      providesTags: (_, __, id) => [{ type: 'TrainingModulesProgress', id }],
    }),
    trainingModulesProgressByTrainingId: builder.query<TrainingModuleProgressByUserId[], string>({
      query: (trainingId) => ({
        url: `/trainings/${trainingId}/modules/user-progress`,
      }),
      providesTags: (_, __, trainingId) => [
        { type: 'TrainingModulesProgressByTrainingId', id: trainingId },
        { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
      ],
    }),
    trainingModulesProgressByTrainingAndUserId: builder.query<
      TrainingModuleProgressByUserId[],
      { trainingId: string; userId?: string }
    >({
      query: ({ trainingId, userId }) => ({
        url: `/trainings/${trainingId}/modules/user-progress?userId=${userId}`,
      }),
      providesTags: (_, __, { trainingId }) => [
        { type: 'TrainingModulesProgressByTrainingAndUserId', id: trainingId },
      ],
    }),
  }),
});

export const {
  useAllTrainingModulesQuery,
  useTrainingModuleQuery,
  useDeleteTrainingModuleMutation,
  useDuplicateTrainingModuleMutation,
  useMoveTrainingModuleToNewPositionMutation,
  usePatchTrainingModuleMutation,
  usePostTrainingModuleMutation,
  useTrainingModulesByTrainingIdQuery,
  useTrainingModuleEstimatedTimeQuery,
  useTrainingModuleLessonsCountQuery,
  useTrainingModulesProgressQuery,
  useTrainingModulesProgressByTrainingIdQuery,
  useTrainingModulesProgressByTrainingAndUserIdQuery,
} = trainingModulesApi;
