import SplitPaneLayout from 'src/layouts/SplitPaneLayout';
import Sidebar from 'src/ui-components/layout/sidebar/Sidebar';
import { useContext, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Thread, useCreateThreadMutation, useFetchThreadsQuery } from 'src/store/api/aiChatApi';
import { useTranslation } from 'react-i18next';
import { WorkbaseNewChatIcon } from 'src/assets/icons/workbaseIcons';
import useIsMobile from 'src/hooks/useIsMobile';
import ChatDialog from './ChatDialog';
import { ChatContext } from '../helpers/chatContext';

export default function FullScreenChat() {
  const { chatId } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [createThread] = useCreateThreadMutation();
  const { data: threads } = useFetchThreadsQuery();
  const { state, dispatch, ws } = useContext(ChatContext);
  const isMobile = useIsMobile();
  // when chat is opened from the link
  useEffect(() => {
    dispatch({ type: 'OPEN_FULLSCREEN' });
    dispatch({ type: 'OPEN_DIALOG' });
    dispatch({ type: 'SET_SELECTED_CHAT', payload: chatId as string });
    return () => {
      dispatch({ type: 'CLOSE_FULLSCREEN' });
      if (isMobile) {
        dispatch({ type: 'CLOSE_DIALOG' });
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!threads?.find((thread: Thread) => thread.id === chatId) && threads?.length) {
      navigate(`/ai/${threads[0].id}`);
    }
  }, [chatId, threads, navigate, dispatch]);

  const handleAddThread = async () => {
    const response = await createThread();
    if ('data' in response) {
      dispatch({ type: 'ADD_THREAD', payload: response.data });
      navigate(`/ai/${response.data.id}`);
    }
  };

  const headerIcon = {
    text: t('assistant.newChat'),
    icon: WorkbaseNewChatIcon,
    onClick: () => handleAddThread(),
  };

  const formattedItems = threads
    ? threads.map((item: any) => ({
        key: item.id,
        text: item.summary || t('assistant.newChat'),
        subtext: new Date(item.createdAt).toLocaleString(),
        prefix: null,
        onClick: () => {
          navigate(`/ai/${item.id}`);
        },
      }))
    : [];
  const chatDialog = (
    <ChatDialog
      ws={ws}
      isLoading={state.isLoading}
      locationBeforeFullscreen={state.locationBeforeFullscreen}
      isFullScreen={!isMobile}
      dispatch={dispatch}
      threadId={chatId || ''}
      open
      messages={state.messages}
    />
  );

  if (isMobile) return chatDialog;

  return (
    <SplitPaneLayout
      leftContent={
        <Sidebar
          activeKey={chatId || ''}
          items={formattedItems}
          dataCy="ai-sidebar"
          headerIcon={headerIcon}
        />
      }
      rightContent={chatDialog}
    />
  );
}
