import { Box, Paper, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useGenerateColors from './utils/useGenerateColors';
import CardContentTemplate from './CardContentTemplate';

export default function GeneratedColors({ color }: { color: string }) {
  const { t } = useTranslation();
  const generatedColors = useGenerateColors(color);

  return (
    <CardContentTemplate
      left={
        <Stack direction="row" gap={1} height="100%">
          {generatedColors.map((generatedColor) => (
            <Paper
              component={Box}
              variant="outlined"
              width="100%"
              key={generatedColor.color}
              sx={{ bgcolor: `${generatedColor.color}` }}
            />
          ))}
        </Stack>
      }
      right={
        <Typography variant="body2" fontWeight="medium" color="textPrimary">
          {t('companySettings.siteInformation.brandingColors.generatedColorTitle')}
        </Typography>
      }
    />
  );
}
