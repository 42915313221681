import { Stack } from '@mui/material';
import { useState } from 'react';
import { Employee } from 'src/features/employees/model';
import normalizeString from 'src/lib/utils/normalizeString';
import {
  TrainingsProgress,
  useTrainingsByUserIdQuery,
  useTrainingsProgressByUserIdQuery,
  useTrainingsAccessQuery,
  DetailedTrainingWithAccess,
} from 'src/store/api/trainings/trainingsApi';
import { SearchTextField } from 'src/ui-components/custom/textfield/search';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import TrainingList from './TrainingList';
import EmptyTrainingProgressPlaceholder from './EmptyTrainingProgressPlaceholder';

export interface TrainingProgressProps {
  employee: Employee;
}

export default function TrainingProgress({ employee }: TrainingProgressProps) {
  const [searchVal, setSearchVal] = useState<string>('');
  const { data: trainingsProgress } = useTrainingsProgressByUserIdQuery(employee.id);
  const { data: trainings, isLoading } = useTrainingsByUserIdQuery(employee.id);
  const { data: trainingsAceess } = useTrainingsAccessQuery();
  const handleSearchValChange = (val: string) => setSearchVal(val);
  const trainingsWithAccessData =
    trainings?.map((training) => {
      const hasTrainingAccess = !!trainingsAceess?.find((access) => access.id === training.id);
      return { ...training, blockAccess: !hasTrainingAccess };
    }) || [];

  return (
    <Stack py={2}>
      {isLoading && (
        <Stack justifyContent="center" alignItems="center" height="100%" position="relative">
          <LottieLogoSpinner />
        </Stack>
      )}
      {!isLoading && trainings && trainings?.length > 0 && (
        <>
          <Stack mb={2.5}>
            <SearchTextField
              data-cy="employee-roles-search-textField"
              fullWidth
              size="small"
              onChange={(e: any) => handleSearchValChange(e.target.value)}
              value={searchVal}
            />
          </Stack>
          <TrainingList
            trainingsProgress={trainingsProgress as TrainingsProgress[]}
            trainingsDetailes={trainingsWithAccessData as DetailedTrainingWithAccess[]}
            employee={employee}
            searchVal={normalizeString(searchVal)}
          />
        </>
      )}
      {!isLoading && trainings?.length === 0 && <EmptyTrainingProgressPlaceholder />}
    </Stack>
  );
}
