import { Button, ButtonProps, Stack, SvgIcon } from '@mui/material';
import { useConfirm } from 'material-ui-confirm';
import { isValidPhoneNumber } from 'libphonenumber-js';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { validateEmployeeData } from 'src/lib/utils/validateUserData';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseCloseCircleIcon,
} from 'src/assets/icons/workbaseIcons';
import {
  useCancelEmailChangeMutation,
  usePatchEmployeeMutation,
} from 'src/store/api/employees/employeesApi';
import { useAppSelector } from 'src/store';
import EditButton from 'src/ui-components/edit-button/EditButton';
import {
  clearEmployee,
  setEmployee,
  transformToPatchRequest,
} from '../../controller/employee.slice';
import { Employee } from '../../model';

export interface Props {
  isEditPath: boolean;
  currentEmployee?: Employee;
  changedData: Partial<Employee> | null;
  id: string;
}
export function SaveCancelChangesButtons({ isEditPath, currentEmployee, changedData, id }: Props) {
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const deleteModalConfirm = useConfirm();
  const employeeDraft = useAppSelector((state) => state.employee.employee);
  const [patchEmployee] = usePatchEmployeeMutation();
  const [cancelEmailChange] = useCancelEmailChangeMutation();

  // eslint-disable-next-line max-statements
  async function handleSaveClick() {
    /* eslint-disable max-depth */
    try {
      if (changedData && Object.keys(changedData).length !== 0) {
        if (Object.keys(changedData).length !== 0) {
          const validationError = validateEmployeeData(employeeDraft as Employee, t);
          if (validationError) {
            showNotification(validationError, 'error');
            return;
          }
          if (
            changedData.phoneNumber &&
            (!isValidPhoneNumber(changedData?.phoneNumber) || changedData?.phoneNumber === '')
          ) {
            showNotification(
              t('employeePage.employeeDetails.changePhoneNumber.errorNotification'),
              'error'
            );
            return;
          }
          if (changedData.roles && changedData.roles.length === 0) {
            showNotification(
              t('employeePage.employeeDetails.changePhoneNumber.errorNotification'),
              'error'
            );
            return;
          }

          const patchRequest = transformToPatchRequest(changedData as Employee);
          const resp: any = await patchEmployee({ id, data: patchRequest });
          if (resp?.error) {
            showNotification(t('employeePage.employeeDetails.contactInfomation.errorMsg'), 'error');
          } else {
            navigate(id, { relative: 'route' });
            showNotification(t('employeePage.employeeDetails.contactInfomation.successMsg'));
            if (changedData.email) {
              showNotification(t('employeePage.employeeDetails.changeEmail.successNotification'));
            }
          }
        }
        navigate(id, { relative: 'route' });
      } else {
        navigate(id, { relative: 'route' });
      }
    } catch (error) {
      showNotification(t('employeePage.employeeDetails.contactInfomation.errorMsg'), 'error');
    }
  }

  const handleCancelClick = () => {
    if (currentEmployee) cancelEmailChange(currentEmployee.id);
    if (changedData && Object.keys(changedData).length !== 0) {
      deleteModalConfirm({
        title: t('employeePage.employeeDetails.contactInfomation.cancelQuestion'),
        description: t('changesMayNotBeSaved'),
        confirmationButtonProps: {
          color: 'error',
        },
        confirmationText: t('confirm'),
        cancellationText: t('cancel'),
        cancellationButtonProps: {
          children: t('cancel'),
          color: 'inherit',
        },
      }).then(() => {
        dispatch(clearEmployee());
        try {
          if (currentEmployee) dispatch(setEmployee(currentEmployee));
          setTimeout(() => navigate(id, { relative: 'route' }));
        } catch (error) {
          showNotification(t('validationErrorMessages.labelErrorTryAgainButton'), 'error');
        }
      });
    } else {
      navigate(id, { relative: 'route' });
    }
  };

  const buttonsData: Array<{
    color: ButtonProps['color'];
    text: string;
    icon: React.ComponentType;
    onClick: () => void;
  }> = [
    {
      color: 'error',
      text: t('editButton.cancel'),
      icon: WorkbaseCloseCircleIcon,
      onClick: handleCancelClick,
    },
    {
      color: 'success',
      text: t('editButton.save'),
      icon: WorkbaseCheckmarkBigFilledIcon,
      onClick: handleSaveClick,
    },
  ];

  return (
    <Stack flexDirection="row" position="fixed" bottom="40px" right="40px" gap={1.5} zIndex={1000}>
      {isEditPath ? (
        <Stack flexDirection="row" gap={1.25}>
          {buttonsData.map(({ color, text, icon, onClick }) => (
            <Button
              size="large"
              color={color}
              disableElevation={false}
              onClick={onClick}
              startIcon={<SvgIcon component={icon} />}
              key={text}
            >
              {text}
            </Button>
          ))}
        </Stack>
      ) : (
        <EditButton />
      )}
    </Stack>
  );
}
