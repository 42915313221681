import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { File, usePatchFileMutation } from 'src/store/api/driveApi';
import { useEffect, useState } from 'react';

export default function FileRenameDialog({
  fileData,
  onClose,
}: {
  fileData: File | null;
  onClose: () => void;
}) {
  const [name, setName] = useState(fileData?.filename || '');
  const [blurred, setBlurred] = useState(false);
  const [renameFile] = usePatchFileMutation();
  const { t } = useTranslation();
  const handleCloseDialog = () => {
    onClose();
  };

  useEffect(() => {
    if (fileData?.filename) {
      setName(fileData?.filename);
      setBlurred(false);
    }
  }, [fileData?.filename]);

  const handleRename = () => {
    if (fileData) {
      renameFile({ id: fileData.id, filename: name });
    }
    onClose();
  };

  const nameInvalid = name === '';

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      data-cy="edit-file-name-dialog"
      open={!!fileData}
      onClose={handleCloseDialog}
    >
      <DialogTitle>{t('documentsPage.editFileDialog.title')}</DialogTitle>
      <DialogContent>
        <Stack direction="column" mt={1}>
          <Stack spacing={2} alignItems="flex-start">
            <TextField
              data-cy="edit-filename-textfield"
              size="small"
              fullWidth
              label={t('documentsPage.editFileDialog.textFieldLabel')}
              name="name"
              error={blurred && nameInvalid}
              onBlur={() => setBlurred(true)}
              helperText={blurred && nameInvalid && t('required')}
              value={name}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="inherit">
          {t('cancel')}
        </Button>
        <Button onClick={handleRename} disabled={nameInvalid}>
          {t('save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
