import { createContext, useContext, useEffect, useMemo, useRef } from 'react';
import * as Y from 'yjs';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import {
  editorDataToYjs,
  handleAddItem,
  handleDisableForwardVideo,
  handleDuplicateItem,
  handleEditorChange,
  handleHrefChange,
  handleImageUpload,
  handleInfoTypeChange,
  handleMediaTypeChange,
  handleRemoveItem,
  handleReplaceVideo,
  handleRequiredVideo,
  handleResizeItem,
  handleSelectedTrainingsChange,
  handleSetImage,
  handleSetVideo,
  handleTrainingsIconChange,
  handleTrainingsSelectAllChange,
  handleTrainingsTitleChange,
  handleTrainingsTypeChange,
  handleUpdateEmbeddedItem,
  handleUpdateVideoPoster,
  handleUpdateVideoTitle,
  moveItemToEndAndExpand,
  moveItemToNewRow,
  yjsToEditorData,
  handleUndoCrop,
} from './helpers';
import {
  handleDragAndDropToSideOfRow,
  handleDropIntoFullRow,
  insertItemBefore,
  moveItemToNewPosition,
} from './helpers/dnd/Dnd.helpers';

interface EditorContextI {
  ydoc: Y.Doc;
  undoManager: Y.UndoManager;
  yjsToEditorData: ReturnType<typeof yjsToEditorData>;
  editorDataToYjs: ReturnType<typeof editorDataToYjs>;
  handleRemoveItem: ReturnType<typeof handleRemoveItem>;
  handleEditorChange: ReturnType<typeof handleEditorChange>;
  handleAddItem: ReturnType<typeof handleAddItem>;
  handleResizeItem: ReturnType<typeof handleResizeItem>;
  handleInfoTypeChange: ReturnType<typeof handleInfoTypeChange>;
  handleMediaTypeChange: ReturnType<typeof handleMediaTypeChange>;
  handleSetImage: ReturnType<typeof handleSetImage>;
  handleSetVideo: ReturnType<typeof handleSetVideo>;
  handleImageUpload: ReturnType<typeof handleImageUpload>;
  moveItemToEndAndExpand: ReturnType<typeof moveItemToEndAndExpand>;
  moveItemToNewRow: ReturnType<typeof moveItemToNewRow>;
  handleDragAndDropToSideOfRow: ReturnType<typeof handleDragAndDropToSideOfRow>;
  handleDuplicateItem: ReturnType<typeof handleDuplicateItem>;
  moveItemToNewPosition: ReturnType<typeof moveItemToNewPosition>;
  handleDropIntoFullRow: ReturnType<typeof handleDropIntoFullRow>;
  handleTrainingsTitleChange: ReturnType<typeof handleTrainingsTitleChange>;
  handleTrainingsIconChange: ReturnType<typeof handleTrainingsIconChange>;
  handleSelectedTrainingsChange: ReturnType<typeof handleSelectedTrainingsChange>;
  handleTrainingsSelectAllChange: ReturnType<typeof handleTrainingsSelectAllChange>;
  handleUpdateVideoPoster: ReturnType<typeof handleUpdateVideoPoster>;
  handleReplaceVideo: ReturnType<typeof handleReplaceVideo>;
  handleDisableForwardVideo: ReturnType<typeof handleDisableForwardVideo>;
  handleRequiredVideo: ReturnType<typeof handleRequiredVideo>;
  handleUpdateVideoTitle: ReturnType<typeof handleUpdateVideoTitle>;
  handleUpdateEmbeddedItem: ReturnType<typeof handleUpdateEmbeddedItem>;
  insertItemBefore: ReturnType<typeof insertItemBefore>;
  handleTrainingsTypeChange: ReturnType<typeof handleTrainingsTypeChange>;
  handleHrefChange: ReturnType<typeof handleHrefChange>;
  handleUndoCrop: ReturnType<typeof handleUndoCrop>;
}

const ydoc = new Y.Doc();
const editorData = ydoc.getMap('editorData');
const undoManager = new Y.UndoManager(editorData);

const EditorContext = createContext<EditorContextI>({
  ydoc,
  undoManager,
  yjsToEditorData: yjsToEditorData(editorData),
  editorDataToYjs: editorDataToYjs(editorData),
  handleRemoveItem: handleRemoveItem(ydoc, editorData),
  handleEditorChange: handleEditorChange(ydoc, editorData),
  handleAddItem: handleAddItem(ydoc, editorData),
  handleResizeItem: handleResizeItem(ydoc, editorData),
  handleInfoTypeChange: handleInfoTypeChange(ydoc, editorData),
  handleMediaTypeChange: handleMediaTypeChange(ydoc, editorData),
  handleSetImage: handleSetImage(ydoc, editorData),
  handleSetVideo: handleSetVideo(ydoc, editorData),
  handleImageUpload: handleImageUpload(ydoc, editorData),
  moveItemToEndAndExpand: moveItemToEndAndExpand(ydoc, editorData),
  moveItemToNewRow: moveItemToNewRow(ydoc, editorData),
  handleDragAndDropToSideOfRow: handleDragAndDropToSideOfRow(ydoc, editorData),
  handleDuplicateItem: handleDuplicateItem(ydoc, editorData),
  moveItemToNewPosition: moveItemToNewPosition(ydoc, editorData),
  handleDropIntoFullRow: handleDropIntoFullRow(ydoc, editorData),
  handleTrainingsTitleChange: handleTrainingsTitleChange(ydoc, editorData),
  handleTrainingsIconChange: handleTrainingsIconChange(ydoc, editorData),
  handleSelectedTrainingsChange: handleSelectedTrainingsChange(ydoc, editorData),
  handleTrainingsSelectAllChange: handleTrainingsSelectAllChange(ydoc, editorData),
  handleUpdateVideoPoster: handleUpdateVideoPoster(ydoc, editorData),
  handleReplaceVideo: handleReplaceVideo(ydoc, editorData),
  handleDisableForwardVideo: handleDisableForwardVideo(ydoc, editorData),
  handleRequiredVideo: handleRequiredVideo(ydoc, editorData),
  handleUpdateVideoTitle: handleUpdateVideoTitle(ydoc, editorData),
  handleUpdateEmbeddedItem: handleUpdateEmbeddedItem(ydoc, editorData),
  insertItemBefore: insertItemBefore(ydoc, editorData),
  handleTrainingsTypeChange: handleTrainingsTypeChange(ydoc, editorData),
  handleHrefChange: handleHrefChange(ydoc, editorData),
  handleUndoCrop: handleUndoCrop(ydoc, editorData),
});

export function EditorProvider({ children }: { children: React.ReactNode }) {
  const ydocRef = useRef(new Y.Doc());
  const { data: userData } = useCurrentUserQuery();

  const undoManagerRef = useRef<Y.UndoManager>(
    new Y.UndoManager(ydocRef.current.getMap('editorData'), {
      // capture each change individually
      captureTimeout: 0,
      trackedOrigins: new Set([]),
    })
  );

  useEffect(() => {
    if (userData?.id) {
      undoManagerRef.current.trackedOrigins.add(userData.id);
    }
  }, [userData]);

  const contextValue: EditorContextI = useMemo(() => {
    const yEditorData = ydocRef.current.getMap('editorData');

    return {
      ydoc: ydocRef.current,
      undoManager: undoManagerRef.current,
      yjsToEditorData: yjsToEditorData(yEditorData),
      editorDataToYjs: editorDataToYjs(yEditorData),
      handleRemoveItem: handleRemoveItem(ydocRef.current, yEditorData, userData?.id),
      handleEditorChange: handleEditorChange(ydocRef.current, yEditorData, userData?.id),
      handleAddItem: handleAddItem(ydocRef.current, yEditorData, userData?.id),
      handleResizeItem: handleResizeItem(ydocRef.current, yEditorData, userData?.id),
      handleInfoTypeChange: handleInfoTypeChange(ydocRef.current, yEditorData, userData?.id),
      handleMediaTypeChange: handleMediaTypeChange(ydocRef.current, yEditorData, userData?.id),
      handleSetImage: handleSetImage(ydocRef.current, yEditorData, userData?.id),
      handleSetVideo: handleSetVideo(ydocRef.current, yEditorData, userData?.id),
      handleImageUpload: handleImageUpload(ydocRef.current, yEditorData, userData?.id),
      moveItemToEndAndExpand: moveItemToEndAndExpand(ydocRef.current, yEditorData, userData?.id),
      moveItemToNewRow: moveItemToNewRow(ydocRef.current, yEditorData, userData?.id),
      handleDragAndDropToSideOfRow: handleDragAndDropToSideOfRow(
        ydocRef.current,
        yEditorData,
        userData?.id
      ),
      handleDuplicateItem: handleDuplicateItem(ydocRef.current, yEditorData, userData?.id),
      moveItemToNewPosition: moveItemToNewPosition(ydocRef.current, yEditorData, userData?.id),
      handleDropIntoFullRow: handleDropIntoFullRow(ydocRef.current, yEditorData, userData?.id),
      handleTrainingsTitleChange: handleTrainingsTitleChange(
        ydocRef.current,
        yEditorData,
        userData?.id
      ),
      handleTrainingsIconChange: handleTrainingsIconChange(
        ydocRef.current,
        yEditorData,
        userData?.id
      ),
      handleSelectedTrainingsChange: handleSelectedTrainingsChange(
        ydocRef.current,
        yEditorData,
        userData?.id
      ),
      handleTrainingsSelectAllChange: handleTrainingsSelectAllChange(
        ydocRef.current,
        yEditorData,
        userData?.id
      ),
      handleUpdateVideoPoster: handleUpdateVideoPoster(ydocRef.current, yEditorData, userData?.id),
      handleReplaceVideo: handleReplaceVideo(ydocRef.current, yEditorData),
      handleUpdateVideoTitle: handleUpdateVideoTitle(ydocRef.current, yEditorData, userData?.id),
      handleDisableForwardVideo: handleDisableForwardVideo(
        ydocRef.current,
        yEditorData,
        userData?.id
      ),
      handleRequiredVideo: handleRequiredVideo(ydocRef.current, yEditorData, userData?.id),
      handleUpdateEmbeddedItem: handleUpdateEmbeddedItem(
        ydocRef.current,
        yEditorData,
        userData?.id
      ),
      insertItemBefore: insertItemBefore(ydocRef.current, yEditorData, userData?.id),
      handleTrainingsTypeChange: handleTrainingsTypeChange(
        ydocRef.current,
        yEditorData,
        userData?.id
      ),
      handleHrefChange: handleHrefChange(ydocRef.current, yEditorData, userData?.id),
      handleUndoCrop: handleUndoCrop(ydocRef.current, yEditorData, userData?.id),
    };
  }, [userData]);

  return <EditorContext.Provider value={contextValue}>{children}</EditorContext.Provider>;
}

export const useEditor = () => {
  const context = useContext(EditorContext);
  if (!context) {
    throw new Error('useEditor must be used within an EditorProvider');
  }
  return context;
};
