import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import persistReducer from 'redux-persist/es/persistReducer';
import storage from 'redux-persist/lib/storage';

interface DashboardState {
  selectedRole?: string;
  selectedNewsfeedItemIdx: number;
}

const initialState: DashboardState = {
  selectedRole: undefined,
  selectedNewsfeedItemIdx: 0,
};

const persistConfig = {
  key: 'dashboard',
  storage,
};

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    setSelectedRole: (state, action: PayloadAction<string>) => {
      state.selectedRole = action.payload;
    },
    setSelectedNewsfeedItemIdx: (state, action: PayloadAction<number>) => {
      state.selectedNewsfeedItemIdx = action.payload;
    },
  },
});

export const { setSelectedRole, setSelectedNewsfeedItemIdx } = dashboardSlice.actions;

export const dashboardReducer = persistReducer(persistConfig, dashboardSlice.reducer);
