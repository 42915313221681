import { Divider, Stack } from '@mui/material';
import Links from 'src/core/links';
import { useAppSelector } from 'src/store';
import { Link } from 'src/store/api/linksApi';
import RoleSelector from './RoleSelector';
import { Role } from '../../store/api/dashboardApi';

interface Props {
  isEditing: boolean;
  links?: Link[];
  roles: Role[];
}

export default function RoleSelectorWithLinks({ isEditing, links = [], roles }: Props) {
  const shouldShowDivider = roles.length > 1;
  const selectedRole = useAppSelector((state) => state.dashboard.selectedRole);
  return (
    <Stack gap={2} width="100%">
      <RoleSelector />
      {((links?.length && !isEditing) || isEditing) && shouldShowDivider && <Divider flexItem />}
      {selectedRole && (
        <Links resourceType="role" resourceId={selectedRole} readOnly={!isEditing} />
      )}
    </Stack>
  );
}
