import { TrainingSection } from 'src/store/api/trainings/trainingSectionsApi';
import { useTrainingLessonsBySectionIdQuery } from 'src/store/api/trainings/trainingLessonsApi';
import normalizeString from 'src/lib/utils/normalizeString';
import { useParams } from 'react-router-dom';
import SectionAccordion from './section-module/SectionAccordion';

interface Props extends TrainingSection {
  searchTerm: string;
  readOnly: boolean;
  mobileView: boolean;
  onAddLessonCallback?: (lessonId: string) => void;
  dataCy?: string;
}

export default function Section({
  dataCy,
  id,
  name,
  status,
  searchTerm,
  readOnly,
  trainingModuleId,
  mobileView,
  icon,
  onAddLessonCallback,
}: Props) {
  const { data } = useTrainingLessonsBySectionIdQuery(id as string);
  const params = useParams<{ trainingId: string; moduleId: string; lessonId?: string }>();

  let isCurrentSectionLessonActive = false;

  const filteredLessons =
    data?.filter((lesson) => {
      if (readOnly && lesson.status === 'draft') return false;
      isCurrentSectionLessonActive = isCurrentSectionLessonActive || params.lessonId === lesson.id;
      const normalizedLessonName = normalizeString(lesson.name);
      const normalizedSearchTerm = normalizeString(searchTerm);
      return normalizedLessonName.includes(normalizedSearchTerm);
    }) || [];
  if (readOnly && status === 'draft') return null;
  if (filteredLessons.length === 0 && searchTerm) return null;
  const totalEstimatedLessonsTime = filteredLessons.reduce(
    (total, lesson) => total + lesson.estimatedTimeToComplete,
    0
  );
  return (
    <SectionAccordion
      trainingModuleId={trainingModuleId}
      name={name}
      id={id}
      status={status}
      readOnly={readOnly}
      icon={icon}
      dataCy={dataCy && `${dataCy}-accordion`}
      mobileView={mobileView}
      totalEstimatedLessonsTime={totalEstimatedLessonsTime}
      filteredLessons={filteredLessons}
      isCurrentSectionLessonActive={isCurrentSectionLessonActive}
      onAddLessonCallback={onAddLessonCallback}
    />
  );
}
