import { Accordion, AccordionDetails, AccordionSummary, SvgIcon } from '@mui/material';
import { TrainingSectionStatus } from 'src/store/api/trainings/trainingSectionsApi';
import { TrainingLesson } from 'src/store/api/trainings/trainingLessonsApi';
import { useEffect, useState } from 'react';
import { WorkbaseArrowDownIcon } from 'src/assets/icons/workbaseIcons';
import Lessons from './Lessons';
import SectionTitle from './SectionTitle';

interface Props {
  id: string;
  dataCy?: string;
  name: string;
  status: TrainingSectionStatus;
  trainingModuleId: string;
  readOnly: boolean;
  icon: string;
  totalEstimatedLessonsTime: number;
  mobileView: boolean;
  filteredLessons: TrainingLesson[];
  isCurrentSectionLessonActive: boolean;
  onAddLessonCallback?: (lessonId: string) => void;
}

export default function SectionAccordion({
  dataCy,
  id,
  mobileView,
  readOnly,
  name,
  trainingModuleId,
  status,
  icon,
  filteredLessons,
  isCurrentSectionLessonActive,
  totalEstimatedLessonsTime,
  onAddLessonCallback,
}: Props) {
  const [expanded, setExpanded] = useState(true);
  const [isOver, setIsOver] = useState(false);
  const showFlicker = isOver && !expanded;
  useEffect(() => {
    if (showFlicker) {
      const timeoutId = setTimeout(() => {
        if (isOver) {
          setExpanded(true);
        }
      }, 1000);
      return () => clearTimeout(timeoutId);
    }
    return () => {};
  }, [showFlicker, isOver]);
  return (
    <Accordion
      expanded={expanded}
      onChange={() => setExpanded(!expanded)}
      variant="outlined"
      sx={{ overflow: 'hidden' }}
      data-cy={dataCy}
    >
      <AccordionSummary expandIcon={<SvgIcon component={WorkbaseArrowDownIcon} />}>
        <SectionTitle
          dataCy={dataCy}
          sectionName={name}
          trainingModuleId={trainingModuleId}
          readOnly={readOnly}
          lessonsCount={filteredLessons?.length}
          sectionId={id}
          sectionStatus={status}
          mobileView={mobileView}
          icon={icon}
          isCurrentSectionLessonActive={isCurrentSectionLessonActive}
          totalEstimatedLessonsTime={totalEstimatedLessonsTime}
          setIsOver={setIsOver}
        />
      </AccordionSummary>
      <AccordionDetails>
        <Lessons
          dataCy={dataCy}
          readOnly={readOnly}
          id={id}
          filteredLessons={filteredLessons}
          mobileView={mobileView}
          onAddLessonCallback={onAddLessonCallback}
        />
      </AccordionDetails>
    </Accordion>
  );
}
