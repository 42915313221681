import { Alert, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ChatStateMessage } from '../../helpers/types';

interface AlertErrorProps {
  error: string | null;
  lastUserMessage: ChatStateMessage | null;
  onSendMessage: (message: string, retry: boolean) => void;
}

export default function AlertError({ error, lastUserMessage, onSendMessage }: AlertErrorProps) {
  const { t } = useTranslation();

  const handleRetry = () => {
    onSendMessage(lastUserMessage?.content[0].text as string, true);
  };
  if (!error) return null;
  return (
    <Alert
      severity="error"
      action={
        <Button color="inherit" onClick={handleRetry}>
          {t('assistant.retry')}
        </Button>
      }
    >
      <Typography>{error}</Typography>
    </Alert>
  );
}
