import { ChangeEvent, useRef, useState } from 'react';
import { InputBase, ListItemIcon, ListItemText, Menu, MenuItem, SvgIcon } from '@mui/material';
import { t } from 'i18next';
import { WorkbaseDeleteSquareIcon, WorkbaseImageNewIcon } from 'src/assets/icons/workbaseIcons';
import ImageEditOverlay from './ImageEditOverlay';

interface Props {
  onImageChange: (e: ChangeEvent<HTMLInputElement>) => Promise<void>;
  onImageRemove: () => Promise<void>;
  dataCy?: string;
}

export default function ImageEditorWithPreview({ onImageRemove, onImageChange, dataCy }: Props) {
  const [isMenuOpen, setMenuOpen] = useState<null | HTMLElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleOpenMenu = (event: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>) =>
    setMenuOpen(event.currentTarget);
  const handleCloseMenu = () => setMenuOpen(null);

  const handleFileMenuItemClick = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  return (
    <>
      <ImageEditOverlay
        dataCy={dataCy}
        onClick={(e) => {
          handleOpenMenu(e);
        }}
      />
      <Menu
        open={Boolean(isMenuOpen)}
        onClose={handleCloseMenu}
        anchorEl={isMenuOpen}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <MenuItem onClick={handleFileMenuItemClick}>
          <ListItemIcon>
            <SvgIcon component={WorkbaseImageNewIcon} color="action" />
          </ListItemIcon>
          <ListItemText
            primary={t('employeePage.employeeDetails.contactInfomation.changeAvatar')}
            primaryTypographyProps={{ fontWeight: 'bold', variant: 'body1' }}
          />
        </MenuItem>
        <MenuItem
          onClick={() => {
            onImageRemove();
            handleCloseMenu();
          }}
        >
          <ListItemIcon>
            <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
          </ListItemIcon>
          <ListItemText
            primary={t('employeePage.employeeDetails.contactInfomation.removeAvatar')}
            primaryTypographyProps={{ fontWeight: 'bold', variant: 'body1' }}
          />
        </MenuItem>
      </Menu>
      <InputBase
        type="file"
        inputRef={fileInputRef}
        inputProps={{
          accept: 'image/jpeg,image/png',
        }}
        onChange={(e: any) => {
          onImageChange(e);
        }}
        sx={{ display: 'none' }}
      />
    </>
  );
}
