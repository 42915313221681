import { Divider, Stack, Box, Paper, SxProps, Theme } from '@mui/material';
import { useState } from 'react';
import BaseActions, { BaseActionsProps } from './BaseActions';
import TextMenu from './text-menu';
import UploadMenu, { UploadActionsProps } from './UploadActions';
import InfoActions, { InfoActionsProps } from './InfoActions';
import EmbeddedActions, { EmbeddedActionsProps } from './EmbeddedActions';
import ImageActions, { ImageActionsProps } from './ImageActions';
import TrainingsActions, { TrainingsActionsProps } from './tranings-actions/TrainingsActions';
import VideoActions, { VideoActionsProps } from './VideoActions';
import TextSelectionLinkInput from './TextSelectionLinkInput';
import LinkInput from './LinkInput';
import UndoCropAction, { UndoCropActionProps } from './UndoCropAction';

export interface EditBarProps {
  baseActionsProps: BaseActionsProps;
  imageActionsProps?: Omit<ImageActionsProps, 'onLinkIconClick'>;
  videoActionsProps?: VideoActionsProps;
  textActionsProps?: {
    textAllignmentHidden?: boolean;
  };
  uploadActionsProps?: UploadActionsProps;
  infoActionsProps?: InfoActionsProps;
  embeddedActionsProps?: EmbeddedActionsProps;
  trainingsActionsProps?: TrainingsActionsProps;
  hrefProps?: HrefProps;
  undoCropActionProps?: UndoCropActionProps;
  onHideLinkInputMode?: () => void;
  sx?: SxProps<Theme>;
}

export interface HrefProps {
  onChange: (href: string) => void;
  href?: string;
}

const divider = <Divider orientation="vertical" flexItem sx={{ height: '100%' }} />;

export default function EditBar({
  baseActionsProps,
  imageActionsProps,
  videoActionsProps,
  textActionsProps,
  uploadActionsProps,
  infoActionsProps,
  embeddedActionsProps,
  trainingsActionsProps,
  hrefProps,
  onHideLinkInputMode,
  undoCropActionProps,
  sx,
}: EditBarProps) {
  const [linkInputVisible, setLinkInputVisible] = useState(false);

  const handleShowLinkInput = () => setLinkInputVisible(true);
  const handleHideLinkInput = () => {
    setLinkInputVisible(false);
    onHideLinkInputMode?.();
  };

  const actions = (
    <>
      {trainingsActionsProps && (
        <>
          <TrainingsActions {...trainingsActionsProps} />
          {divider}
        </>
      )}

      {textActionsProps && (
        <>
          <TextMenu
            {...textActionsProps}
            onLinkIconClick={handleShowLinkInput}
            linkIconAlwaysClickable={!!hrefProps}
          />
          {divider}
        </>
      )}
      {imageActionsProps && (
        <>
          <ImageActions {...imageActionsProps} onLinkIconClick={handleShowLinkInput} />
          {divider}
        </>
      )}
      {videoActionsProps && (
        <>
          <VideoActions {...videoActionsProps} />
          {divider}
        </>
      )}
      {uploadActionsProps && (
        <>
          <UploadMenu {...uploadActionsProps} />
          {divider}
        </>
      )}
      {infoActionsProps && (
        <>
          <InfoActions {...infoActionsProps} />
          {divider}
        </>
      )}
      {embeddedActionsProps && (
        <>
          <EmbeddedActions {...embeddedActionsProps} />
          {divider}
        </>
      )}
      {undoCropActionProps && (
        <>
          <UndoCropAction {...undoCropActionProps} />
          {divider}
        </>
      )}
      <BaseActions {...baseActionsProps} />
    </>
  );

  let linkInput = null;

  if (hrefProps || textActionsProps) {
    linkInput = hrefProps ? (
      <LinkInput
        onRemove={handleHideLinkInput}
        onApply={hrefProps.onChange}
        onClear={() => hrefProps.onChange('')}
        value={hrefProps.href || ''}
      />
    ) : (
      <TextSelectionLinkInput onRemove={handleHideLinkInput} />
    );
  }

  return (
    <Paper
      className="edit-bar"
      variant="outlined"
      component={Box}
      px={1}
      height={54}
      sx={{
        position: 'absolute',
        left: 0,
        bottom: '100%',
        zIndex: 10,
        boxShadow: 'none',
        transform: 'translateY(-4px)',
        display: 'flex !important',
        alignContent: 'center',
        minWidth: 'max-content',
        ...sx,
      }}
      onMouseDown={(e) => e.preventDefault()}
    >
      <Stack direction="row" gap={1} alignItems="center">
        {!linkInputVisible && actions}
        {linkInputVisible && linkInput}
      </Stack>
    </Paper>
  );
}
