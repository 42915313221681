import { Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'src/store';
import { Employee } from 'src/features/employees/model';
import EditableContactInfo from './EditableContactInfo';
import Roles from './Roles';
import EmployeeTextSection from './EmployeeTextSection';

interface Props {
  readOnly: boolean;
  employee: Employee;
}

export default function EmployeeInfo({ readOnly, employee }: Props) {
  const { t } = useTranslation();
  const employeeDraft = useAppSelector((state) => state.employee.employee);
  if (!employeeDraft) return null;
  const sections = [
    {
      key: 'contact-information',
      title: t('employeePage.employeeDetails.contactInfomation.header'),
      items: [
        {
          key: 'email',
          label: t('employeePage.employeeDetails.contactInfomation.emailAddressLabel'),
          text: employeeDraft?.email,
        },
        {
          key: 'phoneNumber',
          label: t('employeePage.employeeDetails.contactInfomation.mobileNumberLabel'),
          text: employeeDraft.phoneNumber || '',
        },
        {
          key: 'location',
          label: t('employeePage.employeeDetails.contactInfomation.locationLabel'),
          text: employeeDraft.location || '',
        },
      ],
    },
    {
      key: 'personal-information',
      title: t('employeePage.employeeDetails.personalInformation.header'),
      items: [
        {
          key: 'dateOfBirth',
          label: t('employeePage.employeeDetails.personalInformation.dateOfBirthLabel'),
          text: employeeDraft.personalInformation.dateOfBirth || '',
        },
        {
          key: 'gender',
          label: t('employeePage.employeeDetails.personalInformation.genderLabel'),
          text: employeeDraft.personalInformation.gender
            ? t(
                `employeePage.employeeDetails.personalInformation.${employeeDraft.personalInformation.gender}`
              )
            : '',
        },
        {
          key: 'citizenship',
          label: t('employeePage.employeeDetails.personalInformation.citizenshipLabel'),
          text: employeeDraft.personalInformation.citizenship || '',
        },
        // {
        //   key: 'city',
        //   label: t('employeePage.employeeDetails.personalInformation.cityLabel'),
        //   text: employeeDraft.personalInformation.city || '',
        // },
        // {
        //   key: 'street',
        //   label: t('employeePage.employeeDetails.personalInformation.streetLabel'),
        //   text: employeeDraft.personalInformation.street || '',
        // },
        // {
        //   key: 'houseNumber',
        //   label: t('employeePage.employeeDetails.personalInformation.houseLabel'),
        //   text: employeeDraft.personalInformation.houseNumber || '',
        // },
      ],
    },
    // {
    //   key: 'hr-information',
    //   title: t('employeePage.employeeDetails.hrInfomation.header'),
    //   items: [
    //     {
    //       key: 'employeeId',
    //       label: t('employeePage.employeeDetails.hrInfomation.employeeIdLabel'),
    //       text: employeeDraft.hrInformation.employeeId || '',
    //     },
    //     {
    //       key: 'hireDate',
    //       label: t('employeePage.employeeDetails.hrInfomation.hireDateLabel'),
    //       text: employeeDraft.hrInformation.hireDate || '',
    //     },
    //     {
    //       key: 'employmentType',
    //       label: t('employeePage.employeeDetails.hrInfomation.employmentTypeLabel'),
    //       text: employeeDraft.hrInformation.employmentType
    //         ? t(
    //             `employeePage.employeeDetails.hrInfomation.${employeeDraft.hrInformation.employmentType}`
    //           )
    //         : '',
    //     },
    //   ],
    // },
    // {
    //   key: 'compensation-details',
    //   title: t('employeePage.employeeDetails.compensationDetails.header'),
    //   items: [
    //     {
    //       key: 'bankAccount',
    //       label: t('employeePage.employeeDetails.compensationDetails.bankAccountLabel'),
    //       text: employeeDraft.compensationDetails.bankAccount || '',
    //     },
    //     {
    //       key: 'salary',
    //       label: t('employeePage.employeeDetails.compensationDetails.salaryLabel'),
    //       text: employeeDraft.compensationDetails.salary || '',
    //     },
    //     {
    //       key: 'bonus',
    //       label: t('employeePage.employeeDetails.compensationDetails.bonusLabel'),
    //       text: employeeDraft.compensationDetails.bonus || '',
    //     },
    //     {
    //       key: 'currency',
    //       label: t('employeePage.employeeDetails.compensationDetails.currencyLabel'),
    //       text: employeeDraft.compensationDetails.currency || '',
    //     },
    //     {
    //       key: 'payFrequency',
    //       label: t('employeePage.employeeDetails.compensationDetails.payFrequencyLabel'),
    //       text: employeeDraft.compensationDetails.payFrequency
    //         ? t(
    //             `employeePage.employeeDetails.compensationDetails.${employeeDraft.compensationDetails.payFrequency}`
    //           )
    //         : '',
    //     },
    //   ],
    // },
  ];

  return (
    <Stack height="100%" gap={2} py={2}>
      <Typography variant="h6">{t('employeePage.roles.title')}</Typography>
      <Roles employeeDraft={employeeDraft} readOnly={readOnly} />
      <Divider />
      {sections.map((section, index) => (
        <Stack key={section.key} gap={2}>
          <Typography variant="h6">{section.title}</Typography>
          {readOnly ? (
            <EmployeeTextSection list={section.items} dataCy={`${section}-text-section`} />
          ) : (
            <EditableContactInfo section={section} employee={employee} />
          )}{' '}
          {index < sections.length - 1 && <Divider />}
        </Stack>
      ))}
    </Stack>
  );
}
