import { Components, Theme } from '@mui/material';

const MuiIconButton: Components<Theme>['MuiIconButton'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
    }),
  },
};

export default MuiIconButton;
