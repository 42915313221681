import { Avatar, ListItemAvatar, ListItemText, MenuItem } from '@mui/material';

interface Props {
  onClick: () => void;
  title: string;
  description?: string;
  avatarUrl?: string | null;
}

export default function SearchEmployeeResultItem({
  onClick,
  title,
  description,
  avatarUrl,
}: Props) {
  return (
    <MenuItem onClick={onClick}>
      <ListItemAvatar>
        <Avatar src={avatarUrl || undefined} />
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={description}
        primaryTypographyProps={{ noWrap: true }}
        secondaryTypographyProps={{ noWrap: true }}
      />
    </MenuItem>
  );
}
