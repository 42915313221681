import { MenuItem, TextField } from '@mui/material';
import { useDispatch } from 'react-redux';
import dayjs from 'dayjs';
import { t } from 'i18next';
import { getData } from 'country-list';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { updateEmployeeDetails } from 'src/features/employees/controller/employee.slice';
import EmployeeFormSection from './EmployeeFormSection';

interface Props {
  items: {
    key: string;
    text: string;
    label: string;
  }[];
}

export default function PersonalInformationSection({ items }: Props) {
  const dispatch = useDispatch();
  const genderOption = [
    { value: 'male', label: t('employeePage.employeeDetails.personalInformation.male') },
    { value: 'female', label: t('employeePage.employeeDetails.personalInformation.female') },
  ];
  const citizenshipOptions = getData().map((country: any) => ({
    value: country.name,
    label: country.name,
  }));

  type Option = {
    value: string;
    label: string;
  };

  const getValueFromLabel = (label: string, options: Option[]): string => {
    const option = options.find((opt) => opt.label === label);
    return option ? option.value : '';
  };

  return (
    <EmployeeFormSection>
      {items.map(({ key, text, label }) => {
        if (key === 'dateOfBirth') {
          return (
            <DatePicker
              data-cy="employee-personal-information-date-of-birth-selector"
              label={label}
              disableFuture
              value={text ? dayjs(text) : null}
              onChange={(val) => {
                dispatch(
                  updateEmployeeDetails({
                    keyPath: 'personalInformation.dateOfBirth',
                    value: val?.toISOString() as string,
                  })
                );
              }}
              slotProps={{
                textField: {
                  size: 'small',
                  fullWidth: true,
                },
              }}
            />
          );
        }
        if (key === 'gender') {
          return (
            <TextField
              select
              fullWidth
              label={label}
              data-cy="employee-personal-information-gender-selector"
              value={getValueFromLabel(text, genderOption)}
              onChange={(event) => {
                const newValue = event.target.value;
                if (text !== newValue) {
                  dispatch(
                    updateEmployeeDetails({
                      keyPath: 'personalInformation.gender',
                      value: newValue,
                    })
                  );
                }
              }}
              size="small"
            >
              {genderOption.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          );
        }
        if (key === 'citizenship') {
          return (
            <TextField
              select
              fullWidth
              label={label}
              data-cy="employee-personal-information-citizenship-selector"
              value={getValueFromLabel(text, citizenshipOptions)}
              onChange={(event) => {
                const newValue = event.target.value;
                if (text !== newValue) {
                  dispatch(
                    updateEmployeeDetails({
                      keyPath: 'personalInformation.citizenship',
                      value: newValue,
                    })
                  );
                }
              }}
              size="small"
            >
              {citizenshipOptions.map((option: Option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          );
        }
        if (key === 'city') {
          return (
            <TextField
              label={label}
              data-сy="employee-personal-information-city-selector"
              value={text}
              onChange={(e) =>
                dispatch(
                  updateEmployeeDetails({
                    keyPath: 'personalInformation.city',
                    value: e.target.value,
                  })
                )
              }
              fullWidth
              size="small"
            />
          );
        }
        if (key === 'street') {
          return (
            <TextField
              label={label}
              data-сy="employee-personal-information-street-selector"
              value={text}
              onChange={(e) =>
                dispatch(
                  updateEmployeeDetails({
                    keyPath: 'personalInformation.street',
                    value: e.target.value,
                  })
                )
              }
              fullWidth
              size="small"
            />
          );
        }
        if (key === 'houseNumber') {
          return (
            <TextField
              label={label}
              data-сy="employee-personal-information-house-number-selector"
              value={text}
              onChange={(e) =>
                dispatch(
                  updateEmployeeDetails({
                    keyPath: 'personalInformation.houseNumber',
                    value: e.target.value,
                  })
                )
              }
              fullWidth
              size="small"
            />
          );
        }

        return null;
      })}
    </EmployeeFormSection>
  );
}
