import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import HighlightWrapper from '../highlight-wrapper';
import { InfoType } from '../edit-bar/InfoActions';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';
import InfoPanelUI from './InfoPanelUI';

interface Props {
  onDelete: () => void;
  onDuplicate: () => void;
  onResizeStop?: ResizeStopCallbackType;
  value?: string;
  infoType?: InfoType;
  readOnly?: boolean;
  onChange?: (val: string) => void;
  handleInfoTypeChange: (type: InfoType) => void;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
  id: string;
}

export default function InfoPanel({
  onDelete,
  onDuplicate,
  value,
  infoType = 'info',
  readOnly = false,
  onChange,
  onResizeStop,
  handleInfoTypeChange,
  dragging,
  onResizeStart,
  id,
}: Props) {
  if (readOnly) return <InfoPanelUI infoType={infoType} value={value} readOnly={readOnly} />;
  return (
    <HighlightWrapper
      id={id}
      editBarProps={{
        baseActionsProps: { onDelete, onDuplicate },
        textActionsProps: {},
        infoActionsProps: {
          infoType,
          onChangeActiveInfoType: (type) => handleInfoTypeChange(type as InfoType),
        },
      }}
      dragging={dragging}
      horizontalResize={{}}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent }) => (
        <InfoPanelUI infoType={infoType} value={value} onChange={onChange} readOnly={readOnly}>
          {EditBarComponent}
        </InfoPanelUI>
      )}
    </HighlightWrapper>
  );
}
