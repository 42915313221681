import { Box, Container, Stack } from '@mui/material';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';
import { useState } from 'react';
import useResizeObserver from '@react-hook/resize-observer';
import { TrainingModuleWithStatus } from 'src/store/api/trainings/trainingModulesApi';
import Sections from './sections';
import ModuleHeader from './ModuleHeader';
import ModuleOverviewBreadcrumbs from './ModuleOverviewBreadcrumbs';

interface Props {
  data: TrainingModuleWithStatus;
  editMode: boolean;
  onAddLessonCallback?: (id: string) => void;
}

export default function ModuleOverviewContent({ data, editMode, onAddLessonCallback }: Props) {
  const contentRef = useScrollOnDrag();
  const [isMobileView, setIsMobileView] = useState<boolean>();

  useResizeObserver(contentRef, (entry) => {
    setIsMobileView(entry.contentRect.width < 600);
  });

  return (
    <Stack alignItems="center" ref={contentRef} height="100%" overflow="auto">
      {isMobileView !== undefined && (
        <>
          <Container
            component={Box}
            maxWidth="lg"
            disableGutters={isMobileView}
            px={2}
            pt={isMobileView ? 2 : 2.5}
          >
            <ModuleOverviewBreadcrumbs
              dataCy="module-overview-breadcrumbs"
              moduleName={data.name}
              moduleId={data.id}
              trainingId={data.trainingId}
            />
          </Container>
          <ModuleHeader
            trainingModule={data}
            readOnly={!editMode}
            mobileView={isMobileView}
            dataCy="module-overview-header"
          />
          <Container maxWidth="lg" component={Box} disableGutters>
            <Sections
              dataCy="module-overview-sections"
              moduleId={data.id}
              mobileView={isMobileView}
              readOnly={!editMode}
              onAddLessonCallback={onAddLessonCallback}
            />
          </Container>
        </>
      )}
    </Stack>
  );
}
