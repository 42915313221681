import { Employee } from 'src/features/employees/model';
import CompensationDetailsSection from './editable-contact-info/CompensationDetailsSection';
import ContactInformationSection from './editable-contact-info/ContactInformationSection';
import HrInformationSection from './editable-contact-info/HrInformationSection';
import PersonalInformationSection from './editable-contact-info/PersonalInformationSection';

interface Props {
  section: {
    key: string;
    title: string;
    items: {
      key: string;
      label: string;
      text: string;
    }[];
  };
  employee: Employee;
}

export default function EditableContactInfo({ section, employee }: Props) {
  switch (section.key) {
    case 'contact-information': {
      return <ContactInformationSection items={section.items} employee={employee} />;
    }
    case 'personal-information': {
      return <PersonalInformationSection items={section.items} />;
    }
    case 'hr-information': {
      return <HrInformationSection items={section.items} />;
    }
    case 'compensation-details': {
      return <CompensationDetailsSection items={section.items} />;
    }
    default:
      return <ContactInformationSection items={section.items} employee={employee} />;
  }
}
