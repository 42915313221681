import { Components, Theme } from '@mui/material';

const MuiTooltip: Components<Theme>['MuiTooltip'] = {
  styleOverrides: {
    tooltip: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius * 0.5,
    }),
  },
};

export default MuiTooltip;
