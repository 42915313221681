import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Divider,
  Stack,
  SvgIcon,
  TextField,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFetchSettingsQuery, useUpdateSettingsMutation } from 'src/store/api/aiChatApi';
import { WorkbaseResetIcon } from 'src/assets/icons/workbaseIcons';
import { useState, useRef, useEffect } from 'react';

const centeredProgress = (
  <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
    <CircularProgress />
  </Box>
);

export default function AssistantSettings() {
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useFetchSettingsQuery();
  const [updateSettings] = useUpdateSettingsMutation();
  const [name, setName] = useState(data?.name || '');
  const [customInstructions, setCustomInstructions] = useState(data?.customInstructions || '');
  const ref = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (data) {
      setName(data.name);
      setCustomInstructions(data.customInstructions);
    }
  }, [data]);

  const handleUpdateSettings = () => {
    updateSettings({
      name,
      customInstructions,
    });
  };

  const resetDefaultButtonDisabled = customInstructions === '';

  const handleResetDefaultButtonClick = () => {
    updateSettings({
      name,
      customInstructions: '',
    }).then((resp: any) => {
      if (!resp.error && ref.current) {
        ref.current.value = '';
        setCustomInstructions('');
      }
    });
  };

  if (error) {
    return (
      <Alert
        severity="error"
        action={
          <Button onClick={refetch} size="small">
            {t('validationErrorMessages.labelErrorTryAgainButton')}
          </Button>
        }
      >
        {t('companySettings.siteInformation.labelErrorDescription')}
      </Alert>
    );
  }

  return isLoading ? (
    centeredProgress
  ) : (
    <Stack width="100%" alignItems="center">
      <Stack gap={2} maxWidth={(theme) => theme.breakpoints.values.lg} width="100%">
        <Stack direction="row" gap={1} alignItems="flex-start">
          <Typography variant="h4" fontWeight="bold" data-cy="site-information-header">
            {t('companySettings.assistant.header')}
          </Typography>
        </Stack>

        {data && (
          <Stack gap={2.5}>
            <TextField
              size="small"
              label={t('companySettings.assistant.name')}
              placeholder={t('companySettings.assistant.namePlaceholder')}
              value={name}
              onBlur={handleUpdateSettings}
              onChange={(e) => {
                setName(e.target.value);
              }}
            />
            <Divider />
            <Stack direction="row" justifyContent="space-between" alignItems="center">
              <Typography color="text.primary" variant="subtitle1" fontWeight="medium">
                {t('companySettings.assistant.resettableTextarea.label')}
              </Typography>
              <Stack justifyContent="right">
                <Button
                  data-cy="reset-button"
                  variant="text"
                  startIcon={<SvgIcon fontSize="inherit" component={WorkbaseResetIcon} />}
                  disabled={resetDefaultButtonDisabled}
                  onClick={handleResetDefaultButtonClick}
                >
                  {t('resettableTextArea.resetDefaultLabel')}
                </Button>
              </Stack>
            </Stack>
            <TextField
              inputRef={ref}
              value={customInstructions}
              placeholder={t('companySettings.assistant.resettableTextarea.placeholder')}
              multiline
              data-cy="ai-assistant-resettable-textarea"
              minRows={7}
              onBlur={handleUpdateSettings}
              maxRows={7}
              onChange={(e) => {
                setCustomInstructions(e.target.value);
              }}
              helperText={t('companySettings.assistant.resettableTextarea.helperText')}
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
