import { Badge, Box, IconButton, Stack, SvgIcon, Tooltip } from '@mui/material';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { WorkbasePermissions, WorkbaseTwoEmployeesIcon } from 'src/assets/icons/workbaseIcons';
import { useState } from 'react';
import { Department, Role } from '../../../model/types';
import { checkRoles, clearCheckedRoles } from '../../../controller/departments.slice';
import EmployeesListedRolesPopup from '../../employees-popup/EmployeesListedRolesPopup';
import DeleteRole from './DeleteRole';

interface SecondaryRolesActionProps {
  department: Department;
  showRoleActionButton?: boolean;
  role: Role;
}

export default function SecondaryRolesAction({
  role,
  department,
  showRoleActionButton = true,
}: SecondaryRolesActionProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isPopupActive, setPopupActive] = useState(false);

  const tooltipText = t('companySettings.sidebar.departments.employeePopup.tooltipText');
  const isRoleDraftMode = role.usersCount === undefined;
  return (
    <>
      <EmployeesListedRolesPopup
        isPopupActive={isPopupActive}
        setPopupActive={setPopupActive}
        name={role.name}
        roleId={role.id}
      />
      <Stack direction="row" alignItems="center" gap={1}>
        <IconButton
          data-cy="department-role-permission-button"
          size="small"
          onClick={() => {
            if (role.id) {
              dispatch(clearCheckedRoles());
              dispatch(checkRoles([role.id]));
            }
          }}
        >
          <Badge badgeContent={role.permissionIds.length} color="primary" showZero>
            <SvgIcon component={WorkbasePermissions} fontSize="small" />
          </Badge>
        </IconButton>
        <Tooltip title={isRoleDraftMode && tooltipText}>
          <Box>
            <IconButton
              data-cy="department-role-assign-to-role-button"
              size="small"
              disabled={isRoleDraftMode}
              onClick={() => setPopupActive(true)}
            >
              <Badge badgeContent={role.usersCount} color="primary" showZero>
                <SvgIcon component={WorkbaseTwoEmployeesIcon} fontSize="small" />
              </Badge>
            </IconButton>
          </Box>
        </Tooltip>
        {showRoleActionButton && (
          <DeleteRole
            roleId={role.id}
            departmentId={department.id}
            rolesLength={department.roles.length}
            usersCount={role?.usersCount}
          />
        )}
      </Stack>
    </>
  );
}
