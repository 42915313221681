import { useTranslation } from 'react-i18next';
import { Button, SvgIcon } from '@mui/material';
import { WorkbaseEmployeeportalIcon } from 'src/assets/icons/workbaseIcons';
import useScreenStream from './useScreenStream';

interface Props {
  onScreenStreamSelect: (val: MediaStream | null) => void;
  stream: MediaStream | null;
  disabled: boolean;
}

export default function ScreenRecorderButton({ onScreenStreamSelect, stream, disabled }: Props) {
  const { getScreenStream, stopScreenStream } = useScreenStream(stream, onScreenStreamSelect);
  const { t } = useTranslation();
  return (
    <Button
      sx={{
        flexShrink: 0,
      }}
      startIcon={
        <SvgIcon>
          <WorkbaseEmployeeportalIcon />
        </SvgIcon>
      }
      disabled={disabled}
      color={stream ? 'error' : 'primary'}
      onClick={() => {
        if (stream) {
          stopScreenStream();
        } else {
          getScreenStream();
        }
      }}
    >
      {t(stream ? 'recording.stopSharing' : 'recording.startSharing')}
    </Button>
  );
}
