import { Grid2 } from '@mui/material';
import React from 'react';

interface Props {
  children: React.ReactNode;
}

export default function EmployeeFormSection({ children }: Props) {
  return (
    <Grid2 container spacing={2} wrap="wrap">
      {React.Children.map(children, (child) => (
        <Grid2 size={4}>{child}</Grid2>
      ))}
    </Grid2>
  );
}
