import { ReactNode } from 'react';
import { Route, Routes, Navigate } from 'react-router-dom';
import Employees from 'src/features/employees';
import Dashboard from 'src/views/dashboard';
import ModuleOverviewView from 'src/views/module-overview';
import WhiteboardView from 'src/views/whiteboard/WhiteboardView';
import LessonView from 'src/views/lesson';
import DocumentsView from 'src/views/documents/DocumentsView';
import CompanySettingsView from 'src/views/company-settings';
import CustomPageView from 'src/views/custom-page';
import EmbeddedAppView from 'src/views/embedded-app';
import FullScreenChat from 'src/features/ai-chat/views/FullScreenChat';
import TrainingsView from 'src/views/trainings';
import TrainingCourseView from 'src/views/training-course/TrainingCourseView';
import { ChatProvider } from 'src/features/ai-chat/helpers/chatContext';
import MainLayout from 'src/layouts/MainLayout';
import Wiki from 'src/views/wiki';
import ErrorLayout from 'src/layouts/ErrorLayout';

interface RouteConfig {
  path: string;
  component: ReactNode;
}

const routes: RouteConfig[] = [
  {
    path: '/',
    component: <Dashboard />,
  },
  {
    path: '/wiki/:id/*',
    component: <Wiki />,
  },
  {
    path: '/wiki',
    component: <Wiki />,
  },
  {
    path: '/documents',
    component: <DocumentsView />,
  },
  {
    path: '/edit',
    component: <Dashboard />,
  },
  {
    path: '/settings/*',
    component: <CompanySettingsView />,
  },
  {
    path: 'team/*',
    component: <Employees />,
  },
  {
    path: 'ai/:chatId',
    component: <FullScreenChat />,
  },
  {
    path: 'page/:id/*',
    component: <CustomPageView />,
  },
  {
    path: 'app/:id/*',
    component: <EmbeddedAppView />,
  },
  {
    path: 'whiteboard/:id/*',
    component: <WhiteboardView />,
  },
  {
    path: '/trainings/:trainingId/:moduleId/:sectionId/:lessonId/*',
    component: <LessonView />,
  },
  {
    path: 'trainings/:trainingId',
    component: <TrainingCourseView />,
  },
  {
    path: 'trainings/:trainingId/edit',
    component: <TrainingCourseView />,
  },
  {
    path: '/trainings/:trainingId/:moduleId/*',
    component: <ModuleOverviewView />,
  },
  {
    path: 'trainings/',
    component: <TrainingsView />,
  },
  {
    path: 'trainings/edit',
    component: <TrainingsView />,
  },
];

function AuthorizedRoutes() {
  return (
    <ErrorLayout>
      <Routes>
        <Route
          element={
            <ChatProvider>
              <MainLayout />
            </ChatProvider>
          }
        >
          {routes.map(({ path, component }) => (
            <Route key={path} path={path} element={component} />
          ))}
          <Route path="*" element={<Navigate to="/" replace />} />
        </Route>
      </Routes>
    </ErrorLayout>
  );
}

export default AuthorizedRoutes;
