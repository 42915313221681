import { Stack, SvgIcon, Typography, Container, ToggleButton } from '@mui/material';
import { useCurrentUserQuery, usePatchCurrentUserMutation } from 'src/store/api/currentUserApi';
import { useTranslation } from 'react-i18next';
import { WorkbaseCloseIcon } from 'src/assets/icons/workbaseIcons';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import GeneralSettings from './general-settings/GeneralSettings';
import LanguageChange from './LanguageChange';
import LogoutBtns from './LogoutBtns';
import ThemeSettings from './ThemeSettings';
import AccessSettings from './AccessSettings';

const centeredProgress = (
  <Stack position="absolute" left="50%" top="50%" sx={{ transform: 'translate(-50%, -50%)' }}>
    <LottieLogoSpinner />
  </Stack>
);

export default function UserProfileMenu({ onClose }: { onClose: () => void }) {
  const { t } = useTranslation();
  const { data, isLoading } = useCurrentUserQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const hasAnyPermission = useHasAnyPermission();

  const hasAccessControl =
    data?.isOwner ||
    hasAnyPermission([
      'ADMIN_ACCESS_DASHBOARD_AND_PAGES',
      'ADMIN_ACCESS_EMBEDDED_APPS',
      'ADMIN_ACCESS_TRAININGS',
      'ADMIN_ACCESS_WIKIS',
    ]);

  const [patchCurrentUser] = usePatchCurrentUserMutation();

  const changeHasAccessSettings = (hasAccess: boolean) => {
    patchCurrentUser({ adminAccessToAllContent: hasAccess });
  };

  return (
    <Stack p={2.5} maxWidth={320} gap={2} data-cy="user-profile-menu">
      {isLoading ? (
        centeredProgress
      ) : (
        <>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" fontWeight="medium">
              {t('generalSettings.header')}
            </Typography>
            <ToggleButton value="close-profile-menu" selected onClick={onClose} size="medium">
              <SvgIcon fontSize="small" component={WorkbaseCloseIcon} />
            </ToggleButton>
          </Stack>
          <Container maxWidth="xs" disableGutters>
            <Stack direction="column" gap={2}>
              {data && (
                <>
                  {hasAccessControl && (
                    <AccessSettings
                      dataCy="user-profile-menu"
                      initialChecked={data.adminAccessToAllContent}
                      onChange={changeHasAccessSettings}
                    />
                  )}
                  <ThemeSettings />
                  <GeneralSettings {...data} dataCy="user-profile-menu" />
                  <LanguageChange {...data} dataCy="user-profile-menu" />
                  <LogoutBtns
                    accessToMultipleCompanies={data.accessToMultipleCompanies}
                    dataCy="user-profile-menu"
                  />
                </>
              )}
            </Stack>
          </Container>
        </>
      )}
    </Stack>
  );
}
