import { Components, Theme } from '@mui/material';
import { WorkbaseChevronDownIcon } from 'src/assets/icons/workbaseIcons';

const MuiSelect: Components<Theme>['MuiSelect'] = {
  defaultProps: {
    IconComponent: WorkbaseChevronDownIcon,
  },
  styleOverrides: {
    root: {
      '.MuiSelect-icon': {
        top: 'calc(50% - 0.75em)',
        right: 14,
      },
      '& .MuiSelect-select': {
        paddingRight: '48px !important',
      },
    },
  },
};

export default MuiSelect;
