import { ChangeEvent } from 'react';
import { Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseDepartmentsIcon } from 'src/assets/icons/workbaseIcons';
import { useAppDispatch } from 'src/store';
import ContrastTextField from 'src/ui-components/custom/textfield/contrast';
import { Department } from '../../../model/types';
import { useDepartmentValid } from '../useDepartmentValid';
import { editDepartmentName } from '../../../controller/departments.slice';
import DeleteDepartment from './DeleteDepartment';

interface DepartmentsCollapsibleTitleProps {
  department: Department;
  departmentId: string;
  departments: Department[];
  inputDepartmentRef: React.RefObject<HTMLInputElement>;
}

export default function DepartmentHeader({
  department,
  departmentId,
  departments,
  inputDepartmentRef,
}: DepartmentsCollapsibleTitleProps) {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const { setDepartmentBlurred, showDepartmentError, validationDepartmentMessage } =
    useDepartmentValid(department.name, department?.id);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(editDepartmentName({ departmentId, name: e.target.value }));
  };

  const handleBlur = () => {
    setDepartmentBlurred(true);
  };

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={1}>
      <Stack direction="row" gap={2} alignItems="center" color="primary.contrastText">
        <SvgIcon component={WorkbaseDepartmentsIcon} color="inherit" fontSize="small" />
        <Typography variant="h6" fontWeight="bold" color="inherit">
          {t('companySettings.sidebar.departments.collapsibleDepartmentSubTitle')}
        </Typography>
      </Stack>
      <ContrastTextField
        defaultValue="small"
        fullWidth
        data-cy="department-name-textfield"
        inputRef={inputDepartmentRef}
        size="small"
        value={department.name}
        onChange={(e) => handleChange(e)}
        onBlur={handleBlur}
        error={showDepartmentError}
        helperText={showDepartmentError && validationDepartmentMessage}
      />
      <DeleteDepartment
        departmentId={department?.id}
        usersCount={department?.usersCount}
        departments={departments}
      />
    </Stack>
  );
}
