import { Avatar, Box, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { BaseEmployeeType } from 'src/features/employees/model';
import EmployeeActionButton from './EmployeeActionButton';

export default function EmployeeRolesList({
  employees,
  roleId,
}: {
  employees: BaseEmployeeType[];
  roleId: string;
}) {
  return (
    <List>
      {employees.map((emp: BaseEmployeeType) => (
        <ListItem disableGutters key={emp.id}>
          <ListItemAvatar>
            <Avatar src={emp.avatarUrl} />
          </ListItemAvatar>
          <ListItemText
            primaryTypographyProps={{
              noWrap: true,
            }}
            secondaryTypographyProps={{
              noWrap: true,
            }}
            primary={`${emp.firstName} ${emp.lastName}`}
            secondary={emp.email ? `${emp.email}` : null}
          />
          <Box ml={2} flexShrink={0}>
            <EmployeeActionButton userId={emp.id} roleId={roleId} />
          </Box>
        </ListItem>
      ))}
    </List>
  );
}
