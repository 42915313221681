import { ToggleButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { ResourceType } from 'src/store/api/searchResourceApi';
import { bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import SvgIconByName from 'src/ui-components/icon/SvgIconByName';
import { ShareLevel } from 'src/lib/types/share-level';
import ShareModalDialog from './ShareModalDialog';

interface Props {
  name: string;
  resourceId: string;
  resourceType: ResourceType;
  shareLevel?: ShareLevel;
  dataCy?: string;
}

export default function ShareModal({ name, resourceId, resourceType, shareLevel, dataCy }: Props) {
  const dialogState = usePopupState({ variant: 'dialog' });

  if (!shareLevel || shareLevel !== ShareLevel.manage) return null;
  return (
    <>
      <Tooltip title={t('share')}>
        <ToggleButton
          data-cy={dataCy && `${dataCy}-share-modal-button-modal-trigger`}
          size="small"
          value="share-modal-button-modal-trigger"
          selected
          {...bindTrigger(dialogState)}
        >
          <SvgIconByName iconName="WorkbaseShareIcon" fontSize="small" />
        </ToggleButton>
      </Tooltip>
      <ShareModalDialog
        name={name}
        resourceId={resourceId}
        resourceType={resourceType}
        dialogState={dialogState}
        shareLevel={shareLevel}
      />
    </>
  );
}
