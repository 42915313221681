import { useTranslation } from 'react-i18next';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useParams } from 'react-router-dom';
import {
  useDeleteTrainingModuleMutation,
  useDuplicateTrainingModuleMutation,
} from 'src/store/api/trainings/trainingModulesApi';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';

export default function ModuleActions({
  moduleId,
  onDelete,
  sectionsCount = 0,
  lessonsCount = 0,
}: {
  moduleId: string;
  onDelete?: () => void;
  sectionsCount?: number;
  lessonsCount?: number;
}) {
  const { t } = useTranslation();
  const param = useParams() as { trainingId: string };

  const [deleteModule] = useDeleteTrainingModuleMutation();
  const [duplicateModule] = useDuplicateTrainingModuleMutation();

  const sectionsText = t('deleteModuleModal.section', { count: sectionsCount });
  const lessonsText = t('deleteModuleModal.lesson', { count: lessonsCount });

  return (
    <DeleteDuplicateMenu
      deleteDialogDescription={t('deleteModuleModal.content', {
        sections: sectionsText,
        lessons: lessonsText,
      })}
      onDelete={() => {
        deleteModule({ id: moduleId, trainingId: param.trainingId }).then((resp: any) => {
          if (resp.error) {
            showNotification(t('notifications.deleteError'), 'error');
          } else {
            onDelete?.();
            showNotification(t('notifications.deleteSuccess'));
          }
        });
      }}
      onDuplicate={() => {
        duplicateModule({ trainingId: param.trainingId, moduleId }).then((resp: any) => {
          if (resp.error) {
            showNotification(t('notifications.duplicateError'), 'error');
          } else {
            showNotification(t('notifications.duplicateSuccess'));
          }
        });
      }}
    />
  );
}
