import React, { createContext, useReducer, useEffect, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Thread } from 'src/store/api/aiChatApi';
import { sidebarApi } from 'src/store/api/sidebarApi';
import { ChatState, ChatAction } from './types';
import { chatReducer } from './chatReducer';
import { useWebSocket } from './use-websocket';
import useFetchChats from './use-fetchChats';

const initialState: ChatState = {
  isOpen: false,
  isFullScreen: false,
  locationBeforeFullscreen: null,
  messages: [],
  threads: [],
  threadId: localStorage.getItem('threadId'),
  currentStream: '',
  waitingResponse: false,
  error: null,
  needsUpdate: null,
  isLoading: false,
  imageUrl: null,
  lastUserMessage: null,
};

export const ChatContext = createContext<{
  state: ChatState;
  dispatch: React.Dispatch<ChatAction>;
  ws: WebSocket | null;
}>({
  state: initialState,
  dispatch: () => null,
  ws: null,
});

export function ChatProvider({ children }: { children: React.ReactNode }) {
  const [state, dispatch] = useReducer(chatReducer, initialState);
  const rtkDispatch = useDispatch<any>();
  const { chatId } = useParams();
  const ws = useWebSocket(chatId || (state.threadId as string), dispatch);
  useFetchChats(dispatch, chatId || (state.threadId as string));

  useEffect(() => {
    dispatch({ type: 'SET_IMAGE_URL', payload: null });
  }, [state.threadId]);

  // handle opening chat from the link + garbage links
  useEffect(() => {
    if (
      state.isFullScreen &&
      chatId &&
      state.threads.find((thread: Thread) => thread.id === chatId)
    ) {
      dispatch({ type: 'SET_SELECTED_CHAT', payload: chatId });
    }
  }, [chatId, dispatch, state.threads, state.isFullScreen]);

  useEffect(() => {
    if (state.needsUpdate) {
      rtkDispatch(sidebarApi.util.invalidateTags(['Sidebar']));
      dispatch({ type: 'SET_NEEDS_UPDATE', payload: null });
    }
  }, [state.needsUpdate, rtkDispatch]);

  const contextValue = useMemo(() => ({ state, dispatch, ws }), [state, dispatch, ws]);

  return <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>;
}
