import {
  Button,
  Divider,
  IconButton,
  InputAdornment,
  SvgIcon,
  TextField,
  Tooltip,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseBackIcon,
  WorkbaseDeleteSquareIcon,
  WorkbaseLinksIcon,
} from '../../../../../../assets/icons/workbaseIcons';

interface Props {
  onRemove: () => void;
  value: string;
  onApply: (val: string) => void;
  onClear: () => void;
}

export default function LinkInput({ onRemove, value, onApply, onClear }: Props) {
  const [val, setVal] = useState(value);
  const hasError = val === '';
  const { t } = useTranslation();

  useEffect(() => {
    setVal(value);
  }, [value]);

  return (
    <>
      <TextField
        autoFocus
        placeholder={t('editor.topBar.linkInputPlaceholder')}
        size="small"
        value={val}
        onChange={(e) => setVal(e.target.value)}
        error={hasError}
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon component={WorkbaseLinksIcon} fontSize="small" />
              </InputAdornment>
            ),
          },
        }}
      />
      <Tooltip title={!val ? t('editor.topBar.tooltip.emptyLinkField') : ''}>
        <span>
          <Button
            disabled={!val}
            disableElevation
            color="inherit"
            onClick={() => {
              onApply(val);
              onRemove();
            }}
          >
            {t('editor.topBar.applyLinkButton')}
          </Button>
        </span>
      </Tooltip>
      <Divider orientation="vertical" flexItem />
      <Tooltip title={t('editor.topBar.tooltip.remove')} placement="top">
        <IconButton
          onClick={() => {
            onClear();
          }}
        >
          <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={t('editor.topBar.tooltip.back')} placement="top">
        <IconButton
          onClick={() => {
            onRemove();
          }}
        >
          <SvgIcon component={WorkbaseBackIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}
