import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Box, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import ShareModal from 'src/core/share-modal/ShareModal';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import {
  useDeleteWhiteboardMutation,
  useDuplicateWhiteboardMutation,
  usePatchWhiteboardMutation,
  useWhiteboardQuery,
} from 'src/store/api/whiteboardsApi';
import { ShareLevel } from 'src/lib/types/share-level';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { WorkbasePressContentIcon } from 'src/assets/icons/workbaseIcons';
import AwarenessAvatars from '../awareness-avatars';

interface Props {
  id: string;
}

export default function WhiteboardActionButtons({ id }: Props) {
  const [deleteWhiteboard] = useDeleteWhiteboardMutation();
  const [duplicateWhiteboard] = useDuplicateWhiteboardMutation();
  const hasAnyPermission = useHasAnyPermission();
  const hasAddWhiteboardsPermission = hasAnyPermission(['ADD_WHITEBOARDS']);
  const { data: shareLevelData } = useShareLevelQuery({
    resourceId: id,
    resourceType: 'whiteboard',
  });
  const [patchWhiteboard] = usePatchWhiteboardMutation();
  const { data } = useWhiteboardQuery(id);

  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <>
      <AwarenessAvatars />
      {data && (
        <>
          <Tooltip title={t('sidebarTooltip')}>
            <Box bgcolor="primary.paper" borderRadius={1}>
              <ToggleButton
                size="small"
                color={data.sidebar ? 'primary' : 'standard'}
                selected
                value={data.sidebar}
                onChange={(_, state) => {
                  patchWhiteboard({ id, sidebar: !state });
                }}
              >
                <SvgIcon component={WorkbasePressContentIcon} fontSize="small" />
              </ToggleButton>
            </Box>
          </Tooltip>
          <ShareModal
            name={data?.name || t('whiteboards.unnamedWhiteboard')}
            resourceType="whiteboard"
            resourceId={id}
            shareLevel={shareLevelData?.shareLevel}
          />
        </>
      )}
      <DeleteDuplicateMenu
        onDelete={
          shareLevelData?.shareLevel === ShareLevel.manage
            ? () => {
                deleteWhiteboard(id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.deleteError'), 'error');
                  } else {
                    showNotification(t('notifications.deleteSuccess'));
                    navigate('/');
                  }
                });
              }
            : undefined
        }
        onDuplicate={
          hasAddWhiteboardsPermission
            ? () => {
                duplicateWhiteboard(id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(t('notifications.duplicateError'), 'error');
                  } else {
                    navigate(`/whiteboard/${resp.data.id}/edit`);
                    showNotification(t('notifications.duplicateSuccess'));
                  }
                });
              }
            : undefined
        }
        deleteDialogDescription={t('whiteboards.deleteWhiteboardModal.content')}
      />
    </>
  );
}
