import TextItem from 'src/features/editor/view/grid/widgets/text-item';
import EditorDivider from 'src/features/editor/view/grid/widgets/divider';
import Button from 'src/features/editor/view/grid/widgets/button';
import HeadlineItem from 'src/features/editor/view/grid/widgets/headline-item';
import InfoPanel from 'src/features/editor/view/grid/widgets/info-panel';
import UploadItem from 'src/features/editor/view/grid/widgets/upload-item';
import SpacerItem from 'src/features/editor/view/grid/widgets/spacer/SpacerItem';
import { useMemo } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { downloadFile } from 'src/lib/utils';
import { useAppDispatch } from 'src/store';
import { ColumnType } from 'src/features/editor/view/grid/widgets/highlight-wrapper/resize/ResizableWrapper';
import { Direction } from 're-resizable/lib/resizer';
import TrainingsItem, {
  TrainingsItemProps,
} from 'src/features/editor/view/grid/widgets/trainings-item/TrainingsItem';
import { useGetTrainingsQuery } from 'src/store/api/trainings/trainingsApi';
import EmbeddedItem from 'src/features/editor/view/grid/widgets/embedded/EmbeddedItem';
import { useAllTrainingModulesQuery } from 'src/store/api/trainings/trainingModulesApi';
import downloadVideo from 'src/lib/utils/downloadVideo';
import TrainingModulesWidget from 'src/views/training-course/modules-widget';
import { imageDuplicate } from 'src/lib/utils/imageDuplicate';
import { videoDuplicate } from 'src/lib/utils/videoDuplicate';
import {
  BaseGridItem,
  ElementType,
  GridButtonItem,
  GridContentItem,
  GridEmbeddedItem,
  GridInfoPanelItem,
  GridItemType,
  GridSpacerItem,
  GridTrainingsItem,
  GridUploadItem,
  ImageGridItem,
  TrainingType,
  VideoGridItem,
} from '../../model/types';
import VideoWithProgress from './widgets/video-item/VideoWithProgress';
import ImageItem from './widgets/image-item';
import { setResizingRowId, useEditor } from '../../controller';

interface RenderFunctionProps {
  item: Partial<GridItemType>;
  readOnly: boolean;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
}

interface TrainingItemWithTrainingsProps extends TrainingsItemProps {
  handleTrainingsTypeChange: (trainingsType: TrainingType) => void;
}

function TrainingItemWithTrainings({ ...props }: TrainingItemWithTrainingsProps) {
  const { data } = useGetTrainingsQuery();
  const { data: modulesData } = useAllTrainingModulesQuery();

  return <TrainingsItem {...props} trainings={data} modules={modulesData} />;
}

const useElementConfigs = (rowId: string) => {
  const dispatch = useAppDispatch();
  const {
    handleRemoveItem,
    handleEditorChange,
    handleResizeItem,
    handleInfoTypeChange,
    handleDuplicateItem,
    handleMediaTypeChange,
    handleImageUpload,
    handleSetImage,
    handleTrainingsTitleChange,
    handleTrainingsIconChange,
    handleSelectedTrainingsChange,
    handleSetVideo,
    handleReplaceVideo,
    handleUpdateVideoPoster,
    handleUpdateVideoTitle,
    handleUpdateEmbeddedItem,
    handleTrainingsTypeChange,
    handleDisableForwardVideo,
    handleRequiredVideo,
    handleHrefChange,
    handleUndoCrop,
  } = useEditor();
  const elementConfigs = useMemo(() => {
    const onResizeStop =
      (id: string) =>
      (resizeProps: {
        column: ColumnType;
        direction: Direction;
        height?: number;
        aspectRatio?: number;
      }) => {
        dispatch(setResizingRowId(null));
        return handleResizeItem(id)(resizeProps);
      };

    const onResizeStart = () => dispatch(setResizingRowId(rowId));

    const configs: Record<
      ElementType,
      {
        render: (props: RenderFunctionProps) => React.ReactNode;
        fullWidth?: boolean;
      }
    > = {
      spacer: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id, height } = item as GridSpacerItem;
          return (
            <SpacerItem
              onResizeStop={onResizeStop(id)}
              id={id}
              onResizeStart={onResizeStart}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              height={height}
              readOnly={readOnly}
              dragging={dragging}
              {...props}
            />
          );
        },
      },
      textItem: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, id } = item as GridContentItem;
          return (
            <TextItem
              value={content}
              id={id}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onChange={handleEditorChange(id)}
              onResizeStop={onResizeStop(id)}
              dragging={dragging}
              onResizeStart={onResizeStart}
              {...props}
            />
          );
        },
      },
      button: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, href, id } = item as GridButtonItem;
          return (
            <Button
              value={content}
              id={id}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onChange={handleEditorChange(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onResizeStop={onResizeStop(id)}
              dragging={dragging}
              onResizeStart={onResizeStart}
              href={href}
              onHrefChange={handleHrefChange(id)}
              {...props}
            />
          );
        },
      },
      divider: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id } = item as BaseGridItem;
          return (
            <EditorDivider
              readOnly={readOnly}
              id={id}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onResizeStop={onResizeStop(id)}
              dragging={dragging}
              onResizeStart={onResizeStart}
              {...props}
            />
          );
        },
      },
      heading: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, id } = item as GridContentItem;
          return (
            <HeadlineItem
              value={content}
              readOnly={readOnly}
              id={id}
              onDelete={() => handleRemoveItem(id)}
              onChange={handleEditorChange(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onResizeStop={onResizeStop(id)}
              dragging={dragging}
              {...props}
            />
          );
        },
        fullWidth: true,
      },
      infoPanel: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { content, infoType, id } = item as GridInfoPanelItem;
          return (
            <InfoPanel
              value={content}
              id={id}
              readOnly={readOnly}
              infoType={infoType}
              handleInfoTypeChange={handleInfoTypeChange(id)}
              onDelete={() => handleRemoveItem(id)}
              onChange={handleEditorChange(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onResizeStop={onResizeStop(id)}
              dragging={dragging}
              onResizeStart={onResizeStart}
              {...props}
            />
          );
        },
      },
      upload: {
        render: ({ item, readOnly, dragging, ...props }) => {
          if (readOnly) return null;
          const { id, mediaType } = item as GridUploadItem;
          return (
            <UploadItem
              id={id}
              type={mediaType}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onResizeStop={onResizeStop(id)}
              onChangeMediaType={handleMediaTypeChange(id)}
              onImageUpload={handleSetImage(id)}
              onVideoUpload={handleSetVideo(id)}
              dragging={dragging}
              onResizeStart={onResizeStart}
              {...props}
            />
          );
        },
      },
      image: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const { id, url, height, href, fileName, imageId } = item as ImageGridItem;
          return (
            <ImageItem
              id={id}
              url={url}
              href={href}
              fileName={fileName}
              height={height}
              readOnly={readOnly}
              onDelete={() => handleRemoveItem(id)}
              onUndoCrop={handleUndoCrop(id)}
              onDuplicate={async () => {
                const response = await imageDuplicate(imageId);
                const newImageId = response.data.result.id;
                handleDuplicateItem(id, { imageId: newImageId });
              }}
              onResizeStop={onResizeStop(id)}
              onHrefUpdate={handleHrefChange(id)}
              onDownload={() => downloadFile(url, fileName)}
              onUploadImage={handleImageUpload(id)}
              onAddCaption={() => {}}
              dragging={dragging}
              onResizeStart={onResizeStart}
              {...props}
            />
          );
        },
      },
      trainings: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const i = item as GridTrainingsItem;
          const {
            id,
            selectedItems,
            title = '',
            icon,
            mode = 'default',
            trainingsType,
            columnStart,
            columnEnd,
          } = i;
          const columnsCountNum = columnEnd - columnStart;

          return (
            <TrainingItemWithTrainings
              id={id}
              title={title}
              icon={icon}
              onTitleChange={handleTrainingsTitleChange(id)}
              onIconChange={handleTrainingsIconChange(id)}
              onTrainingsChange={handleSelectedTrainingsChange(id)}
              selectedItems={selectedItems}
              readOnly={readOnly}
              mode={mode}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onResizeStop={onResizeStop(id)}
              dragging={dragging}
              onResizeStart={onResizeStart}
              trainingsType={trainingsType}
              handleTrainingsTypeChange={handleTrainingsTypeChange(id)}
              columnsCountNum={columnsCountNum}
              {...props}
            />
          );
        },
      },
      video: {
        render: ({ item, readOnly, dragging, ...props }) => {
          const {
            id,
            videoId,
            url,
            fileName,
            height,
            videoPoster,
            title = '',
            isFastForwardDisabled,
            isVideoRequired,
            isVideoUploaded,
          } = item as VideoGridItem;
          return (
            <VideoWithProgress
              id={id}
              videoId={videoId}
              onUndoCrop={handleUndoCrop(id)}
              title={title}
              url={url}
              videoPoster={videoPoster}
              readOnly={readOnly}
              height={height}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={async () => {
                const response = await videoDuplicate(videoId);
                const newVideoId = response.data.id;
                handleDuplicateItem(id, { videoId: newVideoId });
              }}
              onResizeStop={onResizeStop(id)}
              onVideoDownload={() => downloadVideo(videoId, fileName)}
              onReplaceVideo={handleReplaceVideo(id)}
              onUpdateVideoTitle={handleUpdateVideoTitle(id)}
              onUpdateVideoPoster={handleUpdateVideoPoster(id)}
              dragging={dragging}
              onResizeStart={onResizeStart}
              disabledFastForward={isFastForwardDisabled}
              isVideoRequired={isVideoRequired}
              onDisableFastForward={handleDisableForwardVideo(id)}
              onRequired={handleRequiredVideo(id)}
              isVideoUploaded={isVideoUploaded}
              {...props}
            />
          );
        },
      },
      embedded: {
        render: ({ item, dragging, ...props }) => {
          const { id, height, contentType, content } = item as GridEmbeddedItem;
          return (
            <EmbeddedItem
              id={id}
              onDelete={() => handleRemoveItem(id)}
              onDuplicate={() => handleDuplicateItem(id)}
              onResizeStop={onResizeStop(id)}
              dragging={dragging}
              onResizeStart={onResizeStart}
              height={height}
              contentType={contentType}
              content={content}
              onUpdate={handleUpdateEmbeddedItem(id)}
              {...props}
            />
          );
        },
      },
      trainingModules: {
        render: ({ item, dragging, readOnly }) => {
          const { id } = item as GridEmbeddedItem;
          return (
            <TrainingModulesWidget
              id={id}
              onResizeStop={onResizeStop(id)}
              dragging={dragging}
              onResizeStart={onResizeStart}
              readOnly={readOnly}
            />
          );
        },
      },
    };
    return configs;
  }, [
    handleRemoveItem,
    handleEditorChange,
    handleResizeItem,
    handleInfoTypeChange,
    handleDuplicateItem,
    handleMediaTypeChange,
    handleSetImage,
    handleImageUpload,
    handleReplaceVideo,
    handleSetVideo,
    handleUpdateVideoPoster,
    handleUpdateVideoTitle,
    dispatch,
    rowId,
    handleTrainingsTitleChange,
    handleTrainingsIconChange,
    handleSelectedTrainingsChange,
    handleUpdateEmbeddedItem,
    handleTrainingsTypeChange,
    handleDisableForwardVideo,
    handleRequiredVideo,
    handleHrefChange,
    handleUndoCrop,
  ]);

  return elementConfigs;
};

export default useElementConfigs;
