import { forwardRef, memo, RefObject, useContext, useEffect, useRef, useState } from 'react';
import { alpha, Container, Paper, Stack, Typography, useTheme } from '@mui/material';
import { t } from 'i18next';
import { useFetchSettingsQuery } from 'src/store/api/aiChatApi';
import MarkdownMessage from './MarkdownMessage';
import AttachmentImage from './AttachmentImage';
import { ChatContext } from '../../helpers/chatContext';
import { ChatStateMessage } from '../../helpers/types';
import DynamicLottie from './DynamicLottie';
import AlertError from './AlertError';

interface WrappedMessageProps {
  message: ChatStateMessage;
  isMobile: boolean;
  assistantName?: string;
  waitingResponse?: boolean;
}

function WrappedMessage({
  message,
  isMobile,
  assistantName,
  waitingResponse = false,
}: WrappedMessageProps) {
  const imageIndex = message.content.findIndex((content) => content.type === 'image_url');
  const textIndex = message.content.findIndex((content) => content.type === 'text');
  const isUser = message.role === 'user';
  const theme = useTheme();
  return (
    <Stack
      key={message.id}
      direction="row"
      justifyContent={message.role === 'user' ? 'flex-end' : 'flex-start'}
      my={1}
      mr={isUser ? 0 : 5}
      ml={isUser ? 5 : 0}
    >
      <Stack
        overflow="hidden"
        component={Paper}
        elevation={0}
        variant={isUser ? 'outlined' : undefined}
        maxWidth={isMobile ? '100%' : '75%'}
        px={isUser ? 1 : 2}
        py={1}
        bgcolor={isUser ? 'background.default' : 'primary.main'}
        borderRadius={1}
        sx={{
          overflowWrap: 'break-word',
        }}
      >
        {isUser ? (
          <Typography variant="body2">{t('assistant.you')}</Typography>
        ) : (
          <Typography variant="body2" color={alpha(theme.palette.primary.contrastText, 0.7)}>
            {assistantName}
          </Typography>
        )}
        {waitingResponse && (
          <Stack direction="row" alignItems="start" width="100%" pr={5}>
            <Typography color="primary.contrastText">{t('assistant.thinking')}</Typography>
            <DynamicLottie />
          </Stack>
        )}
        {isUser ? (
          <Typography variant="body2">{message?.content[textIndex]?.text}</Typography>
        ) : (
          <MarkdownMessage message={message?.content[textIndex]?.text as string} />
        )}
        {imageIndex !== -1 && (
          <Stack py={0.5}>
            <AttachmentImage
              imageUrl={message.content[imageIndex].image_url?.url as string}
              fileName={message.content[imageIndex].file_name as string}
              readonly
            />
          </Stack>
        )}
      </Stack>
    </Stack>
  );
}
const MessagesList = memo(
  forwardRef<
    HTMLDivElement,
    {
      // errors that are not true
      // eslint-disable-next-line react/no-unused-prop-types
      messages: ChatStateMessage[];
      // eslint-disable-next-line react/no-unused-prop-types
      onSendMessage: (message: string) => void;
      // eslint-disable-next-line react/no-unused-prop-types
      messagesEndRef: RefObject<HTMLDivElement>;
    }
  >(({ messages, onSendMessage, messagesEndRef }, ref) => {
    const { state } = useContext(ChatContext);
    const [isMobile, setIsMobile] = useState(!state.isFullScreen);
    const { currentStream, waitingResponse } = state;
    const { data: chatSettings } = useFetchSettingsQuery();
    const stackContainerRef = useRef<HTMLDivElement | null>(null);

    useEffect(() => {
      if (messagesEndRef.current) {
        messagesEndRef.current.scrollIntoView({ behavior: 'instant', block: 'end' });
      }
    }, [currentStream, messages, messagesEndRef]);

    useEffect(() => {
      setIsMobile(stackContainerRef.current ? stackContainerRef.current.clientWidth <= 600 : true);
    }, []);

    return (
      <Stack px={2} pt={10} overflow="auto" flexGrow={1} ref={ref}>
        <Container maxWidth="lg" pb={2} component={Stack} flexGrow={1} disableGutters>
          {messages.map(
            (message) =>
              message.content[0]?.text && (
                <WrappedMessage
                  key={message.id}
                  message={message}
                  isMobile={isMobile}
                  assistantName={chatSettings?.name || t('sidebar.assistant')}
                />
              )
          )}
          {(currentStream || waitingResponse) && (
            <WrappedMessage
              message={{
                role: 'assistant',
                content: [{ type: 'text', text: currentStream }],
                createdAt: Date.now(),
              }}
              waitingResponse={waitingResponse}
              isMobile={isMobile}
              assistantName={chatSettings?.name || t('sidebar.assistant')}
            />
          )}
          <div ref={messagesEndRef} />
        </Container>
        <AlertError
          lastUserMessage={state.lastUserMessage}
          onSendMessage={onSendMessage}
          error={state.error}
        />
      </Stack>
    );
  })
);

export default MessagesList;
