import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import LogoSettings, { LogoSettingsProps } from './LogoSettings';

interface Props {
  siteLogo: string | null;
  siteLogoDark: string | null;
  siteFavicon: string | null;
  siteFaviconDark: string | null;
  dataCy?: string;
}

interface LogoType extends LogoSettingsProps {
  key: string;
}

export default function LogoSettingsList({
  siteLogo,
  siteLogoDark,
  siteFavicon,
  siteFaviconDark,
  dataCy,
}: Props) {
  const { t } = useTranslation();

  const logoTypes: LogoType[] = [
    {
      key: 'site',
      title: t('companySettings.siteInformation.logoInfo.headers.mainLogolabel'),
      lightLogoUrl: siteLogo,
      darkLogoUrl: siteLogoDark,
      lightLogoKey: 'siteLogo',
      darkLogoKey: 'siteLogoDark',
    },
    {
      key: 'favicon',
      title: t('companySettings.siteInformation.logoInfo.headers.compactLogolabel'),
      lightLogoUrl: siteFavicon,
      darkLogoUrl: siteFaviconDark,
      lightLogoKey: 'siteFavicon',
      darkLogoKey: 'siteFaviconDark',
    },
  ];
  return (
    <Stack direction="row" gap={2.5}>
      {logoTypes.map(({ title, key, darkLogoUrl, lightLogoUrl, lightLogoKey, darkLogoKey }) => (
        <LogoSettings
          key={key}
          title={title}
          darkLogoUrl={darkLogoUrl}
          lightLogoUrl={lightLogoUrl}
          lightLogoKey={lightLogoKey}
          darkLogoKey={darkLogoKey}
          dataCy={dataCy && `${dataCy}-logo-settings`}
        />
      ))}
    </Stack>
  );
}
