import { Stack, IconButton, SvgIcon, TextField, InputBase, Box, Container } from '@mui/material';
import { memo, useContext, useState, useRef } from 'react';
import {
  WorkbaseAttachmentIcon,
  WorkbaseCloseCircleIcon,
  WorkbaseSendIcon,
} from 'src/assets/icons/workbaseIcons';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useTranslation } from 'react-i18next';
import { MessageContent } from 'src/store/api/aiChatApi';
import { ChatContext } from '../../helpers/chatContext';
import { cancelMessage, sendChatMessage } from '../../helpers/websocket-controller';
import AttachmentImage from './AttachmentImage';

interface DialogInputsProps {
  ws: WebSocket | null;
  dispatch: React.Dispatch<any>;
  threadId: string;
}

function DialogInputs({ ws, dispatch, threadId }: DialogInputsProps) {
  const [inputText, setInputText] = useState<string>('');
  const fileInputRef = useRef<HTMLInputElement>(null);
  const { state } = useContext(ChatContext);
  const { t } = useTranslation();
  const handleSendMessage = () => {
    const messageContents: MessageContent[] = [];

    if (inputText.trim()) {
      messageContents.push({ type: 'text', text: inputText });
    }

    if (state.imageUrl) {
      messageContents.push(state.imageUrl);
    }

    if (messageContents.length > 0) {
      dispatch({
        type: 'SEND_CHAT_MESSAGE',
        payload: {
          thread_id: threadId,
          role: 'user',
          content: messageContents,
          createdAt: new Date().getTime(),
        },
      });
      dispatch({ type: 'SET_WAITING_RESPONSE', payload: true });
      dispatch({ type: 'WEBSOCKET_ERROR', payload: null });
      sendChatMessage(ws, messageContents, threadId);
      setInputText('');
      dispatch({ type: 'SET_IMAGE_URL', payload: null });
    }
  };

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInputText(event.target.value);
  };

  const handleImageSelect = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const response = await imageUpload(file);
        const { variants } = response.data.result;
        dispatch({
          type: 'SET_IMAGE_URL',
          payload: {
            type: 'image_url',
            file_name: file.name,
            image_url: {
              url: variants[0],
            },
          },
        });
      } catch (err) {
        showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
      }
    }
  };

  const handleAttachmentClick = () => {
    fileInputRef.current?.click();
  };

  const handleCancelMessage = () => {
    cancelMessage(ws);
  };

  return (
    <Container maxWidth="lg" disableGutters>
      {state.imageUrl && (
        <Box px={2} py={0.5} position="relative" display="flex">
          <AttachmentImage
            imageUrl={state.imageUrl.image_url.url}
            fileName={state.imageUrl.file_name}
            readonly={false}
            onClose={() => dispatch({ type: 'SET_IMAGE_URL', payload: null })}
          />
        </Box>
      )}
      <Stack p={1} direction="row" gap={1} alignItems="center">
        <InputBase
          type="file"
          inputProps={{
            accept: 'image/jpeg,image/png',
          }}
          sx={{ display: 'none' }}
          inputRef={fileInputRef}
          onChange={handleImageSelect}
        />
        <IconButton size="medium" onClick={handleAttachmentClick}>
          <SvgIcon component={WorkbaseAttachmentIcon} />
        </IconButton>

        <TextField
          variant="outlined"
          placeholder={t('assistant.ask')}
          value={inputText}
          autoFocus
          // TODO: FIX TEXTFIELD
          size="small"
          fullWidth
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !state.currentStream?.length && !state.waitingResponse) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
          onChange={handleInputChange}
          multiline
          maxRows={4}
        />

        {/* Send Button */}
        {state.currentStream?.length > 0 ? (
          <IconButton size="medium" onClick={handleCancelMessage}>
            <SvgIcon component={WorkbaseCloseCircleIcon} />
          </IconButton>
        ) : (
          <IconButton
            size="medium"
            onClick={handleSendMessage}
            disabled={state.currentStream?.length > 0 || state.waitingResponse || !inputText}
          >
            <SvgIcon component={WorkbaseSendIcon} />
          </IconButton>
        )}
      </Stack>
    </Container>
  );
}

export default memo(DialogInputs);
