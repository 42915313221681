import { ListItemButton, ListItemText, Stack } from '@mui/material';
import { forwardRef } from 'react';
import useIsMobile from 'src/hooks/useIsMobile';
import { SidebarItem } from '../types';

interface Props {
  data: SidebarItem;
  isActive: boolean;
  dragRef?: React.RefObject<HTMLDivElement>;
  dataCy?: string;
}

const Item = forwardRef<HTMLDivElement, Props>(({ data, isActive, dragRef, dataCy }) => {
  const { prefix, text, subtext, onClick, sufix, key } = data;

  const isMobile = useIsMobile();
  return (
    <ListItemButton
      selected={isActive}
      onClick={onClick}
      ref={dragRef}
      data-cy={dataCy && `${dataCy}-${key}`}
    >
      {prefix}
      {!isMobile && (
        <ListItemText
          primaryTypographyProps={{
            variant: 'subtitle1',
            fontWeight: 'medium',
            noWrap: true,
          }}
          secondaryTypographyProps={{
            variant: 'caption',
            noWrap: true,
          }}
          primary={text}
          secondary={subtext}
        />
      )}
      {sufix && !isMobile && (
        <Stack ml={1} alignItems="center">
          {sufix}
        </Stack>
      )}
    </ListItemButton>
  );
});

export default Item;
