import { Components, Theme } from '@mui/material';

const MuiSwitch: Components<Theme>['MuiSwitch'] = {
  styleOverrides: {
    root: {
      '& .MuiSwitch-track': {
        borderRadius: 10,
      },
      '& .MuiSwitch-switchBase:hover': {
        backgroundColor: 'transparent',
      },
    },
  },
  variants: [
    {
      props: { size: 'small' },
      style: {
        width: 28,
        height: 16,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 2,
          '&.Mui-checked': {
            transform: 'translateX(12px)',
          },
        },
        '& .MuiSwitch-thumb': {
          width: 12,
          height: 12,
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
        '& .MuiSwitch-track': {
          borderRadius: 8,
        },
      },
    },
    {
      props: { size: 'medium' },
      style: {
        width: 40,
        height: 24,
        padding: 0,
        '& .MuiSwitch-switchBase': {
          padding: 4,
          '&.Mui-checked': {
            transform: 'translateX(16px)',
          },
        },
        '& .MuiSwitch-thumb': {
          width: 16,
          height: 16,
          boxShadow: 'none',
        },
        '& .MuiSwitch-track': {
          borderRadius: 12,
        },
      },
    },
  ],
};

export default MuiSwitch;
