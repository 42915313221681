import { useTranslation } from 'react-i18next';
import SingleModule from 'src/features/editor/view/grid/widgets/trainings-item/modules/SingleModule';
import { TrainingModuleWithStatus } from 'src/store/api/trainings/trainingModulesApi';
import {
  EstimatedTime,
  LessonsCount,
  TrainingsProgress,
} from 'src/store/api/trainings/trainingsApi';

interface Props {
  modules: TrainingModuleWithStatus[];
  estimatedTimeData?: EstimatedTime[];
  lessonsCountData?: LessonsCount[];
  progressData?: TrainingsProgress[];
}

export default function ReadOnlyModules({
  modules,
  estimatedTimeData,
  lessonsCountData,
  progressData,
}: Props) {
  const { t } = useTranslation();
  return modules
    .filter(({ status }) => status === 'published')
    .map((module) => (
      <SingleModule
        key={module.id}
        readOnly
        {...module}
        name={module.name || t('trainingDetailPage.unnamedModule')}
        lessonsCount={lessonsCountData?.find((item) => item.id === module.id)?.lessonsCount}
        estimatedTime={
          estimatedTimeData?.find((item) => item.id === module.id)?.estimatedTimeToComplete
        }
        progress={progressData?.find((item) => item.id === module.id)?.progress}
      />
    ));
}
