import { Components, Theme } from '@mui/material';

const MuiListItemButton: Components<Theme>['MuiListItemButton'] = {
  defaultProps: {
    disableRipple: true,
  },
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      paddingLeft: 12,
      paddingRight: 12,
      marginBottom: '2px',
      transition: 'none',
      '&:last-child': {
        marginBottom: 0,
      },
    }),
  },
};

export default MuiListItemButton;
