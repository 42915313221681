import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { set } from 'lodash';
import { Employee, EmployeePatchRequest } from '../model';

// Helper function to transform Employee to EmployeePatchRequest
export const transformToPatchRequest = (
  otherChangedData: Partial<Employee>
): Partial<EmployeePatchRequest> => {
  const { roles, ...patchRequest } = otherChangedData;

  // Initialize patchRequest with otherChangedData except roles
  const finalPatchRequest: Partial<EmployeePatchRequest> = { ...patchRequest };

  // Transform roles separately
  if (roles) {
    const roleIds = Object.values(roles).map((role) => role.id);
    finalPatchRequest.roleIds = roleIds;
  }

  const filteredPatchRequest = Object.fromEntries(
    Object.entries(finalPatchRequest).filter(([key]) => key !== 'avatarUrl')
  );
  return filteredPatchRequest;
};

interface EmployeeState {
  employee: Employee | null;
}

const initialState: EmployeeState = {
  employee: null, // Initial state with no employee selected
};

const employeeSlice = createSlice({
  name: 'employee',
  initialState,
  reducers: {
    setEmployee: (state, action: PayloadAction<Employee>) => {
      state.employee = action.payload;
    },
    updateEmployeeDetails: (state, action: PayloadAction<{ keyPath: string; value: any }>) => {
      if (state.employee) {
        state.employee = set(state.employee, action.payload.keyPath, action.payload.value);
      }
    },
    clearEmployee: (state) => {
      state.employee = null;
    },
  },
});

export const { setEmployee, updateEmployeeDetails, clearEmployee } = employeeSlice.actions;
export default employeeSlice;
