import { Box, Button, Chip, Container, Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { NewsfeedItem, NewsfeedLessonItemId } from 'src/store/api/newsfeedApi';
import { WorkbaseArrowRightIcon, WorkbaseTrainingsIcon } from 'src/assets/icons/workbaseIcons';
import CircularProgressWithLabel from 'src/ui-components/custom/progress/CircularProgressWithLabel';
import ImageWithDefaultFallback from 'src/ui-components/image/ImageWithDefaultFallback';
import useIsMobile from 'src/hooks/useIsMobile';

export default function NewsfeedSliderItem({
  imageUrl,
  defaultImage,
  progress,
  name,
  id,
  type,
}: NewsfeedItem) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const idAsLessonId = id as NewsfeedLessonItemId;
  const typeDict = {
    training: {
      header: t('dashboardPage.header.newTrainingForYou'),
      buttonText: t('dashboardPage.header.startNewTraining'),
      buttonNavigatePath: `/trainings/${id}`,
      unnamedText: t('trainingDetailPage.unnamedTraining'),
    },
    trainingLesson: {
      header: t('dashboardPage.header.newLessonForYou'),
      buttonText: t('dashboardPage.header.startNewLesson'),
      buttonNavigatePath: `/trainings/${idAsLessonId.training}/${idAsLessonId.module}/${idAsLessonId.section}/${idAsLessonId.lesson}`,
      unnamedText: t('modulePage.unnamedLesson'),
    },
  };
  const isMobile = useIsMobile();
  const imageWidth = isMobile ? '100%' : 230;
  const imageHeight = isMobile ? 130 : 130;
  const typeData = typeDict[type];
  return (
    <Container maxWidth="md" disableGutters={isMobile}>
      <Stack gap={2.5}>
        <Stack gap={1} direction="row" justifyContent="center" alignItems="center">
          <SvgIcon component={WorkbaseTrainingsIcon} />
          <Typography variant="h5" fontWeight="medium">
            {typeData.header}
          </Typography>
        </Stack>
        <Stack direction={isMobile ? 'column' : 'row'} gap={2.5}>
          <Box>
            <ImageWithDefaultFallback
              width={imageWidth}
              height={imageHeight}
              src={imageUrl}
              alt="Training image"
              defaultImage={defaultImage}
            />
          </Box>
          <Stack justifyContent="space-between" gap={2} sx={{ flexGrow: 1, minWidth: 0 }}>
            <Box alignSelf="flex-start">
              <Chip label={t('required').toUpperCase()} />
            </Box>
            <Stack direction="row" gap={1.25} alignItems="center">
              <CircularProgressWithLabel value={progress} />
              <Typography variant="h5" noWrap fontWeight="medium" color="text.primary">
                {name || typeData.unnamedText}
              </Typography>
            </Stack>
            <Button
              fullWidth
              onClick={() => navigate(typeData.buttonNavigatePath)}
              endIcon={<SvgIcon component={WorkbaseArrowRightIcon} />}
            >
              {typeData.buttonText}
            </Button>
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
}
