import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useRolesQuery } from 'src/store/api/roles.api';
import { useCreateEmployeesMutation } from 'src/store/api/employees/employeesApi';
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import ChipSelectionMenu from 'src/ui-components/custom/selection-menu/ChipSelectionMenu';
import { Role } from '../../model';
import { EmailChipsValueProps } from './controller/use-email-chips';
import validateNewEmployeesEmails from './controller/helper';
import EmailChips from './EmailChips';

interface Props {
  onClose: () => void;
  open: boolean;
}

export default function NewEmployeeDialogContent({ onClose, open }: Props) {
  const { t } = useTranslation();
  const [rolesSelected, setRolesSelected] = useState<string[]>([]);
  const [roles, setRoles] = useState<Role[]>([]);
  const [createEmployees] = useCreateEmployeesMutation({});
  const [allEmails, setAllEmails] = useState<EmailChipsValueProps[]>([]);
  const { data: rolesData } = useRolesQuery();
  const onRolesChange = (options: string[]) => {
    setRolesSelected(options);
  };

  useEffect(() => {
    if (open && rolesData) {
      setRoles(rolesData);
    }
    if (!open) {
      setRolesSelected([]);
      setAllEmails([]);
    }
  }, [open, rolesData]);

  const allRoles = useMemo(
    () =>
      roles
        ? roles.map((role) => ({
            id: role.id,
            label: `${role.department.name} - ${role.name}`,
          }))
        : [],
    [roles]
  );
  const hasInvalidEmails = allEmails.some((obj) => !obj.isValid);
  const validatingEmails = allEmails.some((obj) => obj.isLoading);

  const disabledConfirmButton =
    allEmails.length === 0 || hasInvalidEmails || validatingEmails || rolesSelected.length === 0;

  const handleChipsCardOnChange = (values: EmailChipsValueProps[]) => {
    validateNewEmployeesEmails(values, allEmails, setAllEmails);
  };

  const handleEmployeeAdd = () => {
    createEmployees({
      emails: allEmails.map((email) => email.label),
      roles: rolesSelected,
      allRoles:
        allRoles
          .map((role) => role.id)
          .sort()
          .join(',') === rolesSelected.sort().join(','),
    }).then((resp: any) => {
      if (resp?.error) {
        showNotification(t('addEmployees.notificationFail'), 'error');
      } else {
        showNotification(
          allEmails.length > 1
            ? t('addEmployees.notificationSuccess.multiplePeopleAdded')
            : t('addEmployees.notificationSuccess.onePersonAdded')
        );
      }
    });
    onClose();
  };

  return (
    <>
      <DialogTitle>{t('addEmployees.title')}</DialogTitle>
      <DialogContent>
        <Stack gap={2} pt={0.5}>
          <EmailChips
            placeholder={t('addEmployees.emailPlaceholder')}
            values={allEmails}
            onChange={(e) => handleChipsCardOnChange(e)}
            error={hasInvalidEmails}
            autoFocus
          />
          <Stack gap={1}>
            <Typography variant="subtitle1">{t('addEmployees.editingRoleLabel')}</Typography>
            <ChipSelectionMenu
              options={allRoles}
              searchable
              selected={rolesSelected}
              onChange={onRolesChange}
              placeholder={t('addEmployees.roleSelectorLabel')}
              emptyErrorText={t('employeePage.employeeDetails.contactInfomation.emptyRoles')}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button fullWidth color="inherit" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          fullWidth
          color="primary"
          disabled={disabledConfirmButton}
          onClick={handleEmployeeAdd}
        >
          {t('addEmployees.confirmButton')}
        </Button>
      </DialogActions>
    </>
  );
}
