import { Box, Container, Paper } from '@mui/material';

interface Props {
  children: React.ReactNode;
}
function CardContent({ children }: Props) {
  return (
    <Paper
      component={Box}
      mt={-2}
      sx={{
        borderTopLeftRadius: 0,
        borderTopRightRadius: 0,
      }}
      pt={4.5}
      pb={2.5}
      variant="outlined"
    >
      <Container maxWidth="lg">{children}</Container>
    </Paper>
  );
}
export default CardContent;
