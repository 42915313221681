import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { EditorWithModeSwitch } from 'src/features/editor';
import { useGetTrainingsQuery } from 'src/store/api/trainings/trainingsApi';
import { useSettingsQuery } from 'src/store/api/settingsApi';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import BrandedHeader from 'src/ui-components/branded-header';
import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import IconText from 'src/ui-components/icon-text/IconText';
import { useCallback } from 'react';

export default function TrainingsView() {
  const { data: userData } = useCurrentUserQuery();
  const { t } = useTranslation();
  const hasAnyPermission = useHasAnyPermission();
  const hasEditingPermissions = userData?.isOwner || hasAnyPermission(['ADMIN_ACCESS_TRAININGS']);
  const { data: settingsData } = useSettingsQuery();
  useGetTrainingsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const renderHeader = useCallback(
    () => (
      <BrandedHeader>
        <Box margin="auto">
          <IconText text={t('sidebar.tabTitleTrainings')} icon="WorkbaseTrainingsIcon" />
        </Box>
      </BrandedHeader>
    ),

    [t]
  );
  if (!settingsData) return null;
  return (
    <EditorWithModeSwitch
      canEdit={!!hasEditingPermissions}
      canView
      renderHeader={renderHeader}
      documentName={`trainingPage.${settingsData?.id}`}
    />
  );
}
