import { Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { EmployeeProgress } from 'src/store/api/employees/employeesApi';
import TopBarLayout from 'src/layouts/TopBarLayout';
import Header from './header/Header';
import { Employee } from '../../model';
import EmployeeDetailsTopBar from './EmployeeDetailsTopBar';
import EmployeeInfo from './employee-info/EmployeeInfo';
import { clearEmployee, setEmployee, updateEmployeeDetails } from '../../controller/employee.slice';
import TrainingProgress from './training-progress/TrainingProgress';

type Props = {
  employee?: Employee;
  readOnly: boolean;
  employeesRefetch: () => void;
  hasManageEmployeePermission: boolean;
  hasViewProgressPermission: boolean;
  progressData?: EmployeeProgress[];
};

export default function EmployeeDetails({
  employee,
  readOnly,
  employeesRefetch,
  hasManageEmployeePermission,
  hasViewProgressPermission,
  progressData,
}: Props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const employeeProgress = progressData?.find((prog) => employee && prog.id === employee.id);
  const progressValue = employeeProgress ? Number(employeeProgress.progress) : 0;
  const handleUpdateEmployee = (updatedEmployeeDetails: Partial<Employee>) => {
    Object.entries(updatedEmployeeDetails).forEach(([key, value]) => {
      dispatch(updateEmployeeDetails({ keyPath: key, value }));
    });
  };

  const tabsData = [
    {
      key: 'information',
      text: t('employeePage.employeeDetails.tabs.informationLabel'),
      disabled: false,
      content: employee && <EmployeeInfo readOnly={readOnly} employee={employee} />,
    },
    {
      key: 'progress',
      text: t('employeePage.employeeDetails.tabs.progressLabel'),
      disabled: !hasViewProgressPermission,
      content: employee && <TrainingProgress employee={employee} />,
    },
  ];

  const [activeTab, setActiveTab] = useState(tabsData[0].key);

  useEffect(() => {
    if (employee) {
      // Fetch employee data from the server based on the employee ID
      dispatch(setEmployee(employee));
    }

    // Clean up when component unmounts
    return () => {
      dispatch(clearEmployee());
    };
  }, [dispatch, employee]);

  if (!employee) return null;

  return (
    <TopBarLayout
      topBar={<EmployeeDetailsTopBar employee={employee} employeesRefetch={employeesRefetch} />}
    >
      <TabContext value={activeTab}>
        <Header
          tabsData={tabsData}
          readOnly={readOnly}
          onUpdateEmployee={handleUpdateEmployee}
          showProgress={hasManageEmployeePermission || hasViewProgressPermission}
          progressValue={progressValue}
          onActiveTabChange={(val) => setActiveTab(val)}
        />
        <Container component={Stack} maxWidth="lg">
          {tabsData.map(({ key, content }) => (
            <TabPanel key={key} value={key}>
              {content}
            </TabPanel>
          ))}
        </Container>
      </TabContext>
    </TopBarLayout>
  );
}
