import { Box, Stack, Typography } from '@mui/material';
import { ChangeEvent, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import { imageUpload } from 'src/lib/utils/imageUpload';
import {
  TrainingModule,
  usePatchTrainingModuleMutation,
  useTrainingModulesProgressQuery,
} from 'src/store/api/trainings/trainingModulesApi';
import BrandedDefaultImage from 'src/ui-components/branded-default-image';
import BrandedHeader from 'src/ui-components/branded-header';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import CircularProgressWithLabel from 'src/ui-components/custom/progress/CircularProgressWithLabel';
import ContrastTextField from 'src/ui-components/custom/textfield/contrast';
import ImageWithDefaultFallback from 'src/ui-components/image/ImageWithDefaultFallback';

interface Props {
  trainingModule: TrainingModule;
  mobileView: boolean;
  readOnly: boolean;
  dataCy?: string;
}

export default function ModuleHeader({ trainingModule, mobileView, readOnly, dataCy }: Props) {
  const { t } = useTranslation();
  const [patchModule] = usePatchTrainingModuleMutation();
  const { data: progressData } = useTrainingModulesProgressQuery(trainingModule.id);
  const handleChangeModuleName = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      patchModule({
        module: {
          name: e.target.value,
          id: trainingModule.id,
          trainingId: trainingModule.trainingId,
        },
      });
    },
    [trainingModule.id, trainingModule.trainingId, patchModule]
  );

  const { value, handleChange } = useDebouncedValue(trainingModule.name, handleChangeModuleName);
  const onImgChange = async (e: ChangeEvent<HTMLInputElement>): Promise<void> => {
    const file = e.target.files?.[0];

    if (!file) return;
    try {
      const response = await imageUpload(file);
      await patchModule({
        module: {
          trainingId: trainingModule.trainingId,
          id: trainingModule.id,
          imageId: response.data.result.id,
        },
        imageSrc: response.data.result.variants[0],
      });
    } catch (err) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
    }
  };

  const removeImage = (): Promise<unknown> =>
    patchModule({
      module: { trainingId: trainingModule.trainingId, id: trainingModule.id, imageId: null },
      imageSrc: '',
    });

  const imageSize = {
    width: mobileView ? '100%' : 290,
    height: 160,
  };

  const image = (
    <>
      {!readOnly && (
        <Box {...imageSize}>
          <EditableImageUpload
            src={trainingModule.imageUrl}
            dataCy={dataCy}
            onClear={removeImage}
            onChange={onImgChange}
            placeholder={<BrandedDefaultImage defaultImage={trainingModule.defaultImage as any} />}
          />
        </Box>
      )}
      {readOnly && (
        <Box>
          <ImageWithDefaultFallback
            height={imageSize.height}
            width={mobileView ? '100%' : imageSize.width}
            src={trainingModule.imageUrl || ''}
            alt="Training module image"
            defaultImage={trainingModule.defaultImage as any}
          />
        </Box>
      )}
    </>
  );

  const progress = <CircularProgressWithLabel value={progressData?.progress || 0} />;

  const name = (
    <Stack minWidth={0} flexGrow={1}>
      <Stack direction="row" alignItems="center" width="100%">
        {readOnly && (
          <Typography
            variant={mobileView ? 'h6' : 'h4'}
            fontWeight="medium"
            sx={{
              display: '-webkit-box',
              WebkitLineClamp: 3,
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
            }}
          >
            {mobileView && `${t('modulePage.moduleTitle')} ${trainingModule.order}:`}{' '}
            {trainingModule.name || t('trainingDetailPage.unnamedModule')}
          </Typography>
        )}
        {!readOnly && (
          <ContrastTextField
            value={value}
            data-cy={dataCy && `${dataCy}-input`}
            onChange={handleChange}
            label={`${t('modulePage.moduleTitle')}:`}
            fullWidth
            placeholder={t('editor.trainingsSection.moduleTitlePlaceholder')}
          />
        )}
      </Stack>
    </Stack>
  );

  return (
    <BrandedHeader blur disableGutters={mobileView}>
      <Box my="auto" width="100%" px={mobileView ? 2 : 0}>
        <Stack
          direction={mobileView ? 'column' : 'row'}
          justifyContent="space-between"
          gap={4}
          alignItems={mobileView ? 'stretch' : 'center'}
          color="primary.contrastText"
        >
          {mobileView && image}
          <Stack
            minWidth={0}
            flexShrink={1}
            flexGrow={1}
            direction="row"
            alignItems="center"
            gap={1}
          >
            {!mobileView && progress}
            {name}
            {mobileView && progress}
          </Stack>
          {!mobileView && image}
        </Stack>
      </Box>
    </BrandedHeader>
  );
}
