import { t } from 'i18next';
import { useCallback } from 'react';
import { usePageQuery, usePatchPageMutation } from 'src/store/api/pagesApi';
import IconTextBrendedHeader from 'src/ui-components/branded-header/IconTextBrendedHeader';

interface Props {
  readOnly: boolean;
  pageId: string;
}

export default function CustomPageHeader({ pageId, readOnly }: Props) {
  const { data } = usePageQuery(pageId);
  const [patchPage] = usePatchPageMutation();

  const handlePageNameChange = useCallback(
    (name: string) => {
      patchPage({ name, id: pageId });
    },
    [patchPage, pageId]
  );

  return (
    <IconTextBrendedHeader
      placeholder={t('customSites.unnamedPage')}
      readOnly={readOnly}
      name={data?.name}
      icon={data?.icon || 'WorkbaseEmptyContentPageIcon'}
      resourceId={pageId}
      resourceType="page"
      onIconChange={(icon: string) => patchPage({ id: pageId, icon })}
      onNameChange={handlePageNameChange}
    />
  );
}
