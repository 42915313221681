import { Category } from '@emoji-mart/data';
import { TabList } from '@mui/lab';
import { SvgIcon, Tab } from '@mui/material';
import { WorkbaseWIcon } from 'src/assets/icons/workbaseIcons';
import SvgIconByName, { IconName } from 'src/ui-components/icon/SvgIconByName';
import { TabVals } from './types';

interface Props {
  setTabVal: (val: TabVals) => void;
  clearSearchVal: () => void;
  emojiCategories: Category[];
}
const emojiCategoryIconDict: Record<Category['id'], IconName> = {
  people: 'WorkbaseLaughingIcon',
  nature: 'WorkbaseDogIcon',
  foods: 'WorkbaseFoodIcon',
  activity: 'WorkbaseBallIcon',
  places: 'WorkbaseAirplaneIcon',
  objects: 'WorkbaseBulbIcon',
  symbols: 'WorkbaseSymbolsIcon',
  flags: 'WorkbaseFlagIcon',
};

export default function IconSelectorTabs({ setTabVal, clearSearchVal, emojiCategories }: Props) {
  return (
    <TabList
      onChange={(_, newVal) => {
        setTabVal(newVal);
        clearSearchVal();
      }}
      variant="fullWidth"
    >
      <Tab label={<SvgIcon component={WorkbaseWIcon} fontSize="small" />} value="workbaseIcons" />
      {emojiCategories.map((category) => (
        <Tab
          key={category.id}
          label={
            <SvgIconByName
              iconName={emojiCategoryIconDict[category.id] as IconName}
              fontSize="small"
            />
          }
          value={category.id}
        />
      ))}
      <Tab
        value="searchIcons"
        // hide "search" tab from view & layout
        sx={{ display: 'none' }}
      />
    </TabList>
  );
}
