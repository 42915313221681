import { Box, ClickAwayListener, MenuList, Paper, Popper } from '@mui/material';
import debounce from 'lodash.debounce';
import { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseHelpIcon } from 'src/assets/icons/workbaseIcons';
import {
  usePostAssignUserToRoleMutation,
  useSearchEmployeeResultsQuery,
} from 'src/store/api/employees/employeesApi';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import Empty from 'src/ui-components/custom/empty/Empty';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import { SearchTextField } from 'src/ui-components/custom/textfield/search';
import SearchEmployeeResulItem from './SearchEmployeeResultItem';

interface Props {
  roleId: string;
  dataCy?: string;
}

function ItemNotFound() {
  const { t } = useTranslation();
  return <Empty icon={WorkbaseHelpIcon} description={t('notFound')} />;
}

function Spinner() {
  return (
    <Box
      sx={{
        height: 280,
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <LottieLogoSpinner />
    </Box>
  );
}

function SearchEmployeesRolesTextField({ roleId, dataCy }: Props) {
  const { t } = useTranslation();
  const [isOpen, setOpen] = useState<null | HTMLElement>(null);
  const textFieldRef = useRef<null | HTMLInputElement>(null);
  const [inputVal, setInputVal] = useState('');
  const [queryToSend, setQueryToSend] = useState(inputVal);
  const [assignUserToRole] = usePostAssignUserToRoleMutation();

  const queryParameters = useMemo(
    () => ({
      roleId,
      query: queryToSend,
    }),
    [roleId, queryToSend]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedHandleQueryToSendSet = useCallback(
    debounce((val: string) => {
      setQueryToSend(val);
    }, 800),
    []
  );

  useEffect(() => {
    debouncedHandleQueryToSendSet(inputVal);
  }, [inputVal, debouncedHandleQueryToSendSet]);

  const handleOpen = () => {
    textFieldRef.current?.querySelector('input')?.focus();
    setOpen(textFieldRef.current);
  };

  useEffect(() => {
    textFieldRef.current?.querySelector('input')?.focus();
  }, []);

  const handleClose = () => setOpen(null);

  const { data: employees, isFetching } = useSearchEmployeeResultsQuery(queryParameters, {
    skip: !queryParameters.query,
  });

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Box ref={textFieldRef}>
        <SearchTextField
          fullWidth
          data-cy={dataCy}
          autoComplete="off"
          value={inputVal}
          onChange={(e) => setInputVal(e.target.value)}
          onFocus={handleOpen}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              handleClose();
            }
          }}
          placeholder={t('add')}
        />
        <Popper
          open={Boolean(isOpen && queryToSend)}
          anchorEl={textFieldRef.current}
          onKeyDown={(e) => {
            if (e.key === 'Escape') {
              handleClose();
            }
          }}
          placement="bottom-start"
          sx={{ width: textFieldRef.current?.clientWidth || 'auto', zIndex: 1301 }}
        >
          <Paper sx={{ maxHeight: 300, overflow: 'scroll', px: 1 }}>
            <MenuList>
              {employees?.length === 0 && !isFetching && <ItemNotFound />}
              {isFetching && <Spinner />}
              {!isFetching &&
                employees && [
                  employees?.map(({ id, firstName, lastName, avatarUrl, email }) => (
                    <SearchEmployeeResulItem
                      key={id}
                      title={`${firstName} ${lastName}`}
                      description={email}
                      avatarUrl={avatarUrl}
                      onClick={() => {
                        assignUserToRole({ roleId, userId: id }).then((resp: any) => {
                          if (resp.error) {
                            showNotification(
                              t(
                                'companySettings.sidebar.departments.removeLinkModal.actionFailedPermissions'
                              ),
                              'error'
                            );
                          }
                        });
                        handleClose();
                        setInputVal('');
                      }}
                    />
                  )),
                ]}
            </MenuList>
          </Paper>
        </Popper>
      </Box>
    </ClickAwayListener>
  );
}

export default SearchEmployeesRolesTextField;
