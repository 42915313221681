import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Navigate } from 'react-router-dom';
import { useAppSelector } from 'src/store';
import { AUTH_URL } from '../../global-config';
import { WorkbaseTwoEmployeesIcon } from '../../assets/icons/workbaseIcons';

function UnauthorizedView() {
  const { t } = useTranslation();
  const refreshToken = useAppSelector((state) => state.auth.refreshToken);
  if (refreshToken) {
    return <Navigate to="/" />;
  }
  const redirectionUrl = new URL(`login?redirect=${window.location.hostname}`, `${AUTH_URL}`).href;
  return (
    <Card variant="outlined">
      <CardHeader
        title={t('unauthorizedPage.title')}
        titleTypographyProps={{ variant: 'h6', fontWeight: 'bold' }}
      />
      <CardContent>
        <Stack alignItems="center">
          <SvgIcon fontSize="large">
            <WorkbaseTwoEmployeesIcon />
          </SvgIcon>
          <Typography color="text.primary" align="center" variant="body1" fontWeight="medium">
            {t('unauthorizedPage.title')}
          </Typography>
        </Stack>
      </CardContent>
      <CardActions>
        <Button color="primary" size="medium" fullWidth href={redirectionUrl}>
          {t('unauthorizedPage.messageButton')}
        </Button>
      </CardActions>
    </Card>
  );
}

export default UnauthorizedView;
