import { Box, SxProps } from '@mui/material';

export default function DndIndicator({
  top,
  bottom,
  sx,
}: {
  top?: number;
  bottom?: number;
  sx?: SxProps;
}) {
  return (
    // using sx prop here since a lot of places in the app have different indicator styles
    // TODO: refactor dnd indicators for all draggable places
    <Box
      position="absolute"
      width="100%"
      top={top}
      bottom={bottom}
      zIndex={10}
      sx={sx}
      data-cy="dnd-indicator"
    >
      <Box position="relative">
        <Box
          width={6}
          height={6}
          borderRadius="50%"
          top={-2}
          bgcolor="primary.main"
          position="absolute"
        />
        <Box width="100%" height="2px" bgcolor="primary.main" />
      </Box>
    </Box>
  );
}
