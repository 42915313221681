import { usePatchWikiCardMutation } from 'src/store/api/wikiApi';
import { useCallback } from 'react';
import { t } from 'i18next';
import IconTextBrendedHeader from 'src/ui-components/branded-header/IconTextBrendedHeader';

interface Props {
  id: string;
  readOnly: boolean;
  name: string;
  icon: string;
}

export default function WikiCardHeader({ id, readOnly, name, icon }: Props) {
  const [patchWikiCard] = usePatchWikiCardMutation();
  const handleNameChange = useCallback(
    (newName: string) => {
      patchWikiCard({ name: newName, id });
    },
    [patchWikiCard, id]
  );
  const handleIconChange = useCallback(
    (newIcon: string) => {
      patchWikiCard({ icon: newIcon, id });
    },
    [patchWikiCard, id]
  );

  return (
    <IconTextBrendedHeader
      placeholder={t('wikiPage.unnamedWikiCard')}
      readOnly={readOnly}
      name={name}
      icon={icon}
      onIconChange={handleIconChange}
      onNameChange={handleNameChange}
      resourceId={id}
      resourceType="wikiCard"
    />
  );
}
