import { ElementType, forwardRef } from 'react';
import { MaterialDesignContent, SnackbarKey, SnackbarProvider, closeSnackbar } from 'notistack';
import { Stack, IconButton, styled, SvgIcon } from '@mui/material';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseCloseIcon,
  WorkbaseErrorFilledIcon,
  WorkbaseInfoFilledIcon,
  WorkbaseWarningFilledIcon,
} from 'src/assets/icons/workbaseIcons';

const StyledMaterialDesignContent = styled(
  forwardRef<HTMLDivElement, any>((props: any, ref) => (
    <MaterialDesignContent {...props} ref={ref} data-cy="notification-content" />
  ))
)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  '&.notistack-MuiContent-success': {
    backgroundColor: theme.palette.success.main,
    color: theme.palette.success.contrastText,
  },
  '&.notistack-MuiContent-error': {
    backgroundColor: theme.palette.error.main,
    color: theme.palette.error.contrastText,
  },
  '&.notistack-MuiContent-warning': {
    backgroundColor: theme.palette.warning.main,
    color: theme.palette.warning.contrastText,
  },
  '&.notistack-MuiContent-info': {
    backgroundColor: theme.palette.info.main,
    color: theme.palette.info.contrastText,
  },
  '&.notistack-MuiContent-default': {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
  },
}));

function CloseButton(key: SnackbarKey) {
  return (
    <IconButton
      onClick={() => {
        closeSnackbar(key);
      }}
      color="inherit"
      size="small"
      data-cy="notification-close-button"
    >
      <SvgIcon component={WorkbaseCloseIcon} fontSize="inherit" />
    </IconButton>
  );
}

function NotificationIcon({ icon }: { icon: ElementType }) {
  return (
    <Stack mr={1} alignItems="center" p={0}>
      <SvgIcon component={icon} fontSize="medium" htmlColor="inherit" />
    </Stack>
  );
}

function NotificationsSnackbarProvider() {
  return (
    <SnackbarProvider
      disableWindowBlurListener
      maxSnack={4}
      iconVariant={{
        success: <NotificationIcon icon={WorkbaseCheckmarkBigFilledIcon} />,
        error: <NotificationIcon icon={WorkbaseErrorFilledIcon} />,
        warning: <NotificationIcon icon={WorkbaseWarningFilledIcon} />,
        info: <NotificationIcon icon={WorkbaseInfoFilledIcon} />,
      }}
      autoHideDuration={4000}
      variant="success"
      Components={{
        default: StyledMaterialDesignContent,
        success: StyledMaterialDesignContent,
        error: StyledMaterialDesignContent,
        info: StyledMaterialDesignContent,
        warning: StyledMaterialDesignContent,
      }}
      anchorOrigin={{
        horizontal: 'right',
        vertical: 'top',
      }}
      action={CloseButton}
    />
  );
}

export default NotificationsSnackbarProvider;
