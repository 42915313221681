import { Box, Button, Card, Divider, Paper, Popover, Stack, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { HexColorPicker } from 'react-colorful';
import { useEffect, useState } from 'react';
import { bindPopover, bindTrigger, usePopupState } from 'material-ui-popup-state/hooks';
import GeneratedColors from './GeneratedColors';
import CardContentTemplate from './CardContentTemplate';

export default function ColorConfiguration({
  patchSettings,
  baseColor,
}: {
  patchSettings: any;
  baseColor: string;
}) {
  const { t } = useTranslation();
  const [previewColor, setPreviewColor] = useState(baseColor);
  const isHex = /^#([0-9a-f]{6}){1,2}$/i;

  const handlePatchBrandingBaseColor = (brandingColor: string) => {
    patchSettings({
      primaryColor: brandingColor,
    });
  };

  useEffect(() => {
    setPreviewColor(baseColor);
  }, [baseColor]);

  const popupState = usePopupState({ variant: 'popover', popupId: 'color-popover' });
  const popoverProps = bindPopover(popupState);
  return (
    <Card variant="outlined">
      <CardContentTemplate
        left={
          <Paper
            component={Box}
            height="100%"
            variant="outlined"
            sx={{ bgcolor: isHex.test(previewColor) ? `${previewColor}` : `${baseColor}` }}
          />
        }
        right={
          <Stack direction="column" gap={1} flexShrink={0}>
            <TextField
              size="small"
              data-cy="branding-hex-color"
              value={previewColor}
              label={t('companySettings.siteInformation.brandingColors.colorsTitle')}
              onChange={(e) => {
                setPreviewColor(e.target.value);
              }}
              onBlur={() => {
                handlePatchBrandingBaseColor(previewColor);
              }}
              error={!isHex.test(previewColor)}
              helperText={
                !isHex.test(previewColor) &&
                t('companySettings.siteInformation.brandingColors.hexError')
              }
            />
            <Button data-cy="pick-color-button" color="inherit" {...bindTrigger(popupState)}>
              {t('companySettings.siteInformation.brandingColors.colorsPickerTitle')}
            </Button>
            <Popover
              {...popoverProps}
              onClose={() => {
                popoverProps.onClose();
                handlePatchBrandingBaseColor(previewColor);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
            >
              <HexColorPicker
                color={previewColor}
                onChange={setPreviewColor}
                style={{ position: 'relative', cursor: 'pointer' }}
              />
            </Popover>
          </Stack>
        }
      />
      <Divider />
      <GeneratedColors color={isHex.test(previewColor) ? previewColor : baseColor} />
    </Card>
  );
}
