import React, { useEffect, useRef, useState } from 'react';
import { Button, Paper, Stack, SvgIcon } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import { WorkbaseArrowDownIcon } from 'src/assets/icons/workbaseIcons';
import MessagesList from './components/MessageList';
import DialogInputs from './components/DialogInputs';
import { ChatStateMessage } from '../helpers/types';
import BasicActionsList from './components/BasicActionsList';
import ChatTopBar from './components/ChatTopBar';
import { sendChatMessage } from '../helpers/websocket-controller';

interface ChatDialogProps {
  isFullScreen: boolean;
  ws: WebSocket | null;
  threadId: string | null;
  open: boolean;
  messages: ChatStateMessage[];
  locationBeforeFullscreen: string | null;
  dispatch: React.Dispatch<any>;
  isLoading: boolean;
}

function ChatDialog({
  isFullScreen = false,
  ws,
  threadId,
  open,
  messages,
  locationBeforeFullscreen,
  isLoading,
  dispatch,
}: ChatDialogProps) {
  const navigate = useNavigate();
  const isMobile = useIsMobile();
  const [showScrollButton, setShowScrollButton] = useState(false);
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const scrollRef = useRef<HTMLDivElement | null>(null);
  const handleSendMessage = (message: string, retry: boolean = false) => {
    if (!retry) {
      dispatch({
        type: 'SEND_CHAT_MESSAGE',
        payload: {
          thread_id: threadId,
          role: 'user',
          content: [{ type: 'text', text: message }],
          createdAt: new Date().getTime(),
        },
      });
    }
    dispatch({ type: 'WEBSOCKET_ERROR', payload: null });
    dispatch({ type: 'SET_WAITING_RESPONSE', payload: true });
    sendChatMessage(ws, [{ type: 'text', text: message }], threadId as string, retry);
  };

  useEffect(
    () => () => {
      if (isFullScreen && !window.location.pathname.startsWith('/ai')) {
        dispatch({ type: 'CLOSE_FULLSCREEN' });
      }
    },
    [isFullScreen, dispatch]
  );

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        const { scrollTop, scrollHeight, clientHeight } = scrollRef.current;
        setShowScrollButton(scrollHeight - scrollTop - clientHeight > 100);
      }
    };

    const container = scrollRef.current;
    if (container) {
      container.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (container) {
        container.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const scrollToBottom = () => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' });
    }
  };

  if (!open && !threadId) return null;

  const content = messages.length ? (
    <MessagesList
      messages={messages}
      onSendMessage={handleSendMessage}
      ref={scrollRef}
      messagesEndRef={messagesEndRef}
    />
  ) : (
    <BasicActionsList onSendMessage={handleSendMessage} />
  );

  return (
    <Paper
      variant="outlined"
      sx={{
        width: '100%',
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        overflow: 'hidden',
        height: '100%',
        border: isFullScreen || isMobile ? 'none' : undefined,
      }}
    >
      <ChatTopBar
        dispatch={dispatch}
        navigate={navigate}
        isFullScreen={isFullScreen}
        threadId={threadId as string}
        locationBeforeFullscreen={locationBeforeFullscreen}
      />
      {isLoading ? (
        <Stack alignItems="center" justifyContent="center" flexGrow={1}>
          <LottieLogoSpinner />
        </Stack>
      ) : (
        content
      )}
      <Stack justifyContent="center" alignItems="center" position="relative">
        {showScrollButton && (
          <Stack position="relative">
            <Button
              variant="contained"
              color="inherit"
              onClick={scrollToBottom}
              sx={{
                position: 'absolute',
                right: '50%',
                transform: 'translateY(-150%) translateX(50%)',
              }}
            >
              <SvgIcon component={WorkbaseArrowDownIcon} fontSize="small" htmlColor="inherit" />
            </Button>
          </Stack>
        )}
        <DialogInputs ws={ws} dispatch={dispatch} threadId={threadId as string} />
      </Stack>
    </Paper>
  );
}

export default React.memo(ChatDialog);
