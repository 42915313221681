import { useDropzone } from 'react-dropzone';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { videoUpload } from 'src/lib/utils/videoUpload';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useAppDispatch } from 'src/store';
import { setRecordingModalPayload } from 'src/features/editor/controller/Editor.slice';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { VideoProgress } from 'src/store/api/trainings/videoProgressApi';
import { Box } from '@mui/material';
import HighlightWrapper from '../highlight-wrapper';
import { allowedFileTypes, MAX_FILESIZE } from '../upload-item/config';
import useUploadingMedia from '../hooks/useUploadingMedia';
import WithUploadProgressWrapper from '../upload-progress/WithUploadProgressWrapper';
import VidstackPlayer from './vidstack-player';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';
import BorderWrapper from '../border-wrapper';

export interface VideoItemProps {
  id: string;
  title: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onReplaceVideo: (videoId: string, poster: string) => void;
  onUpdateVideoPoster: (poster?: string) => void;
  onUpdateVideoTitle: (title: string) => void;
  onVideoDownload: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  url: string | null;
  videoPoster?: string;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
  height?: number;
  disabledFastForward?: boolean;
  onDisableFastForward?: (isFastForwardDisabled: boolean) => void;
  isVideoRequired?: boolean;
  onRequired?: (isVideoRequired: boolean) => void;
  watchedDuration?: number;
  onVideoProgress?: (data: Partial<VideoProgress>) => void;
  documentName?: string;
  isVideoUploaded?: boolean;
  onUndoCrop: () => void;
}

export default function VideoItem({
  id,
  onDelete,
  onDuplicate,
  onResizeStop,
  onVideoDownload,
  dragging,
  onResizeStart,
  onReplaceVideo,
  onUpdateVideoPoster,
  onDisableFastForward,
  disabledFastForward = false,
  onRequired,
  isVideoRequired = false,
  documentName,
  isVideoUploaded,
  url,
  videoPoster,
  readOnly,
  height,
  onUndoCrop,
  watchedDuration,
  onVideoProgress,
}: VideoItemProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState<File | null>(null);

  const dispatch = useAppDispatch();
  const [percentage, setPercentage] = useState(0);

  const { handleClearUploading, handleAddingUploading } = useUploadingMedia(id, 'video');

  const resetStates = () => {
    setLoading(false);
    setFile(null);
    setPercentage(0);
    handleClearUploading();
  };

  const handleDisableForward = () => onDisableFastForward?.(!disabledFastForward);
  const handleRequired = () => onRequired?.(!isVideoRequired);

  function getCurrentFrame(videoElement: HTMLVideoElement): Promise<Blob | null> {
    return new Promise((resolve) => {
      const canvas = document.createElement('canvas');
      canvas.width = videoElement.videoWidth;
      canvas.height = videoElement.videoHeight;
      const ctx = canvas.getContext('2d');
      ctx?.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
      canvas.toBlob((blob) => {
        resolve(blob);
      }, 'image/jpeg');
    });
  }

  const handleThumbnailUpload = useCallback(
    async (thumbnail: Blob) => {
      const reader = new FileReader();
      reader.readAsDataURL(thumbnail);
      (
        document.getElementById(id)?.querySelector('.editor-item-button') as HTMLButtonElement
      )?.focus();
      const response = await imageUpload(new File([thumbnail], 'thumbnail.jpg'));

      if (response.status !== 201) {
        showNotification(t('validationErrorMessages.generalUploadErrorMessage'), 'error');
      }

      const { result } = response.data;
      const { variants } = result;
      const [imageUrl] = variants;
      onUpdateVideoPoster(imageUrl);
    },
    [onUpdateVideoPoster, t, id]
  );

  const handleVideoUpload = async (acceptedFiles: File[]) => {
    setLoading(true);
    const newFile = acceptedFiles[0];
    setFile(Object.assign(newFile));
    if (newFile.size > MAX_FILESIZE) {
      showNotification(t('validationErrorMessages.UploadFileSizeErrorMessage'), 'error');
      resetStates();
      return;
    }
    const onVideoUpload = (vId: string) => {
      onReplaceVideo(vId, '');
      resetStates();
    };
    try {
      handleAddingUploading();
      await videoUpload({
        file: newFile,
        errorMessage: t('validationErrorMessages.UploadFailed'),
        onVideoUploadSuccess: onVideoUpload,
        setPercentage,
        documentName,
      });
    } catch {
      showNotification(t('validationErrorMessages.UploadFailed'), 'error');
      resetStates();
    }
  };

  const handlePosterUploadFromCurrentFrame = async () => {
    const video = document.getElementById(id)?.querySelector('video');
    if (video) {
      const currentFrame = await getCurrentFrame(video);
      if (currentFrame !== null) handleThumbnailUpload(currentFrame);
    }
  };

  const { open: openForPoster } = useDropzone({
    onDrop: (acceptedFiles: File[]) => handleThumbnailUpload(acceptedFiles[0]),
    noDrag: true,
    accept: {
      ...allowedFileTypes.image,
    },
  });

  const { getInputProps, open: openForVideo } = useDropzone({
    onDrop: handleVideoUpload,
    noDrag: true,
    accept: {
      ...allowedFileTypes.video,
    },
  });

  return (
    <HighlightWrapper
      id={id}
      editBarProps={{
        videoActionsProps: {
          onUploadPoster: openForPoster,
          onUploadVideo: () => openForVideo(),
          onUploadPosterFromCurrentFrame: handlePosterUploadFromCurrentFrame,
          onVideoDownload,
          onDisableFastForward: handleDisableForward,
          onRequired: handleRequired,
          isVideoRequired,
          disableFastForward: disabledFastForward,
          showRequired: documentName?.split('.')[0] === 'trainingLesson',
          onOpenRecordingModal: () => {
            dispatch(
              setRecordingModalPayload({
                id,
                onUploadProgress: setPercentage,
              })
            );
          },
          downloadDisabled: loading || !isVideoUploaded,
        },
        baseActionsProps: {
          onDelete,
          onDuplicate,
          hideDuplicate: documentName?.split('.')[0] === 'trainingLesson',
        },
        undoCropActionProps: { onClick: onUndoCrop },
      }}
      dragging={dragging}
      horizontalResize={{}}
      verticalResize={{}}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      readOnly={readOnly}
      height={height}
      minHeight={160}
    >
      {({ EditBarComponent, focused }) => (
        <>
          {EditBarComponent}
          <BorderWrapper readOnly={false}>
            <WithUploadProgressWrapper
              loading={loading}
              uploadProgress={percentage}
              fileSize={file?.size}
              onStopUploading={resetStates}
              mediaType="video"
            >
              <Box height="100%" mb="-7px">
                <VidstackPlayer
                  src={url}
                  poster={videoPoster || ''}
                  watchedDuration={watchedDuration}
                  disabledFastForward={disabledFastForward}
                  onVideoProgress={onVideoProgress}
                  canPlay={readOnly || focused}
                />
              </Box>
            </WithUploadProgressWrapper>
            <input {...getInputProps()} />
          </BorderWrapper>
        </>
      )}
    </HighlightWrapper>
  );
}
