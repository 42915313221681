import { alpha, Box, Button, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { WorkbaseCheckmarkBigFilledIcon } from 'src/assets/icons/workbaseIcons';
import useIsMobile from 'src/hooks/useIsMobile';
import { useMarkTrainingLessonAsDoneMutation } from 'src/store/api/trainings/trainingLessonsApi';

interface Props {
  lessonId: string;
  nextPublishedTrainingLessonId: string | null;
  disabledFinishButton: boolean;
  contentSwiftedLeft: boolean;
}

export default function LessonFooter({
  lessonId,
  nextPublishedTrainingLessonId,
  disabledFinishButton,
  contentSwiftedLeft,
}: Props) {
  const { t, i18n } = useTranslation();
  const params = useParams<{
    lessonId: string;
    trainingId: string;
    sectionId: string;
    moduleId: string;
  }>();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const [markLessonAsDone] = useMarkTrainingLessonAsDoneMutation();
  const isMobile = useIsMobile();
  const swiftLeftValue = i18n.language === 'en' ? 14 : 20;
  const prVal = contentSwiftedLeft ? swiftLeftValue : 0;
  return (
    <Box
      position="absolute"
      bottom={0}
      right={0}
      left={0}
      sx={{
        borderColor: 'divider',
        borderTopStyle: 'solid',
        backdropFilter: 'blur(5px) saturate(100%)',
        borderWidth: '1px',
      }}
      py={2.85}
      px={4}
      zIndex={10}
      bgcolor={({ palette }) => alpha(palette.background.paper, 0.9)}
      display="flex"
      justifyContent="flex-end"
    >
      <Box pr={isMobile ? 0 : prVal}>
        <Button
          disabled={disabledFinishButton}
          size="large"
          onClick={() => {
            markLessonAsDone({
              lessonId,
              sectionId: params.sectionId as string,
              trainingId: params.trainingId,
              moduleId: params.moduleId,
            }).then(() => {
              if (nextPublishedTrainingLessonId !== null) {
                const newPath = pathname.replace(
                  params.lessonId as string,
                  nextPublishedTrainingLessonId
                );
                navigate(newPath);
              }
            });
          }}
          startIcon={
            <SvgIcon fontSize="inherit">
              <WorkbaseCheckmarkBigFilledIcon />
            </SvgIcon>
          }
        >
          {t('lessonPage.finishLesson')}
        </Button>
      </Box>
    </Box>
  );
}
