import { Button, Stack } from '@mui/material';
import { t } from 'i18next';
import { useDrop } from 'react-dnd';
import {
  useMoveTrainingLessonToNewPositionMutation,
  usePostTrainingLessonMutation,
} from 'src/store/api/trainings/trainingLessonsApi';
import DndIndicator from 'src/ui-components/custom/dnd-indicator/DndIndicator';

export default function AddingLessonButton({
  id,
  lessonsCount,
  onAddLessonCallback,
}: {
  id: string;
  lessonsCount: number;
  onAddLessonCallback?: (lessonId: string) => void;
}) {
  const [moveTrainingLessonToNewPosition] = useMoveTrainingLessonToNewPositionMutation();
  const [postTrainingLesson] = usePostTrainingLessonMutation();

  const [{ isOver }, drop] = useDrop({
    accept: 'editing-lesson-item',
    drop: (i: { id: string; sectionId: string }) => {
      moveTrainingLessonToNewPosition({
        sectionId: id as string,
        idOfMovingLesson: i.id,
        order: i.sectionId === id ? lessonsCount : lessonsCount + 1,
      });
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
    }),
  });

  return (
    <Stack position="relative" ref={drop}>
      {isOver && <DndIndicator top={-9} />}
      <Button
        fullWidth
        color="inherit"
        onClick={() => {
          postTrainingLesson({ trainingSectionId: id }).then((resp: any) => {
            if (resp.data.id) {
              onAddLessonCallback?.(resp.data.id);
              setTimeout(() => {
                document.getElementById(resp.data.id)?.querySelector('input')?.focus();
              }, 100);
            }
          });
        }}
      >
        {t('modulePage.addLesson')}
      </Button>
    </Stack>
  );
}
