import { DocumentResourceType } from 'src/store/api/driveApi';
import {
  useDeleteEmbeddedAppMutation,
  useDuplicateEmbeddedAppMutation,
} from 'src/store/api/embeddedAppsApi';
import { useDeletePageMutation, useDuplicatePageMutation } from 'src/store/api/pagesApi';
import {
  useDeleteWhiteboardMutation,
  useDuplicateWhiteboardMutation,
} from 'src/store/api/whiteboardsApi';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';

export default function DocumentsActions({
  id,
  resourceType,
}: {
  id: string;
  resourceType: DocumentResourceType;
}) {
  const [deleteEmbeddedApp] = useDeleteEmbeddedAppMutation();
  const [duplicateEmbeddedApp] = useDuplicateEmbeddedAppMutation();
  const [deleteWhiteboard] = useDeleteWhiteboardMutation();
  const [duplicateWhiteboard] = useDuplicateWhiteboardMutation();
  const [deletePage] = useDeletePageMutation();
  const [duplicatePage] = useDuplicatePageMutation();

  const deleteMap: { [key in DocumentResourceType]: (id: string) => void } = {
    embeddedApp: deleteEmbeddedApp,
    page: deletePage,
    whiteboard: deleteWhiteboard,
  };

  const duplicateMap: { [key in DocumentResourceType]: (id: string) => void } = {
    embeddedApp: duplicateEmbeddedApp,
    page: duplicatePage,
    whiteboard: duplicateWhiteboard,
  };

  const handleDelete = () => {
    deleteMap[resourceType](id);
  };

  const handleDuplicate = () => {
    duplicateMap[resourceType](id);
  };

  return <DeleteDuplicateMenu onDelete={handleDelete} onDuplicate={handleDuplicate} />;
}
