import { useCallback, useEffect, useMemo, useState } from 'react';
import Slider from '@mui/material/Slider';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import debounce from 'lodash.debounce';
import { useTranslation } from 'react-i18next';
import { usePatchSettingsMutation, useSettingsQuery } from 'src/store/api/settingsApi';
import { Button, Stack, SvgIcon } from '@mui/material';
import { WorkbaseResetIcon } from 'src/assets/icons/workbaseIcons';

const MAX = 10;
const MIN = 2;
const DEFAULT = MAX;

function BorderRadiusSlider() {
  const { t } = useTranslation();
  const { data } = useSettingsQuery();
  const [patchSettings] = usePatchSettingsMutation();

  const [val, setVal] = useState(data?.borderRadius || DEFAULT);

  useEffect(() => {
    if (data?.borderRadius) {
      setVal(data.borderRadius);
    }
  }, [data?.borderRadius]);

  const debouncedDispatch = useMemo(
    () =>
      debounce((newValue: number) => {
        patchSettings({ borderRadius: newValue });
      }, 800),
    [patchSettings]
  );

  const debouncedSetBorderRadius = useCallback(
    (newValue: number) => {
      debouncedDispatch(newValue);
    },
    [debouncedDispatch]
  );

  const handleSliderChange = (_: Event, newValue: number | number[]) => {
    if (typeof newValue === 'number') {
      setVal(newValue);
      debouncedSetBorderRadius(newValue);
    }
  };

  return (
    <Box>
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Typography variant="subtitle1" fontWeight="medium" gutterBottom>
          {t('companySettings.siteInformation.borderRadius.label')}: {val}px
        </Typography>
        <Button
          data-cy="reset-border-radius-button"
          color="inherit"
          size="small"
          variant="text"
          startIcon={
            <SvgIcon>
              <WorkbaseResetIcon />
            </SvgIcon>
          }
          onClick={() => {
            patchSettings({
              borderRadius: DEFAULT,
            });
          }}
          disabled={val === DEFAULT}
        >
          {t('resettableTextArea.resetDefaultLabel')}
        </Button>
      </Stack>
      <Slider
        value={val}
        onChange={handleSliderChange}
        aria-labelledby="border-radius-slider"
        step={1}
        min={MIN}
        max={MAX}
        valueLabelDisplay="auto"
        marks={[
          { value: MIN, label: `${MIN}px` },
          { value: MAX, label: `${MAX}px` },
          { value: val, label: `${val}px` },
        ]}
      />
    </Box>
  );
}

export default BorderRadiusSlider;
