import { Components, Theme } from '@mui/material';

const MuiButton: Components<Theme>['MuiButton'] = {
  defaultProps: {
    variant: 'contained',
    disableElevation: true,
    disableRipple: true,
  },
  styleOverrides: {
    root: {
      textTransform: 'unset',
      minWidth: 0,
    },
  },
  variants: [
    {
      props: { disableElevation: false },
      style: ({ theme }) => ({
        boxShadow: theme.shadows[1],
      }),
    },
  ],
};

export default MuiButton;
