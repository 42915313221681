import { ChangeEvent } from 'react';
import { InputBase } from '@mui/material';
import ImageEditOverlay from './ImageEditOverlay';

interface Props {
  onImageChange: (e: ChangeEvent<HTMLInputElement>) => Promise<unknown>;
  dataCy?: string;
}

export default function ImageEditorWithoutPreview({ onImageChange, dataCy }: Props) {
  return (
    <ImageEditOverlay dataCy={dataCy}>
      <InputBase
        type="file"
        inputProps={{
          accept: 'image/jpeg,image/png',
        }}
        onChange={(e: any) => {
          onImageChange(e);
        }}
        sx={{ display: 'none' }}
      />
    </ImageEditOverlay>
  );
}
