import { useDropzone } from 'react-dropzone';
import { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { Box, Button, Stack, SvgIcon, Typography } from '@mui/material';
import {
  WorkbaseContentIcon,
  WorkbaseImageDragAndUploadIcon,
  WorkbaseRecordIcon,
  WorkbaseVideoUploadIcon,
} from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import { ImageUploadType, VideoUploadType } from 'src/features/editor/model';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { videoUpload } from 'src/lib/utils/videoUpload';
import { DocumentNameContext } from 'src/features/editor/view/DocumentNameContext';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useDispatch } from 'react-redux';
import { setRecordingModalPayload } from 'src/features/editor/controller/Editor.slice';
import Empty from 'src/ui-components/custom/empty/Empty';
import HighlightWrapper from '../highlight-wrapper';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';
import { FileType } from '../edit-bar/UploadActions';
import { allowedFileTypes, MAX_FILESIZE } from './config';
import useUploadingMedia from '../hooks/useUploadingMedia';
import BorderWrapper from '../border-wrapper';
import WithUploadProgressWrapper from '../upload-progress/WithUploadProgressWrapper';

interface Props {
  id: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onResizeStop?: ResizeStopCallbackType;
  onChangeMediaType: (type: FileType) => void;
  onImageUpload: (file: ImageUploadType) => void;
  onVideoUpload: (file: VideoUploadType) => void;
  type: FileType;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onResizeStart?: () => void;
}

function RenderTitle({ type }: { type: string }) {
  const { t } = useTranslation();

  return (
    <Typography fontWeight="medium" textAlign="center">
      {t(`uploadItem.${type}.title`)}{' '}
      <Typography color="primary" fontWeight="bold" component="span">
        {t('uploadItem.chooseFileText')}
      </Typography>{' '}
      {t('uploadItem.toUploadText')}
    </Typography>
  );
}

export default function UploadItem({
  id,
  type,
  onDelete,
  onDuplicate,
  onResizeStop,
  onChangeMediaType,
  onImageUpload,
  dragging,
  onResizeStart,
  onVideoUpload,
}: Props) {
  const { t } = useTranslation();
  const [accept, setAccept] = useState(allowedFileTypes[type]);
  const [file, setFile] = useState<File | null>(null);
  const [loading, setLoading] = useState(false);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const documentName = useContext(DocumentNameContext);
  const abortControllerRef = useRef<AbortController | null>(null);
  const dispatch = useDispatch();
  const { handleClearUploading, handleAddingUploading } = useUploadingMedia(id, type);

  const placeholderProps = {
    image: {
      icon: WorkbaseImageDragAndUploadIcon,
      title: <RenderTitle type="image" />,
    },
    video: {
      icon: WorkbaseVideoUploadIcon,
      title: <RenderTitle type="video" />,
      additionalContent: (
        <Stack direction="row" justifyContent="center" mt={1.5}>
          <Button
            color="error"
            startIcon={<SvgIcon component={WorkbaseRecordIcon} fontSize="inherit" />}
            onClick={(e) => {
              e.stopPropagation();
              dispatch(
                setRecordingModalPayload({
                  id,
                  onUploadProgress: setUploadPercentage,
                  onLoading: setLoading,
                })
              );
            }}
          >
            {t('recording.recordVideo')}
          </Button>
        </Stack>
      ),
    },
    file: {
      icon: WorkbaseContentIcon,
      title: <RenderTitle type="file" />,
    },
  };

  const resetStates = () => {
    setLoading(false);
    setFile(null);
    setUploadPercentage(0);
    handleClearUploading();
    if (abortControllerRef.current) {
      abortControllerRef.current.abort();
      abortControllerRef.current = null;
    }
  };

  const handleImageUpload = async (newFile: File) => {
    setFile(newFile);
    const reader = new FileReader();
    reader.readAsDataURL(newFile);

    const response = await imageUpload(
      newFile,
      {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.loaded && progressEvent.total) {
            const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
            setUploadPercentage(percentCompleted);
          }
        },
      },
      documentName
    );

    if (response.status !== 201) {
      throw new Error(`Failed to upload image. Status: ${response.status}`);
    }

    const { result } = response.data;
    const { variants, id: imageId } = result;
    const [imageUrl] = variants;
    onImageUpload({
      url: imageUrl,
      fileName: newFile.name,
      imageId,
    });
    resetStates();
  };

  const handleVideoUpload = useCallback(
    async (newFile: File) => {
      const abortController = new AbortController();
      abortControllerRef.current = abortController;
      const { signal } = abortController;

      setFile(newFile);
      if (newFile.size > MAX_FILESIZE) {
        showNotification(t('validationErrorMessages.UploadFileSizeErrorMessage'), 'error');
        setFile(null);
        setLoading(false);
        handleClearUploading();
        return;
      }

      const uploadVid = (videoId: string) => {
        handleClearUploading();
        onVideoUpload({
          url: null,
          fileName: newFile.name,
          type,
          videoPoster: '',
          videoId,
          isVideoUploaded: false,
        });
      };

      try {
        await videoUpload({
          file: newFile,
          errorMessage: t('validationErrorMessages.UploadFailed'),
          setPercentage: setUploadPercentage,
          onVideoUploadSuccess: uploadVid,
          documentName,
          signal,
        });
      } catch {
        showNotification(t('validationErrorMessages.UploadFailed'), 'error');
        setFile(null);
        setLoading(false);
        handleClearUploading();
      }
    },
    [handleClearUploading, documentName, onVideoUpload, t, type]
  );

  const handleDropSingleFile = async (acceptedFiles: File[]) => {
    try {
      setLoading(true);

      if (!acceptedFiles.length) {
        setLoading(false);
        return;
      }

      handleAddingUploading();

      if (acceptedFiles[0].type.includes('video')) {
        onChangeMediaType('video');
        handleVideoUpload(acceptedFiles[0]);
      } else if (acceptedFiles[0].type.includes('image')) {
        onChangeMediaType('image');
        handleImageUpload(acceptedFiles[0]);
      }
    } catch (err) {
      showNotification(t('validationErrorMessages.UploadFileErrorMessage'), 'error');
      resetStates();
    }
  };

  const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
    onDrop: handleDropSingleFile,
    noClick: true,
    accept,
  });

  useEffect(() => {
    if (isDragActive) {
      setAccept({
        ...allowedFileTypes.image,
        ...allowedFileTypes.video,
      });
    } else {
      setAccept({
        ...allowedFileTypes[type],
      });
    }
  }, [isDragActive, type]);

  const dropzoneProps = {
    ...getRootProps({
      onClick: () => {
        open();
      },
    }),
  };

  return (
    <HighlightWrapper
      id={id}
      editBarProps={{
        baseActionsProps: { onDelete, onDuplicate },
        uploadActionsProps: {
          isLoading: loading,
          activeFileType: type,
          onChangeActiveFileType: onChangeMediaType,
        },
      }}
      dragging={dragging}
      horizontalResize={{}}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
    >
      {({ EditBarComponent, focused }) => (
        <>
          {EditBarComponent}
          <BorderWrapper
            readOnly={false}
            dashed
            {...dropzoneProps}
            px={2}
            sx={{
              pointerEvents: focused ? 'auto' : 'none',
              cursor: focused ? 'pointer' : 'default',
              px: 2,
              ...(isDragActive && {
                opacity: 0.7,
              }),
            }}
          >
            <WithUploadProgressWrapper
              loading={loading}
              uploadProgress={uploadPercentage}
              fileSize={file?.size}
              onStopUploading={resetStates}
              mediaType={type}
            >
              <Box height="100%">
                <Empty
                  icon={placeholderProps[type].icon}
                  description={placeholderProps[type].title}
                >
                  {type === 'video' && placeholderProps[type].additionalContent}
                </Empty>
              </Box>
            </WithUploadProgressWrapper>
          </BorderWrapper>
          <input {...getInputProps()} />
        </>
      )}
    </HighlightWrapper>
  );
}
