import { Box, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import ShareModal from 'src/core/share-modal/ShareModal';
import EditorWithModeSwitch from 'src/features/editor/view/EditorWithModeSwitch';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { ShareLevel } from 'src/lib/types/share-level';
import {
  useDeletePageMutation,
  useDuplicatePageMutation,
  usePageQuery,
  usePatchPageMutation,
} from 'src/store/api/pagesApi';
import { useShareLevelQuery } from 'src/store/api/shareResourceApi';
import DeleteDuplicateMenu from 'src/ui-components/custom/delete-duplicate-menu';
import { WorkbasePressContentIcon } from 'src/assets/icons/workbaseIcons';
import CustomPageHeader from './CustomPageHeader';

export default function CustomPageView() {
  const param = useParams();
  if (!param.id) throw new Error('Param id required for this view');
  const pageId = param.id;

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { data: shareLevelData, error } = useShareLevelQuery({
    resourceType: 'page',
    resourceId: param.id,
  });
  const hasEditingPermissions =
    shareLevelData?.shareLevel !== ShareLevel.view &&
    shareLevelData?.shareLevel !== ShareLevel.none;
  const hasAnyPermission = useHasAnyPermission();
  const hasAddPagesPermission = hasAnyPermission(['ADD_PAGES']);
  const { data } = usePageQuery(pageId);
  const [deletePage] = useDeletePageMutation();
  const [duplicatePage] = useDuplicatePageMutation();
  const [patchPage] = usePatchPageMutation();

  const siteName = data?.name || t('customSites.unnamedPage');

  const hasManagePermission = shareLevelData?.shareLevel === ShareLevel.manage;

  return (
    <EditorWithModeSwitch
      canEdit={hasEditingPermissions}
      canView={shareLevelData?.shareLevel !== ShareLevel.none && !error}
      renderHeader={(readOnly) => <CustomPageHeader readOnly={readOnly} pageId={pageId} />}
      topBarRightContent={
        <>
          {data && (
            <Tooltip title={t('sidebarTooltip')}>
              <Box bgcolor="primary.paper" borderRadius={1}>
                <ToggleButton
                  data-cy="custom-page-top-bar-show-in-sidebar-button"
                  size="small"
                  color={data.sidebar ? 'primary' : 'standard'}
                  selected
                  value={data.sidebar}
                  onChange={(_, state) => {
                    patchPage({ id: data.id, sidebar: !state });
                  }}
                >
                  <SvgIcon component={WorkbasePressContentIcon} fontSize="small" />
                </ToggleButton>
              </Box>
            </Tooltip>
          )}
          <ShareModal
            dataCy="custom-page"
            name={siteName}
            resourceId={pageId}
            resourceType="page"
            shareLevel={shareLevelData?.shareLevel}
          />
          <DeleteDuplicateMenu
            onDuplicate={
              hasAddPagesPermission
                ? () => {
                    duplicatePage(pageId).then((resp: any) => {
                      if (!resp.error) {
                        navigate(`/page/${resp.data.id}/edit`);
                        showNotification(t('notifications.duplicateSuccess'));
                      } else {
                        showNotification(t('notifications.duplicateError'), 'error');
                      }
                    });
                  }
                : undefined
            }
            deleteDialogDescription={t(
              'editor.topBar.customQuestionsOnSettingIcon.deleteCustomSiteQuestion'
            )}
            onDelete={
              hasManagePermission
                ? () => {
                    deletePage(pageId).then((resp: any) => {
                      if (!resp.error) {
                        showNotification(t('notifications.deleteSuccess'));
                        navigate('/');
                      } else {
                        showNotification(t('notifications.deleteError'), 'error');
                      }
                    });
                  }
                : undefined
            }
          />
        </>
      }
      documentName={`page.${pageId}`}
    />
  );
}
