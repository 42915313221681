import { ListItemAvatar, Avatar, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseRoleIcon } from 'src/assets/icons/workbaseIcons';
import { ResourceType } from 'src/store/api/searchResourceApi';
import { ResourceInfo } from 'src/store/api/shareResourceApi';
import AccessControlItem from './AccessControlItem';
import PermissionSelect from '../PermissionSelect';
import RequiredSwitch from '../RequiredSwitch';

interface AccessRoleItemProps {
  resource: ResourceInfo;
  resourceId: string;
  resourceType: ResourceType;
  isTrainingResourceType: boolean;
}

export default function AccessRoleItem(props: AccessRoleItemProps) {
  const { resource, resourceId, resourceType, isTrainingResourceType } = props;
  const { t } = useTranslation();
  const { role, id, required, shareLevel } = resource;

  if (!role || !role.department) {
    return null;
  }

  return (
    <AccessControlItem
      key={id}
      prefix={
        <ListItemAvatar>
          <Avatar>
            <SvgIcon component={WorkbaseRoleIcon} fontSize="medium" />
          </Avatar>
        </ListItemAvatar>
      }
      primaryText={`${t('permissionsShare.roleTitle')}: ${role.name}`}
      secondaryText={`${role.department.name} ${t('permissionsShare.department')} – ${t(
        'permissionsShare.member',
        { count: role.companyUsersCount }
      )}`}
      suffix={
        <>
          <PermissionSelect
            resourceId={resourceId}
            resourceType={resourceType}
            id={id}
            shareLevel={shareLevel}
          />
          {isTrainingResourceType && (
            <RequiredSwitch
              resourceId={resourceId}
              resourceType={resourceType}
              id={id}
              isRequired={required ?? false}
            />
          )}
        </>
      }
    />
  );
}
