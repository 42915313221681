import { Grid2, Typography } from '@mui/material';
import dayjs from 'dayjs';

function formatText(text: string, id: string) {
  if (text) {
    if (['dateOfBirth', 'hireDate'].includes(id) && text) {
      return dayjs(text).format('DD.MM.YYYY');
    }
    return text;
  }
  return 'N/A';
}

interface EmployeeTextSectionProps {
  title?: string;
  list: { key: string; label: string; text: string }[];
  dataCy: string;
}

function EmployeeTextSection({ title, list, dataCy }: EmployeeTextSectionProps) {
  return (
    <Grid2 container columnSpacing={2} wrap="wrap">
      {title && (
        <Grid2 data-cy={dataCy} size={12}>
          <Typography variant="h6" data-cy={`${title}`}>
            {title}
          </Typography>
        </Grid2>
      )}
      {list.map((item) => (
        <Grid2 key={item.key} size={4} columnSpacing={3}>
          <Typography
            variant="subtitle2"
            data-cy={`${item.label}`}
            color="text.secondary"
            align="left"
            fontWeight="normal"
          >
            {item.label}
          </Typography>
          <Typography
            noWrap
            align="left"
            variant="body1"
            data-cy={`${item.label}-value`}
            color="textPrimary"
            fontWeight="bold"
          >
            {formatText(item.text, item.key)}
          </Typography>
        </Grid2>
      ))}
    </Grid2>
  );
}

export default EmployeeTextSection;
