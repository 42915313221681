import { useMemo } from 'react';
import { Virtuoso } from 'react-virtuoso';
import { Stack } from '@mui/material';
import ItemDndWrapper from './ItemDndWrapper';
import { SidebarItem } from '../types';
import Item from './Item';

interface Props {
  data: SidebarItem[];
  activeKey: string;
  onMove?: ({ id, hoverIndex }: { id: string; hoverIndex: number }) => void;
  dataCy?: string;
  enableDnd: boolean;
}

export default function ItemList({ data, activeKey, onMove, dataCy, enableDnd }: Props) {
  const visibleData = useMemo(() => data.filter((item) => !item.hide), [data]);

  const renderItem = (index: number) => {
    const item = visibleData[index];
    return (
      <Stack position="relative" key={item.key} mb={0.5}>
        {enableDnd ? (
          <ItemDndWrapper
            data={item}
            dataCy={dataCy}
            activeKey={activeKey}
            index={index}
            onMove={
              onMove ? ({ id, index: hoverIndex }) => onMove?.({ id, hoverIndex }) : undefined
            }
          />
        ) : (
          <Item data={item} dataCy={dataCy} isActive={activeKey === item?.key} />
        )}
      </Stack>
    );
  };

  return (
    <Virtuoso
      style={{ height: '100%', width: '100%' }}
      totalCount={visibleData.length}
      itemContent={renderItem}
      overscan={200}
    />
  );
}
