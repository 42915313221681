import { Alert, Box, Button, Divider, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  AdminSettings,
  usePatchSettingsMutation,
  useSettingsQuery,
  RecommendationNotificationAdminPreferences,
} from 'src/store/api/settingsApi';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import EmployeeRecommendationForm from './recommendation-form/EmployeeRecommendationForm';
import EmployeeRecommendationTextarea from './EmployeeRecommendationTextarea';

const centeredProgress = (
  <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
    <LottieLogoSpinner />
  </Box>
);

export default function EmployeeRecommendations() {
  const { t } = useTranslation();
  const { data, isLoading, error, refetch } = useSettingsQuery();
  const [patchSettings] = usePatchSettingsMutation();
  const adminSettings = data as AdminSettings | undefined;

  const handlePatchSettings = (
    updates: Partial<RecommendationNotificationAdminPreferences> | undefined
  ) => {
    if (adminSettings && updates) {
      patchSettings({
        recommendationNotificationPreferences: updates,
      });
    }
  };

  if (isLoading) return centeredProgress;
  if (error) {
    return (
      <Alert
        severity="error"
        action={
          <Button onClick={refetch}>{t('validationErrorMessages.labelErrorTryAgainButton')}</Button>
        }
      >
        {t('companySettings.siteInformation.labelErrorDescription')}
      </Alert>
    );
  }
  return (
    <Stack width="100%" alignItems="center">
      <Stack gap={2.5} width="100%" maxWidth={(theme) => theme.breakpoints.values.lg}>
        <Typography variant="h4" fontWeight="bold" color="text.primary">
          {t('companySettings.employeeRecommendations.header')}
        </Typography>
        {data && (
          <>
            <EmployeeRecommendationTextarea
              value={data.recommendationNotificationPreferences.descriptionText}
            />
            <Divider />
            {adminSettings && (
              <EmployeeRecommendationForm
                email={adminSettings?.recommendationNotificationPreferences?.email}
                patchSettings={handlePatchSettings}
                isEmailEnabled={
                  adminSettings?.recommendationNotificationPreferences?.isEmailEnabled
                }
                webhookUrl={adminSettings?.recommendationNotificationPreferences?.webhookUrl}
                isWebhookEnabled={
                  adminSettings?.recommendationNotificationPreferences?.isWebhookEnabled
                }
              />
            )}
          </>
        )}
      </Stack>
    </Stack>
  );
}
