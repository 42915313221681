import React from 'react';
import SliderComponent from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Box, IconButton, SvgIcon } from '@mui/material';
import './slider.css';
import { WorkbaseChevronIcon } from 'src/assets/icons/workbaseIcons';

export interface SliderProps {
  children: React.ReactNode;
  afterChange?: (index: number) => void;
  initialSlide?: number;
  infinite?: boolean;
}

function CustomPaging(props: any) {
  return (
    <Box {...props} sx={{ paddingTop: 1, paddingBottom: 1 }}>
      <Box
        sx={{
          height: 3,
          width: 20,
          borderRadius: 1.5,
          backgroundColor: 'divider',
        }}
      />
    </Box>
  );
}

function AppendDots(dots: React.ReactNode) {
  return (
    <Box
      sx={{
        '& .slick-active > div > div': {
          backgroundColor: 'grey.500',
        },
      }}
    >
      {dots}
    </Box>
  );
}

function Arrow({
  transform,
  onClick,
  className,
}: {
  transform?: string;
  onClick?: () => void;
  className?: string;
}) {
  return (
    <Box className={className}>
      <IconButton onClick={onClick} color="primary">
        <SvgIcon
          component={WorkbaseChevronIcon}
          sx={transform ? { transform: `${transform} !important` } : undefined}
        />
      </IconButton>
    </Box>
  );
}

const settings = {
  infinite: false,
  customPaging: CustomPaging,
  appendDots: AppendDots,
  dots: true,
  nextArrow: <Arrow />,
  prevArrow: <Arrow transform="rotate(180deg)" />,
};

export default function Slider({
  children,
  afterChange,
  initialSlide,
  infinite = false,
}: SliderProps) {
  'use no memo';

  return (
    <SliderComponent
      {...settings}
      infinite={infinite}
      afterChange={afterChange}
      initialSlide={initialSlide}
    >
      {children}
    </SliderComponent>
  );
}
