import { Avatar, Box, Popover, Stack, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { usePopupState, bindTrigger, bindPopover } from 'material-ui-popup-state/hooks';
import { useTranslation } from 'react-i18next';
import { WorkbaseAdjustSettings } from 'src/assets/icons/workbaseIcons';
import UserProfileMenu from 'src/features/user-profile-menu';

interface Props {
  avatarUrl?: string;
  isImpersonated?: boolean;
}

function UserProfile({ avatarUrl, isImpersonated }: Readonly<Props>) {
  const popupState = usePopupState({ variant: 'popover', popupId: 'profile-menu' });
  const { t } = useTranslation();
  const popoverProps = bindPopover(popupState);
  return (
    <>
      <Popover
        slotProps={{
          paper: {
            variant: 'outlined',
          },
        }}
        {...popoverProps}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <UserProfileMenu onClose={popoverProps.onClose} />
      </Popover>
      <Tooltip title={t('header.userAvatarTooltip')}>
        <Box
          borderColor={isImpersonated ? 'error.main' : 'divider'}
          borderRadius={1}
          sx={{ borderStyle: 'solid' }}
        >
          <ToggleButton
            selected={popoverProps.open || isImpersonated}
            color={isImpersonated ? 'error' : 'standard'}
            value="profile-menu"
            {...bindTrigger(popupState)}
            data-cy="user-avatar-button"
            size="small"
            sx={{ margin: '-1px' }}
          >
            <Stack direction="row" pl={1.5} pr={0.5} gap={2} alignItems="center">
              <SvgIcon component={WorkbaseAdjustSettings} color="inherit" />
              <Avatar
                src={avatarUrl}
                sx={{
                  width: 34,
                  height: 34,
                  borderStyle: 'solid',
                  borderWidth: 2,
                  borderColor: isImpersonated ? 'error.main' : 'primary.main',
                }}
              />
            </Stack>
          </ToggleButton>
        </Box>
      </Tooltip>
    </>
  );
}

export default UserProfile;
