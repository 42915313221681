import { Box, Container, Stack, useTheme } from '@mui/material';
import CardContent from './CardContent';

interface Props {
  children: React.ReactNode;
  minHeight?: number;
  cardContent?: React.ReactNode;
  disableGutters?: boolean;
  blur?: boolean;
}

export default function BrandedHeader({
  children,
  cardContent,
  minHeight = 250,
  disableGutters,
  blur = false,
}: Props) {
  const { palette } = useTheme();

  return (
    <Stack p={2} width="100%">
      <Box
        display="flex"
        borderRadius={2}
        bgcolor="primary.main"
        width="100%"
        minHeight={minHeight}
        py={2}
        pb={cardContent ? 3 : 2}
        position="relative"
        overflow="hidden"
        color="primary.contrastText"
      >
        <Box
          color={palette.mode === 'light' ? 'primary.dark' : 'primary.light'}
          width="100%"
          height="100%"
          top={0}
          left={0}
          position="absolute"
          bgcolor={blur ? 'action.disabledBackground' : undefined}
          sx={blur ? { filter: 'blur(8px)' } : undefined}
        />
        <Container
          component={Box}
          disableGutters={disableGutters}
          position="relative"
          display="flex"
          flexGrow={1}
          maxWidth="lg"
        >
          {children}
        </Container>
      </Box>
      {cardContent && <CardContent>{cardContent}</CardContent>}
    </Stack>
  );
}
