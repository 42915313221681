import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  InputAdornment,
  LinearProgress,
  SvgIcon,
  Tab,
  TextField,
} from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { WorkbaseDomainIcon } from 'src/assets/icons/workbaseIcons';
import debounce from 'lodash.debounce';
import validateUrl from 'src/lib/utils/validateUrl';
import { usePostIframeMutation } from 'src/store/api/iframeApi';
import { getEmbeddedUrl } from 'src/lib/utils/getEmbeddedUrl';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import LinkParametersTooltip from 'src/features/embedded-app/view/topbar/LinkParametersTooltip';
import { EmbeddedItemContentType } from '../../../../../model';

export interface EmbeddedItemModalContentProps {
  onConfirm: ({
    contentType,
    content,
  }: {
    contentType: EmbeddedItemContentType;
    content: string;
  }) => void;
  initialContent?: string;
  initialContentType?: EmbeddedItemContentType;
  saveButtonText: string;
  onClose: () => void;
}

export default function EmbeddedItemModalContent({
  onConfirm,
  onClose,
  initialContent = '',
  initialContentType = 'url',
  saveButtonText,
}: EmbeddedItemModalContentProps) {
  const [contentType, setContentType] = useState<EmbeddedItemContentType>(initialContentType);
  const [content, setContent] = useState(initialContent);
  const [touched, setTouched] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const contentChangedRef = useRef(false);
  const [postIframe, { data, error, isLoading }] = usePostIframeMutation({});

  const isValidUrlForIframe = contentChangedRef.current ? !error && data?.success : true;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedPostIframe = useCallback(
    debounce((url: string) => {
      const embedUrl = getEmbeddedUrl(url);
      postIframe({ url: embedUrl }).then(() => setTouched(true));
    }, 300),
    []
  );

  useEffect(() => {
    if (contentType === 'url') {
      const isValidUrl = validateUrl(content);
      setIsValid(isValidUrl);
      if (isValidUrl && contentChangedRef.current) {
        debouncedPostIframe(ensureHttpProtocol(content));
      }
    } else {
      setIsValid(content.trim() !== '');
    }
  }, [content, contentType, debouncedPostIframe]);

  useEffect(() => {
    if (contentType !== initialContentType) {
      setContent('');
    } else {
      setContent(initialContent);
    }
    setTouched(false);
  }, [contentType, initialContent, initialContentType]);

  const handleTextFieldChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setContent(e.target.value);
    contentChangedRef.current = true;
  };

  const handleBlur = () => {
    setTouched(true);
  };

  useEffect(() => {
    contentChangedRef.current = false;
  }, [contentType]);

  const isErrorState = contentType === 'url' ? !isValid || !isValidUrlForIframe : !isValid;

  const errorMsg = !isValid && !isValidUrlForIframe ? t('invalidURL') : t('cantBeEmbedded');

  const showErrorMessage = isErrorState && touched && !isLoading;
  return (
    <>
      <DialogTitle>
        {t('editor.embeddedItemModal.title')}
        <LinkParametersTooltip />
      </DialogTitle>
      <DialogContent>
        <TabContext value={contentType}>
          <TabList
            onChange={(_, newVal) => {
              setContentType(newVal);
            }}
            variant="fullWidth"
          >
            <Tab label={t('editor.embeddedItemModal.urlTab')} value="url" />
            <Tab label={t('editor.embeddedItemModal.htmlCodeTab')} value="htmlCode" />
          </TabList>
          <Box marginTop={2.5}>
            <TabPanel value="url" sx={{ pb: 1, position: 'relative' }}>
              <TextField
                value={content}
                error={showErrorMessage}
                onChange={handleTextFieldChange}
                onBlur={handleBlur}
                fullWidth
                helperText={showErrorMessage ? errorMsg : ''}
                label={t('editor.embeddedItemModal.urlTextField.label')}
                slotProps={{
                  input: {
                    endAdornment: (
                      <InputAdornment position="end">
                        <SvgIcon component={WorkbaseDomainIcon} fontSize="small" />
                      </InputAdornment>
                    ),
                  },
                }}
              />
              <LinearProgress
                sx={{
                  position: 'absolute',
                  bottom: 0,
                  left: 0,
                  right: 0,
                  visibility: isLoading ? 'visible' : 'hidden',
                }}
              />
            </TabPanel>
            <TabPanel value="htmlCode">
              <TextField
                fullWidth
                value={content}
                error={showErrorMessage}
                onBlur={handleBlur}
                onChange={handleTextFieldChange}
                label={t('editor.embeddedItemModal.htmlCodeTextField.label')}
                maxRows={6}
                helperText={
                  showErrorMessage ? t('editor.embeddedItemModal.htmlCodeTextField.error') : ''
                }
                minRows={6}
                multiline
              />
            </TabPanel>
          </Box>
        </TabContext>
      </DialogContent>
      <DialogActions>
        <Button fullWidth color="inherit" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          fullWidth
          color="primary"
          disabled={isErrorState || isLoading}
          onClick={() => {
            onConfirm({
              contentType,
              content: contentType === 'url' ? getEmbeddedUrl(content) : content,
            });
            onClose();
          }}
        >
          {saveButtonText}
        </Button>
      </DialogActions>
    </>
  );
}
