import { Stack, SvgIcon, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { WorkbaseEmailIcon } from 'src/assets/icons/workbaseIcons';
import { useAppSelector } from 'src/store';
import CircularProgressWithContent from 'src/ui-components/custom/progress/CircularProgressWithContent';
import CircularProgressWithLabel from 'src/ui-components/custom/progress/CircularProgressWithLabel';

export default function Progress({ value }: { value: number }) {
  const { t } = useTranslation();
  const employeeDraft = useAppSelector((state) => state.employee.employee);

  return (
    <Stack direction="row" alignItems="center" spacing={2}>
      <Typography
        textAlign="left"
        fontWeight="bold"
        variant="h6"
        data-cy="employee-details-header-progress-text"
        noWrap
      >
        {t('employeePage.employeeDetails.overAllProgressLabel')}
      </Typography>
      <Stack>
        {employeeDraft?.status === 'invited' ? (
          <CircularProgressWithContent
            circularProgressProps={{
              variant: 'determinate',
              value: 100,
              color: 'secondary',
            }}
          >
            <SvgIcon component={WorkbaseEmailIcon} fontSize="small" color="secondary" />
          </CircularProgressWithContent>
        ) : (
          <CircularProgressWithLabel value={Number(value)} />
        )}
      </Stack>
    </Stack>
  );
}
