import { SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { WorkbaseImageIcon, WorkbaseVideoIcon } from '../../../../../../assets/icons/workbaseIcons';

export interface UploadActionsProps {
  activeFileType: FileType;
  isLoading?: boolean;
  onChangeActiveFileType: (type: FileType) => void;
}

export type FileType = 'image' | 'video' | 'file';

export default function UploadActions({
  activeFileType,
  isLoading,
  onChangeActiveFileType,
}: UploadActionsProps) {
  const items = [
    {
      key: 'image',
      onClick: () => onChangeActiveFileType('image'),
      icon: WorkbaseImageIcon,
    },
    {
      key: 'video',
      onClick: () => onChangeActiveFileType('video'),
      icon: WorkbaseVideoIcon,
    },
  ];
  return (
    <>
      {items.map(({ key, onClick, icon }) => (
        <Tooltip title={t(`editor.topBar.tooltip.${key}`)} placement="top">
          <span>
            <ToggleButton
              value={key}
              size="small"
              disabled={isLoading}
              key={key}
              selected={key === activeFileType}
              onMouseDown={(e) => {
                e.preventDefault();
                onClick();
              }}
            >
              <SvgIcon component={icon} fontSize="small" />
            </ToggleButton>
          </span>
        </Tooltip>
      ))}
    </>
  );
}
