import { TabList } from '@mui/lab';
import { Box, Button, Stack, SvgIcon, Tab } from '@mui/material';
import { useTranslation } from 'react-i18next';
import BrandedHeader from 'src/ui-components/branded-header';
import IconText from 'src/ui-components/icon-text/IconText';
import { WorkbaseContentNewIcon } from 'src/assets/icons/workbaseIcons';
import useIsMobile from 'src/hooks/useIsMobile';
import { DocumentsTabData, TabValue } from './types';

interface Props {
  tabsData: DocumentsTabData[];
  onActiveTabChange: (val: TabValue) => void;
  openUpload: () => void;
}

export default function DocumentsHeader({ tabsData, onActiveTabChange, openUpload }: Props) {
  const { t } = useTranslation();
  const isMobile = useIsMobile();
  return (
    <BrandedHeader
      cardContent={
        <Stack direction={isMobile ? 'column' : 'row'} gap={2} justifyContent="space-between">
          <TabList
            variant={isMobile ? 'fullWidth' : 'standard'}
            onChange={(_, newVal) => onActiveTabChange(newVal)}
          >
            {tabsData.map(({ label, key }) => (
              <Tab key={key} label={label} value={key.toString()} />
            ))}
          </TabList>
          <Button
            onClick={openUpload}
            startIcon={<SvgIcon component={WorkbaseContentNewIcon} fontSize="small" />}
          >
            {t('documentsPage.uploadButton')}
          </Button>
        </Stack>
      }
    >
      <Box margin="auto">
        <IconText text={t('sidebar.tabDrive')} icon="WorkbaseFolderIcon" />
      </Box>
    </BrandedHeader>
  );
}
