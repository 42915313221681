import { useEffect } from 'react';
import useThemeLogos from 'src/hooks/useThemeLogos';
import { useSettingsQuery } from '../store/api/settingsApi';

interface SiteInfoProviderProps {
  children: React.ReactNode;
}

export default function SiteInfoProvider({ children }: SiteInfoProviderProps) {
  const { data } = useSettingsQuery();
  const { favicon } = useThemeLogos();

  useEffect(() => {
    if (data?.siteName) {
      document.title = data.siteName ?? 'Workbase';
    }
  }, [data?.siteName]);

  useEffect(() => {
    if (favicon.src) {
      const link = document.querySelector('link[rel="icon"]') || document.createElement('link');
      link.setAttribute('rel', 'icon');
      link.setAttribute('href', favicon.src);
      document.head.appendChild(link);
    }
  }, [favicon.src]);

  return children;
}
