import { Tooltip } from '@mui/material';
import { usePermissionsQuery } from 'src/features/company-settings/departments/controller/permissionsApi';
import { Permission } from 'src/features/company-settings/departments/model/types';
import { useAppDispatch, useAppSelector } from 'src/store';
import { addPermissionToRoles } from 'src/features/company-settings/departments/controller/departments.slice';
import { PermissionCode } from 'src/store/api/userPermissionsApi';
import BaseSinglePermission, { BaseSinglePermissionProps } from './BaseSinglePermission';

interface AdminSinglePermissionProps extends BaseSinglePermissionProps {
  code: PermissionCode;
  hasManagingPermission: boolean;
  tooltipWithoutManagingPermission: string;
}

export default function AdminSinglePermission(props: AdminSinglePermissionProps) {
  const { code, hasManagingPermission, tooltipWithoutManagingPermission } = props;
  const { data: permissionsData } = usePermissionsQuery();
  const dispatch = useAppDispatch();
  const generalPermissionsCodes = [
    'ADD_PAGES',
    'ADD_EMBEDDED_APPS',
    'ADD_TRAININGS',
    'ADD_WHITEBOARDS',
    'ADD_WIKIS',
  ];
  const checkedRolesIds = useAppSelector((state) => state.departments.checkedRoles);
  const deps = useAppSelector((state) => state.departments.departmentsDraftState);

  const checkedRoles = deps
    .flatMap((dep) => dep.roles)
    .filter((role) => checkedRolesIds.includes(role.id));

  const allPermissions =
    permissionsData?.permissionGroups.flatMap((group) => group.permissions) || [];

  const generalPermissions = allPermissions.filter((per: Permission) =>
    generalPermissionsCodes.includes(per.code)
  );

  return (
    <Tooltip placement="top" title={!hasManagingPermission && tooltipWithoutManagingPermission}>
      <BaseSinglePermission
        {...props}
        onChangeCallback={(isChecked, isIndeterminate) => {
          if (!isChecked || (isIndeterminate && isChecked)) {
            const parts = code.split('_');
            const lastPart = parts.includes('EMBEDDED')
              ? parts.slice(-2).join('_')
              : parts.slice(-1).join('_');

            const generalPermission =
              generalPermissions &&
              generalPermissions.find((per: Permission) => per.code === `ADD_${lastPart}`);

            if (generalPermission) {
              dispatch(
                addPermissionToRoles({
                  permissionToAdd: generalPermission.id,
                  rolesIds: checkedRoles.map((checkedRole) => checkedRole.id),
                })
              );
            }
          }
        }}
      />
    </Tooltip>
  );
}
