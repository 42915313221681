import axiosInstance from 'src/axios';
import { API_URL } from 'src/global-config';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';

interface UploadOptions {
  file: File;
  errorMessage: string;
  setPercentage?: (percentage: number) => void;
  onFileUploadSuccess: (id: string) => void;
  onFileUploadError: (id: string) => void;
  filename?: string;
  signal?: AbortSignal;
}

const getFileUploadEndpoint = async (file: any) => {
  const resp = await axiosInstance.post(`${API_URL}/files/upload`, {
    filename: file.name,
    fileType: file.type,
    fileSize: file.size,
  });

  return resp.data;
};
const uploadFileToGoogleCloud = (
  url: string,
  file: File,
  setPercentage: (c: number) => void,
  signal: AbortSignal
  // eslint-disable-next-line max-params
) =>
  new Promise<void>((resolve, reject) => {
    const chunkSize = 1024 * 1024; // 1mb
    let bytesUploaded = 0;
    const fileSize = file.size;

    const uploadChunks = (location: string) => {
      const uploadNextChunk = () => {
        if (signal?.aborted) {
          reject(new Error('Upload aborted'));
          return;
        }

        const updateProgress = (uploaded: number, total: number) => {
          if (setPercentage) {
            const percent = Math.ceil((uploaded / total) * 100);
            setPercentage(percent);
          }
        };

        const end = Math.min(bytesUploaded + chunkSize, fileSize);
        const chunk = file.slice(bytesUploaded, end);

        const xhr = new XMLHttpRequest();
        xhr.open('PUT', location, true);
        xhr.setRequestHeader('Content-Range', `bytes ${bytesUploaded}-${end - 1}/${fileSize}`);

        xhr.onload = () => {
          if (xhr.status === 308) {
            // Incomplete, continue uploading
            bytesUploaded = end;
            updateProgress(bytesUploaded, fileSize);
            uploadNextChunk();
          } else if (xhr.status === 200) {
            // Upload completed
            updateProgress(fileSize, fileSize);
            resolve();
          } else {
            reject(new Error(`Upload failed: ${xhr.status}`));
          }
        };

        xhr.onerror = () => {
          reject(new Error('Error during upload'));
        };

        xhr.send(chunk);
      };

      uploadNextChunk();
    };

    const initiateUpload = () => {
      const xhr = new XMLHttpRequest();
      xhr.open('POST', url, true);
      xhr.setRequestHeader('X-Goog-Resumable', 'start');
      xhr.setRequestHeader('Content-Type', file.type);

      xhr.onload = () => {
        if (xhr.status === 201) {
          const location = xhr.getResponseHeader('Location');
          if (location) {
            uploadChunks(location);
          } else {
            reject(new Error('Failed to get upload location'));
          }
        } else {
          reject(new Error(`Failed to initiate upload: ${xhr.status}`));
        }
      };

      xhr.onerror = () => {
        reject(new Error('Error initiating upload'));
      };

      xhr.send();
    };

    initiateUpload();
  });

const fileUpload = async ({
  file,
  errorMessage,
  setPercentage,
  onFileUploadSuccess,
  onFileUploadError,
  signal,
}: UploadOptions): Promise<any> => {
  const fileData = await getFileUploadEndpoint(file);
  try {
    const localUrl = URL.createObjectURL(file);

    // Upload file in chunks TODO: FIX
    await uploadFileToGoogleCloud(
      fileData.uploadUrl,
      file,
      setPercentage as (p: number) => void,
      signal as AbortSignal
    );
    onFileUploadSuccess(fileData.id);

    return { localUrl, fileId: fileData.id };
  } catch (error) {
    showNotification(errorMessage, 'error');
    onFileUploadError(fileData.id);
  }
  return {};
};

export { getFileUploadEndpoint, fileUpload };
