import { Box } from '@mui/material';
import { useDrop } from 'react-dnd';
import { useEditor } from 'src/features/editor/controller';
import { GridItemType, GridRow } from 'src/features/editor/model';
import useDebouncedIsOver from './useDebouncedIsOver';

interface Props {
  position: 'left' | 'right';
  row: GridRow;
}

const getCanDrop = ({
  row,
  position,
  dragItemRow,
  dragItem,
}: {
  row: GridRow;
  position: 'left' | 'right';
  dragItemRow: GridRow;
  dragItem: GridItemType;
}) => {
  const isSideOccupied = row.items.find(
    (i) => i[position === 'left' ? 'columnStart' : 'columnEnd'] === (position === 'left' ? 1 : 5)
  );
  const isDraggingWithinCurrentRow = dragItemRow?.id === row.id;
  let isDraggingItemToSamePosition = false;
  if (isDraggingWithinCurrentRow) {
    if (position === 'left') {
      isDraggingItemToSamePosition = dragItem.columnStart === 1;
    } else {
      isDraggingItemToSamePosition = dragItem.columnEnd === 5;
    }
  } else {
    isDraggingItemToSamePosition = false;
  }
  const isDraggingHeadingItem = dragItem?.elementType === 'heading';
  const canDrop =
    isSideOccupied &&
    (isDraggingWithinCurrentRow || true) &&
    !isDraggingItemToSamePosition &&
    !isDraggingHeadingItem;
  return canDrop;
};

export default function DroppableSideRowItem({ position, row }: Props) {
  const { handleDragAndDropToSideOfRow } = useEditor();
  const [{ isOver, dragItemRow, dragItem }, drop] = useDrop({
    accept: 'GRID_ITEM',
    drop: (item: { rowIndex: number; item: GridItemType; row: GridRow }, monitor) => {
      if (monitor && getCanDrop({ row, position, dragItemRow, dragItem })) {
        handleDragAndDropToSideOfRow(item.item.id, row.id, position);
      }
    },
    collect: (monitor) => ({
      isOver: !!monitor.isOver(),
      dragItemRow: monitor.getItem()?.row,
      dragItem: monitor.getItem()?.item,
    }),
  });
  const canDrop = getCanDrop({ row, position, dragItemRow, dragItem });
  const margin = position === 'left' ? { marginRight: -3 } : { marginLeft: -3 };
  const debouncedIsOver = useDebouncedIsOver(isOver);

  return (
    <Box
      ref={drop}
      className="side-row-item"
      {...margin}
      minWidth={32}
      position="relative"
      flexShrink={1}
      flexGrow={1}
    >
      <Box
        borderRadius={1}
        bgcolor="primary.main"
        height="100%"
        width={8}
        mr={position === 'left' ? '4px' : 'auto'}
        ml={position === 'left' ? 'auto' : '4px'}
        sx={{
          opacity: debouncedIsOver && canDrop ? 1 : 0,
        }}
      />
    </Box>
  );
}
