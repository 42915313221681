import { Skeleton } from '@mui/material';
import BrandedDefaultImage, { DefaultImage } from '../branded-default-image/BrandedDefaultImage';
import Image from './Image';

interface Props {
  defaultImage: DefaultImage;
  width?: string | number;
  height?: string | number;
  fit?: 'fill' | 'contain' | 'cover' | 'none' | 'scale-down';
  loading?: boolean;
  src?: string | null;
  alt: string;
}

export default function ImageWithDefaultFallback({
  defaultImage,
  loading,
  src,
  alt,
  ...restOfProps
}: Props) {
  if (loading)
    return <Skeleton variant="rectangular" width={restOfProps.width} height={restOfProps.height} />;
  return src ? (
    <Image {...restOfProps} src={src} alt={alt} loading={loading} />
  ) : (
    <BrandedDefaultImage {...restOfProps} defaultImage={defaultImage} />
  );
}
