import { Box, Stack } from '@mui/material';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';

export interface TopBarLayoutProps {
  topBar: React.ReactNode;
  topBarVisible?: boolean;
  children: React.ReactNode;
}

const TOPBAR_OFFSET = 70;

export default function TopBarLayout({
  topBar,
  children,
  topBarVisible = true,
}: TopBarLayoutProps) {
  const scrollRef = useScrollOnDrag();

  return (
    <Stack height="100%" overflow="hidden">
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 5,
          height: TOPBAR_OFFSET,
          transform: topBarVisible ? 'translateY(0)' : `translateY(-${TOPBAR_OFFSET}px)`,
          opacity: topBarVisible ? 1 : 0,
          transition: (theme) =>
            theme.transitions.create(['transform', 'opacity'], {
              duration: theme.transitions.duration.complex,
              easing: theme.transitions.easing.easeInOut,
            }),
        }}
      >
        {topBar}
      </Box>
      <Box
        flexGrow={1}
        position="relative"
        paddingTop={topBarVisible ? `${TOPBAR_OFFSET}px` : 0}
        marginTop={topBarVisible ? 0 : `${TOPBAR_OFFSET}px`}
        flexShrink={0}
        height="100%"
        ref={scrollRef}
        sx={{
          overflowY: 'auto',
          transform: topBarVisible ? `translateY(0)` : `translateY(-${TOPBAR_OFFSET}px)`,
          transition: (theme) =>
            theme.transitions.create('transform', {
              duration: theme.transitions.duration.complex,
              easing: theme.transitions.easing.easeInOut,
            }),
        }}
      >
        {children}
      </Box>
    </Stack>
  );
}
