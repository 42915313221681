import { Button, Stack } from '@mui/material';
import { t } from 'i18next';
import DepartmentComponent from './department/Department';
import { Department } from '../../model/types';

interface DepartmentsListProps {
  departments?: Department[];
  inputDepartmentRef: React.RefObject<HTMLInputElement>;
  scrollRef: React.RefObject<HTMLDivElement>;
  onAddDepartment: () => void;
}

export default function DepartmentsList({
  departments,
  inputDepartmentRef,
  scrollRef,
  onAddDepartment,
}: DepartmentsListProps) {
  return (
    <Stack ref={scrollRef} gap={2.5}>
      {departments?.map((department) => (
        <DepartmentComponent
          inputDepartmentRef={inputDepartmentRef}
          departments={departments}
          department={department}
          key={department.id}
        />
      ))}
      <Button
        color="inherit"
        onClick={onAddDepartment}
        data-cy="departments-add-new-department-at-bottom"
      >
        {t('companySettings.sidebar.departments.departmentsAddButton')}
      </Button>
    </Stack>
  );
}
