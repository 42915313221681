import { Box, IconButton, SvgIcon, Tooltip } from '@mui/material';
import { WorkbaseDeleteSquareIcon } from 'src/assets/icons/workbaseIcons';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useAppDispatch } from 'src/store';
import { useTranslation } from 'react-i18next';
import { Department } from '../../../model/types';
import { deleteDepartment } from '../../../controller/departments.slice';

interface DeleteDepartmentProps {
  departmentId?: string;
  usersCount?: number;
  departments: Department[];
}

export default function DeleteDepartment({
  departmentId,
  usersCount,
  departments,
}: DeleteDepartmentProps) {
  const dispatch = useAppDispatch();
  const { t } = useTranslation();

  const handleDelete = () => {
    if (usersCount && usersCount > 0) {
      showNotification(
        t('companySettings.sidebar.departments.removeLinkModal.deleteAssignedDepartmentError'),
        'error'
      );
    } else if (departments.length === 1) {
      showNotification(
        t('companySettings.sidebar.departments.removeLinkModal.deleteLastDepartmentErrorMessage'),
        'error'
      );
    } else if (departmentId) {
      dispatch(deleteDepartment({ departmentId }));
    }
  };

  return (
    <Box color="primary.contrastText">
      <Tooltip title={t('delete')}>
        <IconButton data-cy="department-delete-button" color="inherit" onClick={handleDelete}>
          <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
    </Box>
  );
}
