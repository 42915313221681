import { TextField, TextFieldProps } from '@mui/material';

export default function ContrastTextField(props: TextFieldProps) {
  return (
    <TextField
      {...props}
      sx={{
        '& .MuiInputBase-input': {
          color: 'primary.contrastText',
        },
        '& .MuiInputLabel-root': {
          color: 'primary.contrastText',
        },
        '& .MuiInputLabel-root.Mui-focused': {
          color: 'primary.contrastText',
        },
        '& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: 'primary.contrastText',
        },
        '&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
          borderColor: 'primary.contrastText',
        },
        '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: 'primary.contrastText',
        },
      }}
    />
  );
}
