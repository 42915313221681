import { memo, useContext } from 'react';
import AppHeader from 'src/features/app-header';
import AppSidebar from 'src/features/app-sidebar';
import { Stack, Grid2 as Grid, Box, Paper } from '@mui/material';
import { ChatContext } from 'src/features/ai-chat/helpers/chatContext';
import ChatDialog from 'src/features/ai-chat/views/ChatDialog';
import { Outlet } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';
import AppSidedrawer from 'src/features/app-sidebar/AppSidedrawer';

function MainLayout() {
  const { state, dispatch, ws } = useContext(ChatContext);
  const renderChat = state.isOpen && !state.isFullScreen;
  const isMobile = useIsMobile();
  return (
    <Stack minWidth="100%" width="100%" direction="row" height="100%" pb={isMobile ? 0 : 2}>
      {!isMobile && <AppSidebar />}
      <Stack mr={isMobile ? 0 : 2} flexGrow={1} flexShrink={1} minWidth={0} height="100%">
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mx={isMobile ? 2 : 0}
          gap={2}
        >
          {isMobile && <AppSidedrawer />}
          <AppHeader />
        </Stack>
        <Grid
          container
          spacing={renderChat ? 1 : 0}
          columns={7}
          flexGrow={1}
          overflow="auto"
          height="100%"
        >
          {renderChat && (
            <Grid size={2} maxHeight="100%">
              {renderChat && (
                <ChatDialog
                  isFullScreen={state.isFullScreen}
                  threadId={state.threadId}
                  isLoading={state.isLoading}
                  open={state.isOpen}
                  messages={state.messages}
                  locationBeforeFullscreen={state.locationBeforeFullscreen}
                  dispatch={dispatch}
                  ws={ws}
                />
              )}
            </Grid>
          )}
          <Grid size="grow" maxHeight="100%">
            <Box flexShrink={1} minHeight={0} height="100%">
              <Paper
                variant="outlined"
                sx={{
                  height: '100%',
                  overflow: 'auto',
                  borderRadius: isMobile ? 0 : undefined,
                  border: isMobile ? 'none' : undefined,
                }}
              >
                <Outlet />
              </Paper>
            </Box>
          </Grid>
        </Grid>
      </Stack>
    </Stack>
  );
}

export default memo(MainLayout);
