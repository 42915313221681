import { Button, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  ApiKeyPostData,
  useApiKeysQuery,
  useDeleteApiKeyMutation,
  useEditApiKeyMutation,
  useGenerateApiKeyMutation,
} from 'src/store/api/apiKeysApi';
import { useState } from 'react';
import { useConfirm } from 'material-ui-confirm';
import { WorkbaseAccountLockIcon } from 'src/assets/icons/workbaseIcons';
import Empty from 'src/ui-components/custom/empty/Empty';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import ApiKeysTable from './view/ApiKeysTable';
import ApiKeysHeader from './view/ApiKeysHeader';
import ApiKeyGenerationDialog from './view/dialogs/ApiKeyGenerationDialog';
import ApiKeyEditDialog, { ApiKeyEditData } from './view/dialogs/ApiKeyEditDialog';

export default function ApiKeys() {
  const { t } = useTranslation();
  const { data: apiKeysData, isLoading: isApiKeysDataLoading } = useApiKeysQuery();
  const [isGenerationDialogOpen, setIsGenerationDialogOpen] = useState(false);
  const confirm = useConfirm();
  const [editDialogData, setEditDialogData] = useState<ApiKeyEditData | null>(null);
  const [generateApiKey] = useGenerateApiKeyMutation();
  const [deleteApiKey] = useDeleteApiKeyMutation();
  const [editApiKey] = useEditApiKeyMutation();

  const handleDeleteApiKey = async (id: string, name: string) => {
    confirm({
      title: t('companySettings.apiKeys.deleteWarningTitle'),
      description: t('companySettings.apiKeys.deleteWarningText'),
      confirmationButtonProps: {
        color: 'error',
      },
      confirmationText: t('delete'),
      cancellationText: t('cancel'),
      cancellationButtonProps: {
        children: t('cancel'),
        color: 'inherit',
      },
    }).then(() => {
      deleteApiKey(id).then((res) => {
        if (!('error' in res)) {
          showNotification(t('companySettings.apiKeys.deleteSuccess', { name }), 'success');
        }
      });
    });
  };
  const handleEditApiKey = async (newName: string) => {
    if (editDialogData && newName !== editDialogData.name) {
      editApiKey({
        id: editDialogData.id,
        data: { name: newName },
      });
    }
    setEditDialogData(null);
  };

  const tableData = {
    rows: apiKeysData || [],
    columnNames: [
      t('companySettings.apiKeys.table.columnNames.keyName'),
      t('companySettings.apiKeys.table.columnNames.createdOn'),
      t('companySettings.apiKeys.table.columnNames.expireOn'),
      t('companySettings.apiKeys.table.columnNames.status'),
      t('companySettings.apiKeys.table.columnNames.actions'),
    ],
    onDelete: (id: string, name: string) => {
      handleDeleteApiKey(id, name);
    },
    onEdit: (id: string, name: string, expiredAt: string) => {
      setEditDialogData({ id, name, expiredAt });
    },
  };

  if (isApiKeysDataLoading) {
    return <LottieLogoSpinner sx={{ margin: 'auto' }} />;
  }
  const apiKeysExist = apiKeysData && apiKeysData.length > 0;
  return (
    <Stack height="100%" pb={2}>
      {editDialogData && (
        <ApiKeyEditDialog
          apiKeyData={editDialogData as ApiKeyEditData}
          onClose={() => setEditDialogData(null)}
          onSubmit={handleEditApiKey}
        />
      )}

      <ApiKeyGenerationDialog
        open={isGenerationDialogOpen}
        onSubmit={(data: ApiKeyPostData) => generateApiKey(data) as any}
        onClose={() => setIsGenerationDialogOpen(false)}
      />
      <Stack alignItems="center" width="100%" height="100%">
        <Stack
          gap={2}
          width="100%"
          height="100%"
          sx={{ maxWidth: ({ breakpoints }) => breakpoints.values.lg }}
        >
          <ApiKeysHeader
            displayButton={apiKeysExist}
            onGenerateApiKey={() => {
              setIsGenerationDialogOpen(true);
            }}
          />

          {!apiKeysExist ? (
            <Stack height="100%" alignItems="center" justifyContent="center">
              <Empty
                icon={WorkbaseAccountLockIcon}
                description={t('companySettings.apiKeys.noKeys')}
              >
                <Button
                  onClick={() => {
                    setIsGenerationDialogOpen(true);
                  }}
                >
                  {t('companySettings.apiKeys.generateApiKey')}
                </Button>
              </Empty>
            </Stack>
          ) : (
            <Stack overflow="hidden">
              <ApiKeysTable {...tableData} />
            </Stack>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
}
