import { Button, ButtonProps, Stack, SvgIcon } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { useAppDispatch, useAppSelector } from 'src/store';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseCloseCircleIcon,
} from 'src/assets/icons/workbaseIcons';
import {
  useDepartmentsQuery,
  usePostDepartmentDataMutation,
} from '../../../controller/departmentsApi';
import { setDepartments } from '../../../controller/departments.slice';
import { validateDepartments } from '../ValidateDepartments';

export default function SaveCancelButtons() {
  const { t } = useTranslation();
  const [postDepartmentData] = usePostDepartmentDataMutation();
  const { data } = useDepartmentsQuery();
  const dispatch = useAppDispatch();
  const { departmentsDraftState } = useAppSelector((state) => state.departments);

  const updatedDepartmentsJSON = JSON.stringify(departmentsDraftState);
  const originalDepartmentsJSON = JSON.stringify(data);
  const hasDepartmentsChanged = data && updatedDepartmentsJSON !== originalDepartmentsJSON;

  const handleCancelClick = () => {
    dispatch(setDepartments(data || []));
  };

  const handleSaveClick = () => {
    const isValid = validateDepartments(departmentsDraftState);

    if (!isValid) {
      showNotification(t('companySettings.sidebar.departments.removeLinkModal.saveError'), 'error');
    } else {
      const updatedDepartments = departmentsDraftState.map((department) => {
        const updatedRoles = department.roles.map((role, index) => ({
          ...role,
          order: index + 1,
        }));

        return {
          ...department,
          roles: updatedRoles,
        };
      });

      postDepartmentData(updatedDepartments).then((resp: any) => {
        let notificationKey = 'companySettings.sidebar.departments.removeLinkModal.saveSuccess';
        let notificationType: 'error' | 'success' = 'success';

        if ('error' in resp) {
          if (resp.error.status === 403) {
            notificationKey =
              'companySettings.sidebar.departments.removeLinkModal.ActionFailedPermissions';
          } else {
            notificationKey = 'companySettings.sidebar.departments.removeLinkModal.savingError';
          }
          notificationType = 'error';
        }

        showNotification(t(notificationKey), notificationType);
      });
    }
  };

  const buttonsData: Array<{
    color: ButtonProps['color'];
    text: string;
    icon: React.ComponentType;
    onClick: () => void;
  }> = [
    {
      color: 'error',
      text: t('editButton.cancel'),
      icon: WorkbaseCloseCircleIcon,
      onClick: handleCancelClick,
    },
    {
      color: 'success',
      text: t('editButton.save'),
      icon: WorkbaseCheckmarkBigFilledIcon,
      onClick: handleSaveClick,
    },
  ];

  return (
    <Stack>
      {hasDepartmentsChanged && (
        <Stack flexDirection="row" position="fixed" bottom="40px" right="40px" gap={1.5}>
          {buttonsData.map(({ color, text, icon, onClick }) => (
            <Button
              size="large"
              color={color}
              onClick={onClick}
              startIcon={<SvgIcon component={icon} />}
              key={text}
            >
              {text}
            </Button>
          ))}
        </Stack>
      )}
    </Stack>
  );
}
