import { TopBar } from 'src/ui-components/layout/top-bar';
import {
  WorkbaseCloseIcon,
  WorkbaseDeleteSquareIcon,
  WorkbaseExitFullscreenIcon,
  WorkbaseFullscreenIcon,
  WorkbaseNewChatIcon,
} from 'src/assets/icons/workbaseIcons';
import { useCallback, useState } from 'react';
import {
  useFetchThreadsQuery,
  useDeleteThreadMutation,
  useCreateThreadMutation,
  Thread,
} from 'src/store/api/aiChatApi';
import { throttle } from 'lodash';
import { NavigateFunction } from 'react-router-dom';
import {
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  SvgIcon,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import useIsMobile from 'src/hooks/useIsMobile';

interface ChatTopBarProps {
  dispatch: React.Dispatch<any>;
  navigate: NavigateFunction;
  isFullScreen: boolean;
  threadId: string;
  locationBeforeFullscreen: string | null;
}

export default function ChatTopBar({
  dispatch,
  navigate,
  isFullScreen,
  threadId,
  locationBeforeFullscreen,
}: ChatTopBarProps) {
  const [isLoading, setIsLoading] = useState(false);
  const { data: threads } = useFetchThreadsQuery();
  const [deleteThread] = useDeleteThreadMutation();
  const [createThread] = useCreateThreadMutation();
  const isMobile = useIsMobile();
  const deleteButtonDisabled =
    (threads && !(threads.length - 1) && !threads[0].summary) || isLoading || !threads?.length;

  const handleAddThread = async () => {
    const response = await createThread();
    if ('data' in response) {
      dispatch({ type: 'ADD_THREAD', payload: response.data });
      dispatch({ type: 'SET_SELECTED_CHAT', payload: response.data.id });
      if (isFullScreen) {
        navigate(`/ai/${response.data.id}`);
      }
    }
  };

  const handleToggleFullScreen = () => {
    if (isFullScreen) {
      dispatch({ type: 'CLOSE_FULLSCREEN' });
      if (locationBeforeFullscreen) {
        navigate(locationBeforeFullscreen);
        dispatch({ type: 'SET_LOCATION_BEFORE_FULLSCREEN', payload: null });
      } else {
        navigate('/', { replace: true });
      }
    } else {
      dispatch({ type: 'OPEN_FULLSCREEN' });
      dispatch({ type: 'SET_LOCATION_BEFORE_FULLSCREEN', payload: window.location.pathname });
      navigate(`/ai/${threadId}`, { replace: true });
    }
  };

  const handleCloseDialog = () => {
    if (isFullScreen) {
      handleToggleFullScreen();
    }
    dispatch({ type: 'CLOSE_DIALOG' });
  };
  const handleDeleteThread = async (id: string) => {
    if (!threads?.length) return;
    setIsLoading(true);
    const previousThreads = threads || [];
    const index = previousThreads.findIndex((thread: Thread) => thread.id === id);
    await deleteThread(id);
    if (index > 0) {
      dispatch({ type: 'SET_SELECTED_CHAT', payload: previousThreads[index - 1].id });
      if (isFullScreen) {
        navigate(`/ai/${previousThreads[index - 1].id}`);
      }
    } else if (index === 0 && previousThreads.length > 1) {
      dispatch({ type: 'SET_SELECTED_CHAT', payload: previousThreads[index + 1].id });
      if (isFullScreen) {
        navigate(`/ai/${previousThreads[index + 1].id}`);
      }
    } else if (previousThreads.length === 1) {
      await handleAddThread();
    }
    setIsLoading(false);
  };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const throttledHandleDeleteThread = useCallback(throttle(handleDeleteThread, 1000), [
    handleDeleteThread,
  ]);

  const handleSelectThread = (e: SelectChangeEvent<string | null>) => {
    if (e.target.value) {
      if (isMobile) {
        navigate(`/ai/${e.target.value}`);
      } else {
        dispatch({ type: 'SET_SELECTED_CHAT', payload: e.target.value });
      }
    }
  };

  const items = [
    {
      key: 'fullScreen',
      elementType: 'fullScreen',
      icon: isFullScreen ? WorkbaseExitFullscreenIcon : WorkbaseFullscreenIcon,
      onClick: handleToggleFullScreen,
    },
    {
      key: 'closeChat',
      elementType: 'closeChat',
      icon: WorkbaseCloseIcon,
      onClick: handleCloseDialog,
    },
  ];

  return (
    <Stack position="absolute" top={0} right={0} zIndex={2} width="100%">
      <TopBar
        rightContent={
          !isMobile && (
            <>
              {isFullScreen && (
                <IconButton
                  size="small"
                  onClick={() => throttledHandleDeleteThread(threadId)}
                  disabled={deleteButtonDisabled}
                >
                  <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="inherit" />
                </IconButton>
              )}
              {items.map((item) => (
                <IconButton key={item.key} onClick={item.onClick} size="small">
                  <SvgIcon component={item.icon} fontSize="inherit" />
                </IconButton>
              ))}
            </>
          )
        }
        leftContent={
          !isFullScreen && (
            <>
              <IconButton onClick={handleAddThread} size="small" disabled={isFullScreen}>
                <SvgIcon component={WorkbaseNewChatIcon} fontSize="inherit" />
              </IconButton>
              <IconButton
                size="small"
                onClick={() => throttledHandleDeleteThread(threadId)}
                disabled={deleteButtonDisabled}
              >
                <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="inherit" />
              </IconButton>
              <Stack
                minWidth={0}
                flexGrow={1}
                justifyContent="center"
                alignItems="center"
                direction="row"
              >
                <Select fullWidth size="small" value={threadId} onChange={handleSelectThread}>
                  {threads?.map((thread: Thread) => (
                    <MenuItem key={thread.id} value={thread.id}>
                      <Typography noWrap>
                        {thread.summary?.length > 0 ? thread.summary : t('assistant.newChat')}
                      </Typography>
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </>
          )
        }
      />
    </Stack>
  );
}
