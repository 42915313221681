import { Direction } from 're-resizable/lib/resizer';
import { InfoType } from 'src/features/editor/view/grid/widgets/edit-bar/InfoActions';
import { FileType } from 'src/features/editor/view/grid/widgets/edit-bar/UploadActions';
import { ColumnType } from 'src/features/editor/view/grid/widgets/highlight-wrapper/resize/ResizableWrapper';
import ensureHttpProtocol from 'src/lib/utils/ensureHttpProtocol';
import { UUID } from 'uuidjs';
import * as Y from 'yjs';
import {
  EditorData,
  EmbeddedItemContentType,
  GridItemType,
  ImageUploadType,
  TrainingType,
  VideoUploadType,
} from '../model';
import { INITIAL_HEIGHTS, MAX_COLUMN_VALUE } from '../model/constants';

export const yjsToEditorData = (yEditorData: any) => (): EditorData => ({
  id: yEditorData.get('id') as string,
  grid:
    (yEditorData.get('grid') as any)?.toArray().map((yRow: any) => ({
      id: yRow.get('id'),
      items: yRow
        .get('items')
        .toArray()
        .map((yItem: any) => ({
          id: yItem.get('id'),
          columnStart: yItem.get('columnStart'),
          columnEnd: yItem.get('columnEnd'),
          elementType: yItem.get('elementType'),
          content: yItem.get('content'),
          height: yItem.get('height'),
          infoType: yItem.get('infoType'),
          mediaType: yItem.get('mediaType'),
          imageId: yItem.get('imageId'),
          url: yItem.get('url'),
          fileName: yItem.get('fileName'),
          href: yItem.get('href'),
          caption: yItem.get('caption'),
          aspectRatio: yItem.get('aspectRatio'),
          title: yItem.get('title'),
          icon: yItem.get('icon'),
          selectedItems: yItem.get('selectedItems'),
          selectedAllItems: yItem.get('selectedAllItems'),
          videoPoster: yItem.get('videoPoster'),
          contentType: yItem.get('contentType'),
          mode: yItem.get('mode'),
          trainingsType: yItem.get('trainingsType'),
          isFastForwardDisabled: yItem.get('isFastForwardDisabled'),
          isVideoRequired: yItem.get('isVideoRequired'),
          videoId: yItem.get('videoId'),
          isVideoUploaded: yItem.get('isVideoUploaded'),
        })),
    })) || [],
});

export const editorDataToYjs = (yEditorData: any) => (editorData: EditorData) => {
  yEditorData.set('id', editorData.id);
  const yGridRows = new Y.Array();
  editorData.grid.forEach((row: any) => {
    const yRow = new Y.Map();
    yRow.set('id', row.id);
    const yItems = new Y.Array();
    row.items.forEach((item: any) => {
      const yItem = new Y.Map(Object.entries(item));
      yItems.push([yItem]);
    });
    yRow.set('items', yItems);
    yGridRows.push([yRow]);
  });

  yEditorData.set('grid', yGridRows);
};

// Finds the index of the target item in the yItems array. Returns -1 if not found.
export function findItemIndexInRow(yItems: any, targetItemId: string): number {
  return yItems.toArray().findIndex((item: any) => item.get('id') === targetItemId);
}

// Remove item by its index from yItems and return if the row is empty after removal.
export function removeItemFromRow(yItems: any, index: number): boolean {
  yItems.delete(index, 1);
  return yItems.length === 0;
}

export const handleRemoveItem =
  (ydoc: Y.Doc, yEditorData: any, origin?: any) => (itemId: string) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');

      gridArray.toArray().some((yRow: any, i: number) => {
        const yItems = yRow.get('items');
        const itemIndexToDelete = findItemIndexInRow(yItems, itemId);

        if (itemIndexToDelete === -1) return false; // Continue searching

        const isRowEmpty = removeItemFromRow(yItems, itemIndexToDelete);
        if (isRowEmpty) {
          gridArray.delete(i, 1);
        }
        return true; // Stop searching
      });
    }, origin);
  };

export const handleInfoTypeChange =
  (ydoc: Y.Doc, yEditorData: any, origin?: any) => (itemId: string) => (infoType: InfoType) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('infoType', infoType);
          return true;
        }
        return false;
      });
    }, origin);
  };

export const handleHrefChange =
  (ydoc: any, yEditorData: any, origin?: any) => (itemId: string) => (href: string) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('href', href && ensureHttpProtocol(href));
          return true;
        }
        return false;
      });
    }, origin);
  };

export const handleMediaTypeChange =
  (ydoc: Y.Doc, yEditorData: any, origin?: any) => (itemId: string) => (mediaType: FileType) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('mediaType', mediaType);
          return true;
        }
        return false;
      });
    }, origin);
  };

export const handleTrainingsTypeChange =
  (ydoc: any, yEditorData: any, origin?: any) =>
  (itemId: string) =>
  (trainingsType: TrainingType) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('trainingsType', trainingsType);
          return true;
        }
        return false;
      });
    }, origin);
  };

export const handleUpdateEmbeddedItem =
  (yDoc: any, yEditorData: any, origin?: any) =>
  (itemId: string) =>
  ({ content, contentType }: { content: string; contentType: EmbeddedItemContentType }) =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('content', content);
          yitem.set('contentType', contentType);
          return true;
        }
        return false;
      });
    }, origin);

export const handleSetImage =
  (yDoc: any, yEditorData: any, origin?: any) =>
  (itemId: string) =>
  ({ url, fileName, imageId }: ImageUploadType) =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('elementType', 'image');
          yitem.set('url', url);
          yitem.set('fileName', fileName);
          yitem.set('imageId', imageId);
          return true;
        }
        return false;
      });
    }, origin);

export const handleSetVideo =
  (yDoc: any, yEditorData: any, origin?: any) =>
  (itemId: string) =>
  ({ url, fileName, videoPoster, height, videoId, isVideoUploaded }: VideoUploadType) =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('elementType', 'video');
          yitem.set('url', url);
          yitem.set('fileName', fileName);
          yitem.set('videoPoster', videoPoster);
          yitem.set('height', height);
          yitem.set('videoId', videoId);
          yitem.set('isVideoUploaded', isVideoUploaded);
          return true;
        }
        return false;
      });
    }, origin);

export const handleUpdateVideoPoster =
  (yDoc: any, yEditorData: any, origin?: any) => (itemId: string) => (poster?: string) =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('videoPoster', poster);
          return true;
        }
        return false;
      });
    }, origin);

export const handleReplaceVideo =
  (yDoc: any, yEditorData: any) => (itemId: string) => (videoId: string, poster?: string) =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('url', null);
          yitem.set('videoId', videoId);
          yitem.set('videoPoster', poster);
          yitem.set('isVideoUploaded', false);
          return true;
        }
        return false;
      });
    });

export const handleUpdateVideoTitle =
  (yDoc: any, yEditorData: any, origin?: any) => (itemId: string) => (title: string) =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('title', title);
          return true;
        }
        return false;
      });
    }, origin);

export const handleDisableForwardVideo =
  (yDoc: any, yEditorData: any, origin?: any) =>
  (itemId: string) =>
  (isFastForwardDisabled: boolean) =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('isFastForwardDisabled', isFastForwardDisabled);
          return true;
        }
        return false;
      });
    }, origin);

export const handleRequiredVideo =
  (yDoc: any, yEditorData: any, origin?: any) => (itemId: string) => (isVideoRequired: boolean) =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('isVideoRequired', isVideoRequired);
          return true;
        }
        return false;
      });
    }, origin);

export const handleImageUpload =
  (yDoc: any, yEditorData: any, origin?: any) =>
  (itemId: string) =>
  (url: string, fileName: string, imageId: string) =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('url', url);
          yitem.set('fileName', fileName);
          yitem.set('imageId', imageId);
          return true;
        }
        return false;
      });
    }, origin);

export const handleEditorChange =
  (ydoc: Y.Doc, yEditorData: any, origin?: any) => (itemId: string) => (updatedContent: string) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray?.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const itemFound = yItems.toArray().some((yItem: any) => {
          if (yItem.get('id') === itemId) {
            yItem.set('content', updatedContent);
            return true; // Breaks the inner loop
          }
          return false;
        });
        return itemFound; // Breaks the outer loop if itemFound is true
      });
    }, origin);
  };

export const handleTrainingsTitleChange =
  (ydoc: any, yEditorData: any, origin?: any) => (itemId: string) => (updatedTitle: string) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray?.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const itemFound = yItems.toArray().some((yItem: any) => {
          if (yItem.get('id') === itemId) {
            yItem.set('title', updatedTitle);
            return true; // Breaks the inner loop
          }
          return false;
        });
        return itemFound; // Breaks the outer loop if itemFound is true
      });
    }, origin);
  };

export const handleTrainingsIconChange =
  (ydoc: any, yEditorData: any, origin?: any) => (itemId: string) => (updatedIcon: string) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray?.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const itemFound = yItems.toArray().some((yItem: any) => {
          if (yItem.get('id') === itemId) {
            yItem.set('icon', updatedIcon);
            return true; // Breaks the inner loop
          }
          return false;
        });
        return itemFound; // Breaks the outer loop if itemFound is true
      });
    }, origin);
  };

export const handleTrainingsSelectAllChange =
  (ydoc: any, yEditorData: any, origin?: any) => (itemId: string) => (selectAll: boolean) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray?.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const itemFound = yItems.toArray().some((yItem: any) => {
          if (yItem.get('id') === itemId) {
            yItem.set('selectedAllTrainings', selectAll);
            return true; // Breaks the inner loop
          }
          return false;
        });
        return itemFound; // Breaks the outer loop if itemFound is true
      });
    }, origin);
  };

export const handleSelectedTrainingsChange =
  (ydoc: any, yEditorData: any, origin?: any) => (itemId: string) => (selectedItems: string[]) => {
    ydoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray?.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');
        const itemFound = yItems.toArray().some((yItem: any) => {
          if (yItem.get('id') === itemId) {
            yItem.set('selectedItems', selectedItems);
            return true; // Breaks the inner loop
          }
          return false;
        });
        return itemFound; // Breaks the outer loop if itemFound is true
      });
    }, origin);
  };

export const handleAddItem =
  (ydoc: any, yEditorData: any, origin?: any) => (newItem: GridItemType) => {
    ydoc.transact(() => {
      const grid: any = yEditorData.get('grid');
      const newRow = new Y.Map();
      newRow.set('id', UUID.generate());
      const yItems = new Y.Array();
      const yNewItem = new Y.Map(Object.entries(newItem).map(([key, value]) => [key, value]));
      yNewItem.set('height', INITIAL_HEIGHTS[newItem.elementType as keyof typeof INITIAL_HEIGHTS]);
      yItems.push([yNewItem]);
      newRow.set('items', yItems);
      grid.push([newRow]);
    }, origin);
  };

const findItemInRow = (gridArray: any, itemId: string) => {
  let result: any = null;
  gridArray.toArray().forEach((yRow: any, index: number) => {
    if (result) return; // Skip further iterations if the item has been found
    const yItems = yRow.get('items');
    const itemIndex = findItemIndexInRow(yItems, itemId);
    if (itemIndex !== -1) {
      result = {
        itemToCopy: yItems.get(itemIndex),
        rowToCopyFrom: yRow,
        rowIndexToCopyFrom: index,
      };
    }
  });
  return result;
};

const findNextColumnStart = (rowToCopyFrom: any, itemToCopy: any) => {
  let nextColumnStart = itemToCopy.get('columnEnd');
  const sortedItems = rowToCopyFrom
    .get('items')
    .toArray()
    .sort((a: any, b: any) => a.get('columnStart') - b.get('columnStart'));

  sortedItems.forEach((item: any) => {
    if (
      (item.get('columnStart') <= nextColumnStart && item.get('columnEnd') > nextColumnStart) ||
      (itemToCopy.get('columnEnd') === 3 &&
        itemToCopy.get('columnStart') === 1 &&
        item.get('columnEnd') === 5)
    ) {
      nextColumnStart = item.get('columnEnd');
    }
  });
  return nextColumnStart;
};

// ------------------ Copying ------------------ //

const createCopiedItem = (itemToCopy: any, nextColumnStart: any) => ({
  id: UUID.generate(),
  columnStart: nextColumnStart,
  columnEnd: nextColumnStart + (itemToCopy.get('columnEnd') - itemToCopy.get('columnStart')),
  elementType: itemToCopy.get('elementType'),
  content: itemToCopy.get('content'),
  infoType: itemToCopy.get('infoType'),
  mediaType: itemToCopy.get('mediaType'),
  url: itemToCopy.get('url'),
  caption: itemToCopy.get('caption'),
  height: itemToCopy.get('height'),
  aspectRatio: itemToCopy.get('aspectRatio'),
  selectedItems: itemToCopy.get('selectedItems'),
  selectedAllTrainings: itemToCopy.get('selectedAllTrainings'),
  title: itemToCopy.get('title'),
  icon: itemToCopy.get('icon'),
  videoPoster: itemToCopy.get('videoPoster'),
  videoId: itemToCopy.get('videoId'),
  isVideoUploaded: itemToCopy.get('isVideoUploaded'),
  imageId: itemToCopy.get('imageId'),
  fileName: itemToCopy.get('fileName'),
  contentType: itemToCopy.get('contentType'),
  trainingsType: itemToCopy.get('trainingsType'),
  href: itemToCopy.get('href'),
});

const createNewRowWithItem = ({
  grid,
  rowIndex,
  itemToCopy,
  newItemProps,
}: {
  grid: any;
  rowIndex: number;
  itemToCopy: any;
  newItemProps: any;
}) => {
  const newRow = new Y.Map();
  newRow.set('id', UUID.generate());
  const yItems = new Y.Array();
  const itemWidth = itemToCopy.get('columnEnd') - itemToCopy.get('columnStart');
  // eslint-disable-next-line no-param-reassign
  newItemProps.columnStart = 1;
  // eslint-disable-next-line no-param-reassign
  newItemProps.columnEnd = itemWidth + 1;

  yItems.push([new Y.Map(Object.entries(newItemProps))]);
  newRow.set('items', yItems);
  grid.insert(rowIndex + 1, [newRow]);
};

const insertNewItem = ({
  newItemProperties,
  rowToCopyFrom,
  gridArray,
  rowIndexToCopyFrom,
  itemToCopy,
}: {
  newItemProperties: any;
  rowToCopyFrom: any;
  gridArray: any;
  rowIndexToCopyFrom: number;
  itemToCopy: any;
}) => {
  if (newItemProperties.columnEnd <= MAX_COLUMN_VALUE) {
    const yNewItem = new Y.Map(Object.entries(newItemProperties));
    let indexToInsert = null;
    rowToCopyFrom
      .get('items')
      .toArray()
      .some((yItem: any, index: number) => {
        if (yItem.get('columnStart') >= newItemProperties.columnEnd) {
          indexToInsert = index;
          return true;
        }
        return false;
      });
    if (indexToInsert === null) {
      indexToInsert = rowToCopyFrom.get('items').toArray().length;
    }
    rowToCopyFrom.get('items').insert(indexToInsert, [yNewItem]);
  } else {
    createNewRowWithItem({
      grid: gridArray,
      rowIndex: rowIndexToCopyFrom,
      itemToCopy,
      newItemProps: newItemProperties,
    });
  }
};

export const handleDuplicateItem =
  (ydoc: any, yEditorData: any, origin?: any) =>
  (itemId: string, overrides?: Partial<GridItemType>) => {
    ydoc.transact(() => {
      const gridArray = yEditorData.get('grid');
      const itemData = findItemInRow(gridArray, itemId);

      if (!itemData) {
        return;
      }

      const { itemToCopy, rowToCopyFrom, rowIndexToCopyFrom } = itemData;
      const nextColumnStart = findNextColumnStart(rowToCopyFrom, itemToCopy);
      const newItemProperties = { ...createCopiedItem(itemToCopy, nextColumnStart), ...overrides };

      insertNewItem({
        newItemProperties,
        rowToCopyFrom,
        gridArray,
        rowIndexToCopyFrom,
        itemToCopy,
      });
      setTimeout(() => {
        const lastItem = document.getElementById(newItemProperties.id);
        const editor = lastItem?.querySelector('.remirror-editor') as HTMLButtonElement | undefined;
        if (editor) return editor.focus();
        const button = lastItem?.querySelector('.editor-item-button') as
          | HTMLButtonElement
          | undefined;
        button?.focus();
        return undefined;
      });
    }, origin);
  };

// ------------------ Resize ------------------ //

const checkIfItemExistsInLeft = (yItems: any, itemIndex: number) => {
  const currentItem = yItems.get(itemIndex)?.toJSON();
  const previousItem = yItems.get(itemIndex - 1)?.toJSON();

  if (currentItem?.columnStart === 1) return true;

  return currentItem?.columnStart === previousItem?.columnEnd;
};

const moveToLeft = (yItems: any, itemIndex: number) => {
  const selectedItem = yItems.get(itemIndex)?.toJSON();
  if (!selectedItem || selectedItem?.columnStart === 1) return;

  yItems.get(itemIndex).set('columnEnd', selectedItem.columnEnd - 1);
  yItems.get(itemIndex).set('columnStart', selectedItem.columnStart - 1);
};

const moveOrResizeToLeft = (yItems: any, itemIndex: number) => {
  // space available
  const selectedItem = yItems.get(itemIndex)?.toJSON();

  if (!selectedItem) return;

  let hasItemInLeft = checkIfItemExistsInLeft(yItems, itemIndex);

  if (hasItemInLeft) {
    if (itemIndex !== 0) {
      moveOrResizeToLeft(yItems, itemIndex - 1);
    }
    hasItemInLeft = checkIfItemExistsInLeft(yItems, itemIndex);
    if (hasItemInLeft) {
      const isResizePossible = selectedItem.columnEnd - selectedItem.columnStart > 1;
      // eslint-disable-next-line max-depth
      if (isResizePossible) {
        yItems.get(itemIndex).set('columnEnd', selectedItem.columnEnd - 1);
      }
    } else {
      moveToLeft(yItems, itemIndex);
    }
  } else {
    moveToLeft(yItems, itemIndex);
  }
};

const moveOrResizeToRight = (yItems: any, itemIndex: number) => {
  // space available

  const selectedItem = yItems.get(itemIndex)?.toJSON();
  if (!selectedItem) return;

  // eslint-disable-next-line @typescript-eslint/no-use-before-define
  let hasItemInRight = checkIfItemExistsInRight(yItems, itemIndex);

  if (hasItemInRight) {
    if (
      !(itemIndex === 3) &&
      !(itemIndex >= yItems.toArray().length - 1) &&
      selectedItem.columnEnd !== 5
    ) {
      moveOrResizeToRight(yItems, itemIndex + 1);
    }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    hasItemInRight = checkIfItemExistsInRight(yItems, itemIndex);
    if (hasItemInRight) {
      const isResizePossible = selectedItem.columnEnd - selectedItem.columnStart > 1;
      // eslint-disable-next-line max-depth
      if (isResizePossible) {
        yItems.get(itemIndex).set('columnStart', selectedItem.columnStart + 1);
      }
    } else {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      moveToRight(yItems, itemIndex);
    }
  } else {
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    moveToRight(yItems, itemIndex);
  }
};

const checkIfItemExistsInRight = (yItems: any, itemIndex: number) => {
  // if (itemIndex === 3) return true;

  const currentItem = yItems.get(itemIndex)?.toJSON();
  const nextItem = yItems.get(itemIndex + 1)?.toJSON();

  if (currentItem?.columnEnd === 5) return true;

  return currentItem?.columnEnd === nextItem?.columnStart;
};

const moveToRight = (yItems: any, itemIndex: number) => {
  const selectedItem = yItems.get(itemIndex)?.toJSON();
  if (!selectedItem || selectedItem.columnEnd === 5) return;

  yItems.get(itemIndex).set('columnEnd', selectedItem.columnEnd + 1);
  yItems.get(itemIndex).set('columnStart', selectedItem.columnStart + 1);
};

export const handleResizeItem =
  (ydoc: any, yEditorData: any, origin?: any) =>
  (itemId: string) =>
  ({
    column,
    direction,
    height,
  }: {
    column: ColumnType;
    direction: Direction;
    height?: number;
  }) => {
    ydoc.transact(() => {
      const gridArray = yEditorData.get('grid');

      // eslint-disable-next-line max-statements
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const itemIndexToResize = findItemIndexInRow(yItems, itemId);
        if (itemIndexToResize === -1) return false; // Continue searching

        const item = yItems.get(itemIndexToResize);

        if (!item) return false;

        item.set('height', height);
        if (yItems.toArray().length === 4) return true;

        // left
        if (direction === 'left' || direction === 'bottomLeft') {
          let difference = item.get('columnStart') - column;

          if (difference === 0) return true;

          if (difference < 0) {
            const newColumn = column >= item.get('columnEnd') ? item.get('columnEnd') - 1 : column;
            item.set('columnStart', newColumn);
            return true;
          }

          do {
            let hasItemInLeft = checkIfItemExistsInLeft(yItems, itemIndexToResize);
            // eslint-disable-next-line max-depth
            if (hasItemInLeft) {
              moveOrResizeToLeft(yItems, itemIndexToResize - 1);
            }
            hasItemInLeft = checkIfItemExistsInLeft(yItems, itemIndexToResize);
            // eslint-disable-next-line max-depth
            if (!hasItemInLeft) {
              item.set('columnStart', item.get('columnStart') - 1);
            } else {
              break;
            }

            difference -= 1;
          } while (difference > 0);

          return true;
        }

        // right
        if (direction === 'right' || direction === 'bottomRight') {
          let difference = column + 1 - item.get('columnEnd');

          if (difference === 0) return true;

          if (difference < 0) {
            const newColumn =
              column <= item.get('columnStart') ? item.get('columnStart') + 1 : column + 1;
            item.set('columnEnd', newColumn);
            return true;
          }

          do {
            let hasItemInRight = checkIfItemExistsInRight(yItems, itemIndexToResize);

            // eslint-disable-next-line max-depth
            if (hasItemInRight) {
              moveOrResizeToRight(yItems, itemIndexToResize + 1);
            }
            hasItemInRight = checkIfItemExistsInRight(yItems, itemIndexToResize);
            // eslint-disable-next-line max-depth
            if (!hasItemInRight) {
              item.set('columnEnd', item.get('columnEnd') + 1);
            } else {
              break;
            }

            difference -= 1;
          } while (difference > 0);
          return true;
        }

        return true; // Stop searching
      });
    }, origin);
  };

// ------------------ Drag and drop ------------------ //

export const moveItemToEndAndExpand =
  (ydoc: any, yEditorData: any, origin?: any) => (itemId: string) => {
    // Find and remove the item
    ydoc.transact(() => {
      let itemToMove: any;
      yEditorData
        .get('grid')
        .toArray()
        .some((yRow: any, rowIndex: number) => {
          const yItems = yRow.get('items');
          const itemIndex = findItemIndexInRow(yItems, itemId);

          if (itemIndex !== -1) {
            itemToMove = yItems.get(itemIndex).toJSON(); // Capture item data
            const isRowEmpty = removeItemFromRow(yItems, itemIndex);

            // Remove the row if it's empty
            if (isRowEmpty) {
              yEditorData.get('grid').delete(rowIndex, 1);
            }

            return true; // Stop iteration
          }
          return false;
        });

      if (!itemToMove) {
        return;
      }

      // Add the item to the end of the grid
      const grid = yEditorData.get('grid');
      const newRow = new Y.Map();
      newRow.set('id', UUID.generate());

      // Set the item to full width
      itemToMove.columnStart = 1;
      itemToMove.columnEnd = 5;

      const yNewItem = new Y.Map(Object.entries(itemToMove));
      const yItems = new Y.Array();
      yItems.push([yNewItem]);
      newRow.set('items', yItems);
      grid.push([newRow]);
    }, origin);
  };

export const moveItemToNewRow =
  (ydoc: any, yEditorData: any, origin?: any) => (itemId: string, newRowPosition: number) => {
    ydoc.transact(() => {
      let itemToMove: any;
      let originalRowIndex = -1;
      let wasRowDeleted = false;
      // Find and remove the item
      yEditorData
        .get('grid')
        .toArray()
        .some((yRow: any, rowIndex: number) => {
          const yItems = yRow.get('items');
          const itemIndex = findItemIndexInRow(yItems, itemId);

          if (itemIndex !== -1) {
            itemToMove = yItems.get(itemIndex).toJSON(); // Capture item data
            originalRowIndex = rowIndex;
            const isRowEmpty = removeItemFromRow(yItems, itemIndex);

            // Remove the row if it's empty
            if (isRowEmpty) {
              yEditorData.get('grid').delete(rowIndex, 1);
              wasRowDeleted = true;
            }

            return true; // Stop iteration
          }
          return false;
        });

      if (!itemToMove) {
        return;
      }

      // Adjust newRowPosition if moving from higher to lower
      if (originalRowIndex !== -1 && originalRowIndex < newRowPosition && wasRowDeleted) {
        // eslint-disable-next-line
        newRowPosition -= 1;
      }

      // Prepare the item for the new position no-param-reassign
      itemToMove.columnStart = 1;
      itemToMove.columnEnd = 5;

      const yNewItem = new Y.Map(Object.entries(itemToMove));

      // Create a new row with the item
      const newRow = new Y.Map();
      newRow.set('id', UUID.generate());
      const yItems = new Y.Array();
      yItems.push([yNewItem]);
      newRow.set('items', yItems);

      // Insert the new row at the specified position
      const grid = yEditorData.get('grid');
      grid.insert(newRowPosition, [newRow]);
    }, origin);
  };

export const handleUndoCrop =
  (yDoc: any, yEditorData: any, origin?: any) => (itemId: string) => () =>
    yDoc.transact(() => {
      const gridArray: any = yEditorData.get('grid');
      gridArray.toArray().some((yRow: any) => {
        const yItems = yRow.get('items');

        const yitem = yItems.toArray().find((item: any) => item.get('id') === itemId);
        if (yitem) {
          yitem.set('height', undefined);
          return true;
        }
        return false;
      });
    }, origin);
