import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Container, ListItemIcon, SvgIcon } from '@mui/material';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import {
  WorkbaseCreditcardIcon,
  WorkbaseDomainIcon,
  WorkbaseMotivationIcon,
  WorkbaseDepartmentsIcon,
  WorkbaseCloudKeyIcon,
  WorkbaseAIIcon,
} from 'src/assets/icons/workbaseIcons';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { SidebarItem } from 'src/ui-components/layout/sidebar/types';
import SplitPaneLayout from 'src/layouts/SplitPaneLayout';
import Sidebar from 'src/ui-components/layout/sidebar/Sidebar';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import AssistantSettings from 'src/features/company-settings/ai-assistant/AssistantSettings';
import SiteInfo from '../../features/company-settings/site-info/view/SiteInfo';
import EmployeeRecommendations from '../../features/company-settings/employee-recommendations';
import Departments from '../../features/company-settings/departments';
import { useRouteChecker } from './useRouteChecker';
import Billing, {
  handleOpenBillingSettingsPopup,
} from '../../features/company-settings/billing/view/Billing';
import { ApiKeys } from '../../features/company-settings/api-keys';

export default function CompanySettingsView() {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const { data: currentUserData, isSuccess: isCurrentUserDataSuccess } = useCurrentUserQuery();
  const hasAnyPermission = useHasAnyPermission();
  const hasManageDepartmentsPermission = hasAnyPermission(['MANAGE_DEPARTMENTS']);
  const hasManageAllCompanySettingsPermission = hasAnyPermission(['MANAGE_ALL_COMPANY_SETTINGS']);
  const hasManageBillingPermission = hasAnyPermission(['MANAGE_BILLING']);

  const memoizedGroups = useMemo(() => {
    const groupsData: SidebarItem[] = [
      {
        key: 'site-information',
        text: t('companySettings.siteInformation.header'),
        subtext: t('companySettings.siteInformation.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseDomainIcon} />
          </ListItemIcon>
        ),
        onClick: () => navigate('site-information', { relative: 'route' }),
        hide: !hasManageAllCompanySettingsPermission,
      },
      {
        key: 'employee-recommendations',
        text: t('companySettings.employeeRecommendations.header'),
        subtext: t('companySettings.employeeRecommendations.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseMotivationIcon} />
          </ListItemIcon>
        ),
        onClick: () => navigate('employee-recommendations', { relative: 'route' }),
        hide: !hasManageAllCompanySettingsPermission,
      },
      {
        key: 'departments',
        text: t('companySettings.sidebar.departments.header'),
        subtext: t('companySettings.sidebar.departments.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseDepartmentsIcon} />
          </ListItemIcon>
        ),
        onClick: () => navigate('departments', { relative: 'route' }),
        hide: !hasManageDepartmentsPermission,
      },
      {
        key: 'api-keys',
        text: t('companySettings.apiKeys.header'),
        subtext: t('companySettings.apiKeys.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseCloudKeyIcon} />
          </ListItemIcon>
        ),
        onClick: () => navigate('api-keys', { relative: 'route' }),
        hide: isCurrentUserDataSuccess ? !currentUserData.isOwner : false,
      },
      {
        key: 'assistant',
        text: t('companySettings.assistant.header'),
        subtext: t('companySettings.assistant.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseAIIcon} />
          </ListItemIcon>
        ),
        onClick: () => navigate('assistant', { relative: 'route' }),
        hide: isCurrentUserDataSuccess ? !currentUserData.isOwner : false,
      },
      {
        key: 'billing',
        text: t('companySettings.sidebar.billing.header'),
        subtext: t('companySettings.sidebar.billing.subText'),
        prefix: (
          <ListItemIcon>
            <SvgIcon component={WorkbaseCreditcardIcon} />
          </ListItemIcon>
        ),
        onClick: () => handleOpenBillingSettingsPopup(),
        hide: !hasManageBillingPermission,
      },
    ];

    return groupsData;
  }, [
    hasManageDepartmentsPermission,
    hasManageAllCompanySettingsPermission,
    hasManageBillingPermission,
    isCurrentUserDataSuccess,
    currentUserData?.isOwner,
    navigate,
    t,
  ]);

  useRouteChecker(memoizedGroups);

  const pathArray = pathname.split('/');
  const activeKey = pathArray[pathArray.length - 1];

  return (
    <SplitPaneLayout
      leftContent={
        <Sidebar activeKey={activeKey} items={memoizedGroups} dataCy="settings-sidebar" />
      }
      rightContent={
        <Container maxWidth="lg" component={Box} height="100%" py={2} overflow="auto">
          <Routes>
            <Route
              path="site-information"
              element={hasManageAllCompanySettingsPermission && <SiteInfo />}
            />
            <Route path="employee-recommendations" element={<EmployeeRecommendations />} />
            <Route path="departments" element={<Departments />} />
            <Route path="api-keys" element={<ApiKeys />} />
            <Route path="assistant" element={<AssistantSettings />} />
            <Route path="billing" element={<Billing />} />
            <Route path="*" element={<Navigate to="/settings/site-information" replace />} />
          </Routes>
        </Container>
      }
    />
  );
}
