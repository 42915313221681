import { Avatar, Stack } from '@mui/material';
import { Employee } from 'src/features/employees/model';
import { ChangeEvent } from 'react';
import { imageUpload } from 'src/lib/utils/imageUpload';
import { t } from 'i18next';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import EditableImageUpload from 'src/ui-components/custom/image-edit/EditableImageUpload';

interface Props {
  employee?: Employee;
  handleUpdateEmployee: (val: Partial<Employee>) => void;
}

export default function EditAvatar({ employee, handleUpdateEmployee }: Props) {
  const handleFileChange = async (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (file) {
      try {
        const response = await imageUpload(file);
        const { id, variants } = response.data.result;
        await handleUpdateEmployee({ avatarId: id, avatarUrl: variants[0] });
      } catch (err) {
        showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
      }
    }
  };

  const removeAvatar = async (): Promise<void> => {
    await handleUpdateEmployee({ avatarId: null, avatarUrl: '' });
  };

  return (
    <Stack
      gap={1.5}
      flexDirection="row"
      alignItems="center"
      width={80}
      height={80}
      minWidth={64}
      flexShrink={0}
    >
      <EditableImageUpload
        dataCy="upload-logo"
        src={employee && employee.avatarUrl}
        onChange={handleFileChange}
        onClear={removeAvatar}
        placeholder={<Avatar variant="rounded" sx={{ width: 80, height: 80 }} />}
      />
    </Stack>
  );
}
