import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { Button, Typography, Stack } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import { WorkbaseErrorLottie } from 'src/assets/lottie';
import { ReactNode } from 'react';
import { NODE_ENV } from 'src/global-config';

interface WorkbaseErrorBoundaryProps {
  children: ReactNode;
}

interface ErrorFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onBackToDashboard = () => {
    resetErrorBoundary();
    navigate('/');
  };

  return (
    <Stack
      role="alert"
      textAlign="center"
      height="100vh"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      gap={2}
    >
      <Lottie style={{ width: '5%', transform: 'scale(2)' }} animationData={WorkbaseErrorLottie} />
      <Stack>
        <Typography variant="h4" color="error">
          {t('errorBoundary.somethingWentWrong')}
        </Typography>
        <Typography variant="h6" fontWeight="bold">
          {error.message}
        </Typography>
        {NODE_ENV !== 'production' &&
          error.stack?.split('\n').map((line) => (
            <Typography key={line} variant="body2" color="textSecondary">
              {line}
            </Typography>
          ))}
      </Stack>
      <Button variant="contained" color="primary" onClick={onBackToDashboard}>
        {t('errorBoundary.backToDashboard')}
      </Button>
    </Stack>
  );
}

export default function ErrorLayout({ children }: WorkbaseErrorBoundaryProps) {
  return <ReactErrorBoundary FallbackComponent={ErrorFallback}>{children}</ReactErrorBoundary>;
}
