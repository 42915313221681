import { useDispatch } from 'react-redux';
import { TextField } from '@mui/material';
import { t } from 'i18next';
import { Employee } from 'src/features/employees/model';
import checkIfCountryCode from 'src/lib/utils/checkIfCountryCode';
import { updateEmployeeDetails } from 'src/features/employees/controller/employee.slice';
import { isValidPhoneNumber } from 'libphonenumber-js';
import EmployeeEmailTextField from '../EmployeeEmailTextField';
import EmployeeFormSection from './EmployeeFormSection';

interface Props {
  items: {
    key: string;
    text: string;
    label: string;
  }[];
  employee: Employee;
}

export default function ContactInformationSection({ items, employee }: Props) {
  const dispatch = useDispatch();
  return (
    <EmployeeFormSection>
      {items.map(({ key, text, label }) => {
        if (key === 'email') {
          return (
            <EmployeeEmailTextField
              employeeId={employee.id}
              dataCy="employee-information-email-selector"
              value={text}
              label={label}
              onChange={(val) => {
                dispatch(updateEmployeeDetails({ keyPath: 'email', value: val }));
              }}
            />
          );
        }
        if (key === 'phoneNumber') {
          return (
            <TextField
              data-cy="employee-information-phone-selector"
              value={text}
              label={label}
              onChange={(e) =>
                dispatch(updateEmployeeDetails({ keyPath: 'phoneNumber', value: e.target.value }))
              }
              fullWidth
              error={text !== '' && !isValidPhoneNumber(text)}
              helperText={
                text !== '' &&
                !isValidPhoneNumber(text) &&
                t('validationErrorMessages.phoneErrorMsg') +
                  (checkIfCountryCode(text)
                    ? ''
                    : ` ${t('validationErrorMessages.missingCountryCode')}`)
              }
              size="small"
            />
          );
        }
        if (key === 'location') {
          return (
            <TextField
              data-сy="employee-information-location-selector"
              value={text}
              label={label}
              onChange={(e) =>
                dispatch(updateEmployeeDetails({ keyPath: 'location', value: e.target.value }))
              }
              fullWidth
              size="small"
            />
          );
        }

        return null;
      })}
    </EmployeeFormSection>
  );
}
