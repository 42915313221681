import { Fragment } from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControlLabel,
  IconButton,
  SvgIcon,
  Tooltip,
} from '@mui/material';
import { t } from 'i18next';
import {
  WorkbaseDownloadIcon,
  WorkbaseImageFrameIcon,
  WorkbaseImageNewIcon,
  WorkbaseRecordIcon,
  WorkbaseVideoUploadIcon,
} from '../../../../../../assets/icons/workbaseIcons';

export interface VideoActionsProps {
  onUploadVideo: () => void;
  onUploadPosterFromCurrentFrame: () => void;
  onUploadPoster: () => void;
  onVideoDownload: () => void;
  onOpenRecordingModal: () => void;
  onDisableFastForward?: () => void;
  onRequired?: () => void;
  disableFastForward?: boolean;
  showRequired?: boolean;
  isVideoRequired?: boolean;
  downloadDisabled: boolean;
}

export default function VideoActions({
  onUploadPoster,
  onUploadPosterFromCurrentFrame,
  onUploadVideo,
  onVideoDownload,
  onDisableFastForward,
  onRequired,
  isVideoRequired,
  disableFastForward,
  showRequired = false,
  onOpenRecordingModal,
  downloadDisabled,
}: VideoActionsProps) {
  const items = [
    {
      key: 'current-frame-as-thumbnail',
      onClick: () => onUploadPosterFromCurrentFrame(),
      icon: WorkbaseImageFrameIcon,
    },
    {
      key: 'upload-thumbnail',
      onClick: () => onUploadPoster(),
      icon: WorkbaseImageNewIcon,
      divider: true,
    },
    {
      key: 'new-video',
      onClick: () => onUploadVideo(),
      icon: WorkbaseVideoUploadIcon,
    },
    {
      key: 'record-video',
      onClick: onOpenRecordingModal,
      icon: WorkbaseRecordIcon,
    },
    {
      key: 'download-video',
      onClick: () => onVideoDownload(),
      icon: WorkbaseDownloadIcon,
      divider: showRequired,
      disabled: downloadDisabled,
    },
  ];

  return (
    <>
      {items.map(({ key, onClick, icon, divider, disabled }) => (
        <Fragment key={`item-${key}`}>
          <Tooltip title={t(`editor.topBar.tooltip.${key}`)} placement="top">
            <Box>
              <IconButton
                disabled={disabled}
                onMouseDown={(e) => {
                  e.preventDefault();
                  onClick();
                }}
              >
                <SvgIcon component={icon} fontSize="small" />
              </IconButton>
            </Box>
          </Tooltip>
          {divider && <Divider orientation="vertical" flexItem />}
        </Fragment>
      ))}
      {showRequired && (
        <>
          <FormControlLabel
            sx={{ mr: 1 }}
            onClick={(e) => {
              if (onRequired) {
                onRequired();
                e.stopPropagation();
                e.preventDefault();
              }
            }}
            label={t('editor.videoItem.required')}
            control={<Checkbox onChange={onRequired} checked={isVideoRequired} />}
          />
          <Divider orientation="vertical" flexItem />
          <FormControlLabel
            sx={{ mr: 1 }}
            onClick={(e) => {
              if (onDisableFastForward) {
                onDisableFastForward();
                e.stopPropagation();
                e.preventDefault();
              }
            }}
            label={t('editor.videoItem.disableFastForward')}
            control={<Checkbox onChange={onDisableFastForward} checked={disableFastForward} />}
          />
        </>
      )}
    </>
  );
}
