import { Box, InputAdornment, Stack, SvgIcon, TextFieldProps } from '@mui/material';
import { ChangeEvent, useCallback, useState } from 'react';
import useDebouncedValue from 'src/hooks/useDebouncedValue';
import { WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';
import IconOrEmojiSelector from '../custom/icon-selector';
import ContrastTextField from '../custom/textfield/contrast';
import IconText from './IconText';

interface Props {
  isEditable?: boolean;
  icon: string;
  text?: string;
  placeholder: string;
  onTextChange: (val: string) => void;
  onIconChange: (val: string) => void;
  textFieldProps?: TextFieldProps;
}

export default function ContrastEditableIconText({
  isEditable = true,
  icon,
  text,
  placeholder,
  onTextChange,
  onIconChange,
  textFieldProps,
}: Props) {
  const [wasFocused, setWasFocused] = useState(false);
  const handleTextChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onTextChange?.(e.target.value);
    },
    [onTextChange]
  );
  const { value, handleChange } = useDebouncedValue(text, handleTextChange);
  const readOnlyContent = <IconText icon={icon} text={text || placeholder} />;
  const editContent = (
    <Stack direction="row" gap={2} alignItems="center">
      <IconOrEmojiSelector value={icon} onChange={onIconChange} />
      <ContrastTextField
        onFocus={() => setWasFocused(true)}
        slotProps={{
          input: {
            endAdornment: (
              <InputAdornment position="end">
                <Box color="primary.contrastText" display="flex">
                  <SvgIcon component={WorkbaseEditIcon} color="inherit" />
                </Box>
              </InputAdornment>
            ),
          },
        }}
        placeholder={placeholder}
        autoFocus={!wasFocused}
        value={value}
        onChange={handleChange}
        {...textFieldProps}
      />
    </Stack>
  );
  return isEditable ? editContent : readOnlyContent;
}
