import { Box, Link } from '@mui/material';
import useThemeLogos from 'src/hooks/useThemeLogos';
import { Link as ReactRouterLink } from 'react-router-dom';
import Image from 'src/ui-components/image';

interface Props {
  fullWidth: boolean;
}

export default function AppLogo({ fullWidth }: Props) {
  const { logo, favicon } = useThemeLogos();
  const imgData = fullWidth ? logo : favicon;

  return (
    <Box width={fullWidth ? 180 : 48} flexShrink={0}>
      <Link to="/" component={ReactRouterLink} data-cy="app-logo">
        <Image height={48} fit="contain" src={imgData.src} alt={imgData.alt} />
      </Link>
    </Box>
  );
}
