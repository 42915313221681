import { Box, Button, Checkbox, FormControlLabel, Stack, SvgIcon, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkbaseAddIcon } from 'src/assets/icons/workbaseIcons';
import { useAppDispatch, useAppSelector } from 'src/store';
import { SearchTextField } from 'src/ui-components/custom/textfield/search';
import { checkRoles, clearCheckedRoles, uncheckRoles } from '../controller/departments.slice';
import { Department } from '../model/types';

interface DepartmentsHeaderProps {
  handleAddNewDepartment: () => void;
  setSearchVal: (val: string) => void;
  filteredDepartments: Department[];
  searchVal: string;
}

export default function DepartmentsHeader({
  handleAddNewDepartment,
  setSearchVal,
  filteredDepartments,
  searchVal,
}: DepartmentsHeaderProps) {
  const { t } = useTranslation();

  const dispatch = useAppDispatch();
  const checkedRoles = useAppSelector((state) => state.departments.checkedRoles);

  const handleSearchValChange = (val: string) => setSearchVal(val);

  const isEveryRoleChecked = filteredDepartments.length
    ? filteredDepartments.every((department) =>
        department?.roles.every((role) => checkedRoles.includes(role.id))
      )
    : false;

  useEffect(
    () => () => {
      dispatch(clearCheckedRoles());
    },
    [dispatch]
  );

  return (
    <>
      <Stack direction="row" justifyContent="space-between" alignItems="baseline">
        <Typography variant="h4" fontWeight="bold">
          {t('companySettings.sidebar.departments.header')}
        </Typography>
        <Button
          data-cy="add-new-department-button"
          startIcon={<SvgIcon component={WorkbaseAddIcon} />}
          onClick={handleAddNewDepartment}
        >
          {t('companySettings.sidebar.departments.departmentsAddButton')}
        </Button>
      </Stack>
      <Stack direction="row" gap={2}>
        <Box>
          <FormControlLabel
            slotProps={{
              typography: {
                noWrap: true,
              },
            }}
            control={
              <Checkbox
                data-cy="is-all-select-checkbox"
                checked={isEveryRoleChecked}
                onChange={() => {
                  if (isEveryRoleChecked) {
                    dispatch(
                      uncheckRoles(
                        filteredDepartments.flatMap((department) =>
                          department.roles.map((role) => role.id)
                        )
                      )
                    );
                  } else {
                    dispatch(
                      checkRoles(
                        filteredDepartments.flatMap((department) =>
                          department.roles.map((role) => role.id)
                        )
                      )
                    );
                  }
                }}
              />
            }
            label={t('selectAllButton')}
          />
        </Box>
        <SearchTextField
          size="small"
          data-cy="department-and-roles-search-textfield"
          fullWidth
          value={searchVal}
          onChange={(e) => handleSearchValChange(e.target.value)}
        />
      </Stack>
    </>
  );
}
