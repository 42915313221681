import { Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import useIsMobile from 'src/hooks/useIsMobile';
import EditButton from 'src/ui-components/edit-button/EditButton';

interface Props {
  children: (isEditMode: boolean) => JSX.Element;
  switchButtonVisible?: boolean;
}

function ModeSwitch({ children, switchButtonVisible = true }: Props) {
  const { pathname } = useLocation();
  const isEditPath = pathname.endsWith('/edit');
  const isMobile = useIsMobile();
  return (
    <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
      {children(isEditPath)}
      {switchButtonVisible && !isMobile && (
        <Box position="fixed" right={40} bottom={40} zIndex={10}>
          <EditButton />
        </Box>
      )}
    </Box>
  );
}

export default ModeSwitch;
