import { Breadcrumbs, Button } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useParams, Link as RouterLink } from 'react-router-dom';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useTrainingLessonQuery } from 'src/store/api/trainings/trainingLessonsApi';
import { useGetSingleTrainingQuery } from 'src/store/api/trainings/trainingsApi';

interface Props {
  moduleName: string;
  trainingId: string;
  moduleId: string;
  dataCy: string;
}

export default function ModuleOverviewBreadcrumbs({
  moduleName,
  trainingId,
  moduleId,
  dataCy,
}: Props) {
  const { t } = useTranslation();
  const params = useParams();
  const { lessonId } = params;
  const { data: trainingData } = useGetSingleTrainingQuery(trainingId);
  const { data: lessonData } = useTrainingLessonQuery(lessonId as string, {
    skip: lessonId === undefined,
  });

  const path = useMaintainEditPath();

  const lessonItem = lessonId
    ? [
        {
          key: 'lesson',
          label: lessonData?.name || t('modulePage.unnamedLesson'),
          to: `/trainings/${trainingId}/${moduleId}/${lessonData?.trainingSectionId}/${lessonId}`,
        },
      ]
    : [];

  const trainingsItem = !lessonId
    ? [
        {
          key: 'trainings',
          label: t('sidebar.tabTitleTrainings'),
          to: `/trainings${path}`,
        },
      ]
    : [];

  const data = [
    ...trainingsItem,
    {
      key: 'training',
      label: trainingData?.name || t('trainingDetailPage.unnamedTraining'),
      to: `/trainings/${trainingId}${path}`,
    },
    {
      key: 'module',
      label: moduleName || t('trainingDetailPage.unnamedModule'),
      to: `/trainings/${trainingId}/${moduleId}${path}`,
    },
    ...lessonItem,
  ];

  return (
    <Breadcrumbs>
      {data.map(({ key, label, to }) => (
        <Button
          key={key}
          variant="text"
          size="medium"
          color="inherit"
          component={RouterLink}
          to={to}
          data-cy={dataCy && `${dataCy}-${key}`}
        >
          {label}
        </Button>
      ))}
    </Breadcrumbs>
  );
}
