import {
  MediaPlayer,
  MediaPlayerInstance,
  MediaPlayRequestEvent,
  MediaProvider,
  MediaTimeUpdateEventDetail,
  Poster,
} from '@vidstack/react';
import {
  DefaultBufferingIndicator,
  defaultLayoutIcons,
  DefaultVideoLayout,
} from '@vidstack/react/player/layouts/default';
import '@vidstack/react/player/styles/default/theme.css';
import '@vidstack/react/player/styles/default/layouts/video.css';
import { Stack, styled, Typography, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VideoProgress } from 'src/store/api/trainings/videoProgressApi';
import { useEffect, useRef } from 'react';
import useDefaultLayoutTranslations from './useDefaultLayoutTranslations';

interface Props {
  src: string | null;
  title?: string;
  poster: string;
  watchedDuration?: number;
  onVideoProgress?: (data: Partial<VideoProgress>) => void;
  disabledFastForward?: boolean;
  canPlay?: boolean;
}

const StyledMediaPlayer = styled(MediaPlayer)<{ $progress?: number }>`
  border: none !important;
  video,
  img {
    object-fit: cover;
    height: 100%;
  }
  .vds-settings-menu-items {
    border-radius: ${({ theme }) => theme.shape.borderRadius * 2}px;
    .vds-menu-item-label {
      font-family: Base !important;
      font-weight: 500 !important;
      font-size: 16px;
    }
    .vds-menu-item-icon {
      width: 20px !important;
      height: 20px !important;
    }
    .vds-menu-item {
      border-radius: ${({ theme }) => theme.shape.borderRadius}px;
    }
  }

  ${({ $progress }) =>
    $progress
      ? `.vds-time-slider .vds-slider-progress {
      width: ${$progress * 100}% !important;
    }`
      : ''}
`;

export default function VidstackPlayer({
  src,
  title,
  poster,
  onVideoProgress,
  disabledFastForward,
  watchedDuration,
  canPlay = true,
}: Props) {
  const { t } = useTranslation();
  const playerRef = useRef<MediaPlayerInstance>(null);
  const currentWatchedDurationRef = useRef<number>(watchedDuration || 0);
  const canPlayVideoRef = useRef<boolean>(canPlay);
  const translations = useDefaultLayoutTranslations();
  const { palette } = useTheme();
  useEffect(() => {
    if (playerRef.current && watchedDuration !== undefined) {
      playerRef.current.currentTime = watchedDuration;
    }
  }, [watchedDuration]);

  const handleTimeUpdate = (detail: MediaTimeUpdateEventDetail) => {
    const { currentTime } = detail;
    if (currentTime > currentWatchedDurationRef.current) {
      const maxSeekDuration = playerRef.current?.paused ? 0.01 : 1;
      const isFastForwarding = currentTime - currentWatchedDurationRef.current > maxSeekDuration;
      if (isFastForwarding && disabledFastForward && playerRef.current) {
        playerRef.current.currentTime = currentWatchedDurationRef.current;
        return undefined;
      }
      currentWatchedDurationRef.current = currentTime;
      if (onVideoProgress) {
        onVideoProgress({ watchedDuration: currentTime });
      }
    }
    return undefined;
  };

  const handleEnded = () => {
    if (onVideoProgress) {
      onVideoProgress({ watchedDuration: playerRef.current?.duration, isWatched: true });
    }
  };

  const handlePlayRequest = (event: MediaPlayRequestEvent) => {
    if (!canPlayVideoRef.current) {
      event.preventDefault();
    }
  };

  const eventHandlers = {
    ...(onVideoProgress &&
      watchedDuration !== undefined && { onTimeUpdate: handleTimeUpdate, onEnded: handleEnded }),
    onMediaPlayRequest: handlePlayRequest,
  };

  return (
    <StyledMediaPlayer
      key={poster}
      className="player"
      ref={playerRef}
      style={{ height: '100%' }}
      src={src || undefined}
      viewType="video"
      streamType="on-demand"
      logLevel="warn"
      crossOrigin
      playsInline
      title={title}
      controlsDelay={1500}
      poster={poster}
      onMouseDown={() => {
        canPlayVideoRef.current = canPlay;
      }}
      $progress={
        disabledFastForward && playerRef.current?.duration && watchedDuration
          ? watchedDuration / (playerRef.current?.duration || 1)
          : undefined
      }
      {...eventHandlers}
    >
      <MediaProvider>
        <Poster className="vds-poster" />
      </MediaProvider>
      <DefaultVideoLayout
        icons={defaultLayoutIcons}
        translations={translations}
        colorScheme={palette.mode}
        slots={{
          bufferingIndicator: (
            <Stack
              position="absolute"
              left={0}
              top={0}
              right={0}
              bottom={0}
              justifyContent="center"
              alignItems="center"
              gap={2}
              sx={{
                pointerEvents: 'none',
                '& > *': {
                  position: 'relative !important',
                  width: 'auto',
                  height: 'auto',
                },
              }}
            >
              <DefaultBufferingIndicator />
              {!src && (
                <Typography
                  position="relative"
                  zIndex={1}
                  variant="h6"
                  color="white.main"
                  align="center"
                >
                  {t('editor.videoItem.videoProcessingLabel')}
                </Typography>
              )}
            </Stack>
          ),
          googleCastButton: null,
        }}
      />
    </StyledMediaPlayer>
  );
}
