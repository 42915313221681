import { Link } from '@mui/material';
import { useDropzone } from 'react-dropzone';
import { useContext, useState } from 'react';
import { ConnectDragPreview, ConnectDragSource } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { imageUpload } from 'src/lib/utils/imageUpload';
import Image from 'src/ui-components/image';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { allowedFileTypes } from '../upload-item/config';
import HighlightWrapper from '../highlight-wrapper';
import { ResizeStopCallbackType } from '../highlight-wrapper/resize/ResizableWrapper';
import useUploadingMedia from '../hooks/useUploadingMedia';
import { DocumentNameContext } from '../../../DocumentNameContext';
import WithUploadProgressWrapper from '../upload-progress/WithUploadProgressWrapper';

interface Props {
  id: string;
  onDelete: () => void;
  onDuplicate: () => void;
  onUploadImage: (url: string, fileName: string, imageId: string) => void;
  onAddCaption: () => void;
  onDownload: () => void;
  readOnly?: boolean;
  onResizeStop?: ResizeStopCallbackType;
  url: string;
  fileName: string;
  href?: string;
  height?: number;
  caption?: string;
  onUndoCrop: () => void;
  dragging?: {
    dragRef: ConnectDragSource;
    previewRef: ConnectDragPreview;
  };
  onHrefUpdate: (href: string) => void;
  onResizeStart?: () => void;
}

export default function ImageItem({
  id,
  onDelete,
  onDuplicate,
  readOnly = false,
  onResizeStop,
  onUploadImage,
  onDownload,
  url,
  href,
  dragging,
  height,
  onResizeStart,
  onHrefUpdate,
  onUndoCrop,
}: Props) {
  const { t } = useTranslation();
  const [file, setFile] = useState<File | null>(null);
  const [uploadPercentage, setUploadPercentage] = useState(0);
  const [loading, setLoading] = useState(false);
  const { handleClearUploading, handleAddingUploading } = useUploadingMedia(id, 'image');
  const documentName = useContext(DocumentNameContext);
  const resetStates = () => {
    setLoading(false);
    setFile(null);
    setUploadPercentage(0);
    handleClearUploading();
  };

  const handleDropSingleFile = async (acceptedFiles: File[]) => {
    setFile(Object.assign(acceptedFiles[0]));
    const reader = new FileReader();
    reader.readAsDataURL(acceptedFiles[0]);

    setLoading(true);
    handleAddingUploading();
    const response = await imageUpload(
      acceptedFiles[0],
      {
        onUploadProgress: (progressEvent) => {
          if (progressEvent.loaded && progressEvent.total) {
            const percentComplete = (progressEvent.loaded / progressEvent.total) * 100;
            setUploadPercentage(+percentComplete.toFixed(0));
          }
        },
      },
      documentName
    );

    if (response.status !== 201) {
      showNotification(t('validationErrorMessages.UploadImageErrorMessage'), 'error');
      resetStates();
    }

    const { result } = response.data;
    const { variants, id: imageId } = result;
    const [imageUrl] = variants;
    onUploadImage(imageUrl, acceptedFiles[0].name, imageId);

    resetStates();
  };

  const { getInputProps, open } = useDropzone({
    onDrop: handleDropSingleFile,
    noDrag: true,
    accept: {
      ...allowedFileTypes.image,
    },
  });

  const imageComponent = <Image key={id} height="100%" src={url} alt={url} />;

  const imageComponentWithLink = (
    <Link key={id} href={href} sx={{ height: '100%' }} target="_blank">
      {imageComponent}
    </Link>
  );
  const readOnlyContent = href ? imageComponentWithLink : imageComponent;
  const editContent = imageComponent;
  return (
    <HighlightWrapper
      id={id}
      editBarProps={{
        imageActionsProps: {
          onUpload: () => open(),
          onDownload,
        },
        hrefProps: {
          href,
          onChange: onHrefUpdate,
        },
        undoCropActionProps: {
          onClick: onUndoCrop,
        },
        baseActionsProps: { onDelete, onDuplicate },
      }}
      dragging={dragging}
      horizontalResize={{}}
      verticalResize={{}}
      onResizeStop={onResizeStop}
      onResizeStart={onResizeStart}
      readOnly={readOnly}
      height={height}
    >
      {({ EditBarComponent }) => (
        <>
          {EditBarComponent}
          <WithUploadProgressWrapper
            loading={loading}
            uploadProgress={uploadPercentage}
            fileSize={file?.size}
            onStopUploading={resetStates}
            mediaType="image"
          >
            {readOnly ? readOnlyContent : editContent}
          </WithUploadProgressWrapper>
          <input {...getInputProps()} />
        </>
      )}
    </HighlightWrapper>
  );
}
