import { Components, Theme } from '@mui/material';

const MuiMenuItem: Components<Theme>['MuiMenuItem'] = {
  styleOverrides: {
    root: ({ theme }) => ({
      borderRadius: theme.shape.borderRadius,
      fontWeight: 500,
      paddingLeft: 12,
      paddingRight: 12,
      marginBottom: '2px',
      '&:last-child': {
        marginBottom: 0,
      },
    }),
  },
};

export default MuiMenuItem;
