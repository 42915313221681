import { Stack } from '@mui/material';
import { Employee } from 'src/features/employees/model';
import { TrainingModuleWithStatus } from 'src/store/api/trainings/trainingModulesApi';
import SingleModule from './SingleModule';

interface Props {
  trainingModulesData: TrainingModuleWithStatus[];
  employee: Employee;
  searchVal: string;
  blockAccess?: boolean;
}

export default function ModulesList({
  trainingModulesData,
  employee,
  searchVal,
  blockAccess = false,
}: Props) {
  return (
    <Stack gap={1.5}>
      {trainingModulesData
        .filter(({ status }) => status === 'published')
        .map((module) => (
          <SingleModule
            key={module.id}
            module={module}
            employee={employee}
            trainingId={module.trainingId}
            searchVal={searchVal}
            blockAccess={blockAccess}
          />
        ))}
    </Stack>
  );
}
