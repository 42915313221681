import UAParser from 'ua-parser-js';
import { API_URL } from 'src/global-config';
import aiChatApi, { MessageContent } from 'src/store/api/aiChatApi';
import { NavigateFunction } from 'react-router-dom';
import { Dispatch } from 'react';
import { ChatAction } from './types';

function loadAllUserData() {
  const parser = new UAParser();
  const result = parser.getResult();

  return {
    browser: {
      name: result.browser.name,
    },
    os: {
      name: result.os.name,
      version: result.os.version,
    },
    device: {
      type: result.device.type || 'N/A',
      model: result.device.model || 'N/A',
      vendor: result.device.vendor || 'N/A',
    },
    role: localStorage.getItem('persist:dashboard')
      ? JSON.parse(JSON.parse(localStorage.getItem('persist:dashboard') || '{}').selectedRole)
      : null,
    location: window.location.pathname,
  };
}

// WebSocket URL Setup
const wsUrl = new URL(API_URL);
wsUrl.pathname = '/ai-assistant';
const socketProtocol = wsUrl.protocol === 'https:' ? 'wss' : 'ws';
const socketUrl = `${socketProtocol}://${wsUrl.host}/ai-assistant`;

// Connect WebSocket and manage lifecycle

export const connectWebSocket = (
  dispatch: React.Dispatch<ChatAction>,
  authToken: string,
  threadId: string,
  navigate: NavigateFunction,
  rtkDispatch: Dispatch<any>
  // eslint-disable-next-line max-params
) => {
  const ws = new WebSocket(socketUrl);
  ws.onopen = () => {
    ws.send(
      JSON.stringify({
        type: 'auth',
        token: authToken,
        threadId,
      })
    );
    dispatch({ type: 'SET_WAITING_RESPONSE', payload: false });
    dispatch({ type: 'CLEAR_CURRENT_STREAM' });
  };

  ws.onmessage = (event) => {
    const messageData = JSON.parse(event.data);
    const messageHandlers: { [key: string]: (data: any) => void } = {
      textDelta(data) {
        dispatch({ type: 'UPDATE_CURRENT_STREAM', payload: data.delta.value });
      },
      end() {
        dispatch({ type: 'COMPLETE_STREAM' });
        dispatch({ type: 'SET_NEEDS_UPDATE', payload: '1' });
      },
      error(data) {
        dispatch({ type: 'WEBSOCKET_ERROR', payload: data.error });
        dispatch({ type: 'SET_WAITING_RESPONSE', payload: false });
      },
      notification(data) {
        if (data.message.type === 'pageCreated') {
          dispatch({ type: 'SET_NEEDS_UPDATE', payload: '1' });
          navigate(`/page/${data.message.args.pageId}`);
        }
        if (data.message.type === 'pageAiUpdateContentCompleted') {
          dispatch({ type: 'SET_NEEDS_UPDATE', payload: data.message.args.pageId });
        }
        if (data.message.type === 'summaryCreated') {
          rtkDispatch(
            aiChatApi.util.updateQueryData('fetchThreads', undefined, (draft) => {
              if (draft && data?.message?.args) {
                const { threadId: id } = data.message.args;
                const { summary } = data.message.args;
                const thread = draft.find((thrd) => thrd.id === id);
                if (thread) {
                  thread.summary = summary;
                }
              }
            })
          );

          dispatch({
            type: 'UPDATE_THREAD_SUMMARY',
            payload: {
              id: data.message.args.threadId,
              summary: data.message.args.summary,
            },
          });
        }
      },
    };

    if (messageHandlers[messageData.type]) {
      messageHandlers[messageData.type](messageData);
    }
  };

  ws.onerror = () => {
    dispatch({ type: 'WEBSOCKET_ERROR', payload: 'WebSocket error' });
    dispatch({ type: 'SET_WAITING_RESPONSE', payload: false });
  };
  return ws;
};

// Send a chat message
export const sendChatMessage = (
  ws: WebSocket | null,
  content: MessageContent[],
  threadId: string,
  retry: boolean = false
  // eslint-disable-next-line max-params
) => {
  if (ws && ws.readyState === WebSocket.OPEN) {
    ws.send(
      JSON.stringify({
        type: 'content',
        content,
        metadata: { ...loadAllUserData(), retry },
        threadId,
      })
    );
  }
};

export const cancelMessage = (ws: WebSocket | null) => {
  if (ws && ws.readyState === WebSocket.OPEN) {
    ws.send(
      JSON.stringify({
        type: 'system',
        content: 'cancelRun',
      })
    );
  }
};

// Disconnect WebSocket
export const disconnectWebSocket = (ws: WebSocket | null) => {
  if (ws) {
    ws.close();
  }
};
