import { useTranslation } from 'react-i18next';
import { useParams, Link as RouterLink } from 'react-router-dom';
import useMaintainEditPath from 'src/lib/hooks/useMaintainEditPath';
import { useTrainingLessonQuery } from 'src/store/api/trainings/trainingLessonsApi';
import { useGetSingleTrainingQuery } from 'src/store/api/trainings/trainingsApi';
import { useModuleByIdQuery } from 'src/store/api/modulesApi';
import { Box, Breadcrumbs, Button } from '@mui/material';

export default function LessonBreadcrumbs() {
  const { t } = useTranslation();
  const params = useParams();
  const trainingId = params.trainingId as string;
  const moduleId = params.moduleId as string;
  const lessonId = params.lessonId as string;
  const { data: trainingData } = useGetSingleTrainingQuery(trainingId);
  const { data: moduleData } = useModuleByIdQuery(moduleId);
  const { data: lessonData } = useTrainingLessonQuery(lessonId);
  const path = useMaintainEditPath();

  const data = [
    {
      key: 'training',
      label: trainingData?.name || t('trainingDetailPage.unnamedTraining'),
      to: `/trainings/${params.trainingId}${path}`,
    },
    {
      key: 'module',
      label: moduleData?.name || t('trainingDetailPage.unnamedModule'),
      to: `/trainings/${trainingId}/${moduleId}${path}`,
    },
    {
      key: 'lesson',
      label: lessonData?.name || t('modulePage.unnamedLesson'),
      to: `/trainings/${trainingId}/${moduleId}/${lessonId}${path}`,
    },
  ];

  return (
    <Box mt={2}>
      <Breadcrumbs>
        {data.map(({ key, label, to }) => (
          <Button
            data-cy={`${label}-lesson-breadcrumb`}
            key={key}
            variant="text"
            size="medium"
            color="inherit"
            component={RouterLink}
            to={to}
          >
            {label}
          </Button>
        ))}
      </Breadcrumbs>
    </Box>
  );
}
