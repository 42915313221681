import { Avatar, Stack, Tab } from '@mui/material';
import { Employee } from 'src/features/employees/model';
import { useAppSelector } from 'src/store';
import BrandedHeader from 'src/ui-components/branded-header';
import { TabList } from '@mui/lab';
import HeaderReadOnly from './HeaderReadOnly';
import HeaderEdit from './HeaderEdit';
import EditAvatar from './EditAvatar';
import Progress from './Progress';

export type EmployeeProps = {
  onUpdateEmployee: (val: Partial<Employee>) => void;
  readOnly: boolean;
  tabsData: {
    key: string;
    text: string;
    disabled: boolean;
  }[];
  showProgress: boolean;
  progressValue: number;
  onActiveTabChange: (val: string) => void;
};

export default function Header({
  onUpdateEmployee,
  readOnly,
  tabsData,
  showProgress,
  progressValue,
  onActiveTabChange,
}: EmployeeProps) {
  const employeeDraft = useAppSelector((state) => state.employee.employee);

  if (!employeeDraft) return null;

  return (
    <BrandedHeader
      cardContent={
        <Stack justifyContent="space-between" direction="row">
          <TabList onChange={(_, val) => onActiveTabChange(val)}>
            {tabsData.map(({ key, text, disabled }) => (
              <Tab key={key} label={text} value={key} data-cy={`${text}-tab`} disabled={disabled} />
            ))}
          </TabList>
          {showProgress && <Progress value={progressValue} />}
        </Stack>
      }
    >
      <Stack
        direction="row"
        width="100%"
        height="100%"
        maxWidth="100%"
        justifyContent="center"
        alignItems="center"
        gap={2}
        minWidth={0}
      >
        {readOnly ? (
          <Avatar
            data-cy="employee-avatar"
            src={employeeDraft?.avatarUrl}
            sx={{ width: 80, height: 80 }}
            variant="rounded"
          />
        ) : (
          <EditAvatar employee={employeeDraft} handleUpdateEmployee={onUpdateEmployee} />
        )}
        <Stack
          direction="column"
          alignItems="flex-start"
          justifyContent="center"
          maxWidth="100%"
          flexGrow={1}
          minWidth={0}
          gap={1}
        >
          {readOnly || employeeDraft.status === 'invited' ? (
            <HeaderReadOnly employee={employeeDraft} />
          ) : (
            <HeaderEdit employee={employeeDraft} handleUpdateEmployee={onUpdateEmployee} />
          )}
        </Stack>
      </Stack>
    </BrandedHeader>
  );
}
