import { Box } from '@mui/material';

export default function HighlightBorder() {
  return (
    <Box
      className="opacity-editor-item"
      borderRadius={1}
      sx={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        transition: (theme) =>
          theme.transitions.create('opacity', {
            duration: theme.transitions.duration.shortest,
            easing: theme.transitions.easing.easeInOut,
          }),
        borderWidth: 2,
        opacity: 0,
        borderStyle: 'solid',
        borderColor: 'primary.main',
        pointerEvents: 'none',
        zIndex: 99999,
      }}
    />
  );
}
