import { Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { PermissionGroup, PermissionGroupCode } from '../../../model/types';
import usePermissionsTranslations from './usePermissionsTranslations';
import AdminSinglePermission from './single-permission/AdminSinglePermission';
import GeneralSinglePermission from './single-permission/GeneralSinglePermission';
import TeamPermission from './single-permission/TeamSinglePermission';
import SettingSinglePermission from './single-permission/SettingSinglePermission';

interface PermissionGroupComponentProps {
  permissionGroup: PermissionGroup;
}

export default function PermissionGroupComponent({
  permissionGroup,
}: PermissionGroupComponentProps) {
  const { code, permissions } = permissionGroup;
  const { groupTitleNames, permissionNames } = usePermissionsTranslations();
  const hasAnyPermission = useHasAnyPermission();
  const hasManagingPermission = hasAnyPermission(['MANAGE_PERMISSIONS']);

  const tooltipWithoutManagingPermission = t(
    'companySettings.sidebar.departments.permissions.permissionTooltip.withoutManagingPermission'
  );

  return (
    <Stack>
      <Typography variant="body1" fontWeight="medium" align="left" color="text.primary">
        {groupTitleNames[code]}
      </Typography>
      <Stack>
        {permissions.map((permission) => {
          if (!permissionNames[permission.code]?.name) return null;

          const props = {
            id: permission.id,
            name: permissionNames[permission.code].name,
            description: permissionNames[permission.code].description,
            key: permission.id,
            hasManagingPermission: !!hasManagingPermission,
            // eslint-disable-next-line object-shorthand
            tooltipWithoutManagingPermission: tooltipWithoutManagingPermission,
          };
          const permissionComponentsDict: Record<PermissionGroupCode, JSX.Element> = {
            SETTINGS: (
              <SettingSinglePermission
                {...props}
                code={permission.code}
                permissions={permissions}
              />
            ),
            ADMIN_PERMISSIONS: <AdminSinglePermission {...props} code={permission.code} />,
            TEAM: <TeamPermission {...props} code={permission.code} permissions={permissions} />,
            GENERAL: (
              <GeneralSinglePermission
                {...props}
                code={
                  permission.code as
                    | 'ADD_PAGES'
                    | 'ADD_EMBEDDED_APPS'
                    | 'ADD_TRAININGS'
                    | 'ADD_WHITEBOARDS'
                    | 'ADD_WIKIS'
                }
              />
            ),
          };

          return permissionComponentsDict[permissionGroup.code];
        })}
      </Stack>
    </Stack>
  );
}
