import { Box, List } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { TrainingLesson } from 'src/store/api/trainings/trainingLessonsApi';
import ReadOnlyLesson from '../lesson/ReadOnlyLesson';
import EditLesson from '../lesson/EditLesson';

interface Props {
  filteredLessons: TrainingLesson[];
  readOnly: boolean;
  mobileView: boolean;
  id: string;
  dataCy?: string;
}

export default function FilteredLessons({
  filteredLessons,
  readOnly,
  mobileView,
  id,
  dataCy,
}: Props) {
  const params = useParams<{ trainingId: string; moduleId: string; lessonId?: string }>();
  const navigate = useNavigate();
  return (
    <List>
      {filteredLessons.map((lesson, idx) => (
        <Box
          key={lesson.id}
          sx={{ cursor: 'pointer' }}
          onClick={() =>
            navigate(
              `/trainings/${params.trainingId}/${params.moduleId}/${lesson.trainingSectionId}/${
                lesson.id
              }${readOnly ? '' : '/edit'}`
            )
          }
        >
          {readOnly ? (
            <ReadOnlyLesson lesson={lesson} />
          ) : (
            <EditLesson
              dataCy={dataCy}
              mobileView={mobileView}
              lesson={lesson}
              filteredLessons={filteredLessons}
              sectionId={id}
              index={idx}
            />
          )}
        </Box>
      ))}
    </List>
  );
}
