import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { t } from 'i18next';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { WorkbaseDeleteSquareIcon } from 'src/assets/icons/workbaseIcons';
import { useAppDispatch } from 'src/store';
import { deleteRole, uncheckRoles } from '../../../controller/departments.slice';

interface DeleteRoleProps {
  roleId?: string;
  departmentId?: string;
  rolesLength?: number;
  usersCount?: number;
}

export default function DeleteRole({
  roleId,
  departmentId,
  rolesLength,
  usersCount,
}: DeleteRoleProps) {
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    if (usersCount && usersCount > 0) {
      showNotification(
        t('companySettings.sidebar.departments.removeLinkModal.deleteAssignedRoleError'),
        'error'
      );
    } else if (rolesLength && rolesLength < 2) {
      showNotification(
        t('companySettings.sidebar.departments.removeLinkModal.deleteOneRoleError'),
        'error'
      );
    } else if (departmentId && roleId) {
      dispatch(deleteRole({ departmentId, roleId }));
      dispatch(uncheckRoles([roleId]));
    }
  };

  return (
    <Tooltip title={t('delete')}>
      <IconButton
        data-cy="department-role-delete-button"
        color="inherit"
        size="small"
        onClick={handleDelete}
      >
        <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
      </IconButton>
    </Tooltip>
  );
}
