import { ReactNode } from 'react';
import { ButtonBase, SvgIcon, useTheme } from '@mui/material';
import { WorkbaseEditIcon } from 'src/assets/icons/workbaseIcons';

interface ImageEditButtonProps {
  onClick?: (e: any) => void;
  children?: ReactNode;
  dataCy?: string;
}

export default function ImageEditOverlay({ onClick, children, dataCy }: ImageEditButtonProps) {
  const { palette } = useTheme();
  return (
    <ButtonBase
      component="label"
      onClick={(e) => {
        onClick?.(e);
      }}
      data-cy={dataCy && `${dataCy}-overlay-button`}
      sx={{
        opacity: 0,
        position: 'absolute',
        pointerEvents: 'all',
        transition: 'opacity .2s ease-in',
        '&:hover': {
          opacity: 0.8,
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        zIndex: 2,
        bottom: 0,
        borderRadius: 1,
        left: 0,
        bgcolor: 'action.active',
        color: palette.mode === 'dark' ? 'common.black' : 'common.white',
      }}
    >
      {children}
      <SvgIcon component={WorkbaseEditIcon} />
    </ButtonBase>
  );
}
