import { IconButton, SvgIcon, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  WorkbaseCopyDuplicateIcon,
  WorkbaseDeleteSquareIcon,
} from '../../../../../../assets/icons/workbaseIcons';

export interface BaseActionsProps {
  onDuplicate: () => void;
  onDelete: () => void;
  hideDuplicate?: boolean;
}

export default function BaseActions({
  onDuplicate,
  onDelete,
  hideDuplicate = false,
}: BaseActionsProps) {
  const { t } = useTranslation();
  return (
    <>
      {!hideDuplicate && (
        <Tooltip title={t('editor.topBar.tooltip.duplicate')} placement="top">
          <IconButton
            onMouseDown={(e) => {
              e.preventDefault();
              onDuplicate();
            }}
          >
            <SvgIcon component={WorkbaseCopyDuplicateIcon} fontSize="small" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={t('editor.topBar.tooltip.remove')} placement="top">
        <IconButton
          onMouseDown={(e) => {
            e.preventDefault();
            onDelete();
          }}
        >
          <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
        </IconButton>
      </Tooltip>
    </>
  );
}
