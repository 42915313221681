import { Box, CardContentProps, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import { ElementType } from 'react';
import BrandedDefaultImage from 'src/ui-components/branded-default-image';
import CircularProgressWithLabel from 'src/ui-components/custom/progress/CircularProgressWithLabel';
import ImageWithDefaultFallback from 'src/ui-components/image/ImageWithDefaultFallback';

export interface ImageProgressCardProps extends CardContentProps {
  image?: string | null;
  dataCy?: string;
  prefix?: string;
  title: string;
  description: string;
  progress: number;
  onClick?: () => void;
  icon?: ElementType;
}

export default function SummaryProgressCard({
  image,
  prefix,
  title,
  description,
  progress,
  onClick,
  dataCy,
  icon,
}: ImageProgressCardProps) {
  return (
    <Stack
      gap={2}
      flexDirection="row"
      alignItems="center"
      width="100%"
      pr={2}
      sx={{ cursor: 'pointer' }}
      onClick={(e) => {
        if (onClick) {
          e.preventDefault();
          e.stopPropagation();
          onClick();
        }
      }}
    >
      {icon ? (
        <IconButton data-cy={dataCy} color="inherit">
          <SvgIcon component={icon} />
        </IconButton>
      ) : (
        <Box borderRadius={1} flexShrink={0} width={60} height={34}>
          {image && (
            <ImageWithDefaultFallback
              height="100%"
              width="100%"
              src={image}
              alt="Training progress image"
              defaultImage={image as any}
            />
          )}
          {!image && <BrandedDefaultImage data-cy={dataCy} defaultImage="WorkbaseDefault1" />}
        </Box>
      )}
      <Stack
        flexDirection="row"
        gap={2}
        justifyContent="space-between"
        alignItems="center"
        flexGrow="1"
        minWidth={0}
      >
        <Stack minWidth={0}>
          <Stack flexDirection="row" gap={0.5} minWidth={0} overflow="hidden" width="100%">
            {prefix && (
              <Typography variant="subtitle1" fontWeight="bold" color="grey[500]">
                {prefix}
              </Typography>
            )}
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              noWrap
              data-cy={dataCy && `${dataCy}-title`}
            >
              {title}
            </Typography>
          </Stack>
          <Typography
            variant="body2"
            fontWeight="medium"
            color="text.secondary"
            data-cy={dataCy && `${dataCy}-description`}
          >
            {description}
          </Typography>
        </Stack>
        <CircularProgressWithLabel value={progress % 100} />
      </Stack>
    </Stack>
  );
}
