import { useTranslation } from 'react-i18next';
import { matchPath, useLocation, useNavigate } from 'react-router-dom';
import {
  WorkbaseMotivationIcon,
  WorkbaseEmployeeCardsIcon,
  WorkbaseSettingsIcon,
} from 'src/assets/icons/workbaseIcons';
import useHasAnyPermission from 'src/hooks/useHasAnyPermission';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import {
  RecommendationNotificationAdminPreferences,
  useSettingsQuery,
} from 'src/store/api/settingsApi';

function useActionButtonsData(onRecommendationIconClick: () => void) {
  const { t } = useTranslation();
  const location = useLocation();
  const { data: currentUserData } = useCurrentUserQuery();
  const navigate = useNavigate();
  const { data: settingsData } = useSettingsQuery(undefined, { refetchOnMountOrArgChange: true });
  const hasAnyPermission = useHasAnyPermission();
  const defaultIconsData = [];

  if (
    (
      settingsData?.recommendationNotificationPreferences as RecommendationNotificationAdminPreferences
    )?.isEmailEnabled ||
    (
      settingsData?.recommendationNotificationPreferences as RecommendationNotificationAdminPreferences
    )?.isWebhookEnabled
  ) {
    defaultIconsData.push({
      key: 'recommendation',
      icon: { icon: WorkbaseMotivationIcon },
      onClick: onRecommendationIconClick,
      isActive: false,
      tooltip: t('header.employeeRecommendationIconTooltip'),
    });
  }

  if (currentUserData?.isOwner || hasAnyPermission(['VIEW_EMPLOYEES'])) {
    defaultIconsData.push({
      key: 'employee',
      icon: { icon: WorkbaseEmployeeCardsIcon },
      onClick: () => navigate('/team'),
      isActive: !!matchPath({ path: 'team', end: false }, location.pathname),
      tooltip: t('header.employeeIconTooltip'),
    });
  }

  if (hasAnyPermission(['MANAGE_DEPARTMENTS', 'MANAGE_ALL_COMPANY_SETTINGS', 'MANAGE_BILLING'])) {
    defaultIconsData.push({
      key: 'settings',
      icon: { icon: WorkbaseSettingsIcon },
      onClick: () =>
        navigate(
          currentUserData?.isOwner ||
            hasAnyPermission(['MANAGE_DEPARTMENTS', 'MANAGE_ALL_COMPANY_SETTINGS'])
            ? '/settings/site-information'
            : '/settings/employee-recommendations'
        ),
      isActive: !!matchPath({ path: 'settings', end: false }, location.pathname),
      tooltip: t('header.settingsIconTooltip'),
    });
  }

  return defaultIconsData;
}

export default useActionButtonsData;
