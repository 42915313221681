import { Button, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export default function ApiKeysHeader({
  onGenerateApiKey,
  displayButton,
}: {
  onGenerateApiKey: () => void;
  displayButton?: boolean;
}) {
  const { t } = useTranslation();

  return (
    <Stack direction="row" justifyContent="space-between" alignItems="baseline">
      <Stack direction="row" gap={1} alignItems="baseline">
        <Typography variant="h4" fontWeight="bold" color="text.primary">
          {t('companySettings.apiKeys.innerHeader')}
        </Typography>
      </Stack>
      {displayButton && (
        <Button onClick={onGenerateApiKey} data-cy="generate-api-key-button">
          {t('companySettings.apiKeys.generateApiKey')}
        </Button>
      )}
    </Stack>
  );
}
