import { Box, BoxProps, useTheme } from '@mui/material';
import { useLottie } from 'lottie-react';
import { colorify } from 'lottie-colorify';
import loading from 'src/assets/lottie/loading.json';
import useThemeLogos from 'src/hooks/useThemeLogos';
import { useMemo } from 'react';

const sizeVal = 150;

export default function LottieLogoSpinner({ ...restOfProps }: BoxProps) {
  const { palette } = useTheme();
  const { favicon } = useThemeLogos();

  const animationData = useMemo(
    () =>
      colorify(
        Array.from({ length: 79 }, () => palette.primary.main),
        loading
      ),
    [palette.primary.main]
  );

  const options = {
    animationData,
    loop: true,
  };

  const { View } = useLottie(options, { width: sizeVal, height: sizeVal });

  return (
    <Box
      width={sizeVal}
      height={sizeVal}
      position="relative"
      display="flex"
      alignItems="center"
      justifyContent="center"
      {...restOfProps}
    >
      {View}
      <Box position="absolute" width="30%" height="30%">
        <Box
          component="img"
          width="100%"
          height="100%"
          sx={{ objectPosition: 'center', objectFit: 'contain' }}
          src={favicon.src}
          alt={favicon.alt}
        />
      </Box>
    </Box>
  );
}
