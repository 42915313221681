import { ElementType, useCallback, useEffect, useState } from 'react';
import { InputAdornment, MenuItem, Stack, SvgIcon, TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { throttledShowNotification } from 'src/ui-components/custom/notifications-snackbar';

interface Props {
  selectedValue: string;
  onSelect: (val: string) => void;
  deviceKind: MediaDeviceKind;
  label: {
    icon: ElementType;
    text: string;
  };
}

const cleanLabel = (label: string) => label.replace(/\([^)]*\)/g, '').trim();

export default function DeviceSelect({ selectedValue, onSelect, deviceKind, label }: Props) {
  const { t } = useTranslation();
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [devices, setDevices] = useState<MediaDeviceInfo[]>([]);
  const [accessError, setAccessError] = useState(false);

  const getDevices = useCallback(
    async (withAutoSelect = true) => {
      try {
        const getUserMediaType = deviceKind === 'videoinput' ? 'video' : 'audio';
        const stream = await navigator.mediaDevices.getUserMedia({ [getUserMediaType]: true });
        setMediaStream(stream);
        const mediaDevices = await navigator.mediaDevices.enumerateDevices();
        const devicesToSet = mediaDevices.filter((device) => device.kind === deviceKind);
        const defaultDevice = devicesToSet.find((device) => device.deviceId === 'default');
        const filteredDevices = devicesToSet.filter((device) => {
          if (
            defaultDevice &&
            device.groupId === defaultDevice.groupId &&
            device.deviceId !== 'default'
          ) {
            return false;
          }
          return true;
        });
        setDevices(filteredDevices);
        const currentDeviceExists = filteredDevices.some(
          (device) => device.deviceId === selectedValue
        );
        if (!currentDeviceExists && withAutoSelect) {
          onSelect(filteredDevices[0]?.deviceId || '');
        }
        setAccessError(false);
      } catch (error) {
        throttledShowNotification(t('recording.noDeviceAccess'), 'error');
        setAccessError(true);
      }
    },
    [onSelect, deviceKind, t, selectedValue]
  );

  useEffect(() => {
    if (selectedValue === '' && devices.length === 0 && !accessError) {
      getDevices();
    }
  }, [getDevices, selectedValue, devices, accessError]);

  useEffect(() => {
    if (selectedValue) {
      const onDeviceChange = () => getDevices();
      navigator.mediaDevices.addEventListener('devicechange', onDeviceChange);

      return () => {
        navigator.mediaDevices.removeEventListener('devicechange', onDeviceChange);
        if (mediaStream) {
          mediaStream.getTracks().forEach((track) => track.stop());
        }
      };
    }
    return undefined;
  }, [getDevices, selectedValue, mediaStream]);

  const devicesMenuData = devices.map((cam) => ({
    value: cam.deviceId,
    label: cleanLabel(cam.label),
  }));

  const availableDeviceOptions = [
    ...devicesMenuData,
    {
      value: 'noDevice',
      label: t('recording.noDevice'),
    },
  ];
  return (
    <Stack gap={0.75} flexBasis="50%">
      <TextField
        select
        value={selectedValue}
        label={label.text}
        onChange={(e) => onSelect(e.target.value as string)}
        size="small"
        slotProps={{
          input: {
            startAdornment: (
              <InputAdornment position="start">
                <SvgIcon component={label.icon} color="primary" />
              </InputAdornment>
            ),
          },
        }}
      >
        {availableDeviceOptions.map(({ value, label: optionLabel }) => (
          <MenuItem key={value} value={value}>
            {optionLabel}
          </MenuItem>
        ))}
      </TextField>
    </Stack>
  );
}
