import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { WorkbaseAddSquareIcon } from 'src/assets/icons/workbaseIcons';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import Sidebar from 'src/ui-components/layout/sidebar/Sidebar';
import { useUpdateCardsOrderMutation, useCreateWikiCardMutation } from 'src/store/api/wikiApi';
import { generateKeyBetween } from 'fractional-indexing-jittered';
import { PositionedSidebarItem } from 'src/ui-components/layout/sidebar/types';

interface WikiSidebarProps {
  data: PositionedSidebarItem[];
  hasAddPermission?: boolean;
  wikiId: string;
  isEditMode: boolean;
}

export default function WikiSidebar({
  data,
  hasAddPermission,
  wikiId,
  isEditMode,
}: WikiSidebarProps) {
  const [updateOrder] = useUpdateCardsOrderMutation();
  const [createWikiCard] = useCreateWikiCardMutation();
  const navigate = useNavigate();
  const handleCreateCard = () => {
    createWikiCard().then((resp: any) => {
      if (!resp.error) {
        navigate(`/wiki/${resp.data.id}/edit`, { replace: true });
        showNotification(t('notifications.addingSuccess'));
      } else {
        showNotification(t('notifications.addingError'), 'error');
      }
    });
  };

  const handleUpdateOrder = ({ id, order }: { id: string; order: number }) => {
    const prev = data[order - 1]?.position ?? null;
    const next = data[order]?.position ?? null;
    const pos = generateKeyBetween(prev, next);
    updateOrder({ id, position: pos });
  };

  return (
    <Sidebar
      withDragAndDrop={hasAddPermission && isEditMode}
      reorderFunction={handleUpdateOrder}
      items={data}
      dataCy="wiki-sidebar"
      disableSearchBar={!data?.length}
      activeKey={wikiId as string}
      headerIcon={
        hasAddPermission
          ? {
              icon: WorkbaseAddSquareIcon,
              text: t('wikiPage.headerAddButton'),
              onClick: handleCreateCard,
            }
          : undefined
      }
    />
  );
}
