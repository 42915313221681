import { Box } from '@mui/material';
import BrandedHeader from 'src/ui-components/branded-header';
import { ResourceType, useLinksQuery } from 'src/store/api/linksApi';
import Links from 'src/core/links/Links';
import ContrastEditableIconText from '../icon-text/ContrastEditableIconText';

interface Props {
  readOnly: boolean;
  name?: string;
  icon: string;
  resourceId: string;
  resourceType: ResourceType;
  onIconChange: (icon: string) => void;
  onNameChange: (name: string) => void;
  placeholder: string;
}

export default function IconTextBrendedHeader({
  readOnly,
  name,
  icon,
  onIconChange,
  onNameChange,
  resourceId,
  resourceType,
  placeholder,
}: Props) {
  const { data: links, isSuccess } = useLinksQuery({ resourceId, resourceType });

  const handleIconChange = (newIcon: string) => {
    onIconChange(newIcon);
  };
  const handleNameChange = (newName: string) => {
    onNameChange(newName);
  };
  const shouldRenderCardContent = isSuccess && !(readOnly && links?.length === 0);

  return (
    <BrandedHeader
      cardContent={
        shouldRenderCardContent && (
          <Links resourceType={resourceType} resourceId={resourceId} readOnly={readOnly} />
        )
      }
    >
      <Box margin="auto" width="80%">
        <ContrastEditableIconText
          placeholder={placeholder}
          text={name}
          isEditable={!readOnly}
          icon={icon}
          textFieldProps={{
            fullWidth: true,
          }}
          onTextChange={handleNameChange}
          onIconChange={handleIconChange}
        />
      </Box>
    </BrandedHeader>
  );
}
