import { Box } from '@mui/material';
import { t } from 'i18next';
import { useNavigate } from 'react-router-dom';
import { TrainingLesson } from 'src/store/api/trainings/trainingLessonsApi';
import { throttledShowNotification } from 'src/ui-components/custom/notifications-snackbar';
import SummaryProgressCard from '../SummaryProgressCard';

interface Props {
  lesson: TrainingLesson;
  trainingId: string;
  trainingModuleId: string;
  blockAccess?: boolean;
}

export default function SingleLesson({
  lesson,
  trainingId,
  trainingModuleId,
  blockAccess = false,
}: Props) {
  const navigate = useNavigate();
  const estimatedTimeLabel = t('minute', { count: lesson.estimatedTimeToComplete || 0 });
  return (
    <Box
      key={lesson.id}
      onClick={() => {
        if (blockAccess) {
          throttledShowNotification(t('employeePage.progress.noAccessLesson'), 'error');
        } else {
          navigate(
            `/trainings/${trainingId}/${trainingModuleId}/${lesson.trainingSectionId}/${lesson.id}`
          );
        }
      }}
    >
      <SummaryProgressCard
        data-cy="lesson-image-progress-card"
        image={lesson.imageUrl}
        title={lesson.name || t('employeePage.progress.unnamedLesson')}
        description={estimatedTimeLabel}
        progress={lesson.done ? 100 : 0}
      />
    </Box>
  );
}
