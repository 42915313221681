import { SvgIcon, ToggleButton } from '@mui/material';
import { WorkbaseChevronIcon, WorkbaseChevronBackIcon } from 'src/assets/icons/workbaseIcons';

interface ToggleFullWidthArrowProps {
  isFullWidth: boolean;
  onFullWidthChange: (isFullWidth: boolean) => void;
}

export const toggleFullWidthArrowClassName = 'toggle-full-width-arrow';

export default function ToggleFullWidthArrow({
  isFullWidth,
  onFullWidthChange,
}: ToggleFullWidthArrowProps) {
  return (
    <ToggleButton
      value="toggle-full-width"
      data-cy="sidebarToggleFullWidthArrow"
      onClick={() => onFullWidthChange(!isFullWidth)}
      size="small"
      className={toggleFullWidthArrowClassName}
      selected
      color="primary"
      sx={{
        position: 'absolute',
        zIndex: 20,
        right: 0,
        top: '50%',
        opacity: 0,
        transition: (theme) =>
          theme.transitions.create(['opacity'], {
            duration: theme.transitions.duration.standard,
            easing: theme.transitions.easing.easeInOut,
          }),
        transform: 'translate(50%, -50%)',
      }}
    >
      <SvgIcon
        fontSize="inherit"
        component={isFullWidth ? WorkbaseChevronBackIcon : WorkbaseChevronIcon}
      />
    </ToggleButton>
  );
}
