import { ReactNode, useEffect, useState } from 'react';
import {
  createTheme,
  CssBaseline,
  PaletteMode,
  ThemeProvider,
  responsiveFontSizes,
} from '@mui/material';
import typography from 'src/mui/typography';
import components from 'src/mui/components';
import { useAppSelector } from 'src/store';
import { darkModePalette, lightModePalette } from 'src/mui/palette';
import { useSettingsQuery } from 'src/store/api/settingsApi';
import LottieFullScreenSpinner from 'src/core/lottie-logo-spinner/LottieFullScreenSpinner';

export default function CustomThemeProvider({ children }: { children: ReactNode }) {
  const { data } = useSettingsQuery();
  const { paletteMode } = useAppSelector((state) => state.themeSettings);

  const [effectivePaletteMode, setEffectivePaletteMode] = useState<PaletteMode>(
    paletteMode === 'auto' ? 'dark' : paletteMode
  );

  useEffect(() => {
    if (paletteMode === 'auto') {
      const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');

      setEffectivePaletteMode(mediaQuery.matches ? 'dark' : 'light');

      const handleChange = (event: MediaQueryListEvent) => {
        setEffectivePaletteMode(event.matches ? 'dark' : 'light');
      };

      mediaQuery.addEventListener('change', handleChange);

      return () => {
        mediaQuery.removeEventListener('change', handleChange);
      };
    }
    setEffectivePaletteMode(paletteMode);
    return undefined;
  }, [paletteMode]);

  const primaryColor = data?.primaryColor ? { primary: { main: data.primaryColor } } : {};

  const palette = effectivePaletteMode === 'dark' ? darkModePalette : lightModePalette;

  const theme = createTheme({
    palette: {
      mode: effectivePaletteMode,
      ...primaryColor,
      ...palette,
    },
    typography,
    components,
    shape: {
      borderRadius: data?.borderRadius,
    },
  });

  const withResponsiveFontSizesTheme = responsiveFontSizes(theme);

  return (
    <ThemeProvider theme={withResponsiveFontSizesTheme}>
      <CssBaseline />
      <LottieFullScreenSpinner />
      {children}
    </ThemeProvider>
  );
}
