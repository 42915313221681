import React from 'react';
import { getDescendants, RenderParams } from '@minoru/react-dnd-treeview';
import { IconButton, ListItemButton, ListItemIcon, ListItemText, SvgIcon } from '@mui/material';
import { WorkbaseArrowDownIcon, WorkbaseArrowUpIcon } from 'src/assets/icons/workbaseIcons';
import SvgIconOrEmoji from 'src/ui-components/icon/SvgIconOrEmoji';
import { TreeNodeModel } from './types';

const TREE_X_OFFSET = 12;

interface Props {
  node: TreeNodeModel;
  treeData: TreeNodeModel[];
  onToggleOpen: (id: TreeNodeModel['id']) => void;
  renderParams: RenderParams;
  isDragging: boolean;
}

function Node({ node, onToggleOpen, treeData, renderParams, isDragging }: Props) {
  const indent = renderParams.depth * TREE_X_OFFSET;

  const handleToggle = (e: React.MouseEvent) => {
    e.stopPropagation();
    onToggleOpen(node.id);
  };

  const hasDescendants = getDescendants(treeData, node.id).length > 0;

  return (
    <ListItemButton
      style={{ marginInlineStart: indent }}
      className={
        renderParams.isDropTarget && !renderParams.isDragging && node.droppable && isDragging
          ? 'dropTarget'
          : ''
      }
      dense
      data-cy={node.id}
      selected={node.data?.active}
      onClick={node.data?.onClick}
      disableRipple
    >
      <ListItemIcon>
        <SvgIconOrEmoji content={node.data?.icon as string} fontSize="small" />
      </ListItemIcon>
      <ListItemText
        primary={node.text}
        primaryTypographyProps={{ noWrap: true, fontWeight: 'medium' }}
      />
      {hasDescendants && (
        <IconButton onClick={handleToggle} size="small" data-cy={`${node.id}.nodeExpandButton`}>
          <SvgIcon
            component={renderParams.isOpen ? WorkbaseArrowUpIcon : WorkbaseArrowDownIcon}
            fontSize="inherit"
          />
        </IconButton>
      )}
    </ListItemButton>
  );
}

export default Node;
