const lightModePalette = {
  background: {
    default: '#f6f6f6',
  },
  text: {
    primary: 'rgba(0, 0, 0, 0.8)',
  },
  action: {
    hoverOpacity: 0.06,
    selectedOpacity: 0.1,
    focusOpacity: 0.14,
    activatedOpacity: 0.12,
    active: 'rgba(0, 0, 0, 0.8)',
    hover: 'rgba(0, 0, 0, 0.06)',
    selected: 'rgba(0, 0, 0, 0.1)',
    focus: 'rgba(0, 0, 0, 0.14)',
  },
  divider: 'rgba(0, 0, 0, 0.1)',
};

export default lightModePalette;
