import { Stack } from '@mui/material';
import { ChangeEvent, ReactNode, useState } from 'react';
import { WorkbaseAddSquareIcon } from 'src/assets/icons/workbaseIcons';
import { useTranslation } from 'react-i18next';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import Image from 'src/ui-components/image';
import { ImageProps } from 'src/ui-components/image/Image';
import ImageEditorWithPreview from './ImageEditorWithPreview';
import ImageEditorWithoutPreview from './ImageEditorWithoutPreview';
import Empty from '../empty/Empty';

export interface EditableImageUploadProps {
  src?: string | null;
  placeholder?: ReactNode;
  altText?: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => Promise<unknown>;
  onClear: () => Promise<unknown>;
  dataCy?: string;
  gutters?: boolean;
  bgcolor?: string;
  fit?: ImageProps['fit'];
}

export default function EditableImageUpload({
  src,
  altText = '',
  onChange,
  placeholder,
  onClear,
  dataCy,
  gutters = false,
  bgcolor = 'background.default',
  fit = 'cover',
}: EditableImageUploadProps) {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const defaultImage = placeholder || (
    <Empty
      svgIconProps={{ fontSize: 'small' }}
      icon={WorkbaseAddSquareIcon}
      description={t('Upload')}
      typographyProps={{
        variant: 'caption',
      }}
    />
  );

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    await onChange(e);
    setLoading(false);
  };

  const handleClear = async () => {
    setLoading(true);
    await onClear();
    setLoading(false);
  };
  const content = {
    loading: <LottieLogoSpinner sx={{ width: 50, height: 50 }} />,
    withImage: (
      <>
        <Image
          data-cy={dataCy && `${dataCy}-image`}
          src={src as string}
          alt={altText}
          height="100%"
          width="100%"
          fit={fit}
        />
        <ImageEditorWithPreview
          onImageChange={handleChange}
          onImageRemove={handleClear}
          dataCy={dataCy}
        />
      </>
    ),
    withoutImage: (
      <>
        {defaultImage}
        <ImageEditorWithoutPreview onImageChange={handleChange} dataCy={dataCy} />
      </>
    ),
  };

  const renderContent = () => {
    if (loading) {
      return content.loading;
    }
    return src ? content.withImage : content.withoutImage;
  };

  return (
    <Stack
      borderRadius={1}
      justifyContent="center"
      alignItems="center"
      bgcolor={bgcolor}
      width="100%"
      p={gutters ? 1 : 0}
      height="100%"
      position="relative"
      overflow="hidden"
    >
      {renderContent()}
    </Stack>
  );
}
