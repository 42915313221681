import { useTranslation } from 'react-i18next';
import {
  WorkbaseEmailBadgeIcon,
  WorkbaseDeleteSquareIcon,
  WorkbaseEmailIcon,
  WorkbaseLiveChatIcon,
  WorkbaseResetIcon,
  WorkbaseTelefonWaveIcon,
  WorkbaseAccountLockIcon,
} from 'src/assets/icons/workbaseIcons';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import axiosInstance from 'src/axios/axios';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { employeesApi } from 'src/store/api/employees/employeesApi';
import { Stack, SvgIcon, ToggleButton, Tooltip } from '@mui/material';
import { TopBarWithIcons } from 'src/ui-components/layout/top-bar';
import { useConfirm } from 'material-ui-confirm';
import { useCurrentUserQuery } from 'src/store/api/currentUserApi';
import { Employee } from '../../model';
import {
  resetPasswordApi,
  usePostRequestResendInvitationMutation,
  usePostRequestResetPasswordMutation,
  useResetPasswordCooldownQuery,
  useResendInvitationCooldownQuery,
  resendInvitationApi,
} from '../../controller';

interface Props {
  employee: Employee;
  employeesRefetch: () => void;
}

const openLink = (url: string) => () => {
  const aTag = document.createElement('a');
  aTag.setAttribute('href', url);
  aTag.setAttribute('target', '_blank');
  aTag.click();
};

export default function EmployeeDetailsTopBar({ employee, employeesRefetch }: Props) {
  const { t } = useTranslation();
  const param = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { data, isSuccess } = useResetPasswordCooldownQuery(param.id as string, {
    refetchOnMountOrArgChange: true,
  });
  const { data: currentUserData } = useCurrentUserQuery();
  const { data: dataResendInvitation, isSuccess: isSuccessResend } =
    useResendInvitationCooldownQuery(param.id as string, {
      refetchOnMountOrArgChange: true,
      skip: employee.status === 'active',
    });

  const showConfirmDialog = useConfirm();

  const [requestResetPassword] = usePostRequestResetPasswordMutation();
  const [requestResendInvitation] = usePostRequestResendInvitationMutation();

  const shownName =
    employee.firstName && employee.lastName
      ? `${employee.firstName} ${employee.lastName}`
      : employee.email;

  useEffect(() => {
    if (isSuccess) {
      const intervalSpan = 1000;
      const interval = setInterval(() => {
        dispatch(
          resetPasswordApi.util.updateQueryData(
            'resetPasswordCooldown',
            employee.id,

            (draft) =>
              draft && {
                ...draft,
                cooldown:
                  draft.cooldown > 0
                    ? draft.cooldown - intervalSpan
                    : draft.cooldown - intervalSpan,
              }
          )
        );
      }, intervalSpan);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatch, employee.id, isSuccess]);

  useEffect(() => {
    if (isSuccessResend) {
      const intervalSpan = 1000;
      const interval = setInterval(() => {
        dispatch(
          resendInvitationApi.util.updateQueryData(
            'resendInvitationCooldown',
            employee.id,

            (draft) =>
              draft && {
                ...draft,
                cooldown:
                  draft.cooldown > 0
                    ? draft.cooldown - intervalSpan
                    : draft.cooldown - intervalSpan,
              }
          )
        );
      }, intervalSpan);
      return () => clearInterval(interval);
    }
    return undefined;
  }, [dispatch, employee.id, isSuccessResend]);

  const statusDependentItem =
    employee.status === 'active'
      ? [
          {
            key: 'reset-password',
            tooltip: t('employeePage.employeeDetails.actionButtons.resetPassword'),
            icon: WorkbaseResetIcon,
            onClick: () => {
              if (data && data.cooldown > 0) {
                const time = Math.ceil((data.cooldown ?? 0) / 60000);

                const timeUnit =
                  time > 1
                    ? t('employeePage.employeeDetails.resetPassword.minutes')
                    : t('employeePage.employeeDetails.resetPassword.minute');

                showNotification(
                  t('employeePage.employeeDetails.resetPassword.waitNotification', {
                    time,
                    timeUnit,
                  }),
                  'error'
                );
              } else {
                requestResetPassword(employee.id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(
                      t('employeePage.employeeDetails.resetPassword.errorNotification', {
                        name: `${employee.firstName} ${employee.lastName}`,
                      }),
                      'error'
                    );
                  } else {
                    showNotification(
                      t('employeePage.employeeDetails.resetPassword.successNotification', {
                        name: `${employee.firstName} ${employee.lastName}`,
                      }),
                      'success'
                    );
                  }
                });
              }
            },
          },
        ]
      : [
          {
            key: 'resend-invitation',
            tooltip: t('employeePage.employeeDetails.actionButtons.resendInvitation'),
            icon: WorkbaseEmailBadgeIcon,
            onClick: () => {
              if (dataResendInvitation && dataResendInvitation.cooldown > 0) {
                const time = Math.ceil((dataResendInvitation.cooldown ?? 0) / 60000);

                const timeUnit =
                  time > 1
                    ? t('employeePage.employeeDetails.resendInvitation.minutes')
                    : t('employeePage.employeeDetails.resendInvitation.minute');

                showNotification(
                  t('employeePage.employeeDetails.resendInvitation.waitNotification', {
                    time,
                    timeUnit,
                  }),
                  'error'
                );
              } else {
                requestResendInvitation(employee.id).then((resp: any) => {
                  if (resp.error) {
                    showNotification(
                      t('employeePage.employeeDetails.resendInvitation.errorNotification'),
                      'error'
                    );
                  } else {
                    showNotification(
                      t('employeePage.employeeDetails.resendInvitation.successNotification'),
                      'success'
                    );
                  }
                });
              }
            },
          },
        ];

  const toolItems = [
    {
      key: 'call',
      tooltip: t('employeePage.employeeDetails.actionButtons.call'),
      icon: WorkbaseTelefonWaveIcon,
      onClick: openLink(`tel:+${employee.phoneNumber}`),
      disabled: !employee.phoneNumber.length,
    },
    {
      key: 'email',
      tooltip: t('employeePage.employeeDetails.actionButtons.email'),
      icon: WorkbaseEmailIcon,
      onClick: openLink(`mailto:${employee.email}`),
      disabled: !employee.email.length,
    },
    {
      key: 'whats-app',
      tooltip: t('employeePage.employeeDetails.actionButtons.whatsApp'),
      icon: WorkbaseLiveChatIcon,
      onClick: openLink(`https://wa.me/${employee.phoneNumber}`),
      disabled: !employee.phoneNumber.length,
    },
    ...statusDependentItem,
  ];

  const rightContent = (
    <>
      {employee.status === 'active' &&
        currentUserData?.isOwner &&
        employee.id !== currentUserData.id && (
          <Tooltip title={t('makeOwner')}>
            <ToggleButton
              value="make-owner"
              size="small"
              selected
              onClick={() => {
                showConfirmDialog({
                  title: t('editor.topBar.customQuestionsOnSettingIcon.makeOwner', {
                    name: shownName,
                  }),
                  description: t('employeePage.employeeDetails.makeOwner.question', {
                    name: shownName,
                  }),
                  confirmationText: t('confirm'),
                  cancellationText: t('cancel'),
                  cancellationButtonProps: {
                    color: 'inherit',
                  },
                }).then(() => {
                  axiosInstance
                    .post(`companies/users/${param.id}/transfer-ownership`)
                    .then(() => {
                      showNotification(t('notifications.makeOwnerSuccess', { name: shownName }));
                      employeesRefetch();
                    })
                    .catch(() => {
                      showNotification(
                        t('notifications.makeOwnerError', { name: shownName }),
                        'error'
                      );
                    });
                });
              }}
            >
              <SvgIcon component={WorkbaseAccountLockIcon} fontSize="small" />
            </ToggleButton>
          </Tooltip>
        )}
      <Tooltip
        title={employee.isOwner ? t('employeePage.employeeDetails.deleteTooltip') : t('delete')}
      >
        <Stack>
          <ToggleButton
            value="delete"
            selected
            size="small"
            onClick={() => {
              showConfirmDialog({
                title: t('areYouSure'),
                description: t('employeePage.employeeDetails.deleteEmployee.question', {
                  name: shownName,
                }),
                confirmationButtonProps: {
                  color: 'error',
                },
                confirmationText: t('delete'),
                cancellationText: t('cancel'),
                cancellationButtonProps: {
                  children: t('cancel'),
                  color: 'inherit',
                },
              }).then(() => {
                axiosInstance
                  .delete(`companies/users/${param.id}`)
                  .then(() => {
                    showNotification(t('notifications.deleteSuccess'));
                    dispatch(
                      employeesApi.endpoints.employees.initiate(undefined, { forceRefetch: true })
                    );
                    navigate('/team/');
                  })
                  .catch(() => {
                    showNotification(t('notifications.deleteError'), 'error');
                  });
              });
            }}
            disabled={employee.isOwner}
          >
            <SvgIcon component={WorkbaseDeleteSquareIcon} fontSize="small" />
          </ToggleButton>
        </Stack>
      </Tooltip>
    </>
  );

  return <TopBarWithIcons icons={toolItems} rightContent={rightContent} />;
}
