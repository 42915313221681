import { Box, Grid2, Stack } from '@mui/material';
import { useState, useRef } from 'react';
import { useAppDispatch, useAppSelector } from 'src/store';
import useScrollOnDrag from 'src/lib/hooks/use-scroll-on-drag';
import LottieLogoSpinner from 'src/core/lottie-logo-spinner';
import DepartmentsList from './departments/DepartmentsList';
import { filterDepartmentsBySearchVal } from './departments-search/FilterDepartmentsBySearchVal';
import { addDepartment } from '../controller/departments.slice';
import SaveCancelButtons from './actions/saveCancelButtons/SaveCancelButtons';
import PermissionsSideBar from './permissions-sidebar/PermissionsSideBar';
import { useDepartmentsQuery } from '../controller/departmentsApi';
import DepartmentsHeader from './DepartmentsHeader';

export default function Departments() {
  const [searchVal, setSearchVal] = useState<string>('');
  const inputDepartmentRef = useRef<HTMLInputElement | null>(null);
  const departmentScrollRef = useScrollOnDrag();
  const { isLoading } = useDepartmentsQuery();
  const dispatch = useAppDispatch();
  const { departmentsDraftState } = useAppSelector((state) => state.departments);

  const filteredDepartments = filterDepartmentsBySearchVal(departmentsDraftState, searchVal);

  const handleAddNewDepartment = () => {
    dispatch(addDepartment());
    requestAnimationFrame(() => {
      if (inputDepartmentRef.current) {
        inputDepartmentRef.current.scrollIntoView({
          behavior: 'instant',
          block: 'start',
        });
        inputDepartmentRef.current.focus();
      }
    });
  };

  if (isLoading) {
    return (
      <Box height="100%" width="100%" display="flex" alignItems="center" justifyContent="center">
        <LottieLogoSpinner />
      </Box>
    );
  }

  return (
    <>
      <Stack width="100%" height="100%" gap={2} pb={2}>
        <DepartmentsHeader
          handleAddNewDepartment={handleAddNewDepartment}
          setSearchVal={setSearchVal}
          filteredDepartments={filteredDepartments}
          searchVal={searchVal}
        />
        <Grid2 container spacing={2} minHeight={0} direction="row">
          <Grid2 size={8} maxHeight="100%" overflow="auto" pb={0.25}>
            {filteredDepartments && (
              <DepartmentsList
                scrollRef={departmentScrollRef}
                departments={filteredDepartments}
                inputDepartmentRef={inputDepartmentRef}
                onAddDepartment={handleAddNewDepartment}
              />
            )}
          </Grid2>
          <Grid2 size={4} maxHeight="100%">
            <PermissionsSideBar />
          </Grid2>
        </Grid2>
      </Stack>
      <SaveCancelButtons />
    </>
  );
}
