import { useEffect, useRef } from 'react';
import { useAppSelector } from 'src/store';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { connectWebSocket, disconnectWebSocket } from './websocket-controller';

export const useWebSocket = (threadId: string | null, dispatch: React.Dispatch<any>) => {
  const ws = useRef<WebSocket | null>(null);
  const authToken = useAppSelector(({ auth }) => auth.token);
  const navigate = useNavigate();
  const rtkDispatch = useDispatch();

  useEffect(() => {
    if (ws.current) {
      ws.current.onclose = () => {
        ws.current = connectWebSocket(
          dispatch,
          authToken as string,
          threadId as string,
          navigate,
          rtkDispatch
        );
      };
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ws.current, threadId]);

  useEffect(() => {
    if (!ws.current || (ws.current.readyState === WebSocket.CLOSED && threadId)) {
      ws.current = connectWebSocket(
        dispatch,
        authToken as string,
        threadId as string,
        navigate,
        rtkDispatch
      );
    }

    return () => {
      if (ws.current && ws.current.readyState !== WebSocket.CONNECTING) {
        disconnectWebSocket(ws.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, threadId]);

  return ws.current;
};
