import { useTranslation } from 'react-i18next';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/store';
import { Permission } from 'src/features/company-settings/departments/model/types';
import { addPermissionToRoles } from 'src/features/company-settings/departments/controller/departments.slice';
import { PermissionCode } from 'src/store/api/userPermissionsApi';
import BaseSinglePermission, { BaseSinglePermissionProps } from './BaseSinglePermission';

interface SettingSinglePermissionProps extends BaseSinglePermissionProps {
  code: PermissionCode;
  permissions: any;
  hasManagingPermission: boolean;
  tooltipWithoutManagingPermission: string;
}

export default function SettingSinglePermission(props: SettingSinglePermissionProps) {
  const { code, permissions, hasManagingPermission, tooltipWithoutManagingPermission } = props;
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const checkedRolesIds = useAppSelector((state) => state.departments.checkedRoles);
  const deps = useAppSelector((state) => state.departments.departmentsDraftState);
  const tooltipText = t(
    'companySettings.sidebar.departments.permissions.permissionTooltip.manageDepartments'
  );

  const checkedRoles = deps
    .flatMap((dep) => dep.roles)
    .filter((role) => checkedRolesIds.includes(role.id));

  const managePermissionId = permissions.find(
    ({ code: employeeCode }: any) => employeeCode === 'MANAGE_PERMISSIONS'
  )?.id;

  const isCheckedManagePermissions = checkedRoles.some((role) =>
    role.permissionIds.includes(managePermissionId)
  );

  let disabled = false;

  if (code === 'MANAGE_DEPARTMENTS' && isCheckedManagePermissions) {
    disabled = true;
  }

  return (
    <Tooltip placement="top" title={!hasManagingPermission && tooltipWithoutManagingPermission}>
      <BaseSinglePermission
        {...props}
        tooltipText={tooltipText}
        disabled={disabled}
        onChangeCallback={(isChecked, isIndeterminate) => {
          if (!isChecked || (isIndeterminate && isChecked)) {
            if (code === 'MANAGE_PERMISSIONS') {
              const manageDepartmentsPermission = permissions.find(
                (per: Permission) => per.code === 'MANAGE_DEPARTMENTS'
              );
              // eslint-disable-next-line max-depth
              if (manageDepartmentsPermission) {
                dispatch(
                  addPermissionToRoles({
                    permissionToAdd: manageDepartmentsPermission.id,
                    rolesIds: checkedRoles.map((checkedRole) => checkedRole.id),
                  })
                );
              }
            }
          }
        }}
      />
    </Tooltip>
  );
}
