import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  Stack,
  SvgIcon,
  TextField,
} from '@mui/material';
import { useState } from 'react';
import {
  WorkbaseCheckmarkBigFilledIcon,
  WorkbaseCopyDuplicateIcon,
} from 'src/assets/icons/workbaseIcons';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { ApiKey, ApiKeyPostData } from 'src/store/api/apiKeysApi';
import { useTranslation } from 'react-i18next';
import { DatePicker } from '@mui/x-date-pickers';
import { shouldDisableDate } from '../../controller/helpers';
import { useApiKeyForm } from '../../controller/useApiKeyForm';

export default function ApiKeyGenerationDialog({
  open,
  onClose,
  onSubmit,
}: {
  open: boolean;
  onClose: () => void;
  onSubmit: (data: ApiKeyPostData) => Promise<{ data: ApiKey; status: number }>;
}) {
  const { t } = useTranslation();
  const [apiKeyData, setApiKeyData] = useState<ApiKey | null>(null);

  const {
    name,
    nameError,
    expiryDate,
    dateError,
    handleNameChange,
    handleNameBlur,
    handleDateError,
    handleDateChange,
    reset,
  } = useApiKeyForm();

  const handleGenerateApiKey = () => {
    onSubmit({ name, expiredAt: expiryDate?.toISOString() } as ApiKeyPostData).then(
      (res: { data: ApiKey }) => {
        setApiKeyData(res.data);
        reset();
      }
    );
  };

  const handleCopyApiKey = () => {
    navigator.clipboard.writeText(apiKeyData?.apiKey || '');
    showNotification(t('companySettings.apiKeys.copiedToClipboard'), 'success');
  };

  const handleCloseDialog = () => {
    onClose();
    setApiKeyData(null);
    reset();
  };

  const formDataValid = !dateError && !nameError && !!name.trim();
  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      data-cy="generate-api-key-dialog"
      open={open}
      onClose={handleCloseDialog}
    >
      <DialogTitle>
        <Stack direction="row" gap={1} alignItems="center">
          {apiKeyData && (
            <Stack color="success.main">
              <SvgIcon component={WorkbaseCheckmarkBigFilledIcon} htmlColor="inherit" />
            </Stack>
          )}
          {apiKeyData
            ? t('companySettings.apiKeys.apiKeyGenerated')
            : t('companySettings.apiKeys.generateApiKey')}
        </Stack>
      </DialogTitle>
      <DialogContent>
        {apiKeyData ? (
          <Stack spacing={2} mt={1} width="100">
            <TextField
              data-cy="api-key-textfield"
              label={t('companySettings.apiKeys.label')}
              value={apiKeyData?.name}
              size="small"
              fullWidth
              slotProps={{
                input: {
                  readOnly: true,
                },
              }}
            />
            <TextField
              data-cy="api-key-inner-header-textfield"
              label={t('companySettings.apiKeys.innerHeader')}
              value={apiKeyData?.apiKey}
              fullWidth
              size="small"
              slotProps={{
                input: {
                  readOnly: true,
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton onClick={handleCopyApiKey}>
                        <SvgIcon component={WorkbaseCopyDuplicateIcon} />
                      </IconButton>
                    </InputAdornment>
                  ),
                },
              }}
            />
            <Alert severity="warning">{t('companySettings.apiKeys.noteWarning')}</Alert>
          </Stack>
        ) : (
          <Stack pt={1} spacing={2} alignItems="flex-start">
            <FormControl fullWidth error={!!nameError}>
              <TextField
                data-cy="api-keys-textfield"
                label={t('companySettings.apiKeys.label')}
                value={name}
                size="small"
                onChange={handleNameChange}
                onBlur={handleNameBlur}
                placeholder={t('companySettings.apiKeys.namePlaceholder')}
                fullWidth
                error={!!nameError}
              />
              {nameError && <FormHelperText>{t(nameError)}</FormHelperText>}
            </FormControl>
            <FormControl fullWidth>
              <DatePicker
                label={t('companySettings.apiKeys.expiryDate')}
                onError={handleDateError}
                value={expiryDate}
                shouldDisableDate={shouldDisableDate}
                disablePast
                onChange={handleDateChange}
                slotProps={{
                  textField: {
                    size: 'small',
                  },
                  popper: {
                    placement: 'bottom-end',
                  },
                }}
              />
              <FormHelperText error={!!dateError}>
                {dateError
                  ? t(`companySettings.apiKeys.${dateError}`)
                  : t('companySettings.apiKeys.expiryDateHelperText')}
              </FormHelperText>
            </FormControl>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="inherit" onClick={handleCloseDialog} fullWidth>
          {apiKeyData ? t('close') : t('cancel')}
        </Button>
        <Button
          onClick={apiKeyData ? handleCopyApiKey : handleGenerateApiKey}
          startIcon={
            apiKeyData ? (
              <SvgIcon component={WorkbaseCopyDuplicateIcon} fontSize="small" />
            ) : undefined
          }
          disabled={!apiKeyData && !formDataValid}
          fullWidth
        >
          {apiKeyData
            ? t('companySettings.apiKeys.copyApiKey')
            : t('companySettings.apiKeys.generateApiKey')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
