import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from 'src/store/api/api';

interface BaseNewsfeedItem {
  progress: number;
  name: string;
  imageUrl: string | null;
  defaultImage: any;
}

export type NewsfeedItem = NewsfeedTrainingItem | NewsfeedLessonItem;

export interface NewsfeedTrainingItem extends BaseNewsfeedItem {
  type: 'training';
  id: string;
}

export interface NewsfeedLessonItem extends BaseNewsfeedItem {
  type: 'trainingLesson';
  id: NewsfeedLessonItemId;
}

export interface NewsfeedLessonItemId {
  lesson: string;
  training: string;
  module: string;
  section: string;
}

export type NewsfeedItemType = 'training' | 'trainingLesson';

export const newsfeedApi = createApi({
  reducerPath: 'newsfeedApi',
  baseQuery,
  endpoints: (builder) => ({
    // queries
    newsfeed: builder.query<NewsfeedItem[], void>({
      query: () => `/dashboard/newsfeed`,
      keepUnusedDataFor: 0,
    }),
  }),
});

export const { useNewsfeedQuery } = newsfeedApi;
