import { Stack, Typography } from '@mui/material';
import SvgIconOrEmoji from '../icon/SvgIconOrEmoji';

interface Props {
  icon: string;
  text: string;
}

export default function IconText({ icon, text }: Props) {
  return (
    <Stack direction="row" gap={2} alignItems="center" justifyContent="center" width="100%">
      <SvgIconOrEmoji content={icon} fontSize="large" />
      <Typography variant="h4" fontWeight="medium">
        {text}
      </Typography>
    </Stack>
  );
}
