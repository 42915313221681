import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useBoolean } from 'src/lib/hooks/use-boolean';
import { ResourceType } from 'src/store/api/searchResourceApi';
import { showNotification } from 'src/ui-components/custom/notifications-snackbar';
import { ShareLevel } from 'src/lib/types/share-level';
import { bindDialog, PopupState } from 'material-ui-popup-state/hooks';
import SvgIconByName from 'src/ui-components/icon/SvgIconByName';
import { useState } from 'react';
import SearchResourceTextField from './search-text-field/SearchResourceTextField';
import GeneralAccess from './GeneralAccess';
import AccessControlListGroup from './access-list/AccessControlListGroup';
import { TabValue } from './access-list/TrainingAccessControlList';

interface Props {
  name: string;
  resourceId: string;
  resourceType: ResourceType;
  dialogState: PopupState;
  shareLevel: ShareLevel;
}

const copyToClipboard = (id: string, type: string) => {
  const url = window.location.href;
  let modifiedUrl;
  let modifiedType = type;
  if (url.includes('documents')) {
    modifiedType = modifiedType.replace(/^embeddedApp$/, 'app');
    modifiedUrl = `${document.location.host}/${modifiedType}/${id}`;
  } else {
    modifiedUrl = url.replace(/\/edit\b/, '');
  }

  if (modifiedUrl) {
    navigator.clipboard.writeText(modifiedUrl).then(() => {
      showNotification(t('linkCopiedToClipboard'), 'success');
    });
  }
};

export default function ShareModalDialog({
  name,
  resourceId,
  resourceType,
  dialogState,
  shareLevel,
}: Props) {
  const dialogContentVisibility = useBoolean(true);
  const [activeTabButton, setActiveTabButton] = useState<TabValue>();

  return (
    <Dialog {...bindDialog(dialogState)}>
      <DialogTitle>{`${t('share')} "${name}"`}</DialogTitle>
      <DialogContent>
        <Stack gap={2}>
          <SearchResourceTextField
            resourceId={resourceId}
            resourceType={resourceType}
            activeTabButton={activeTabButton}
          />
          <Stack gap={1}>
            <Typography variant="body1" fontWeight="bold">
              {t('permissionsShare.subtitle')}
            </Typography>
            <AccessControlListGroup
              activeTabButton={activeTabButton}
              setActiveTabButton={setActiveTabButton}
              resourceType={resourceType}
              resourceId={resourceId}
              handleModalHide={() => dialogContentVisibility.onFalse()}
              handleModalShow={() => dialogContentVisibility.onTrue()}
              currentShareLevel={shareLevel}
            />
          </Stack>
          <Stack gap={1}>
            <Typography variant="body1" fontWeight="bold">
              {t('permissionsShare.subtitle')}
            </Typography>
            <GeneralAccess resourceId={resourceId} resourceType={resourceType} />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button
          color="inherit"
          onClick={() => copyToClipboard(resourceId, resourceType)}
          startIcon={<SvgIconByName iconName="WorkbaseCopyDuplicateIcon" />}
        >
          {t('permissionsShare.copyLinkButton')}
        </Button>
        <Box flexGrow={2}>
          <Button fullWidth onClick={dialogState.close}>
            {t('permissionsShare.doneButton')}
          </Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
}
