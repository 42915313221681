import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Stack,
  TextField,
} from '@mui/material';
import { ApiKeyPostData } from 'src/store/api/apiKeysApi';
import { useTranslation } from 'react-i18next';
import { useApiKeyForm } from '../../controller/useApiKeyForm';

export type ApiKeyEditData = ApiKeyPostData & { id: string };

export default function ApiKeyEditDialog({
  apiKeyData,
  onClose,
  onSubmit,
}: {
  apiKeyData: ApiKeyEditData;
  onClose: () => void;
  onSubmit: (newName: string) => void;
}) {
  const { name, nameError, handleNameChange, handleNameBlur } = useApiKeyForm(apiKeyData.name);

  const { t } = useTranslation();
  const handleCloseDialog = () => {
    onClose();
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      data-cy="edit-api-key"
      open={!!apiKeyData}
      onClose={handleCloseDialog}
    >
      <DialogTitle>{t('companySettings.apiKeys.editApiKey')}</DialogTitle>
      <DialogContent>
        <Stack direction="column" mt={1}>
          <Stack spacing={2} alignItems="flex-start">
            <FormControl fullWidth error={!!nameError}>
              <TextField
                data-cy="edit-api-key-textfield"
                size="small"
                fullWidth
                label={t('companySettings.apiKeys.table.columnNames.keyName')}
                name="name"
                error={!!nameError}
                onBlur={handleNameBlur}
                value={name}
                onChange={(e: any) => {
                  handleNameChange(e);
                }}
                placeholder={t('companySettings.apiKeys.namePlaceholder')}
              />
              {!!nameError && <FormHelperText>{t(nameError)}</FormHelperText>}
            </FormControl>
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseDialog} color="inherit">
          {t('cancel')}
        </Button>
        <Button onClick={() => onSubmit(name)} disabled={!!nameError}>
          {t('companySettings.apiKeys.saveChanges')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
